/*  React */
import React, { useContext, useEffect, useState } from 'react'
import { Route, Switch, useHistory, useLocation } from 'react-router-dom'

/* Material UI */
import { makeStyles, CssBaseline, Grid } from '@material-ui/core'

/* Pages */
import {
    AsignacionPermisosManagement,
    AsignacionSalasManagement,
    SalasManagement,
    UsersManagement,
} from './administracion'
import { Calidad, InviernoVerano, Kpi, KpiEditor, Liquidaciones, Productividad, ResumenPrecios, VistaGeneral } from './produccion'
import { Forraje } from './forraje'
import { RecoleccionManagement } from './recoleccion'
import { SituacionSanitaria } from './situacionSanitaria'
import { ClimaEmbed, Profile, Welcome} from './'

/* Context */
import {
    AdminFilterContextProvider,
    AsignacionPermisosContextProvider,
    AsignacionSalasContextProvider,
    PermissionPresetsContextProvider,
    SalasContextProvider,
    UsuariosContextProvider,
} from '../contexts/administracion'
import {
    CalidadContextProvider,
    DataContextProvider,
    InviernoVeranoContextProvider,
    ResumenPreciosContextProvider,
    ProductividadContextProvider,
    VistaGeneralContextProvider,
} from '../contexts/produccion'
import { ForrajeContextProvider } from '../contexts/forraje'
import { RecoleccionContextProvider } from '../contexts/recoleccion'
import { AuthContext } from '../contexts'

const drawerWidth = 400 // 480

const useStyles = makeStyles(theme => ({
    root: {
        // display: 'flex'
    },
    toolbar: {
        [theme.breakpoints.down('md')]: theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: '25px 16px 0px 16px',
        [theme.breakpoints.up('lg')]: {
            padding: '50px 96px 0px 96px',
            marginLeft: drawerWidth,
        },
    },
    pageTitle: {
        color: theme.titleColor,
        fontSize: 32,
        [theme.breakpoints.up('lg')]: {
            fontSize: 64,
        },
        fontWeight: 'bold',
    },
    forecastContainer: {
        width: 350,
        height: 75,
        position: 'relative',
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
}))

const MainContent = () => {
    const { permissions, isAuthenticated, userInfo } = useContext(AuthContext)
    const classes = useStyles()
    const route = useLocation()

    const history = useHistory()

    const ConditionalWrapper = ({ condition, wrapper, children }) => {
        return condition ? wrapper(children) : children
    }

    const handleTitle = route => {
        if (route?.pathname) {
            switch (route.pathname) {
                case '/vista-general':
                    return 'Vista General'
                case '/calidad':
                    return 'Calidad'
                case '/productividad':
                    return 'Productividad'
                case '/invierno-verano':
                    return 'Relación Invierno-Verano'
                case '/kpi':
                    return 'KPI (Modo prueba)'
                case '/kpiEditor':
                    return 'KPI modo editor (Modo prueba)'
                case '/resumen-precios':
                    return 'Resumen precios'
                case '/liquidaciones':
                    return 'Liquidaciones'
                case '/situacion-sanitaria':
                    return 'Situación sanitaria'
                case '/forraje':
                    return 'Forraje'
                case '/recoleccion':
                    return 'Recolección'
                case '/clima':
                    return 'Clima'
                case '/perfil':
                    return 'Perfil'
                case '/usuarios':
                    return 'Usuarios'
                case '/salas':
                    return 'Salas de ordeñas'
                case '/horarios':
                    return 'Horarios de ordeñas'
                case '/asignacion-salas':
                    return 'Asignación de salas'
                case '/asignacion-permisos':
                    return 'Asignación de permisos'
                case '/asignacion-horarios':
                    return 'Asignación de horarios'
                default:
                    return ''
            }
        } else {
            return 'Bienvenido/a'
        }
    }

    const [classNameWelcome, setClassNameWelcome] = useState('root-other')

    useEffect(() => {
        history.listen((data) => {
            if (data.pathname === '/' && isAuthenticated) {
                setClassNameWelcome((userInfo.email==='pia.mckenzie@soprole.cl' || userInfo.email==='gonzalo.orellana@kauel.com') ? 'root-welcome' : 'root-welcome-2')
            } else {
                setClassNameWelcome('root-other')
            }
        })
        if (history.location.pathname === '/' && isAuthenticated) {
            setClassNameWelcome((userInfo.email==='pia.mckenzie@soprole.cl' || userInfo.email==='gonzalo.orellana@kauel.com') ? 'root-welcome' : 'root-welcome-2')
        } else {
            setClassNameWelcome('root-other')
        }
    }, [history])

    return (
        <>
            {
                <div className={classes.root}>
                    <CssBaseline />
                    <main className={`${classes.content} ${classNameWelcome}`}>
                        <div className={classes.toolbar} />
                        <Grid container>
                            <Grid item xs>
                                {route.pathname !== '/' && (
                                    <span className={classes.pageTitle} style={{ marginRight: 10 }}>
                                        {handleTitle(route)}
                                    </span>
                                )}
                            </Grid>
                        </Grid>
                        <DataContextProvider>
                            <AdminFilterContextProvider>
                                <PermissionPresetsContextProvider>
                                    <UsuariosContextProvider condition={permissions.administracion < 1}>
                                        <SalasContextProvider>
                                            <ConditionalWrapper
                                                condition={permissions.administracion > 0}
                                                wrapper={children => (
                                                    <AsignacionSalasContextProvider>
                                                        {children}
                                                    </AsignacionSalasContextProvider>
                                                )}
                                            >
                                                <ConditionalWrapper
                                                    condition={permissions.administracion > 0}
                                                    wrapper={children => (
                                                        <AsignacionPermisosContextProvider>
                                                            {children}
                                                        </AsignacionPermisosContextProvider>
                                                    )}
                                                >
                                                    <RecoleccionContextProvider>
                                                        <Switch>
                                                            <Route exact path={['/']} render={() => <Welcome />} />
                                                            {permissions.produccion.vistaGeneral > 0 && (
                                                                <Route
                                                                    exact
                                                                    path={['/vista-general']}
                                                                    render={() => (
                                                                        <VistaGeneralContextProvider>
                                                                            <VistaGeneral />
                                                                        </VistaGeneralContextProvider>
                                                                    )}
                                                                />
                                                            )}
                                                            {permissions.produccion.calidad > 0 && (
                                                                <Route
                                                                    exact
                                                                    path={['/calidad']}
                                                                    render={() => (
                                                                        <CalidadContextProvider>
                                                                            <Calidad />
                                                                        </CalidadContextProvider>
                                                                    )}
                                                                />
                                                            )}
                                                            <Route
                                                                exact
                                                                path={['/clima']}
                                                                render={() => <ClimaEmbed />}
                                                            />
                                                            <Route
                                                                exact
                                                                path={['/perfil']}
                                                                render={() => <Profile />}
                                                            />
                                                            {permissions.produccion.productividad > 0 && (
                                                                <Route
                                                                    exact
                                                                    path={['/productividad']}
                                                                    render={() => (
                                                                        <ProductividadContextProvider>
                                                                            <Productividad />
                                                                        </ProductividadContextProvider>
                                                                    )}
                                                                />
                                                            )}
                                                            {permissions.produccion.inviernoVerano > 0 && (
                                                                <Route
                                                                    exact
                                                                    path={['/invierno-verano']}
                                                                    render={() => (
                                                                        <InviernoVeranoContextProvider>
                                                                            <InviernoVerano />
                                                                        </InviernoVeranoContextProvider>
                                                                    )}
                                                                />
                                                            )}
                                                            {permissions.produccion.kpi > 0 && (
                                                                <Route
                                                                    exact
                                                                    path={['/kpi']}
                                                                    render={() => (
                                                                        <InviernoVeranoContextProvider>
                                                                            <VistaGeneralContextProvider>
                                                                                <Kpi />
                                                                            </VistaGeneralContextProvider>
                                                                        </InviernoVeranoContextProvider>
                                                                    )}
                                                                />
                                                            )}
                                                            <Route
                                                                exact
                                                                path={['/kpiEditor']}
                                                                render={() => (
                                                                    <InviernoVeranoContextProvider>
                                                                        <KpiEditor />
                                                                    </InviernoVeranoContextProvider>
                                                                )}
                                                            />
                                                            {permissions.facturacion.resumenPrecios > 0 && (
                                                                <Route
                                                                    exact
                                                                    path={['/resumen-precios']}
                                                                    render={() => (
                                                                        <ResumenPreciosContextProvider>
                                                                            <ResumenPrecios />
                                                                        </ResumenPreciosContextProvider>
                                                                    )}
                                                                />
                                                            )}
                                                            {permissions.facturacion.liquidaciones > 0 && (
                                                                <Route
                                                                    exact
                                                                    path={['/liquidaciones']}
                                                                    render={() => <Liquidaciones />}
                                                                />
                                                            )}
                                                            {permissions.situacionSanitaria > 0 && (
                                                                <Route
                                                                    exact
                                                                    path={['/situacion-sanitaria']}
                                                                    render={() => <SituacionSanitaria />}
                                                                />
                                                            )}
                                                            {permissions.forraje > 0 && (
                                                                <Route
                                                                    exact
                                                                    path={['/forraje']}
                                                                    render={() => (
                                                                        <ForrajeContextProvider>
                                                                            <Forraje />
                                                                        </ForrajeContextProvider>
                                                                    )}
                                                                />
                                                            )}
                                                            {permissions.recoleccion > 0 && (
                                                                <Route
                                                                    exact
                                                                    path={['/recoleccion']}
                                                                    render={() => <RecoleccionManagement />}
                                                                />
                                                            )}
                                                            {permissions.administracion > 0 && (
                                                                <Route
                                                                    exact
                                                                    path={['/usuarios']}
                                                                    render={() => <UsersManagement />}
                                                                />
                                                            )}
                                                            {permissions.administracion > 0 && (
                                                                <Route
                                                                    exact
                                                                    path={['/salas']}
                                                                    render={() => <SalasManagement />}
                                                                />
                                                            )}
                                                            {permissions.administracion > 0 && (
                                                                <Route
                                                                    exact
                                                                    path={['/asignacion-salas']}
                                                                    render={() => <AsignacionSalasManagement />}
                                                                />
                                                            )}
                                                            {permissions.administracion > 0 && (
                                                                <Route
                                                                    exact
                                                                    path={['/asignacion-permisos']}
                                                                    render={() => <AsignacionPermisosManagement />}
                                                                />
                                                            )}
                                                        </Switch>
                                                    </RecoleccionContextProvider>
                                                </ConditionalWrapper>
                                            </ConditionalWrapper>
                                        </SalasContextProvider>
                                    </UsuariosContextProvider>
                                </PermissionPresetsContextProvider>
                            </AdminFilterContextProvider>
                        </DataContextProvider>
                    </main>
                </div>
            }
        </>
    )
}

export default MainContent
