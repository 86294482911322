import React, {useState, useEffect} from "react"
import { Grid } from "@material-ui/core"

const PautasDePagoComponent = ({classNameText}) => {
    const linkCache = [
        {
            name: 'Pauta de pago Noviembre 2022 Isla de Chiloé',
            index: 0,
            link: 'https://www.prolesur.cl/medios/2023/01/Pauta-de-pago-Noviembre-2022-Isla-de-Chiloe.pdf'
        },
        {
            name: 'Pauta de pago Noviembre 2022 Región de La Araucanía',
            index: 1,
            link: 'https://www.prolesur.cl/medios/2023/01/Pauta-de-pago-Noviembre-2022-Region-de-La-Araucania.pdf'
        },
        {
            name: 'Pauta de pago Noviembre 2022 Región de Los Lagos',
            index: 2,
            link: 'https://www.prolesur.cl/medios/2023/01/Pauta-de-pago-Noviembre-2022-Region-de-Los-Lagos.pdf'
        },
        {
            name: 'Pauta de pago Noviembre 2022 Región de Los Ríos',
            index: 3,
            link: 'https://www.prolesur.cl/medios/2023/01/Pauta-de-pago-Noviembre-2022-Region-de-Los-Rios.pdf'
        },
        {
            name: 'Pauta de pago Septiembre 2022 Isla de Chiloé',
            index: 4,
            link: 'https://www.prolesur.cl/medios/2023/01/Pauta-de-pago-Septiembre-2022-Isla-de-Chiloe.pdf'
        },
        {
            name: 'Pauta de pago Septiembre 2022 Región de La Araucanía',
            index: 5,
            link: 'https://www.prolesur.cl/medios/2023/01/Pauta-de-pago-Septiembre-2022-Region-de-La-Araucania.pdf'
        },
        {
            name: 'Pauta de pago Septiembre 2022 Región de Los Lagos',
            index: 6,
            link: 'https://www.prolesur.cl/medios/2023/01/Pauta-de-pago-Septiembre-2022-Region-de-Los-Lagos.pdf'
        },
        {
            name: 'Pauta de pago Septiembre 2022 Región de Los Ríos',
            index: 7,
            link: 'https://www.prolesur.cl/medios/2023/01/Pauta-de-pago-Septiembre-2022-Region-de-Los-Rios.pdf'
        },
        {
            name: 'Pauta de pago 29 Diciembre 2021 Región de La Araucanía',
            index: 8,
            link: ''
        },
        {
            name: 'Pauta de pago Marzo 2022 Región de Los Ríos',
            index: 9,
            link: 'https://www.prolesur.cl/medios/2023/01/Pauta-de-pago-29-Diciembre-2021-Region-de-La-Araucania.pdf'
        },
        {
            name: 'Pauta de pago Marzo 2022 Región de Los Lagos',
            index: 10,
            link: 'https://www.prolesur.cl/medios/2023/01/Pauta-de-pago-Marzo-2022-Region-de-Los-Lagos.pdf'
        },
        {
            name: 'Pauta de pago Marzo 2022 Región de La Araucanía',
            index: 11,
            link: 'https://www.prolesur.cl/medios/2023/01/Pauta-de-pago-Marzo-2022-Region-de-La-Araucania.pdf'
        },
        {
            name: 'Pauta de pago Abril 2022 Región de Los Ríos',
            index: 12,
            link: 'https://www.prolesur.cl/medios/2023/01/Pauta-de-pago-Abril-2022-Region-de-La-Araucania.pdf'
        },
        {
            name: 'Pauta de pago Abril 2022 Región de Los Lagos',
            index: 13,
            link: 'https://www.prolesur.cl/medios/2023/01/Pauta-de-pago-Abril-2022-Region-de-Los-Lagos.pdf'
        },
        {
            name: 'Pauta de pago Abril 2022 Región de La Araucanía',
            index: 14,
            link: 'https://www.prolesur.cl/medios/2023/01/Pauta-de-pago-Abril-2022-Region-de-La-Araucania.pdf'
        },
        {
            name: 'Pauta de pago Abril 2022 Isla de Chiloé',
            index: 15,
            link: 'https://www.prolesur.cl/medios/2023/01/Pauta-de-pago-Abril-2022-Isla-de-Chiloe.pdf'
        },
        {
            name: 'Pauta de pago 29 Diciembre 2021 Región de Los Ríos',
            index: 16,
            link: 'https://www.prolesur.cl/medios/2023/01/Pauta-de-pago-29-Diciembre-2021-Region-de-Los-Rios.pdf'
        },
        {
            name: 'Pauta de pago 29 Diciembre 2021 Región de Los Lagos',
            index: 17,
            link: 'https://www.prolesur.cl/medios/2023/01/Pauta-de-pago-29-Diciembre-2021-Region-de-Los-Lagos.pdf'
        },
        {
            name: 'Pauta de pago 29 Diciembre 2021 Isla de Chiloé',
            index: 18,
            link: 'https://www.prolesur.cl/medios/2023/01/Pauta-de-pago-29-Diciembre-2021-Isla-de-Chiloe.pdf'
        },
    ]
    const [openData, setOpenData] = useState(false)
    const [links, setLinks] = useState([])
    useEffect(() => {
        setLinks(linkCache)
        setTimeout(() => {
            setOpenData(true)
        }, 1000);
    }, [])
    return (
        <Grid container>
            <Grid item xs={12}>
                <div className='welcome-text'>
                    <div className='welcome-text-title'>
                        <p>Pautas de pago</p>
                    </div>
                    <div className={classNameText}>
                        {openData && <Grid container  style={{ position: 'relative' }}>
                            <Grid item xs={2}>
                                
                            </Grid>
                            <Grid item xs={8} style={{padding:20}}>
                                <div
                                    style={{
                                        border: '#ccc solid',
                                        borderWidth: '1px 0px'
                                    }}
                                >
                                    {
                                        links.map((link, i) => {
                                            return (
                                                <div key={i} style={{
                                                    width:'100%',
                                                    padding: 10,
                                                    border: '#ccc solid',
                                                    borderWidth: '1px 2px',
                                                    margin: '0px 10px'
                                                }}>
                                                    <a href={link.link} target={'_blank'} rel="noopener noreferrer" >{link.name}</a>
                                                </div>
                                            )
                                        })
                                    }
                                    <div className="pautas-pago-container">
                                        {/* <p className="pautas-pago-text">
                                            Pautas de pago
                                        </p> */}
                                        <a href="https://www.prolesur.cl/pauta-de-pagos/" target={'_blank'} rel="noopener noreferrer">Link al sitio</a>
                                    </div>
                                </div>
                                {/* <Grid container>
                                    <Grid item xs={6}>
                                        <div className="pautas-pago-container">
                                            <p className="pautas-pago-text">
                                                Pautas de pago
                                            </p>
                                            <a href="#">Link al sitio</a>
                                        </div>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div className="pautas-pago-container">
                                            <p className="pautas-pago-text">
                                                Pautas de pago
                                            </p>
                                            <a href="#">Link al sitio</a>
                                        </div>
                                    </Grid>
                                </Grid> */}
                            </Grid>
                            <Grid item xs={2}>
                                
                            </Grid>
                        </Grid>}
                    </div>
                </div>
            </Grid>
        </Grid>
    )
}

export default PautasDePagoComponent
