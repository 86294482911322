/* React */
import React from 'react'

/* Material UI */
import { FormControl, Grid, makeStyles } from '@material-ui/core'

/* Components */
import { MyDatePicker, PredioFilter, TemporalidadFilter } from './'

const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1, 'auto'),
        '& .MuiInputBase-input': {
            background: 'white',
            fontSize: 16,
            fontWeight: 600,
            borderRadius: 12,
        },
        '& .MuiInputBase-root': {
            background: 'white',
            fontSize: 16,
            fontWeight: 600,
            borderRadius: 12,
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: 12,
        },
        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            borderWidth: 0,
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(14px, -14px) scale(0.75)',
        },
    },
}))

const Filter = props => {
    const classes = useStyles()

    return (
        <Grid container spacing={2} style={{ marginTop: 8 }}>
            <Grid item xs={6} sm={3}>
                <FormControl fullWidth variant="outlined" className={classes.formControl}>
                    <PredioFilter />
                </FormControl>
            </Grid>
            <Grid item xs={6} sm={3}>
                <FormControl fullWidth variant="outlined" className={classes.formControl}>
                    <TemporalidadFilter />
                </FormControl>
            </Grid>
            <Grid item xs={6} sm={3}>
                <FormControl fullWidth variant="outlined" className={classes.formControl}>
                    <MyDatePicker label="Inicio" />
                </FormControl>
            </Grid>
            <Grid item xs={6} sm={3}>
                <FormControl fullWidth variant="outlined" className={classes.formControl}>
                    <MyDatePicker label="Término" />
                </FormControl>
            </Grid>
        </Grid>
    )
}

export default Filter
