import { Popover } from "@material-ui/core"
import React, { useEffect } from "react"

const VerDatoPopover = ({id, open, anchorEl, handleClose, data}) => {
    useEffect(() => {
        console.log(data)
    }, [])
    return (
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={() => {handleClose()}}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
            }}
            >
                <div className={ 'popover-container-ver-datos'}>
                    <h3>{data.title}</h3>
                    {   (data.title === 'Datos de hectárea')
                        ? 
                        <div>
                            <p>Hectáreas totales: <b>{data.data.hectarea.hectareasTotales}</b></p>
                            <p>Hectáreas pastoreo: <b>{data.data.hectarea.hectareasPastoreo}</b></p>
                            <p>Hectáreas cultivo: <b>{data.data.hectarea.hectareasCultivo}</b></p>
                            <p>Hectáreas recría: <b>{data.data.hectarea.hectareasRecria}</b></p>
                        </div>
                        :
                        <div>
                            <p>Vaca masa: <b>{data.data.vaca.vMasa}</b></p>
                            <p>Vacas ordeñas: <b>{data.data.vaca.vOrdenas}</b></p>
                            <p>Vacas reparto: <b>{data.data.vaca.vReparto}</b></p>
                            <p>Vacas secas: <b>{data.data.vaca.vSecas}</b></p>
                        </div>
                    }
                    <div style={{width: '100%', fontStyle: 'italic', fontSize: 12, textAlign: 'right'}}>
                        <p>Presione fuera de la ventana para cerrar.</p>
                    </div>
                </div>
        </Popover>
    )
}

export default VerDatoPopover