/* GraphQL */
import { gql } from '@apollo/client'

export const GET_PRESETS = gql`
    query Presets($presetIds: [ID]) {
        permissionPresets(presetIds: $presetIds) {
            _id
            name
            color
            produccion {
                vistaGeneral
                calidad
                productividad
                inviernoVerano
                kpi
            }
            facturacion {
                resumenPrecios
                liquidaciones
            }
            situacionSanitaria
            forraje
            recoleccion
            administracion
            createdAt
            updatedAt
        }
    }
`
