/* React */
import React, { Fragment, useState } from 'react'

/* Material UI */
import {
    Button,
    Divider,
    Grid,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    withStyles,
} from '@material-ui/core'

/* Snackbar */
import { useSnackbar } from 'notistack'

/* Utilities */
import fileDownload from 'js-file-download'
import axios from 'axios'

const useStyles = makeStyles(theme => ({
    paperGeneralCard: {
        // margin: theme.spacing(2, 'auto'),
        // padding: theme.spacing(2),
        borderRadius: 12,
    },
    chart: {
        height: 300,
        margin: '10px 20px',
        marginLeft: 0,
        [theme.breakpoints.up('lg')]: {
            margin: '10px 20px',
        },
    },
    headerImage: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: 20,
        marginLeft: 20,
        marginRight: 20,
        [theme.breakpoints.up('lg')]: {
            marginLeft: 45,
        },
    },
    tableHeadCell: {
        fontWeight: 'bold',
        fontSize: 18,
        backgroundColor: '#e4efff',
        borderBottom: 'none',
        borderRight: 'solid 1px #dfdfdf',
        borderLeft: 'solid 1px #dfdfdf',
        padding: '12px 16px',
    },
    tableBodyCell: {
        borderBottom: 'none',
        fontSize: 18,
        borderRight: 'solid 1px #dfdfdf',
        borderLeft: 'solid 1px #dfdfdf',
        padding: '12px 16px',
    },
    table: {
        '&::-webkit-scrollbar': {
            width: 8,
            height: 12,
            // background-color: #f5f5f5;
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: 10,
            backgroundColor: '#d8d8d8',
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: 'white',
            borderRadius: 10,
        },
    },
}))

const monthFormatter = month => {
    switch (parseInt(month)) {
        case 1:
            return 'Ene'
        case 2:
            return 'Feb'
        case 3:
            return 'Mar'
        case 4:
            return 'Abr'
        case 5:
            return 'May'
        case 6:
            return 'Jun'
        case 7:
            return 'Jul'
        case 8:
            return 'Ago'
        case 9:
            return 'Sep'
        case 10:
            return 'Oct'
        case 11:
            return 'Nov'
        case 12:
            return 'Dic'
        default:
            return month
    }
}

const DownloadLiquidacion = async (salaId, year, month) => {
    const response = await axios.get(`/ftp/getliquidacion/${salaId}/${year}/${month}`, {
        responseType: 'blob',
    })
    return response
}

const headCells = [
    { id: 'fecha', label: 'Fecha' },
    { id: 'download', label: 'Descargar' },
]

const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: '#f9f9f9',
        },
    },
}))(TableRow)

const CartaLiquidacion = ({ bg, data, producerCode, salaId }) => {
    const [showTable, setShowTable] = useState(false)
    const classes = useStyles()
    const { enqueueSnackbar } = useSnackbar()

    /* const handleGetLiquidacion = (producerCode, month, year) => {
        const fileName = `${producerCode}_${('0' + month).slice(-2)}_${year}.pdf`
        enqueueSnackbar(`No se pudo encontrar el documento de liquidación: ${fileName}.`, { variant: 'error' })
    } */

    const handleDownloadLiquidacion = (salaId, year, month, producerCode) => {
        DownloadLiquidacion(salaId, year, month)
            .then(response => {
                if (response.data.type === 'application/json') {
                    const reader = new FileReader()
                    reader.onload = e => {
                        if (e.target.readyState === 2) {
                            const message = JSON.parse(e.target.result)
                            return enqueueSnackbar(message.error, { variant: 'error' })
                        }
                    }
                    reader.readAsText(response.data)
                } else {
                    fileDownload(response.data, `${producerCode}_${year}_${('0' + month).slice(-2)}.pdf`)
                }
            })
            .catch(err => {
                console.log(err)
                enqueueSnackbar('Error en la red.', { variant: 'error' })
            })
    }

    return (
        <Paper elevation={0} className={classes.paperGeneralCard}>
            <Fragment>
                <Grid container>
                    <Grid container item xs={12} justifyContent="center">
                        <span
                            style={{
                                color: '#292929',
                                fontSize: 32,
                                fontWeight: 800,
                                marginTop: 18,
                                marginBottom: 18,
                            }}
                        >
                            Código {producerCode}
                        </span>
                    </Grid>
                    {showTable && (
                        <Grid item xs={12}>
                            <div style={{ maxHeight: 286, overflowY: 'auto' }} className={classes.table}>
                                <Table stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            {headCells.map(head => {
                                                return (
                                                    <TableCell
                                                        className={classes.tableHeadCell}
                                                        key={head.id}
                                                        style={{ textAlign: 'center' }}
                                                    >
                                                        {head.label}
                                                    </TableCell>
                                                )
                                            })}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data?.map((row, index) => {
                                            return (
                                                <StyledTableRow key={index}>
                                                    <TableCell
                                                        className={classes.tableBodyCell}
                                                        style={{ textAlign: 'center' }}
                                                    >
                                                        {monthFormatter(row.month) + '-' + row.year}
                                                    </TableCell>
                                                    <TableCell
                                                        className={classes.tableBodyCell}
                                                        style={{ textAlign: 'center' }}
                                                    >
                                                        <Button
                                                            onClick={() =>
                                                                handleDownloadLiquidacion(
                                                                    salaId,
                                                                    row.year,
                                                                    row.month,
                                                                    producerCode
                                                                )
                                                            }
                                                            style={{ fontWeight: 'bold' }}
                                                            color="primary"
                                                        >
                                                            Descargar
                                                        </Button>
                                                    </TableCell>
                                                </StyledTableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </div>
                        </Grid>
                    )}
                </Grid>
                <Divider />
                <Grid container item xs={12}>
                    <Button
                        color="primary"
                        size="large"
                        style={{ width: '100%', padding: 12 }}
                        onClick={() => setShowTable(!showTable)}
                    >
                        {showTable ? 'Ver menos' : 'Ver liquidaciones'}
                    </Button>
                </Grid>
            </Fragment>
        </Paper>
    )
}

export default CartaLiquidacion
