/* React */
import React, { createContext, useEffect, useState } from 'react'

/* GraphQL */
import { useLazyQuery /* , gql */ } from '@apollo/client'
import { SOCIEDADES } from '../graphQL/sociedades'

/* Snackbar */
import { useSnackbar } from 'notistack'
import { useHistory } from 'react-router-dom'
import { getSociedadesFromRoutes, getSalasFromRoutes } from '../functions'
export const AuthContext = createContext()

/* const LAST_DATE_IN_METRICS = gql`
    query LastDateInMetrics
    {
        lastDateInMetrics {
            FECHA_AV
        }
    }
` */

const login = async (email, password) => {
    const requestBody = {
        user: {
            email,
            password,
        },
    }
    return fetch('/auth/login', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
        credentials: 'include',
    })
    /* try {
        const res = await axios.post('/auth/login', requestBody)
        return res.data
    } catch (error) {
        console.log(error)
        throw error
    } */
}

const logout = async () => {
    return fetch('/auth/logout', {
        method: 'POST',
        credentials: 'include',
    })
    /* try {
        const res = await axios.post('/auth/logout')
        return res.data
    } catch (error) {
        console.log(error)
        throw error
    } */
}

const checkToken = async () => {
    return fetch('/auth/checktoken', {
        method: 'GET',
        credentials: 'include',
    })
    /* try {
        const res = await axios.get('/auth/checktoken')
        return res.data
    } catch (error) {
        console.log(error)
        throw error
    } */
}

const resetPassword = async email => {
    const requestBody = {
        email,
    }
    return fetch('/auth/forgotpassword', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
    })
}

const changePassword = async (token, password) => {
    const requestBody = {
        password,
    }
    return fetch(`/auth/resetpassword/${token}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
    })
}

const AuthContextProvider = props => {
    // const [isAuthenticated, setIsAuthenticated] = useState(false)
    // const [user, setUser] = useState('')
    const { enqueueSnackbar } = useSnackbar()
    const [userInfo, setUserInfo] = useState({})
    const [avatar, setAvatar] = useState({})
    const [sociedadesId, setSociedadesId] = useState([])
    const [sociedades, setSociedades] = useState([])
    const [salas, setSalas] = useState([])
    const [getSociedades, { data: sociedadesData }] = useLazyQuery(SOCIEDADES, {
        fetchPolicy: 'cache-and-network',
    })
    const [loading, setLoading] = useState(false)
    const [permissions, setPermissions] = useState({
        produccion: {
            vistaGeneral: 0,
            calidad: 0,
            productividad: 0,
            inviernoVerano: 0,
            kpi: 0
        },
        facturacion: {
            resumenPrecios: 0,
            liquidaciones: 0,
        },
        situacionSanitaria: 0,
        forraje: 0,
        recoleccion: 0,
        administracion: 0,
    })
    // const [lastDate, setLastDate] = useState(new Date())
    const [mapKey, setMapKey] = useState(null)

    const [isAuthenticated, setIsAuthenticated] = useState(false)
    const [isAdmin, setIsAdmin] = useState(false)
    const history = useHistory()

    useEffect(() => {
        if (localStorage.getItem('isSuperadmin')) {
            const res = localStorage.getItem('isSuperadmin')
            setIsAdmin(res === 'true' && true)
        }
    },[localStorage.getItem('isSuperadmin')])

    /* const [lastDateInMetrics, { data: lastDateData }] = useLazyQuery(LAST_DATE_IN_METRICS, {
        fetchPolicy: 'network-only'
    }) */

    const initLogin = (email, password) => {
        setLoading(true)
        login(email, password)
            .then(response => {
                getSociedadesFromRoutes()
                getSalasFromRoutes()
                setLoading(false)
                if (!response.ok) {
                    return Promise.reject(response)
                }
                return response.json()
            })
            .then(response => {
                console.log(response)
                setUserInfo(response.userInfo)
                localStorage.setItem('isAdmin', response.userInfo.roleSystem)
                localStorage.setItem('isSuperadmin', response.userInfo.isAdmin ? response.userInfo.isAdmin : 'false')
                setAvatar({
                    image: response.avatar?.img,
                    filetype: response.avatar?.name.split('.').pop(),
                })
                setPermissions({ ...permissions, ...response.permissions })
                console.log(response.sociedades && response.sociedades[0] && response.sociedades[0]._id)
                if(response.sociedades && response.sociedades[0] && response.sociedades[0]._id) {
                    setSociedadesId(response.sociedades.map(sociedad => {
                        return sociedad._id
                    }))
                    setSalas(response.salas.map(sala => {
                        return sala._id
                    }))
                } else {
                    setSociedadesId(response.sociedades)
                    setSalas(response.salas)
                }
                setMapKey(response.mapKey)
                setIsAuthenticated(true)
                enqueueSnackbar('Has iniciado sesión correctamente.', {
                    variant: 'success',
                })
                history.push('/')
            })
            .catch(error => {
                setLoading(false)
                if (error.status === 422) {
                    enqueueSnackbar('Debe llenar ambos campos.', { variant: 'error' })
                } else if (error.status === 401) {
                    enqueueSnackbar('Inicio de sesión fallido. Credenciales incorrectas.', { variant: 'error' })
                } else {
                    enqueueSnackbar('No tiene conexión a internet o el servidor tiene problemas.', { variant: 'error' })
                }
            })
    }

    const initLogout = () => {
        logout()
            .then(() => {
                indexedDB.databases().then(dbList => {
                    dbList.forEach((db) => {
                        indexedDB.deleteDatabase(db.name)
                    })
                })
                setUserInfo({})
                setIsAuthenticated(false)
            })
            .catch(error => {
                console.log(error)
            })
    }

    useEffect(() => {
        if (sociedadesData?.sociedades?.length >= 0) {
            const varSociedades = sociedadesData.sociedades ? [...sociedadesData.sociedades] : []
            const sociedadesReales = []
            varSociedades.forEach((sociedad) => {
                if (sociedad) {
                    sociedadesReales.push(sociedad)
                }
            })
            setSociedades(sociedadesReales)
        }
    }, [sociedadesData])

    useEffect(() => {
        console.log(sociedadesId)
        if (sociedadesId?.length > 0) {
            getSociedades({
                variables: {
                    sociedadIds: sociedadesId,
                },
            })
        }
    }, [sociedadesId])

    useEffect(() => {
        setLoading(true)
        // Check Token if valid and get user info
        checkToken()
            .then(response => {
                setLoading(false)
                if (!response.ok) {
                    return Promise.reject(response)
                }
                return response.json()
            })
            .then(response => {
                console.log(response)
                setUserInfo(response.userInfo)
                setAvatar({
                    image: response.avatar?.img,
                    filetype: response.avatar?.name.split('.').pop(),
                })
                setPermissions({ ...permissions, ...response.permissions })
                setSociedadesId(response.sociedades)
                setSalas(response.salas)
                setMapKey(response.mapKey)
                setIsAuthenticated(true)
                // lastDateInMetrics()
                enqueueSnackbar('Has iniciado sesión correctamente.', {
                    variant: 'success',
                })
            })
            .catch(error => {
                setLoading(false)
                if (error.status === 422) {
                    enqueueSnackbar('Debe llenar ambos campos.', { variant: 'error' })
                } else if (error.status === 401) {
                    // enqueueSnackbar('Debe iniciar sesión nuevamente.', { variant: 'warning' })
                } else {
                    enqueueSnackbar('No tiene conexión a internet o el servidor tiene problemas.', { variant: 'error' })
                }
            })
    }, [])

    return (
        <AuthContext.Provider
            value={{
                isAuthenticated,
                userInfo,
                avatar,
                permissions,
                sociedades,
                salas,
                // lastDate,
                initLogin,
                initLogout,
                resetPassword,
                changePassword,
                loading,
                mapKey,
                isAdmin
            }}
        >
            {props.children}
        </AuthContext.Provider>
    )
}

export default AuthContextProvider
