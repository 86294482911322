/* React */
import React, { Fragment, useEffect, useState } from 'react'

/* Material UI */
import {
    Button,
    Divider,
    Grid,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    withStyles,
} from '@material-ui/core'

/* Components */
import { ButtonVerTabla } from '../comun'

/* Utilities */
import { format } from 'date-fns'
import { es } from 'date-fns/locale'

const useStyles = makeStyles(theme => ({
    paperGeneralCard: {
        // margin: theme.spacing(2, 'auto'),
        // padding: theme.spacing(2),
        borderRadius: 12,
    },
    chart: {
        height: 300,
        margin: '10px 20px',
        marginLeft: 0,
        [theme.breakpoints.up('lg')]: {
            margin: '10px 20px',
        },
    },
    headerImage: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: 20,
        marginLeft: 20,
        marginRight: 20,
        [theme.breakpoints.up('lg')]: {
            marginLeft: 45,
        },
    },
    tableHeadCell: {
        fontWeight: 'bold',
        fontSize: 18,
        backgroundColor: '#e4efff',
        borderBottom: 'none',
        borderRight: 'solid 1px #dfdfdf',
        borderLeft: 'solid 1px #dfdfdf',
        padding: '12px 16px',
    },
    tableBodyCell: {
        borderBottom: 'none',
        fontSize: 18,
        borderRight: 'solid 1px #dfdfdf',
        borderLeft: 'solid 1px #dfdfdf',
        padding: '12px 16px',
    },
    table: {
        '&::-webkit-scrollbar': {
            width: 8,
            height: 12,
            // background-color: #f5f5f5;
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: 10,
            backgroundColor: '#d8d8d8',
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: 'white',
            borderRadius: 10,
        },
    },
}))

const headCells = [
    { id: 'fecha', label: 'Fecha de retiro' },
    { id: 'camion', label: 'Camión' },
    { id: 'cantidad', label: 'Cantidad*' },
]

const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: '#f9f9f9',
        },
    },
}))(TableRow)

const CartaRecoleccion = ({ bg, data }) => {
    const [showTable, setShowTable] = useState(false)
    const [parsed, setParsed] = useState([])
    const classes = useStyles()

    useEffect(() => {
        /* const newParsed = data?.data?.sort((a, b) => a.fecha > b.fecha ? -1 : 1).map((point) => {
            return {
                fecha: point.fecha,
                camion: point.camion,
                cantidad: point.cantidad
            }
        }) */
        const data_ = data?.data
            ?.sort((a, b) => (a.FECHA_AV > b.FECHA_AV ? -1 : 1))
            .map(point => {
                return {
                    FECHA_AV: point.FECHA_AV,
                    CAMION: point.CAMION,
                    CANTIDAD: point.CANTIDAD,
                }
            })
        const total =
            data_.reduce((prev, cur) => {
                return prev + cur.CANTIDAD
            }, 0) || 0
        const parts = total.toString().split('.')
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.')
        const newParsed = {
            QMEL_LIFNR: data.QMEL_LIFNR,
            FECHA_AV: data_[0]?.FECHA_AV,
            data: data_,
            total: parts.join(','),
        }
        setParsed(newParsed || [])
    }, [data])
    // console.log(data)

    return (
        <Paper elevation={0} className={classes.paperGeneralCard}>
            {!showTable ? (
                <Grid container style={{ backgroundColor: bg, borderRadius: 12 }}>
                    <Grid item xs={12}>
                        <div className={classes.headerImage}>
                            <div style={{ display: 'inline-block' }}>
                                <ButtonVerTabla
                                    variant="outlined"
                                    color="primary"
                                    size="large"
                                    text="Ver detalle"
                                    onClick={() => setShowTable(true)}
                                />
                            </div>
                        </div>
                    </Grid>
                    <Grid container style={{ padding: 20, paddingTop: 0 }} spacing={2}>
                        <Grid item xs={7}>
                            <div style={{ color: 'white', fontSize: 32, fontWeight: 800 }}>
                                Productor {parsed.QMEL_LIFNR}
                            </div>
                            {/* <div style={{ color: '#ddd', marginLeft: 20, fontSize: 16, fontWeight: 'bold' }}>
                                Último retiro
                            </div>
                            <div style={{ color: 'white', marginLeft: 20, fontSize: 16, paddingBottom: 10 }}>
                                {parsed?.fecha || 'Sin data'}
                            </div> */}
                        </Grid>
                        <Grid container item xs={5} alignContent="center">
                            <Grid item xs={12}>
                                <div
                                    style={{
                                        color: '#ddd',
                                        fontSize: 16,
                                        fontWeight: 'bold',
                                        textAlign: 'center',
                                        paddingTop: 5,
                                    }}
                                >
                                    Último retiro
                                </div>
                            </Grid>
                            <Grid item xs={12} style={{ textAlign: 'center' }}>
                                <div style={{ color: 'white', fontSize: 16 }}>
                                    {parsed?.FECHA_AV
                                        ? format(new Date(parsed.FECHA_AV), 'PP', { locale: es })
                                        : 'Sin data'}
                                </div>
                            </Grid>
                            {/* <Grid item xs={12}>
                                <div style={{ color: '#ddd', marginInline: 10, fontSize: 16, fontWeight: 'bold', textAlign: 'center' }}>
                                    Total retirado
                                </div>
                            </Grid>
                            <Grid item xs={12} style={{ textAlign: 'center', alignSelf: 'baseline' }}>
                                <div style={{ color: 'white', fontSize: 32, fontWeight: 'bold', textAlign: 'center', verticalAlign: 'top' }}>
                                    {parsed?.total} L
                                </div>
                            </Grid> */}
                        </Grid>
                        <Grid container item xs={12} alignItems="center">
                            <Grid item xs={4} style={{ color: '#ddd', fontSize: 16, fontWeight: 'bold' }}>
                                Total retirado:
                            </Grid>
                            <Grid
                                item
                                xs={8}
                                style={{
                                    color: 'white',
                                    fontSize: 32,
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                }}
                            >
                                {parsed?.total || 0} L
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            ) : (
                <Fragment>
                    <Grid container>
                        <Grid container item xs={12} justifyContent="center">
                            <span
                                style={{
                                    color: '#292929',
                                    fontSize: 32,
                                    fontWeight: 800,
                                    marginTop: 10,
                                }}
                            >
                                Productor {parsed.QMEL_LIFNR}
                            </span>
                        </Grid>
                        <Grid container item xs={12} justifyContent="center">
                            <span
                                style={{
                                    color: '#8B8B8B',
                                    fontSize: 18,
                                    backgroundColor: '#FFECB7',
                                    borderRadius: 12,
                                    padding: '2px 8px',
                                    textAlign: 'center',
                                    margin: '0px 4px',
                                }}
                            >
                                (*) expresado en litros
                            </span>
                        </Grid>
                        <Grid item xs={12}>
                            <div style={{ marginTop: 18, maxHeight: 286, overflowY: 'auto' }} className={classes.table}>
                                <Table stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            {headCells.map(head => {
                                                return (
                                                    <TableCell
                                                        className={classes.tableHeadCell}
                                                        key={head.id}
                                                        style={{ textAlign: 'center' }}
                                                    >
                                                        {head.label}
                                                    </TableCell>
                                                )
                                            })}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {parsed?.data?.map((row, index) => {
                                            return (
                                                <StyledTableRow key={index}>
                                                    <TableCell
                                                        className={classes.tableBodyCell}
                                                        style={{ textAlign: 'center' }}
                                                    >
                                                        {new Date(row.FECHA_AV).toLocaleDateString('ES')}
                                                    </TableCell>
                                                    <TableCell
                                                        className={classes.tableBodyCell}
                                                        style={{ textAlign: 'center' }}
                                                    >
                                                        {row.CAMION}
                                                    </TableCell>
                                                    <TableCell
                                                        className={classes.tableBodyCell}
                                                        style={{ textAlign: 'center' }}
                                                    >
                                                        {row.CANTIDAD}
                                                    </TableCell>
                                                </StyledTableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </div>
                        </Grid>
                    </Grid>
                    <div className={classes.tableButtonMobile}>
                        <Divider />
                        <Grid container item xs={12}>
                            <Button
                                color="primary"
                                size="large"
                                style={{ width: '100%', padding: 12 }}
                                onClick={() => setShowTable(false)}
                            >
                                Volver
                            </Button>
                        </Grid>
                    </div>
                </Fragment>
            )}
        </Paper>
    )
}

export default CartaRecoleccion
