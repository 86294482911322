/* Material UI */
import { Button, withStyles } from '@material-ui/core'

const ButtonKpiSelected = withStyles(theme => ({
    root: {
        color: 'white',
        borderRadius: 12,
        padding: '15px 18px 15px 18px',
        width: 220,
        height: 50,
        fontSize: 20,
        fontWeight: 'bold',
        lineHeight: 1,
        textTransform: 'none',
        backgroundColor: '#65B34D',
        '&:hover': {
            backgroundColor: '#F5FFF7',
            color: '#65B34D'
        },
    },
}))(Button)

export default ButtonKpiSelected
