/* React */
import React from 'react'

/* Material UI */
import { SvgIcon } from '@material-ui/core'

const RecolectionIcon = props => (
    <SvgIcon {...props} viewBox="0 0 32.054 32.242">
        <g transform="translate(-207.947 -64.887)">
            <path
                d="M276.079,77l-2.311-3.338a1.683,1.683,0,0,1-.3-.974l.008-1.174A2.907,2.907,0,0,0,271.7,66.3h-6.749a2.907,2.907,0,0,0-1.737,5.24l-.014,1.151a1.688,1.688,0,0,1-.3.943L260.568,77a4,4,0,0,0-.716,2.29V94.745a3.393,3.393,0,0,0,3.389,3.389H273.4a3.393,3.393,0,0,0,3.389-3.389V79.288A4,4,0,0,0,276.079,77Zm-11.13-8.367H271.7a.581.581,0,0,1,0,1.163h-6.749a.581.581,0,0,1,0-1.163Zm-.138,6.331a4.006,4.006,0,0,0,.715-2.241l.007-.6h5.608l0,.548a4,4,0,0,0,.715,2.314l1.706,2.465a16.1,16.1,0,0,0-4.395,1c-2.2.723-4.47,1.463-6.981.807a1.682,1.682,0,0,1,.3-.937ZM273.4,95.8H263.241a1.061,1.061,0,0,1-1.06-1.06V81.656a10.1,10.1,0,0,0,2,.2,18.09,18.09,0,0,0,5.708-1.188,10.444,10.444,0,0,1,4.574-.841V94.745A1.061,1.061,0,0,1,273.4,95.8Z"
                transform="translate(-36.793 -1.004)"
            />
            <path
                d="M214.7,65.08l-6.561,6.562a.657.657,0,0,0,.464,1.121h1.667a.657.657,0,0,1,.657.657v7.272a.657.657,0,0,0,.657.657h7.161a.657.657,0,0,0,.657-.657V73.419a.657.657,0,0,1,.657-.657h1.667a.657.657,0,0,0,.465-1.121l-6.562-6.562A.657.657,0,0,0,214.7,65.08Z"
                transform="translate(0 0)"
            />
        </g>
    </SvgIcon>
)

export default RecolectionIcon
