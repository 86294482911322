/* React */
import React from 'react'

/* Material UI */
import { SvgIcon } from '@material-ui/core'

const CowIcon = props => (
    <SvgIcon {...props} viewBox="0 0 36.003 26">
        <g transform="translate(-186.907 -396.23)">
            <circle cx="1.921" cy="1.921" r="1.921" transform="translate(198.027 403.763)" />
            <circle cx="1.921" cy="1.921" r="1.921" transform="translate(198.985 415.365) rotate(-45)" />
            <circle cx="1.921" cy="1.921" r="1.921" transform="translate(205.344 415.365) rotate(-45)" />
            <path
                d="M222.491,398.448a6.009,6.009,0,0,0-3.714-1.271,6.082,6.082,0,0,0-5.339,3.188,7.723,7.723,0,0,0-5.906-4.093,7.106,7.106,0,0,0-.759-.042h-3.782a7.676,7.676,0,0,0-6.632,4.1,6.081,6.081,0,0,0-5.32-3.155,6.008,6.008,0,0,0-3.714,1.271l-.419.324.068.526a6.059,6.059,0,0,0,8.237,4.86c-.019.247-.031.5-.031.75v8.648a9.434,9.434,0,0,0,.768,3.761,7.833,7.833,0,0,0,7.043,4.914H206.8a7.824,7.824,0,0,0,7.038-4.915l.159-.368-.018-.042a9.474,9.474,0,0,0,.385-1.3,9.636,9.636,0,0,0,.22-2.047v-8.648c0-.26-.011-.517-.031-.771a6.061,6.061,0,0,0,8.292-4.839l.068-.526Zm-29.5,4.283a4.224,4.224,0,0,1-4.073-3.13,4.171,4.171,0,0,1,2.123-.573,4.224,4.224,0,0,1,4.072,3.13A4.168,4.168,0,0,1,192.99,402.731Zm16.87,1.241a1.615,1.615,0,1,1-1.615,1.615A1.615,1.615,0,0,1,209.86,403.972Zm-3.086,16.407h-3.757a5.841,5.841,0,0,1-5.172-3.431,5.842,5.842,0,0,1,5.172-3.433H206.8a5.231,5.231,0,0,1,.561.031,6,6,0,0,1,4.592,3.377A5.838,5.838,0,0,1,206.773,420.379Zm5.957-6.823a7.8,7.8,0,0,1-.075,1.057,7.606,7.606,0,0,0-5.1-2.908,7.1,7.1,0,0,0-.759-.042h-3.782a7.556,7.556,0,0,0-5.9,3.005,7.838,7.838,0,0,1-.08-1.113v-8.648c0-3.764,2.673-6.826,5.959-6.826h3.669a6.864,6.864,0,0,0-1.488,4.213c0,3.757,3.218,6.91,7.557,7.775Zm4.1-10.825a4.166,4.166,0,0,1-2.122-.573,4.224,4.224,0,0,1,4.072-3.13,4.171,4.171,0,0,1,2.123.573A4.224,4.224,0,0,1,216.827,402.731Z"
                transform="translate(0 0)"
            />
        </g>
    </SvgIcon>
)

export default CowIcon
