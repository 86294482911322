/* React */
import React, { createContext, useContext, useEffect, useRef, useState } from 'react'

/* GraphQL */
import { useMutation, useQuery } from '@apollo/client'
import { /* CREATE_USER, */ DELETE_USER, EDIT_USER, USERS } from '../../graphQL/users'

/* Material UI */
import { FormControl, IconButton, makeStyles, MenuItem, Select, Tooltip } from '@material-ui/core'
import { EditOutlined, Clear } from '@material-ui/icons'

/* Snackbar */
import { useSnackbar } from 'notistack'

/* Context */
import { PermissionPresetsContext } from './'
import { AuthContext } from '../'

/* Components */
import { TableSwitch } from '../../components/administracion'
import { createUser } from '../../routes/Users.route'

export const UsuariosContext = createContext()

const useStyles = makeStyles(theme => ({
    formControlSelect: {
        margin: theme.spacing(1, 'auto'),
        '& .MuiInputBase-input': {
            // background: 'white',
            fontSize: 14,
            fontWeight: 800,
            // borderRadius: 12
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: 12,
        },
        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            borderWidth: 0,
        },
    },
}))

const UsuariosContextProvider = props => {
    const { permissions, sociedades } = useContext(AuthContext)
    const formFieldsRef = useRef([])
    const { presets } = useContext(PermissionPresetsContext)
    const classes = useStyles()
    const [editUserId, setEditUserId] = useState('')
    const [editUserEmail, setEditUserEmail] = useState('')
    const [users, setUsers] = useState([])
    const { enqueueSnackbar } = useSnackbar()
    const [isCreate, setIsCreate] = useState()
    const [redir, setRedir] = useState(false)
    /* const [isRedir, setIsRedir] = useState(false) */

    const { data, refetch } = useQuery(
        USERS,
        {
            fetchPolicy: 'cache-and-network',
            variables: {
                isAdmin: localStorage.getItem('isSuperadmin')
            },
            skip: props.condition
        }
    )

    useEffect(() => {
        if (data) {
            console.log(data)
        }
    },[data])
    
    /* const [createUser] = useMutation(CREATE_USER, {
        onCompleted(data) {
            if (data?.createUser?.message) {
                enqueueSnackbar(data.createUser.message, {
                    variant: 'success',
                    persist: true,
                })
                if (isRedir) {
                    setRedir(true)
                    setRedir(false)
                }
            }
        },
        onError(error) {
            enqueueSnackbar(error.message, { variant: 'error' })
        },
    }) */
    const [editUser] = useMutation(EDIT_USER, {
        onCompleted(data) {
            if (data && data.editUser) {
                enqueueSnackbar('Usuario editado correctamente', {
                    variant: 'success',
                })
            }
        },
        onError(error) {
            enqueueSnackbar(error.message, { variant: 'error' })
        },
    })
    const [deleteUser] = useMutation(DELETE_USER, {
        onCompleted(data) {
            if (data && data.deleteUser) {
                enqueueSnackbar('Usuario eliminado correctamente', {
                    variant: 'success',
                })
            }
        },
        onError(error) {
            enqueueSnackbar(error.message, { variant: 'error' })
        },
    })

    const [mobileSelection, setMobileSelection] = useState([
        { name: 'Nombres', cellFields: ['name'] },
        { name: 'RUT', cellFields: ['RUT'] },
        { name: 'Cargo', cellFields: ['position'] },
        { name: 'Email', cellFields: ['email'] },
        { name: 'Teléfono', cellFields: ['phone'] },
        { name: 'Sociedad', cellFields: ['sociedades'] },
        { name: 'Rol', cellFields: ['role'] },
        ...(permissions?.administracion > 1 ? [{ name: 'Edición', cellFields: ['enabled', 'edit', 'remove'] }] : []),
    ])

    const [formFields, setFormFields] = useState([
        {
            name: 'name',
            type: 'textInput',
            label: 'Nombres',
            placeHolder: 'Nombre',
            value: '',
            enabled: true
        },
        {
            name: 'lastName',
            type: 'textInput',
            label: 'Apellidos',
            placeHolder: 'Apellido',
            value: '',
            enabled: true
        },
        {
            name: 'RUT',
            type: 'textInput',
            label: 'Rut',
            placeHolder: '12345678-9',
            value: '',
            enabled: true
        },
        {
            name: 'position',
            type: 'textInput',
            label: 'Cargo',
            placeHolder: 'Puesto del trabajador',
            value: '',
            enabled: true
        },
        {
            name: 'email',
            type: 'textInput',
            label: 'E-mail',
            placeHolder: 'ejemplo@prolesur.cl',
            value: '',
            enabled: true
        },
        {
            name: 'phone',
            type: 'textInput',
            label: 'Teléfono',
            placeHolder: '+56 9 1234 5678',
            value: '',
            enabled: true
        },
        {
            name: 'password',
            type: 'textInput',
            label: 'Nueva Password',
            value: '',
            placeHolder: 'Ingrese Nueva Password',
            enabled: true
        },
        {
            name: 'sociedades',
            type: 'multiple',
            label: 'Sociedad',
            menuItems: sociedades,
            value: '',
            property: 'masterCode',
            enabled: true
        }
         /* ,
        {
            name: 'role',
            type: 'select',
            label: 'Rol',
            defaultValue: 'Personalizado',
            menuItems: presets,
            value: ''
        } */,
    ])

    useEffect(() => {
        setFormFields([
            {
                name: 'name',
                type: 'textInput',
                label: 'Nombres',
                placeHolder: 'Nombre',
                value: '',
                required: true,
                enabled: true
            },
            {
                name: 'lastName',
                type: 'textInput',
                label: 'Apellidos',
                placeHolder: 'Apellido',
                value: '',
                enabled: true
            },
            {
                name: 'RUT',
                type: 'textInput',
                label: 'Rut',
                placeHolder: '12345678-9',
                value: '',
                enabled: true
            },
            {
                name: 'position',
                type: 'textInput',
                label: 'Cargo',
                placeHolder: 'Puesto del trabajador',
                value: '',
                enabled: true
            },
            {
                name: 'email',
                type: 'textInput',
                label: 'E-mail',
                placeHolder: 'ejemplo@prolesur.cl',
                value: '',
                required: true,
                enabled: true
            },
            {
                name: 'phone',
                type: 'textInput',
                label: 'Teléfono',
                placeHolder: '+56 9 1234 5678',
                value: '',
                enabled: true
            },
            {
                name: 'password',
                type: 'textInput',
                label: 'Nueva Password',
                value: '',
                placeHolder: 'Ingrese Nueva Password',
                enabled: true
            },
            {
                name: 'sociedades',
                type: 'multiple',
                label: 'Sociedad',
                menuItems: sociedades,
                value: '',
                property: 'masterCode',
                required: true,
                enabled: true
            } /* ,
            {
                name: 'role',
                type: 'select',
                label: 'Rol',
                defaultValue: 'Personalizado',
                menuItems: presets,
                value: ''
            } */,
        ])
        formFieldsRef.current = [
            {
                name: 'name',
                type: 'textInput',
                label: 'Nombres',
                placeHolder: 'Nombre',
                value: '',
                required: true,
                enabled: true
            },
            {
                name: 'lastName',
                type: 'textInput',
                label: 'Apellidos',
                placeHolder: 'Apellido',
                value: '',
                enabled: true
            },
            {
                name: 'RUT',
                type: 'textInput',
                label: 'Rut',
                placeHolder: '12345678-9',
                value: '',
                enabled: true
            },
            {
                name: 'position',
                type: 'textInput',
                label: 'Cargo',
                placeHolder: 'Puesto del trabajador',
                value: '',
                enabled: true
            },
            {
                name: 'email',
                type: 'textInput',
                label: 'E-mail',
                placeHolder: 'ejemplo@prolesur.cl',
                value: '',
                required: true,
                enabled: true
            },
            {
                name: 'phone',
                type: 'textInput',
                label: 'Teléfono',
                placeHolder: '+56 9 1234 5678',
                value: '',
                enabled: true
            },
            {
                name: 'password',
                type: 'textInput',
                label: 'Nueva Password',
                value: '',
                placeHolder: 'Ingrese Nueva Password',
                enabled: true
            },
            {
                name: 'sociedades',
                type: 'multiple',
                label: 'Sociedad',
                menuItems: sociedades,
                value: '',
                property: 'masterCode',
                required: true,
                enabled: true
            } /* ,
            {
                name: 'role',
                type: 'select',
                label: 'Rol',
                defaultValue: 'Personalizado',
                menuItems: presets,
                value: ''
            } */,
        ]
    }, [presets, sociedades])

    const [columns, setColumns] = useState([
        { field: 'lastName', headerName: 'Apellidos', sortable: true },
        { field: 'name', headerName: 'Nombres', sortable: true },
        { field: 'RUT', headerName: 'Rut', sortable: true },
        { field: 'position', headerName: 'Cargo', sortable: true },
        { field: 'email', headerName: 'Email', sortable: true },
        { field: 'phone', headerName: 'Teléfono', sortable: true },
        { field: 'sociedad', headerName: 'Sociedad', sortable: true },
        {
            field: 'role',
            headerName: 'Rol',
            ...(permissions?.administracion > 1 ? { sortable: true, disablePadding: true } : { sortable: false }),
        },
        ...(permissions?.administracion > 1
            ? [
                  {
                      field: 'enabled',
                      headerName: 'Estado',
                      sortable: false,
                      disablePadding: true,
                      center: true,
                  },
                  {
                      field: 'edit',
                      headerName: 'Editar',
                      sortable: false,
                      disablePadding: true,
                      center: true,
                  },
                  {
                      field: 'remove',
                      headerName: 'Borrar',
                      sortable: false,
                      disablePadding: true,
                      center: true,
                  },
              ]
            : []),
    ])

    useEffect(() => {
        setMobileSelection([
            { name: 'Nombres', cellFields: ['name'] },
            { name: 'RUT', cellFields: ['RUT'] },
            { name: 'Cargo', cellFields: ['position'] },
            { name: 'Email', cellFields: ['email'] },
            { name: 'Teléfono', cellFields: ['phone'] },
            { name: 'Sociedad', cellFields: ['sociedad'] },
            { name: 'Rol', cellFields: ['role'] },
            ...(permissions?.administracion > 1
                ? [{ name: 'Edición', cellFields: ['enabled', 'edit', 'remove'] }]
                : []),
        ])
        setColumns([
            { field: 'lastName', headerName: 'Apellidos', sortable: true },
            { field: 'name', headerName: 'Nombres', sortable: true },
            { field: 'RUT', headerName: 'Rut', sortable: true },
            { field: 'position', headerName: 'Cargo', sortable: true },
            { field: 'email', headerName: 'Email', sortable: true },
            { field: 'phone', headerName: 'Teléfono', sortable: true },
            { field: 'sociedad', headerName: 'Sociedad', sortable: true },
            {
                field: 'role',
                headerName: 'Rol',
                ...(permissions?.administracion > 1 ? { sortable: true, disablePadding: true } : { sortable: false }),
            },
            ...(permissions?.administracion > 1
                ? [
                      {
                          field: 'enabled',
                          headerName: 'Estado',
                          sortable: false,
                          disablePadding: true,
                          center: true,
                      },
                      {
                          field: 'edit',
                          headerName: 'Editar',
                          sortable: false,
                          disablePadding: true,
                          center: true,
                      },
                      {
                          field: 'remove',
                          headerName: 'Borrar',
                          sortable: false,
                          disablePadding: true,
                          center: true,
                      },
                  ]
                : []),
        ])
    }, [permissions])

    const createData = user => {
        return {
            _id: user._id,
            name: user.name,
            lastName: user.lastName,
            RUT: user.RUT,
            position: user.position,
            email: user.email,
            phone: user.phone,
            permissions: user.permissions,
            sociedades: user.sociedades[0]?.name || '',
            sociedad: sociedadesPopUp(user.sociedades), // user.sociedades[0]?.name || '',
            role:
                permissions?.administracion > 1
                    ? selectRol(user._id, user.role)
                    : presets.find(x => x._id === user.role)?.name || 'Personalizado',
            enabled: switchEstatus(user._id, user.enabled),
            edit: iconEdit(user._id, user.email),
            remove: iconClear(user._id),
        }
    }

    useEffect(() => {
        if (data?.users?.length >= 0) {
            console.log(data.users)
            const _parsed = data.users.map(user => {
                return createData(user)
            })
            console.log(_parsed)
            setUsers(_parsed)
        }
    }, [data])

    const selectRol = (id, value) => (
        <FormControl variant="outlined" fullWidth className={classes.formControlSelect}>
            <Select
                value={value}
                onChange={event => handleChangeSelect(event, id)}
                MenuProps={{
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                }}
                fullWidth
                displayEmpty
            >
                <MenuItem value={''} disabled>
                    Personalizado
                </MenuItem>
                {presets.map((preset, index) => {
                    return (
                        <MenuItem key={index} value={preset._id}>
                            {preset.name}
                        </MenuItem>
                    )
                })}
            </Select>
        </FormControl>
    )

    const switchEstatus = (id, value) => {
        return (
            <TableSwitch
                name="estatus"
                checked={value}
                onChange={event => handleChangeSwitch(event, id)}
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
            />
        )
    }

    const iconEdit = (id, email) => {
        return (
            <IconButton aria-label="edit" onClick={() => handleClickIconEdit(id, email)}>
                <EditOutlined style={{ color: '#000' }} />
            </IconButton>
        )
    }

    const sociedadesPopUp = sociedades => {
        if (sociedades?.length > 1) {
            return (
                <Tooltip
                    placement="top"
                    arrow
                    title={
                        <div>
                            {sociedades.map((sociedad, index) => {
                                return (
                                    <div key={index} style={{ fontSize: 14, marginBottom: 10 }}>
                                        <b>{`${sociedad.masterCode} `}</b>
                                        {`${sociedad.name}`}
                                    </div>
                                )
                            })}
                        </div>
                    }
                >
                    <span>
                        {`${sociedades[0]?.name}`}
                        <b>{` y ${sociedades.length - 1} más`}</b>
                    </span>
                </Tooltip>
            )
        } else {
            return sociedades[0]?.name || ''
        }
    }

    const handleClickIconEdit = (id, email) => {
        setEditUserId(id)
        setEditUserEmail(email)
        const _data = data.users.find(e => e._id === id)

        // actualizar campos del formulario
        setFormFields(
            formFieldsRef.current.map(item => ({
                ...item,
                value: _data[item.name],
            }))
        )
        setIsCreate(true)
    }

    const handleClickCreateButton = async (values, isRedir) => {
        // editar usuario
        if (editUserId !== '') {
            console.log(values)
            editUser({
                variables: {
                    userId: editUserId,
                    name: values[0],
                    lastName: values[1],
                    RUT: values[2],
                    position: values[3],
                    email: values[4],
                    phone: values[5],
                    password: values[6],
                    sociedadesIds: values[7].map(sociedad => sociedad._id),
                    role: values[8],
                },
            })
        } else {
            console.log(values)
            console.log({
                name: values[0],
                lastName: values[1],
                RUT: values[2] ? values[2] : '',
                position: values[3] ? values[3] : '',
                email: values[4],
                phone: values[5] ? values[5] : '',
                password: values[6],
                sociedades: values[7].map(sociedad => sociedad._id), 
                /* role: values[8], */
                enabled: true,
            })
            /* setIsRedir(isRedir) */
            /* const response =  */await createUser(
                {
                    name: values[0],
                    lastName: values[1],
                    RUT: values[2] ? values[2] : '',
                    position: values[3] ? values[3] : '',
                    email: values[4],
                    phone: values[5] ? values[5] : '',
                    password: values[6],
                    sociedades: values[7].map(sociedad => sociedad._id), 
                    role: values[8],
                    enabled: true,
                }
            )
            /* console.log(response) */
            /* setUsers(users => [...users, response.user]) */
            /* setRedir(true) */
            refetch()
            setRedir(false)
            /* createUser({
                variables: {
                    name: values[0],
                    lastName: values[1],
                    RUT: values[2] ? values[2] : '',
                    position: values[3] ? values[3] : '',
                    email: values[4],
                    phone: values[5] ? values[5] : '',
                    sociedadesIds: values[7].map(sociedad => sociedad._id), 
                    role: values[8],
                    enabled: true,
                },
                refetchQueries: () => [
                    {
                        query: USERS,
                    },
                ],
            }) */
        }

        setIsCreate(false)
    }

    const iconClear = _id => (
        <IconButton
            aria-label="edit"
            onClick={() => {
                deleteUser({
                    variables: { userId: _id },
                    refetchQueries: () => [
                        {
                            query: USERS,
                        },
                    ],
                })
            }}
        >
            <Clear style={{ color: 'red' }} />
        </IconButton>
    )

    const handleChangeSwitch = (event, id) => {
        editUser({
            variables: {
                userId: id,
                enabled: event.target.checked,
            },
        })
    }

    const handleChangeSelect = (event, id) => {
        editUser({
            variables: {
                userId: id,
                role: event.target.value,
            },
        })
    }

    return (
        <UsuariosContext.Provider
            value={{
                columns,
                data: data?.users || [],
                users,
                formFields,
                handleClickCreateButton,
                editUserId,
                setEditUserId,
                mobileSelection,
                refetch,
                editUserEmail,
                redir,
                isCreate,
                setIsCreate,
            }}
        >
            {props.children}
        </UsuariosContext.Provider>
    )
}

export default UsuariosContextProvider
