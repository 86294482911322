/* React */
import React, { useContext, useEffect } from 'react'

/* Material UI */
import { Grid } from '@material-ui/core'

/* Context */
import { FilterContext, ProductividadContext } from '../../contexts/produccion'

/* Components */
import { ProductoresList, SoloPredioFilter } from '../../components/produccion/filter'
import { CartaTablaGrafico } from '../../components/produccion'

const Productividad = props => {
    const {
        fiveDaysData,
        annualData,
        queryServer,
        loadingAnnual,
        loading5Days,
        loading5DiasCSV,
        loading12MesesCSV,
        downloadCSV,
    } = useContext(ProductividadContext)
    const { predioFilter } = useContext(FilterContext)

    useEffect(() => {
        queryServer()
    }, [predioFilter])

    return (
        <div>
            <SoloPredioFilter />
            <ProductoresList />
            <Grid container spacing={2} style={{ marginTop: 8 }}>
                <Grid item xs={12}>
                    <CartaTablaGrafico
                        type="area"
                        title1="Últimos 12 meses"
                        title2="Últimos 12 meses"
                        subtitle1="Litros de leche"
                        subtitle2="Litros de leche"
                        data={annualData}
                        forecast={false}
                        buttons={true}
                        yLabel={'Litros'}
                        decimalPlaces={0}
                        downloadCSV={() => downloadCSV('12Meses')}
                        loadingCSV={loading12MesesCSV}
                        loading={loadingAnnual}
                        view="Productividad"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <CartaTablaGrafico
                        type="bar"
                        title1="Últimos 5 días"
                        title2="Últimos 5 días"
                        subtitle1="Litros de leche"
                        subtitle2="Litros de leche"
                        data={fiveDaysData}
                        forecast={false}
                        buttons={true}
                        yLabel={'Litros'}
                        decimalPlaces={0}
                        downloadCSV={() => downloadCSV('5Dias')}
                        loadingCSV={loading5DiasCSV}
                        loading={loading5Days}
                        view="Productividad"
                    />
                </Grid>
            </Grid>
        </div>
    )
}

export default Productividad
