import { Button, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select, makeStyles } from '@material-ui/core'
import axios from 'axios'
import React, {useContext, useEffect, useState} from 'react'
import { useHistory } from 'react-router-dom'
import { PieChart, Pie, Cell } from 'recharts'
import { ButtonKpiSelected } from '../../components/comun'
import { FilterContext, VistaGeneralContext } from '../../contexts/produccion'
import { NuevoDatoPopover, NuevoDatoVacasPopover, VerDatoPopover } from '../../modals'
import { AuthContext } from '../../contexts'
import { SalasContext } from '../../contexts/administracion'

const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1, 'auto'),
        '& .MuiInputBase-input': {
            background: 'white',
            fontSize: 16,
            fontWeight: 600,
            borderRadius: 12,
        },
        '& .MuiInputBase-root': {
            background: 'white',
            fontSize: 16,
            fontWeight: 600,
            borderRadius: 12,
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: 12,
        },
        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            borderWidth: 0,
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(14px, -14px) scale(0.75)',
        },
    },
}))

const Kpi = props => {
    const buttonsKpiPraderas = [
        { name: 'Acumulado mes', id: 0, state: false},
        { name: 'Promedio año', id: 1, state: true}
    ]

    const buttonsKpiDesempenoAnimal = [
        { name: 'Litros día (Último día con datos)', id: 0, state: false},
        { name: 'Acumulado mes', id: 1, state: false},
        { name: 'Promedio año', id: 2, state: true}
    ]
    const currentDate = new Date()
    currentDate.setDate(currentDate.getDate() - 4);
    currentDate.setHours(14, 59, 59, 999)
    const previousDateYear = new Date()
    previousDateYear.setDate(previousDateYear.getDate() - 365)
    previousDateYear.setHours(0, 0, 0, 0)
    const date = new Date()
    const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1)
    const previousDateDay = new Date()
    previousDateDay.setDate(previousDateDay.getDate() - 6)
    previousDateDay.setHours(0, 0, 0, 0)
    const history = useHistory()
    const { 
        salas
     } = useContext(SalasContext)
    const { 
        permissions
     } = useContext(AuthContext)
    const { 
        filtered, 
        setPredioFilter, 
        predioFilter } = useContext(FilterContext)
    const { 
        queryServer, 
        litrosTotales, 
        grasaTotal, 
        proteinaTotal, 
        setDateSelected } = useContext(VistaGeneralContext)
    const [datosVacas, setDatosVacas] = useState([])
    const [datos, setDatos] = useState([])
    const [anchorEl, setAnchorEl] = useState(null)
    const [anchorElVacas, setAnchorElVacas] = useState(null)
    const [anchorElVerData, setAnchorVerData] = useState(null)
    const [elementData, setElementData] = useState()
    const [elementDataVacas, setElementDataVacas] = useState()
    const [tipoDatoSeleccionado, setTipoDatoSelecciondo] = useState('vacas')
    const [hectareasTotales, sethectareasTotales] = useState(0)
    const [hectareasPastoreo, setHectareasPastoreo] = useState(0)
    const [hectareasCultivo, setHectareasCultivo] = useState(0)
    const [hectareasRecria, setHectareasRecria] = useState(0)
    const [hectareasParaCalculo, setHectareasParaCalculo] = useState(0)
    const [vMasa, setVMasa] = useState(0)
    const [vOrdenas, setVOrdenadas] = useState(0)
    const [vReparto, setVReparto] = useState(0)
    const [vSecas, SetVSecas] = useState(0)
    const [salasFiltered, setSalasFiltered] = useState([])
    const [salaSeleccionada, setSalaSeleccionada] = useState()
    const [salaSeleccionadaCode, setSalaSeleccionadaCode] = useState()
    const [dataPraderas, setDataPraderas] = useState()
    const [promedioSeleccionado, setPromedioSeleccionado] = useState('anual')
    const [buttonsPraderas, setButtonsPraderas] = useState(buttonsKpiPraderas)
    const [buttonsVacas, setButtonsVacas] = useState(buttonsKpiDesempenoAnimal)
    const [selection, setSelectionType] = useState()
    const [litrosHectarea, setLitrosHectarea] = useState(0)
    const [grasaHectarea, setGrasaHectarea] = useState(0)
    const [proteinaHectarea, setProteinaHectarea] = useState(0)
    const [litrosVacaOrdenada, setLitrosVacaOrdenada] = useState(0)
    const [litrosVacaMasa, setLitrosVacaMasa] = useState(0)
    const [grasaVacaOrdenada, setGrasaVacaOrdenada] = useState(0)
    const [grasaVacaMasa, setGrasaVacaMasa] = useState(0)
    const [proteinasVacaOrdenada, setProteinasVacaOrdenada] = useState(0)
    const [proteinasVacaMasa, setProteinasVacaMasa] = useState(0)
    const [salaSeleccionadaState, setSalaSeleccionadaState] = useState(false)
    const [havePermission, setHavePermission] = useState(false)
    const [decimales, setDecimales] = useState(0)
    const [openBackdrop, setOpenBackdrop] = useState(false)

    useEffect(() => {
        console.log(permissions)
        if (permissions.produccion.kpi === 1) {
            setHavePermission(false)
        } else if (permissions.produccion.kpi === 2) {
            setHavePermission(true)
        }
    }, [permissions])

    useEffect(() => {
        const ltXht = litrosTotales.currentYear / hectareasParaCalculo
        const grXht = (grasaTotal.currentYear / hectareasParaCalculo)
        const prXht = (proteinaTotal.currentYear / hectareasParaCalculo)
        const ltXvacaOrdena = litrosTotales.currentYear / vOrdenas
        const ltXvacaMasa = litrosTotales.currentYear / vMasa
        if (!selection) {
            if (hectareasParaCalculo && litrosTotales && vOrdenas && vMasa) {
                setLitrosHectarea(ltXht)
                setGrasaHectarea(grXht)
                setProteinaHectarea(prXht)
                setLitrosVacaOrdenada(ltXvacaOrdena)
                setLitrosVacaMasa(ltXvacaMasa)
                setGrasaVacaOrdenada(grasaTotal.currentYear / vOrdenas)
                setGrasaVacaMasa(grasaTotal.currentYear / vMasa)
                setProteinasVacaOrdenada(grasaTotal.currentYear / vOrdenas)
                setProteinasVacaMasa(proteinaTotal.currentYear / vMasa)
        }
        } else {
            if (selection === "hectarea") {
                if (hectareasParaCalculo && litrosTotales) {
                    setLitrosHectarea(ltXht)
                    setGrasaHectarea(grXht)
                    setProteinaHectarea(prXht)
                }
            } else if (selection === 'vaca') {
                if (vOrdenas && vMasa && litrosTotales) {
                    setLitrosVacaOrdenada(ltXvacaOrdena)
                    setLitrosVacaMasa(ltXvacaMasa)
                    setGrasaVacaOrdenada(grasaTotal.currentYear / vOrdenas)
                    setGrasaVacaMasa(grasaTotal.currentYear / vMasa)
                    setProteinasVacaOrdenada(grasaTotal.currentYear / vOrdenas)
                    setProteinasVacaMasa(proteinaTotal.currentYear / vMasa)
                }
            }
        }
    },[litrosTotales, selection, hectareasTotales, vOrdenas, vMasa])

    useEffect(() => {
        if (promedioSeleccionado==='anual') {
            queryServer(previousDateYear, currentDate)
        } else if (promedioSeleccionado==='mensual') {
            queryServer(firstDayOfMonth, currentDate)
        } else if (promedioSeleccionado==='dia') {
            queryServer(previousDateDay, currentDate)
        }
        setDateSelected(promedioSeleccionado)
    },[promedioSeleccionado, salaSeleccionada])

    useEffect(() => {
        if (salas.length > 0) {
            const data_ = [...salas]
            data_.sort((a, b) => {
                if (a.producerCode < b.producerCode) {
                    return -1
                }
                if (b.producerCode < a.producerCode) {
                    return 1
                }
                return 0
            })
            setSalasFiltered(data_)
        }
    },[salas])

    useEffect(() => {
        init()
    }, [])

    useEffect(() => {
        let vMasaCache = 0
        let vOrdenasCache = 0
        let vRepartoCache = 0
        let vSecasCache = 0
        console.log(datos, datosVacas)
        datosVacas.reverse().forEach((dato, i) => {
            if (i === 0) {
                vMasaCache = vMasaCache + dato.vMasa
                vOrdenasCache = vOrdenasCache + dato.vOrdenas
                vRepartoCache = vRepartoCache + dato.vReparto
                vSecasCache = vSecasCache + dato.vSecas
            }
        })
        setVMasa(vMasaCache)
        setVOrdenadas(vOrdenasCache)
        setVReparto(vRepartoCache)
        SetVSecas(vSecasCache)
        let hectareasTotalesCache = 0
        let hectareasPastoreoCache = 0
        let hectareasCultivoCache = 0
        let hectareasRecriaCache = 0
        datos.reverse().forEach((dato, i) => {
            if (i === 0) {
                hectareasTotalesCache = hectareasTotalesCache + dato.hectareasTotales
                hectareasPastoreoCache = hectareasPastoreoCache + dato.hectareasPastoreo
                hectareasCultivoCache = hectareasCultivoCache + dato.hectareasCultivo.total
                hectareasRecriaCache = hectareasRecriaCache + dato.hectareasRecria
            }
        })
        sethectareasTotales(hectareasTotalesCache)
        setHectareasPastoreo(hectareasPastoreoCache)
        setHectareasCultivo(hectareasCultivoCache)
        setHectareasRecria(hectareasRecriaCache)
        setHectareasParaCalculo(hectareasPastoreoCache + hectareasCultivoCache)
    }, [datosVacas, datos])
    
    const classes = useStyles()
    const stylesButtonsPraderas = {
        color: 'white',
        borderRadius: 12,
        padding: '15px 18px 15px 18px',
        width: '100%',
        height: 50,
        fontSize: 14,
        fontWeight: 'bold',
        lineHeight: 1,
        textTransform: 'none',
        backgroundColor: '#65B34D',
        margin: '0px 5px',
        '&:hover': {
            backgroundColor: '#F5FFF7',
            color: '#65B34D'
        }
    }
    const stylesButtonsPraderasUnselected = {
        color: '#65B34D',
        borderRadius: 12,
        borderColor: '#65B34D',
        borderWidth: 2,
        borderStyle: 'solid',
        padding: '15px 18px 15px 18px',
        width: '100%',
        height: 50,
        fontSize: 14,
        fontWeight: 'bold',
        lineHeight: 1,
        textTransform: 'none',
        backgroundColor: 'transparent',
        margin: '0px 5px',
        '&:hover': {
            backgroundColor: '#65B34D',
            color: 'white'
        }
    }
    const stylesButtonsDesempenoAnimal = {
        color: 'white',
        borderRadius: 12,
        padding: '15px 18px 15px 18px',
        width: '100%',
        height: 50,
        fontSize: 14,
        fontWeight: 'bold',
        lineHeight: 1,
        textTransform: 'none',
        backgroundColor: '#03559E',
        margin: '0px 5px',
        '&:hover': {
            backgroundColor: '#F5FFF7',
            color: '#03559E'
        }
    }
    const stylesButtonsDesempenoAnimalUnselected = {
        color: '#03559E',
        borderRadius: 12,
        borderColor: '#03559E',
        borderWidth: 2,
        borderStyle: 'solid',
        padding: '15px 18px 15px 18px',
        width: '100%',
        height: 50,
        fontSize: 14,
        fontWeight: 'bold',
        lineHeight: 1,
        textTransform: 'none',
        backgroundColor: 'transparent',
        margin: '0px 5px',
        '&:hover': {
            backgroundColor: '#03559E',
            color: 'white'
        }
    }
    const data = [
        { name: 'Hectáreas de pastoreo', value: hectareasPastoreo, color: '#E2231A' },
        { name: 'Hectáreas de cultivo', value: hectareasCultivo, color: '#0072BA' },
        { name: 'Hectáreas para recría', value: hectareasRecria, color: '#539301' }
    ]
    const dataVacas = [
        { name: 'Vacas ordeñadas', value: vOrdenas, color: '#43B9DD' },
        { name: 'Otras', value: (vReparto + vSecas), color: '#00408A' }
    ]
    const RADIAN = Math.PI / 180
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5
        const x = cx + radius * Math.cos(-midAngle * RADIAN)
        const y = cy + radius * Math.sin(-midAngle * RADIAN)
        return (
            <>
                <text style={{ fontSize: 30 }} x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                    {`${(percent * 100).toFixed(0)}%`}
                </text>
            </>
        )
    }

    useEffect(() => {
        if (litrosHectarea > 0 && grasaHectarea > 0 && proteinaHectarea > 0) {
            setDataPraderas(data)
        }
    }, [litrosHectarea, grasaHectarea, proteinaHectarea])

    const init = async () => {
        const response = await axios.post('/kpi/getKpiVacasBySociedadIdOne', {salas: salaSeleccionada})
        if (response.data.kpiVacas.length > 0)
        setDatosVacas(response.data.kpiVacas)
        const response2 = await axios.post('/kpi/getKpiBySociedadIdOne', {salas: salaSeleccionada})
        if (response2.data.kpi.length > 0)
        setDatos(response2.data.kpi)
    }

    useEffect(() => {
        const salaSeleccionadaData = JSON.parse(localStorage.getItem('SalaSeleccionada'))
        setOpenBackdrop(true)
        if (salasFiltered.length > 0 && salaSeleccionadaData) {
            if (salaSeleccionadaData) {
                setSalaSeleccionada(salaSeleccionadaData.salaSeleccionada)
                setSalaSeleccionadaCode(salaSeleccionadaData.salaSeleccionadaCode)
                setPredioFilter(salaSeleccionadaData.predioFilter)
                setSalaSeleccionadaState(salaSeleccionadaData.salaSeleccionadaState)
                setSelectionType(salaSeleccionadaData.selectionType)
                setOpenBackdrop(false)
            }
        } else {
            setOpenBackdrop(false)
        }
    },[salasFiltered])

    const selectPraderasMes = () => {
        setSelectionType('hectarea')
        setPromedioSeleccionado('mensual')
        const buttons = [...buttonsKpiPraderas]
        buttons.forEach(button => {
            if (button.id === 0) {
                button.state = true
            } else {
                button.state = false
            }
        })
        setButtonsPraderas(buttons)
    }

    const selectPraderasAno = () => {
        setSelectionType('hectarea')
        setPromedioSeleccionado('anual')
        const buttons = [...buttonsKpiPraderas]
        buttons.forEach(button => {
            if (button.id === 1) {
                button.state = true
            } else {
                button.state = false
            }
        })
        setButtonsPraderas(buttons)
    }

    const selectDesempenoAnimalDia = () => {
        setSelectionType('vaca')
        setPromedioSeleccionado('dia')
        const buttons = [...buttonsKpiDesempenoAnimal]
        buttons.forEach(button => {
            if (button.id === 0) {
                button.state = true
            } else {
                button.state = false
            }
        })
        setButtonsVacas(buttons)
    }

    const selectDesempenoAnimalMes = () => {
        setSelectionType('vaca')
        setPromedioSeleccionado('mensual')
        const buttons = [...buttonsKpiDesempenoAnimal]
        buttons.forEach(button => {
            if (button.id === 1) {
                button.state = true
            } else {
                button.state = false
            }
        })
        setButtonsVacas(buttons)
    }

    const selectDesempenoAnimalAno = () => {
        setSelectionType('vaca')
        setPromedioSeleccionado('anual')
        const buttons = [...buttonsKpiDesempenoAnimal]
        buttons.forEach(button => {
            if (button.id === 2) {
                button.state = true
            } else {
                button.state = false
            }
        })
        setButtonsVacas(buttons)
    }

    const open = Boolean(anchorEl);
    const openVacaData = Boolean(anchorElVacas)
    const openVerData = Boolean(anchorElVerData)
    const id = (open || openVacaData || openVerData) ? 'simple-popover' : undefined;

    const handleClose = () => {
        setAnchorEl(null)
        setAnchorElVacas(null)
        init()
    }

    const handleCloseVerData = () => {
        setAnchorVerData(null)
    }
    
    const handleClick = (event) => {
        if (event.target.textContent === '+ Ingresar Superficie') {
            setTipoDatoSelecciondo('hectareas')
            setAnchorEl(event.currentTarget)
        } else {
            setTipoDatoSelecciondo('vacas')
            setAnchorElVacas(event.currentTarget)
        }
    }

    const handleClickVerData = (event) => {
        if (event.target.textContent === 'Ver Datos Hectáreas') {
            setTipoDatoSelecciondo('hectareas')
            setAnchorVerData(event.currentTarget)
        } else {
            setTipoDatoSelecciondo('vacas')
            setAnchorVerData(event.currentTarget)
        }
    }

    const handleChangeSala = (event, child) => {
        setSalaSeleccionada(event.target.value)
        setSalaSeleccionadaCode(event.nativeEvent.target.innerText)
        setPredioFilter([event.nativeEvent.target.innerText.substring(0,5)])
        setSalaSeleccionadaState(true)
        setSelectionType(undefined)
        const selectionData = {
            salaSeleccionada: event.target.value,
            salaSeleccionadaCode: event.nativeEvent.target.innerText,
            predioFilter: [event.nativeEvent.target.innerText.substring(0,5)],
            salaSeleccionadaState: true,
            selectionType: undefined
        }
        localStorage.setItem('SalaSeleccionada', JSON.stringify(selectionData))
    }

    useEffect(() => {
        init()
    },[salaSeleccionada])

    const toModoEditor = () => {
        history.push('/kpiEditor')
    }

    const numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return (
        <div>
            {/* <Backdrop
                sx={{ color: '#fff', zIndex: 1 }}
                open={openBackdrop}
                >
                <CircularProgress color="inherit" />
            </Backdrop> */}
            {openVerData && <VerDatoPopover
                open={openVerData}
                anchorEl={anchorElVerData}
                handleClose={handleCloseVerData}
                data={{
                    title: (tipoDatoSeleccionado==='hectareas') ? 'Datos de hectárea' : 'Datos de vacas',
                    data: {
                        hectarea: {
                            hectareasTotales: hectareasTotales,
                            hectareasPastoreo: hectareasPastoreo,
                            hectareasCultivo: hectareasCultivo,
                            hectareasRecria: hectareasRecria
                        },
                        vaca: {
                            vMasa: vMasa,
                            vOrdenas: vOrdenas,
                            vReparto: vReparto,
                            vSecas: vSecas
                        }
                    }
                }}
            />}
            <NuevoDatoPopover
                id={id}
                anchorEl={anchorEl}
                filtered={filtered}
                tipoDatoSeleccionado={tipoDatoSeleccionado}
                elementData={elementData}
                open={open}
                handleClose={handleClose}
                setElementData={setElementData}
                toModoEditor={toModoEditor}
                isKPIPage={true}
            />
            <NuevoDatoVacasPopover
                id={id}
                anchorEl={anchorElVacas}
                filtered={filtered}
                tipoDatoSeleccionado={tipoDatoSeleccionado}
                elementDataVacas={elementDataVacas}
                openVacaData={openVacaData}
                handleClose={handleClose}
                setElementDataVacas={setElementDataVacas}
                toModoEditor={toModoEditor}
                isKPIPage={true}
            />
            <Grid container>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                    <FormControl fullWidth variant="outlined" className={classes.formControl}>
                        <InputLabel>Productor</InputLabel>
                        <Select
                            value={salaSeleccionada}
                            /* defaultValue="" */
                            onChange={handleChangeSala}
                            label={'Productor'}
                        >
                            {
                                salasFiltered.map((sala, i) => {
                                    return (
                                        <MenuItem key={i} value={sala._id} onClick={(e) => {console.log(e); handleChangeSala(e)}}>
                                            {sala.producerCode} - {sala.name}
                                        </MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            {
                !salaSeleccionadaState
                ?
                <Grid container>
                    <Grid item xs={12}>
                        <FormControl fullWidth variant="outlined" className={classes.formControl}>
                            {
                                openBackdrop
                                ?
                                <div>
                                    <CircularProgress color="inherit" /> Buscando sala.
                                </div>
                                : 
                                <div> 
                                    <h3>Seleccione una sala</h3>
                                </div>    
                            }
                        </FormControl>
                    </Grid>
                </Grid>
                :
                <>
                <Grid container>
                    <Grid item xs={12}>
                        <FormControl fullWidth variant="outlined" className={classes.formControl}>
                            <h3>Sala seleccionada: {salaSeleccionadaCode}</h3>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={8}>
                        <h1>
                            KPI Praderas lechería
                        </h1>
                    </Grid>
                    <Grid item xs={4}>
                        <div style={{ width: '100%', textAlign: 'right' }}>
                        <h1>
                            <button disabled={(decimales === 0)} name='Quitar decimales' title='Quitar decimales' onClick={() => {setDecimales((decimales - 1))}} style={{ padding: '5px 10px', marginLeft: 10, borderRadius: 20, borderColor: 'transparent', backgroundColor: '#ccc', color: 'white', fontWeight: 'bold', cursor: 'pointer' }}>
                                <img style={{filter: 'invert(15%) sepia(88%) saturate(3261%) hue-rotate(194deg) brightness(100%) contrast(103%)'}} src="./icons/removeDecimal.png" width={20} alt="" />
                            </button>
                            <button disabled={(decimales === 2)} name='Aumentar decimales' title='Aumentar decimales' onClick={() => {setDecimales((decimales + 1))}} style={{ padding: '5px 10px', marginLeft: 10, borderRadius: 20, borderColor: 'transparent', backgroundColor: '#ccc', color: 'white', fontWeight: 'bold', cursor: 'pointer' }}>
                                <img style={{filter: 'invert(15%) sepia(88%) saturate(3261%) hue-rotate(194deg) brightness(100%) contrast(103%)'}} src="./icons/addDecimal.png" width={20} alt="" />
                            </button>
                            { havePermission && 
                                <button name='Agregar o editar datos' title='Agregar o editar datos' onClick={toModoEditor} style={{ padding: '5px 10px', marginLeft: 10, borderRadius: 20, borderColor: 'transparent', backgroundColor: '#ccc', color: 'white', fontWeight: 'bold', cursor: 'pointer' }}>
                                    <img style={{filter: 'invert(15%) sepia(88%) saturate(3261%) hue-rotate(194deg) brightness(100%) contrast(103%)'}} src="./icons/edit.png" width={20} alt="" />
                                </button>
                            }
                        </h1>
                        </div>
                    </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginTop: 8 }}>
                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6} container>
                        <p style={{ fontSize: 20, marginTop: 0, color: '#575C58', width: '100%' }}>
                            Hectáreas totales: {hectareasTotales}
                        </p>
                        <br />
                        <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                            {dataPraderas ? <PieChart width={400} height={400}>
                                <Pie
                                    data={dataPraderas}
                                    cx="50%"
                                    cy="50%"
                                    labelLine={false}
                                    label={renderCustomizedLabel}
                                    outerRadius={190}
                                    fill="#8884d8"
                                    dataKey="value"
                                >
                                    {dataPraderas.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={entry.color} />
                                    ))}
                                </Pie>
                            </PieChart> : <div style={{ width: 400, height: 400 }}></div>}
                            <br />
                            <div style={{ width: '100%', textAlign: 'center', marginTop: 20, marginBottom: 20 }}>
                                <Grid container>
                                    {
                                        data.map((dato, i) => {
                                            return (
                                                <Grid key={i} item lg={6} xl={6} style={{marginBottom: 10}}>
                                                    <Grid container>
                                                        <Grid item>
                                                            <div style={{ height: 20, width: 30, backgroundColor: dato.color }}></div>
                                                        </Grid>
                                                        <Grid item>
                                                            <p style={{ margin: 0, marginLeft: 10, fontSize: 11 }}>{dato.name}</p>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            )
                                        })
                                    }
                                </Grid>
                            </div>
                            <div style={{ width: '100%', textAlign: 'center' }}>
                                {havePermission && 
                                <Button
                                    onClick={handleClick}
                                    variant="contained"
                                    style={{ backgroundColor: '#539301', color: 'white', marginRight: 5 }}>
                                    <p style={{ fontSize: 15, margin: 0 }}>
                                        + Ingresar Superficie
                                    </p>
                                </Button>}
                                
                                <Button
                                    onClick={handleClickVerData}
                                    variant="contained"
                                    style={{ backgroundColor: '#0072BA', color: 'white', marginLeft: 5 }}>
                                    <p style={{ fontSize: 15, margin: 0 }}>
                                        Ver Datos Hectáreas
                                    </p>
                                </Button>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6} container>
                        <Grid container >
                            {
                                buttonsPraderas.map((button, index) => {
                                    return (
                                        <Grid key={index} item xs={12} sm={12} md={12} lg={6} xl={6} container>
                                            <ButtonKpiSelected style={button.state ? stylesButtonsPraderas : stylesButtonsPraderasUnselected} onClick={(button.id === 0) ? selectPraderasMes : selectPraderasAno}>
                                                {button.name}
                                            </ButtonKpiSelected>
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                        <Grid container>
                            <div style={{ width: '100%', margin: 10, borderRadius: 20, textAlign: 'center', backgroundColor: '#F5FFF7', color: '#65B34D' }} /* onMouseOver={handleOpenDataPasada} */>
                                <h1 style={{ fontSize: 80, marginTop: 30, marginBottom: 20 }}>
                                    {(predioFilter.length === 1) ? numberWithCommas(litrosHectarea.toFixed(decimales)) : "0"}
                                </h1>
                                <p style={{ fontSize: 20, marginTop: 0, color: '#575C58' }}>
                                    Litros / Hectárea
                                </p>
                            </div>
                        </Grid>
                        <Grid container >
                            <Grid item xs={12} sm={12} md={12} lg={4} xl={4} container>
                                <div style={{ width: '100%', margin: 5, borderRadius: 20, textAlign: 'center', backgroundColor: '#F5FFF7', color: '#65B34D' }}>
                                    <h1 style={{ fontSize: 45, marginTop: 13, marginBottom: 20 }}>
                                    {(predioFilter.length === 1) ? numberWithCommas((grasaHectarea).toFixed(decimales)) : "0"}
                                    </h1>
                                    <p style={{ fontSize: 20, marginTop: 10, color: '#575C58' }}>
                                        Kg Grasa / <br />
                                        Hectárea
                                    </p>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={4} xl={4} container>
                                <div style={{ width: '100%', margin: 5, borderRadius: 20, textAlign: 'center', backgroundColor: '#F5FFF7', color: '#65B34D' }}>
                                    <h1 style={{ fontSize: 45, marginTop: 13, marginBottom: 20 }}>
                                    {(predioFilter.length === 1) ? numberWithCommas((proteinaHectarea).toFixed(decimales)) : "0"}
                                    </h1>
                                    <p style={{ fontSize: 20, marginTop: 10, color: '#575C58' }}>
                                        Kg Proteína / <br />
                                        Hectárea
                                    </p>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={4} xl={4} container>
                                <div style={{ width: '100%', margin: 5, borderRadius: 20, textAlign: 'center', backgroundColor: '#F5FFF7', color: '#65B34D' }}>
                                    <h1 style={{ fontSize: 45, marginTop: 13, marginBottom: 20 }}>
                                    {(predioFilter.length === 1) ? numberWithCommas((Number(grasaHectarea) + Number(proteinaHectarea)).toFixed(decimales)) : "0"}
                                    </h1>
                                    <p style={{ fontSize: 20, marginTop: 10, color: '#575C58' }}>
                                        Kg Sólido / <br />
                                        Hectárea
                                    </p>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <h1>
                    KPI Desempeño animal
                </h1>
                <Grid container spacing={2} style={{ marginTop: 8 }}>
                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6} container>
                        <p style={{ fontSize: 20, marginTop: 0, color: '#575C58', width: '100%' }}>
                            Vacas totales: {vMasa}
                        </p>
                        <br />
                        <div style={{ marginLeft: 'auto', marginRight: 'auto', position: 'relative' }}>
                            <div style={{ position: 'absolute', top: '13%', left: '0%', width: 400, fontSize: 70, textAlign: 'center', color: '#43B9DD' }}>
                                <p><strong>{((vOrdenas*100)/vMasa).toFixed(0)}%</strong></p>
                            </div>
                            <PieChart width={400} height={400}>
                                <Pie
                                    data={dataVacas}
                                    cx="50%"
                                    cy="50%"
                                    innerRadius={120}
                                    outerRadius={190}
                                    fill="#8884d8"
                                    paddingAngle={5}
                                    dataKey="value"
                                >
                                    {
                                        dataVacas.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={entry.color} />
                                        ))
                                    }
                                </Pie>
                            </PieChart>
                            <br />
                            <div style={{ width: '100%', textAlign: 'center', marginTop: 20, marginBottom: 20 }}>
                                <Grid container>
                                    {
                                        dataVacas.map((dato, i) => {
                                            return (
                                                <Grid key={i} item lg={12} xl={12} style={{marginBottom: 10}}>
                                                    <Grid container>
                                                        <Grid item>
                                                            <div style={{ height: 20, width: 30, backgroundColor: dato.color }}></div>
                                                        </Grid>
                                                        <Grid item>
                                                            <p style={{ margin: 0, marginLeft: 10 }}>{dato.name}</p>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            )
                                        })
                                    }
                                </Grid>
                            </div>
                            <div style={{ width: '100%', textAlign: 'center' }}>
                                {
                                    havePermission 
                                    && 
                                    <Button 
                                    onClick={handleClick}  
                                    variant="contained" 
                                    color='primary' 
                                    style={{ backgroundColor: '#00408A', color: 'white', marginRight: 5 }}>
                                        <p style={{ fontSize: 15, margin: 0 }}>
                                            + Ingresar Vaca Masa
                                        </p>
                                    </Button>
                                }
                                <Button
                                    onClick={handleClickVerData}
                                    variant="contained"
                                    style={{ backgroundColor: '#0072BA', color: 'white', marginLeft: 5 }}>
                                    <p style={{ fontSize: 15, margin: 0 }}>
                                        Ver Datos Vacas
                                    </p>
                                </Button>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6} container>
                        <Grid container >
                            {
                                buttonsVacas.map((button, index) => {
                                    return (
                                        <Grid key={index} item xs={12} sm={12} md={12} lg={4} xl={4} container>
                                            <ButtonKpiSelected
                                                style={button.state ? stylesButtonsDesempenoAnimal : stylesButtonsDesempenoAnimalUnselected}
                                                onClick={button.id === 0 ? selectDesempenoAnimalDia : (button.id === 1 ? selectDesempenoAnimalMes : selectDesempenoAnimalAno)}
                                            >
                                                {button.name}
                                            </ButtonKpiSelected>
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                        <Grid container >
                            <Grid item xs={12} sm={12} md={12} lg={6} xl={6} container>
                                <div style={{ width: '100%', margin: 5, borderRadius: 20, textAlign: 'center', backgroundColor: '#F4FCFF', color: '#30649E' }}>
                                    <h1 style={{ fontSize: 60, marginTop: 15, marginBottom: 5 }}>
                                        {numberWithCommas(litrosVacaOrdenada.toFixed(decimales))}
                                    </h1>
                                    <p style={{ fontSize: 14, marginTop: 10, color: '#575C58' }}>
                                        Litros / vaca ordeñada
                                    </p>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={6} xl={6} container>
                                <div style={{ width: '100%', margin: 5, borderRadius: 20, textAlign: 'center', backgroundColor: '#F4FCFF', color: '#30649E' }}>
                                    <h1 style={{ fontSize: 60, marginTop: 15, marginBottom: 5 }}>
                                        {numberWithCommas(litrosVacaMasa.toFixed(decimales))}
                                    </h1>
                                    <p style={{ fontSize: 14, marginTop: 10, color: '#575C58' }}>
                                        Litros / vaca masa
                                    </p>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container >
                            <Grid item xs={12} sm={12} md={12} lg={4} xl={4} container>
                                <div style={{ width: '100%', margin: 5, borderRadius: 20, textAlign: 'center', backgroundColor: '#F4FCFF', color: '#30649E' }}>
                                    <h1 style={{ fontSize: 45, marginTop: 15, marginBottom: 5 }}>
                                        {numberWithCommas((grasaVacaOrdenada).toFixed(decimales))}
                                    </h1>
                                    <p style={{ fontSize: 14, marginTop: 10, color: '#575C58' }}>
                                        Kg grasa / <br />
                                        vaca ordeñada
                                    </p>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={4} xl={4} container>
                                <div style={{ width: '100%', margin: 5, borderRadius: 20, textAlign: 'center', backgroundColor: '#F4FCFF', color: '#30649E' }}>
                                    <h1 style={{ fontSize: 45, marginTop: 15, marginBottom: 5 }}>
                                        {numberWithCommas((proteinasVacaOrdenada).toFixed(decimales))}
                                    </h1>
                                    <p style={{ fontSize: 14, marginTop: 10, color: '#575C58' }}>
                                        Kg proteína / <br />
                                        vaca ordeñada
                                    </p>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={4} xl={4} container>
                                <div style={{ width: '100%', margin: 5, borderRadius: 20, textAlign: 'center', backgroundColor: '#F4FCFF', color: '#30649E' }}>
                                    <h1 style={{ fontSize: 45, marginTop: 15, marginBottom: 5 }}>
                                        {numberWithCommas((Number(grasaVacaOrdenada) + Number(proteinasVacaOrdenada)).toFixed(decimales))}
                                    </h1>
                                    <p style={{ fontSize: 14, marginTop: 10, color: '#575C58' }}>
                                        Kg sólidos / <br />
                                        vaca ordeñada
                                    </p>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container >
                            <Grid item xs={12} sm={12} md={12} lg={4} xl={4} container>
                                <div style={{ width: '100%', margin: 5, borderRadius: 20, textAlign: 'center', backgroundColor: '#F4FCFF', color: '#30649E' }}>
                                    <h1 style={{ fontSize: 45, marginTop: 15, marginBottom: 5 }}>
                                        {numberWithCommas((grasaVacaMasa).toFixed(decimales))}
                                    </h1>
                                    <p style={{ fontSize: 14, marginTop: 10, color: '#575C58' }}>
                                        Kg grasa / <br />
                                        vaca masa
                                    </p>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={4} xl={4} container>
                                <div style={{ width: '100%', margin: 5, borderRadius: 20, textAlign: 'center', backgroundColor: '#F4FCFF', color: '#30649E' }}>
                                    <h1 style={{ fontSize: 45, marginTop: 15, marginBottom: 5 }}>
                                        {numberWithCommas((proteinasVacaMasa).toFixed(decimales))}
                                    </h1>
                                    <p style={{ fontSize: 14, marginTop: 10, color: '#575C58' }}>
                                        Kg proteína / <br />
                                        vaca masa
                                    </p>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={4} xl={4} container>
                                <div style={{ width: '100%', margin: 5, borderRadius: 20, textAlign: 'center', backgroundColor: '#F4FCFF', color: '#30649E' }}>
                                    <h1 style={{ fontSize: 45, marginTop: 15, marginBottom: 5 }}>
                                        {(numberWithCommas((Number(grasaVacaMasa) + Number(proteinasVacaMasa)).toFixed(decimales)))}
                                    </h1>
                                    <p style={{ fontSize: 14, marginTop: 10, color: '#575C58' }}>
                                        Kg sólidos / <br />
                                        vaca masa
                                    </p>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    {/* <div style={{ width: '100%', textAlign: 'center', marginTop: 100 }}>
                        <a href="#"><img src="./pdf-icon.png" width={30} /> Descargar datos hasta la fecha</a>
                    </div> */}
                </Grid>
                </>
            }
            <br />
            <br />
            <br />
        </div>
    )
}

export default Kpi
