import React, { useEffect, useState } from "react"
import { Grid } from "@material-ui/core"
import axios from "axios"

const EquipoAgricolaComponent = ({classNameText}) => {
    const [isSelection, setIsSelection] = useState(false)
    const [listaEquipo, setListaEquipo] = useState([])
    const [openData, setOpenData] = useState(false)

    useEffect(() => {
        init()
    }, [])

    const init = async () => {
        const response = await axios.get('/equipo/leerEquipo')
        setListaEquipo(response.data.data)
        setTimeout(() => {
            setOpenData(true)
        }, 1000);
    }

    const selection = () => {
        if (isSelection) {
            setIsSelection(false)
        } else {
            setIsSelection(true)
        }
    }
    return (
        <Grid container>
            <Grid item xs={12}>
                <div className='welcome-text'>
                    <div className='welcome-text-title'>
                        <p>Equipo Agrícola</p>
                    </div>
                    <div className={classNameText}>
                        {openData && <Grid container  style={{ position: 'relative' }}>
                            <div className={!isSelection ? "icono-equipo" : "icono-equipo-activo"} onClick={selection}>
                                <img src="./landing/icono-equipo.png" width={30} alt="equipo-icono" />
                            </div>
                            <Grid item xs={2}>
                                
                            </Grid>
                            {
                                isSelection
                                ?
                                <Grid item xs={8}>
                                    <Grid container className="user-list">
                                        {
                                            listaEquipo.map((usuario, index) => {
                                                return (
                                                    <Grid container key={index}>
                                                        <Grid item xs={6}>
                                                            <p className="user-item cargo">{usuario.charge}</p>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <p className="user-item text-12">{usuario.name} {usuario.lastName}</p>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <a className="user-item text-12" href={`mailto:${usuario.email}`}>{usuario.email}</a>
                                                        </Grid>
                                                    </Grid>
                                                )
                                            })
                                        }
                                    </Grid>
                                </Grid>
                                :
                                <Grid item xs={8}>
                                    <div className="equipo-agricola">
                                        <p className="title-equipo-agricola">Gerente Depto. Agrícola</p>
                                    </div>
                                    <Grid container>
                                        <Grid item xs={3}>
                                            
                                        </Grid>
                                        <Grid item xs={3}>
                                            <div className="equipo-agricola bottomLine rightLine">
                                                <p className="title-equipo-table">Distrito Norte</p>
                                            </div>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <div className="equipo-agricola bottomLine rightLine">
                                                <p className="title-equipo-table">Distrito Los Lagos</p>
                                            </div>                                        
                                        </Grid>
                                        <Grid item xs={3}>
                                            <div className="equipo-agricola bottomLine">
                                                <p className="title-equipo-table">Distrito Osorno</p>
                                            </div>                                        
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={3}>
                                            <div className="equipo-agricola">
                                                <p className="title-equipo-table-row">Sub Gerente de procesos lácteos</p>
                                            </div>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <div className="equipo-agricola bottomLine rightLine">
                                                <img src="./user.png" alt="user" className="icon-user" width={35} />
                                                <p className="title-equipo-table-content">Marcelo Rippes</p>
                                            </div>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <div className="equipo-agricola bottomLine rightLine">
                                                <img src="./user.png" alt="user" className="icon-user" width={35} />    
                                                <p className="title-equipo-table-content">Cristian De la Paz</p>
                                            </div>                                        
                                        </Grid>
                                        <Grid item xs={3}>
                                            <div className="equipo-agricola bottomLine">
                                                <img src="./user.png" alt="user" className="icon-user" width={35} />    
                                                <p className="title-equipo-table-content">Carlos Silva</p>
                                            </div>                                        
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={3}>
                                            <div className="equipo-agricola">
                                                <p className="title-equipo-table-row">Equipo Agropecuario de terreno</p>
                                            </div>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <div className="equipo-agricola bottomLine rightLine">
                                                <img src="./user.png" alt="user" className="icon-user" width={35} />    
                                                <p className="title-equipo-table-content">Lautaro Catrileo</p>
                                                <img src="./user.png" alt="user" className="icon-user" width={35} />    
                                                <p className="title-equipo-table-content">Felipe Lembeye</p>
                                            </div>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <div className="equipo-agricola bottomLine rightLine">
                                                <img src="./user.png" alt="user" className="icon-user" width={35} />    
                                                <p className="title-equipo-table-content">César González</p>
                                                <img src="./user.png" alt="user" className="icon-user" width={35} />    
                                                <p className="title-equipo-table-content">Juan Pablo Soto</p>
                                            </div>                                        
                                        </Grid>
                                        <Grid item xs={3}>
                                            <div className="equipo-agricola bottomLine">
                                                <img src="./user.png" alt="user" className="icon-user" width={35} />    
                                                <p className="title-equipo-table-content">Ana María Manzilla</p>
                                                <img src="./user.png" alt="user" className="icon-user" width={35} />    
                                                <p className="title-equipo-table-content">Juan Pablo García</p>
                                            </div>                                        
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={3}>
                                            <div className="equipo-agricola">
                                                <p className="title-equipo-table-row">Equipo transporte de leche</p>
                                            </div>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <div className="equipo-agricola bottomLine rightLine">
                                                <img src="./user.png" alt="user" className="icon-user" width={35} />    
                                                <p className="title-equipo-table-content">Horacio Massardo</p>
                                            </div>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <div className="equipo-agricola bottomLine">
                                                <Grid container>
                                                    {/* <Grid item style={{ textAlign: 'center' }} xs={6}>
                                                    <img src="./user.png" alt="user" className="icon-user" width={35} />    
                                                        <p className="title-equipo-table-content">Flavio Quezada</p>
                                                    </Grid> */}
                                                    <Grid item style={{ textAlign: 'center' }} xs={12}>
                                                    <img src="./user.png" alt="user" className="icon-user" width={35} />    
                                                        <p className="title-equipo-table-content">Erick Lüer</p>                                                    
                                                    </Grid>
                                                </Grid>
                                            </div>                                        
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={3}>
                                            <div className="equipo-agricola">
                                                <p className="title-equipo-table-row">Administrativo agropecuario</p>
                                            </div>
                                        </Grid>
                                        <Grid item xs={9} className="bottomLine">
                                            <Grid container>
                                                <Grid item xs={3}>
                                                
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <div className="equipo-agricola">
                                                        <img src="./user.png" alt="user" className="icon-user" width={35} />    
                                                        <p className="title-equipo-table-content">Glenda Oyarzo</p>
                                                    </div>                                        
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={3}>
                                            <div className="equipo-agricola">
                                                <p className="title-equipo-table-row">Business Partner Depto. Agrícola</p>
                                            </div>
                                        </Grid>
                                        <Grid item xs={9}>
                                            <div className="equipo-agricola bottomLine">
                                                <img src="./user.png" alt="user" className="icon-user" width={35} />    
                                                <p className="title-equipo-table-content">Pía McKenzie</p>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }
                            <Grid item xs={2}>
                                
                            </Grid>
                        </Grid>}
                    </div>
                </div>
            </Grid>
        </Grid>
    )
}

export default EquipoAgricolaComponent
