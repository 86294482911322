/* React */
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Link/* , useHistory */ } from 'react-router-dom'

/* Material UI */
import {
    Avatar,
    AppBar,
    Collapse,
    CssBaseline,
    Divider,
    Drawer,
    Hidden,
    IconButton,
    List,
    Toolbar,
    makeStyles,
    useTheme,
} from '@material-ui/core'
import { Menu/* , Refresh */ } from '@material-ui/icons'

/* Context */
import { AuthContext, MenuContext } from '../../contexts'

/* Components */
import { ButtonMenu, ButtonSubMenu } from './'
import { AvatarMask, AvatarMaskMobile /*, ProlesurIcon */ } from '../icons'
/* import { PrimaryButton } from '../comun' */

const drawerWidth = 400 // 480

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('lg')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        [theme.breakpoints.up('lg')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
        background: '#fff',
        zIndex: theme.zIndex.drawer + 101,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('lg')]: {
            display: 'none',
        },
    },
    // necessary for content to be below app bar
    // toolbar: theme.mixins.toolbar,
    toolbar: {
        [theme.breakpoints.down('md')]: theme.mixins.toolbar,
    },
    drawerPaper: {
        width: drawerWidth,
        border: 0,
        '&::-webkit-scrollbar': {
            width: '0px',
            height: '0px',
        },
    },
    content: {
        flexGrow: 1,
        padding: '55px 96px 0px 96px',
    },
    collapse: {
        backgroundColor: theme.drawer.collapseBgColor,
    },
    nested: {
        backgroundColor: theme.drawer.collapseBgColor,
        [theme.breakpoints.down('md')]: {
            // marginLeft: theme.spacing(15)
            marginLeft: theme.spacing(12),
        },
        [theme.breakpoints.up('lg')]: {
            // marginLeft: theme.spacing(18)
            marginLeft: theme.spacing(13),
        },
    },
    avatar: {
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            justifyContent: 'center',
        },
        [theme.breakpoints.up('lg')]: {
            display: 'flex',
            justifyContent: 'center',
        },
        margin: 0,
        position: 'relative'
    },
    userNameDiv: {
        display: 'flex',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'flex-start',
            flex: '0 0 220px',
        },
        [theme.breakpoints.up('lg')]: {
            justifyContent: 'center',
            margin: 2.5,
        },
    },
    userNameSpan: {
        display: 'flex',
        color: theme.titleColor,
        [theme.breakpoints.down('md')]: {
            justifyContent: 'left',
            alignItems: 'left',
            textAlign: 'left',
            fontSize: 18,
            // width: 220,
        },
        [theme.breakpoints.up('lg')]: {
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            fontSize: 20,
            width: 270,
        },
        fontWeight: 'bold',
        wordWrap: 'break-word',
    },
    userJob: {
        color: theme.subTitleColor,
        display: 'flex',
        [theme.breakpoints.down('md')]: {
            fontSize: 12,
            justifyContent: 'flex-start',
            margin: 0,
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: 16,
            justifyContent: 'center',
            margin: '2.5px 0px',
        },
        fontWeight: 600,
    },
    dividerMenuItems: {
        [theme.breakpoints.down('md')]: {
            marginLeft: 50,
            marginRight: 54,
        },
        [theme.breakpoints.up('lg')]: {
            marginLeft: 70,
            marginRight: 64,
        },
    },
}))

function MenuNavigation({ window }) {
    const { menuItems, subMenuItems, open, handleDrawerToggle, handleClickMenu } = useContext(MenuContext)
    /* const history = useHistory() */
    const { userInfo, avatar } = useContext(AuthContext)
    const classes = useStyles()
    const theme = useTheme()

    const [sliceMenu, setSliceMenu] = useState([menuItems.slice(0, 6), menuItems.slice(6, 9)])
    useEffect(() => {
        setSliceMenu([menuItems.slice(0, 6), menuItems.slice(6, 9)])
    }, [menuItems])

/*     const handleReload = () => {
        history.go(0)
    } */

    const drawer = (
        <Fragment>
            <div className={classes.toolbar} />
            <Hidden mdDown>
                <div className={classes.toolbar} />
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        margin: 10,
                    }}
                >
                    <Link to="/">
                        <img /* height={50}  */src={'/logo.sorpole.png'} />
                        {/* <ProlesurIcon /> */}
                    </Link>
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        paddingInline: theme.spacing(1),
                    }}
                >
                    <div className={classes.avatar}>
                        {/* <img src={'/mask.png'}
                            style={{ position: 'absolute', bottom: -5, width: 140, height: 180 }}
                        /> */}
                        <AvatarMask
                            style={{
                                position: 'absolute',
                            }}
                        />
                        <IconButton
                            style={{
                                position: 'absolute',
                                marginTop: 6,
                                marginLeft: -3,
                                width: 123,
                                height: 122,
                                overflow: 'hidden',
                                padding: 0,
                                cursor: 'pointer',
                                borderColor: 'red',
                                borderWidth: 7,
                                borderStyle: 'solid'
                            }}
                            onClick={() => handleClickMenu(7)}
                            component={Link}
                            to="/profile"
                        >
                            {!avatar?.filetype && !avatar?.image ? (
                                <img
                                    src={`data:image/${avatar.filetype};base64,${avatar.image}`}
                                    style={{
                                        width: 80, 
                                        height: 80
                                        /* position: 'absolute',
                                        marginTop: 0,
                                        marginLeft: 0,
                                        width: 123,
                                        height: 122,
                                        borderColor: '#E2231A',
                                        borderWidth: 7,
                                        borderStyle: 'solid' */
                                        /* width: 123, 
                                        height: 122, 
                                        objectFit: 'cover',
                                        borderColor: 'red',
                                        borderWidth: 10,
                                        borderStyle: 'solid' */
                                    }}
                                />
                            ) : (
                                <Avatar
                                    style={{
                                        position: 'absolute',
                                        marginTop: 0,
                                        marginLeft: 0,
                                        width: 123,
                                        height: 122,
                                        borderColor: '#E2231A',
                                        borderWidth: 7,
                                        borderStyle: 'solid'
                                    }}
                                />
                            )}
                        </IconButton>
                    </div>
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        paddingInline: theme.spacing(1),
                        margin: 10,
                    }}
                >
                    <div className={classes.userNameDiv}>
                        <span className={classes.userNameSpan}>{userInfo?.fullName}</span>
                    </div>
                    <span className={classes.userJob}>{userInfo?.position}</span>
                </div>
                {/* <div style={{ margin: theme.spacing(2) }} /> */}
            </Hidden>
            <Hidden lgUp>
                <div style={{ display: 'block', margin: '36px auto 10px' }}>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'stretch',
                            margin: '0px 30px',
                        }}
                    >
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div className={classes.avatar}>
                                <AvatarMaskMobile
                                    style={{
                                        position: 'absolute',
                                    }}
                                />
                                <IconButton
                                    style={{
                                        borderColor: 'red',
                                        borderWidth: 7,
                                        borderStyle: 'solid',
                                        position: 'absolute',
                                        marginTop: 4,
                                        marginLeft: 0,
                                        width: 82,
                                        height: 82,
                                        overflow: 'hidden',
                                        padding: 0,
                                        cursor: 'pointer',
                                        /* position: 'absolute',
                                        marginTop: 6,
                                        marginLeft: -3,
                                        width: 123,
                                        height: 122,
                                        overflow: 'hidden',
                                        padding: 0,
                                        cursor: 'pointer',
                                        borderColor: 'red',
                                        borderWidth: 7,
                                        borderStyle: 'solid' */
                                    }}
                                    onClick={() => handleClickMenu(7)}
                                    component={Link}
                                    to="/profile"
                                >
                                    {avatar?.filetype && avatar?.image ? (
                                        <img
                                            src={`data:image/${avatar.filetype};base64,${avatar.image}`}
                                            style={{ width: 75, height: 75, objectFit: 'cover' }}
                                        />
                                    ) : (
                                        <Avatar
                                            style={{
                                                position: 'absolute',
                                                marginTop: 4,
                                                marginLeft: 0,
                                                width: 82,
                                                height: 82,
                                            }}
                                        />
                                    )}
                                </IconButton>
                            </div>
                        </div>
                        <div style={{ margin: 'auto 0px auto 16px' }}>
                            <div className={classes.userNameDiv}>
                                <span className={classes.userNameSpan}>{userInfo?.fullName}</span>
                            </div>
                            <span className={classes.userJob}>{userInfo?.position}</span>
                        </div>
                    </div>
                </div>
            </Hidden>
            <div style={{ overflowY: 'auto', textAlign: 'center' }}>
                {/* <PrimaryButton
                    onClick={handleReload}
                    startIcon={<Refresh />}
                    style={{ height: 'unset', padding: 10, marginTop: 10 }}
                >
                    Cargar cambios
                </PrimaryButton> */}
                {sliceMenu.map((item, index) => (
                    <div key={index}>
                        <List>
                            {item.map((item, index) => (
                                <Fragment key={index}>
                                    <ButtonMenu
                                        id={item.id}
                                        text={item.text}
                                        isCollapsible={item.isCollapsible}
                                        isOpen={item.open}
                                        {...(item.link && { link: item.link })}
                                    />
                                    {item.isCollapsible ? (
                                        <Collapse
                                            key={index}
                                            in={item.open}
                                            timeout="auto"
                                            unmountOnExit
                                            className={classes.collapse}
                                        >
                                            <List component="div" disablePadding className={classes.nested}>
                                                {subMenuItems.map((subItem, index) =>
                                                    subItem.menuId === item.id ? (
                                                        <ButtonSubMenu
                                                            key={index}
                                                            id={subItem.id}
                                                            text={subItem.text}
                                                            {...(subItem.link && { link: subItem.link })}
                                                        />
                                                    ) : (
                                                        <Fragment key={index}></Fragment>
                                                    )
                                                )}
                                            </List>
                                        </Collapse>
                                    ) : (
                                        <></>
                                    )}
                                </Fragment>
                            ))}
                        </List>
                        {index < sliceMenu.length - 1 ? <Divider className={classes.dividerMenuItems} /> : <></>}
                    </div>
                ))}
            </div>
        </Fragment>
    )

    const container = window !== undefined ? () => window().document.body : undefined

    return (
        <div className={classes.root}>
            <CssBaseline />
            <Hidden lgUp>
                <div style={{ flexGrow: 1 }}>
                    <AppBar position="absolute" elevation={0} className={classes.appBar}>
                        <Toolbar>
                            <IconButton
                                color="secondary"
                                aria-label="open drawer"
                                edge="start"
                                onClick={handleDrawerToggle}
                                className={classes.menuButton}
                            >
                                <Menu />
                            </IconButton>
                            <div style={{ flexGrow: 1 }} />
                            <Link to="/">
                                <img height={50} src={'/logo.sorpole.png'} />
                                {/* <ProlesurIcon style={{ margin: theme.spacing(1) }} /> */}
                            </Link>
                        </Toolbar>
                    </AppBar>
                </div>
            </Hidden>
            <nav className={classes.drawer} aria-label="mailbox folders">
                <Hidden lgUp>
                    <Drawer
                        container={container}
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'bottom' : 'top'}
                        open={open}
                        onClose={handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true,
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
                <Hidden mdDown>
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
            </nav>
        </div>
    )
}

export default MenuNavigation
