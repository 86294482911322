/* GraphQL */
import { gql } from '@apollo/client'

export const SOCIEDADES = gql`
    query Sociedades($sociedadIds: [ID]) {
        sociedades(sociedadIds: $sociedadIds) {
            _id
            masterCode
            name
            RUT
            description
            enabled
            createdAt
            updatedAt
        }
    }
`
