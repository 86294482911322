/* React */
import React, { Fragment, useContext, useState } from 'react'

/* Material UI */
import {
    Hidden,
    useTheme,
    CssBaseline,
    Paper,
    Grid,
    makeStyles,
    Typography,
    TextField,
    Button,
    withStyles,
} from '@material-ui/core'

/* Components */
import BgImage from '../bg.jpg'
import { ProlesurIcon } from '../components/icons'
import { Link, Redirect, useParams } from 'react-router-dom'
import { AuthContext } from '../contexts'
import { useSnackbar } from 'notistack'

const useStyles = makeStyles(theme => ({
    root: {
        height: '100vh',
        [theme.breakpoints.down('xs')]: {
            padding: '48px 14px',
        },
        [theme.breakpoints.up('sm')]: {
            padding: '48px 18%',
        },
        [theme.breakpoints.up('lg')]: {
            padding: '96px 18%',
        },
    },
    bgImage: {
        background: `linear-gradient(rgba(255,255,255,.8), rgba(255,255,255,.8)), url(${BgImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    loginImage: {
        backgroundImage: `url(${BgImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    paper: {
        borderTopLeftRadius: theme.spacing(2),
        borderBottomLeftRadius: theme.spacing(2),
    },
    divPaper: {
        margin: '64px 18%',
        // display: 'flex',
        // flexDirection: 'column',
    },
    paperMobil: {
        // height: '100%',
        borderRadius: 12,
    },
    paperMobilContent: {
        padding: theme.spacing(4, 4),
    },
    prolesur: {
        [theme.breakpoints.down('md')]: {
            marginTop: 0,
            marginBottom: 30,
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: 0,
            marginBottom: 120,
        },
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'flex',
    },
    welcome: {
        display: 'flex',
        color: '#7e8792',
        fontSize: 13,
        fontWeight: 'normal',
        textAlign: 'left',
    },
    title: {
        display: 'flex',
        color: '#292929',
        [theme.breakpoints.down('md')]: {
            fontSize: 24,
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: 32,
        },
        fontWeight: 800,
        textAlign: 'left',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        // marginTop: theme.spacing(1),
    },
    textField: {
        // '& .MuiFormLabel-root': {
        //     color: '#7e8792'
        // },
        // "& .MuiFormLabel-root.Mui-focused": {
        //     color: 'primary'
        // },
        '& .MuiInputBase-input': {
            color: '#292929',
            [theme.breakpoints.down('md')]: {
                fontSize: 12,
            },
            [theme.breakpoints.up('lg')]: {
                fontSize: 16,
            },
            // fontFamily: 'Open Sans',
            fontWeight: 600,
        },
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    forgetPass: {
        textAlign: 'center',
    },
}))

const MyButton = withStyles(theme => ({
    root: {
        color: '#fff',
        backgroundColor: '#72bf44',
        [theme.breakpoints.down('md')]: {
            fontSize: 12,
            height: 44,
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: 16,
            height: 57,
        },
        fontWeight: 600,
        borderRadius: 24,
        '&:hover': {
            backgroundColor: '#729e44',
        },
    },
}))(Button)

const ResetForm = () => {
    const classes = useStyles()

    const { changePassword } = useContext(AuthContext)
    const [password, setPassword] = useState('')
    const [repeat, setRepeat] = useState('')
    const [redirect, setRedirect] = useState(false)
    const { token } = useParams()

    const { enqueueSnackbar } = useSnackbar()

    const handleSubmitReset = event => {
        event.preventDefault()
        if (!password || password !== repeat) {
            return enqueueSnackbar('Contraseña en ambos campos debe coincidir.', {
                variant: 'error',
            })
        }
        changePassword(token, password)
            .then(response => {
                if (!response.ok && response.status !== 400) {
                    return Promise.reject(response)
                }
                return response.json().then(json => ({
                    status: response.status,
                    ok: response.ok,
                    ...json,
                }))
            })
            .then(response => {
                if (response.ok) {
                    enqueueSnackbar(response.message, { variant: 'success' })
                    setRedirect(true)
                } else {
                    enqueueSnackbar(response.message, { variant: 'error' })
                }
            })
            .catch(async err => {
                console.log('ERROR', err.response.message)
                if (err.status === 401) {
                    return enqueueSnackbar('Error al cambiar contraseña. Token expirado.', { variant: 'error' })
                }
                return enqueueSnackbar('Error al cambiar contraseña. Error en el servidor.', { variant: 'error' })
            })
    }

    return (
        <Fragment>
            {redirect && <Redirect to="/" />}
            <Link to="/">
                <ProlesurIcon className={classes.prolesur} />
            </Link>
            <Typography className={classes.title} component="h1" variant="h5">
                Reiniciar contraseña
            </Typography>
            <form className={classes.form} noValidate onSubmit={handleSubmitReset}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Contraseña"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    className={classes.textField}
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="repeat"
                    label="Repetir contraseña"
                    type="password"
                    id="repeat"
                    autoComplete="current-password"
                    className={classes.textField}
                    value={repeat}
                    onChange={e => setRepeat(e.target.value)}
                />
                <MyButton
                    type="submit"
                    fullWidth
                    variant="contained"
                    // color="primary"
                    disableElevation
                    className={classes.submit}
                    // onClick={() => onClickIniciarSesion()}
                >
                    Cambiar Contraseña
                </MyButton>
            </form>
        </Fragment>
    )
}

const ResetPassword = () => {
    const theme = useTheme()
    const classes = useStyles()

    return (
        <div className={classes.bgImage}>
            <CssBaseline />
            <Hidden mdDown>
                <Grid container component="main" className={classes.root}>
                    <Grid item xs={6} component={Paper} elevation={0} square className={classes.paper}>
                        <div className={classes.divPaper}>
                            <ResetForm />
                        </div>
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        className={classes.loginImage}
                        style={{
                            borderTopRightRadius: theme.spacing(2),
                            borderBottomRightRadius: theme.spacing(2),
                        }}
                    />
                </Grid>
            </Hidden>
            <Hidden lgUp>
                <div className={classes.root}>
                    <Paper elevation={0} className={classes.paperMobil}>
                        <div
                            className={classes.loginImage}
                            style={{
                                width: '100%',
                                height: '20%',
                                borderTopLeftRadius: theme.spacing(2),
                                borderTopRightRadius: theme.spacing(2),
                            }}
                        />
                        <div className={classes.paperMobilContent}>
                            <ResetForm />
                        </div>
                    </Paper>
                </div>
            </Hidden>
        </div>
    )
}

export default ResetPassword
