/* React */
import React, { useContext, useEffect, useState } from 'react'

/* Context */
import { FilterContext, VistaGeneralContext } from '../../contexts/produccion'

/* Components */
import { Filter, ProductoresList } from '../../components/produccion/filter'
import { CartaTablaGrafico } from '../../components/produccion'

const VistaGeneral = props => {
    const { generalData, queryServer, loading, maxValues, minValues, downloadCSV, loadingCSV } =
        useContext(VistaGeneralContext)
    const { startTimeFilter, endTimeFilter, temporalidadFilter, predioFilter } = useContext(FilterContext)
    const [days, setDays] = useState(0)
    const [temporalidad, setTemporalidad] = useState(null)

    useEffect(() => {
        console.log(endTimeFilter, startTimeFilter)
        const diffTime = Math.abs(endTimeFilter - startTimeFilter)
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
        setDays(diffDays)
        queryServer(startTimeFilter, endTimeFilter)
    }, [startTimeFilter, endTimeFilter, temporalidadFilter, predioFilter])

    useEffect(() => {
        switch (temporalidadFilter) {
            case 'Semanal':
                setTemporalidad('semana')
                break
            case 'Quincenal':
                setTemporalidad('quincena')
                break
            case 'Mensual':
                setTemporalidad('mes')
                break
            case 'Anual':
                setTemporalidad('año')
                break
            default:
                setTemporalidad(null)
                break
        }
    }, [temporalidadFilter])

    return (
        <div>
            <Filter />
            <ProductoresList />
            <div style={{ margin: '16px 0px' }}>
                <CartaTablaGrafico
                    type="area"
                    title1="Litros totales"
                    title2="Litros totales"
                    subtitle1={
                        temporalidad
                            ? `Últim${
                                  temporalidad === 'año' || temporalidad === 'mes' ? 'o' : 'a'
                              } ${temporalidad} vs el año anterior.`
                            : `Últimos ${days} días vs el año anterior`
                    }
                    subtitle2={
                        temporalidad
                            ? `Últim${temporalidad === 'año' || temporalidad === 'mes' ? 'o' : 'a'} ${temporalidad}`
                            : `Últimos ${days} días`
                    }
                    data={generalData}
                    isSideSelector={true}
                    forecast={false}
                    buttons={true}
                    downloadCSV={downloadCSV}
                    loadingCSV={loadingCSV}
                    maxValue={maxValues}
                    minValue={minValues}
                    loading={loading}
                    view="Vista General"
                />
            </div>
        </div>
    )
}

export default VistaGeneral
