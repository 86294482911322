/* React */
import React, { createContext, useState } from 'react'

export const ResumenPreciosContext = createContext()

const randomValue = () => Math.round(2000 * Math.random() + 500)

const ResumenPreciosContextProvider = props => {
    const [dataAnual, setDataAnual] = useState([
        {
            name: '02/20',
            'año actual': randomValue(),
            'año anterior': randomValue(),
            forecast: randomValue(),
        },
        {
            name: '03/20',
            'año actual': randomValue(),
            'año anterior': randomValue(),
            forecast: randomValue(),
        },
        {
            name: '04/20',
            'año actual': randomValue(),
            'año anterior': randomValue(),
            forecast: randomValue(),
        },
        {
            name: '05/20',
            'año actual': randomValue(),
            'año anterior': randomValue(),
            forecast: randomValue(),
        },
        {
            name: '06/20',
            'año actual': randomValue(),
            'año anterior': randomValue(),
            forecast: randomValue(),
        },
        {
            name: '07/20',
            'año actual': randomValue(),
            'año anterior': randomValue(),
            forecast: randomValue(),
        },
        {
            name: '08/20',
            'año actual': randomValue(),
            'año anterior': randomValue(),
            forecast: randomValue(),
        },
        {
            name: '09/20',
            'año actual': randomValue(),
            'año anterior': randomValue(),
            forecast: randomValue(),
        },
        {
            name: '10/20',
            'año actual': randomValue(),
            'año anterior': randomValue(),
            forecast: randomValue(),
        },
        {
            name: '11/20',
            'año actual': randomValue(),
            'año anterior': randomValue(),
            forecast: randomValue(),
        },
        {
            name: '12/20',
            'año actual': randomValue(),
            'año anterior': randomValue(),
            forecast: randomValue(),
        },
        {
            name: '01/21',
            'año actual': randomValue(),
            'año anterior': randomValue(),
            forecast: randomValue(),
        },
    ])

    const setChartDataAnual = data => {
        setDataAnual(data)
    }

    return (
        <ResumenPreciosContext.Provider value={{ dataAnual, setChartDataAnual }}>
            {props.children}
        </ResumenPreciosContext.Provider>
    )
}

export default ResumenPreciosContextProvider
