import React, { useEffect, useState } from "react"
import { Grid } from "@material-ui/core"

const ActividadesComponent = ({classNameText}) => {
    const [openData, setOpenData] = useState(false)
    useEffect(() => {
        setTimeout(() => {
            setOpenData(true)
        }, 1000);
    }, [])
    const actividadesTemp = [
        {
            id: 0,
            titulo: 'Titulo de la actividad o actividades',
            elementos: [
                {
                    subtitulo: 'Subtitulo de la actividad 1',
                    texto: 'Texto sobre la actividad y las actividades que se hace en Soprole Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sit amet mauris commodo quis imperdiet massa tincidunt. Integer enim neque volutpat ac. Ipsum suspendisse ultrices gravida dictum. Rhoncus urna neque viverra justo nec ultrices dui sapien eget. Purus ut faucibus pulvinar elementum integer enimneque volutpat ac. Fames ac turpis egestas integer eget. Sed turpis tincidunt id aliquet risus feugiat in ante metus. Dictum fusce ut placerat orci nulla pellentesque dignissim enim sit. Quisque egestas diam in arcu cursus euismod quis viverra nibh. Nibh venenatis cras sed felis eget velit. Vel pharetra vel turpis nunc. Egestas diam in arcu cursus euismod quis viverra. Elit scelerisque mauris pellentesque pulvinar pellentesque habitant morbi tristique. Rutrum tellus pellentesque eu tincidunt tortor aliquam nulla facilisi. Dapibus ultrices in iaculis nunc sed. Eget mauris pharetra et ultrices neque ornare aenean euismod elementum.',
                    imagenes: [
                        {
                            url: '',
                            alt: 'imagen-1'
                        }
                    ]
                },
                {
                    subtitulo: 'Subtitulo de la actividad 2',
                    texto: 'Aliquet bibendum enim facilisis gravida neque. Dignissim sodales ut eu sem integer vitae. Lorem sed risus ultricies tristique nulla. Rhoncus urna neque viverra justo nec. Leo a diam sollicitudin tempor id eu nisl nunc. At imperdiet dui accumsan sit amet. Sed adipiscing diam donec adipiscing tristique risus nec feugiat in. Id neque aliquam vestibulum morbi. Sit amet consectetur adipiscing elit duis tristique sollicitudin nibh sit. Ut aliquam purus sit amet luctus venenatis lectus magna fringilla. Luctus accumsan tortor posuere ac ut. Kat nibh sed pulvinar proin gravida. Habitant morbi tristique senectus et netus et.',
                    imagenes: [
                        {
                            url: '',
                            alt: 'imagen-2'
                        },
                        {
                            url: '',
                            alt: 'imagen-3'
                        }
                    ]
                }
            ]
        }
    ]
    const [actividades, setActividades] = useState(actividadesTemp)
    const [indexActividad, setIndexActividad] = useState(0)
    useEffect(() => {
        setActividades(actividadesTemp)
        setIndexActividad(0)
    }, [])
    return (
        <Grid container>
            <Grid item xs={12}>
                <div className='welcome-text'>
                    <div className='welcome-text-title'>
                        <p>Actividades</p>
                    </div>
                    <div className={classNameText}>
                        {openData && <Grid container  style={{ position: 'relative' }}>
                            <Grid item xs={2}>
                                
                            </Grid>
                            <Grid item xs={8}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        {/* <div className="actividades-container-title">
                                            <p>{actividades[indexActividad].titulo}</p>
                                        </div> */}
                                    </Grid>
                                    <Grid item xs={12}>
                                        {
                                            (actividades[indexActividad].elementos.lenght > 1)
                                            ?
                                            <div className="actividades-container">
                                                <h1>
                                                    En construcción
                                                </h1>
                                            </div>
                                            :
                                            <div className="actividades-container">
                                                <h1>
                                                    En construcción
                                                </h1>
                                            </div>
                                        }
                                    </Grid>
                                    <Grid item xs={12}>
                                        
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={2}>
                                
                            </Grid>
                        </Grid>}
                    </div>
                </div>
            </Grid>
        </Grid>
    )
}

export default ActividadesComponent