/* React */
import React, { useContext, useEffect, useState } from 'react'

/* Material UI */
import { Checkbox, ListItemIcon, ListItemText, MenuItem, Select } from '@material-ui/core'

/* Context */
import { FilterContext } from '../../../contexts/produccion'
import { AuthContext } from '../../../contexts'
import { SalasContext } from '../../../contexts/administracion'
import { Check } from '@material-ui/icons'

const PredioFilter = () => {
    const { filtered, setFiltered, initialFiltered, setInitialFiltered } = useContext(FilterContext)
    const [tmpFiltered, setTmpFiltered] = useState([])
    const [allOrNone, setAllOrNone] = useState(true)
    const [open, setOpen] = useState(false)
    const { sociedades, salas: salasPermission, isAdmin } = useContext(AuthContext)
    const { data: salas } = useContext(SalasContext)
    const [sociedadSala, setSociedadSala] = useState([])

    useEffect(() => {
        console.log(salas)
        console.log(salasPermission)
        if (sociedades?.length >= 0) {
            const data_ = []
            console.log(sociedades)
            sociedades.forEach(sociedad => {
                const salas_ = isAdmin ? salas
                .filter(sala => sala.sociedad._id === sociedad._id)
                /* .filter(
                    sala =>
                        salasPermission.findIndex(
                            salaPermission => salaPermission.sala === sala._id && salaPermission.enabled
                        ) > -1
                ) */
                .sort((a, b) => (a.producerCode < b.producerCode ? -1 : 1)) 
                : 
                salas
                .filter(sala => sala.sociedad._id === sociedad._id)
                .filter(
                    sala =>
                        salasPermission.findIndex(
                            salaPermission => salaPermission.sala === sala._id && salaPermission.enabled
                        ) > -1
                )
                .sort((a, b) => (a.producerCode < b.producerCode ? -1 : 1))
                if (salas_?.length > 0) {
                    data_.push({
                        name: sociedad.name,
                        _id: sociedad._id,
                        masterId: sociedad._id,
                        code: sociedad.masterCode,
                        isSociedad: true,
                        checked: true,
                    })
                    salas_.forEach(sala =>
                        data_.push({
                            name: sala.name,
                            _id: sala._id,
                            masterId: sala.sociedad._id,
                            code: sala.producerCode,
                            isSociedad: false,
                            checked: true,
                        })
                    )
                }
            })
            console.log(data_)
            setSociedadSala(data_)
            setTmpFiltered(data_)
            if (!initialFiltered && data_.length > 0) {
                setFiltered(data_)
                setInitialFiltered(true)
            }
        }
    }, [sociedades, salas, salasPermission])

    const handleChangeSala = (event, child) => {
        if (child.props.value === 'accept') {
            handleClose()
            return
        } else if (child.props.value === 'all') {
            handleSelectAll()
            return
        }
        const {
            props: {
                value: { _id, masterId, isSociedad, checked },
            },
        } = child
        console.log(_id, masterId, isSociedad, checked)
        const data_ = [...sociedadSala]

        data_.filter(sala => sala[isSociedad ? 'masterId' : '_id'] === _id).forEach(sala => (sala.checked = !checked))

        const allChecked = data_
            .filter(sala => sala.masterId === masterId && !sala.isSociedad)
            .every(sala => sala.checked)

        data_.filter(sala => sala._id === masterId).forEach(sala => (sala.checked = allChecked))
        const data = data_.filter(sala => sala.checked)
        console.log(data)
        setTmpFiltered(data_.filter(sala => sala.checked))
        setAllOrNone(data_.every(sala => sala.checked === true))
    }

    const handleSelectAll = (event, value) => {
        const data_ = [...sociedadSala]
        data_.forEach(sala => (sala.checked = !allOrNone))
        setTmpFiltered(data_)
        setAllOrNone(!allOrNone)
    }

    const handleClose = () => {
        setOpen(false)
        setFiltered(tmpFiltered)
    }

    return (
        <Select
            value={filtered}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={handleClose}
            onChange={handleChangeSala}
            MenuProps={{
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                },
                getContentAnchorEl: null,
                autoFocus: true,
            }}
            renderValue={() => <span>Productor</span>}
            fullWidth
            displayEmpty
            multiple
        >
            <MenuItem value="all" style={{ padding: '0px 8px', fontWeight: 'bold' }}>
                <ListItemIcon>
                    <Check />
                </ListItemIcon>
                <ListItemText
                    primary={(allOrNone ? 'Des' : 'S') + 'eleccionar todas'}
                    primaryTypographyProps={{ style: { fontWeight: 'bold' } }}
                />
            </MenuItem>
            <MenuItem value="accept" style={{ padding: '0px 8px', fontWeight: 'bold' }}>
                <ListItemIcon>
                    <Check />
                </ListItemIcon>
                <ListItemText primary="Aceptar" primaryTypographyProps={{ style: { fontWeight: 'bold' } }} />
            </MenuItem>
            {sociedadSala.map((data, index) => (
                <MenuItem
                    key={index}
                    value={data}
                    style={{
                        padding: !data.isSociedad ? '0px 24px' : '0px 8px',
                        fontWeight: !data.isSociedad ? 'normal' : 'bold',
                    }}
                >
                    <Checkbox checked={data.checked}/>
                    <ListItemText
                        primary={`${data.code} - ${data.name}`}
                        primaryTypographyProps={{
                            style: { fontWeight: !data.isSociedad ? 'normal' : 'bold' },
                        }}
                    />
                </MenuItem>
            ))}
            <MenuItem value="accept" style={{ padding: '0px 8px', fontWeight: 'bold' }}>
                <ListItemIcon>
                    <Check />
                </ListItemIcon>
                <ListItemText primary="Aceptar" primaryTypographyProps={{ style: { fontWeight: 'bold' } }} />
            </MenuItem>
            <MenuItem value="all" style={{ padding: '0px 8px', fontWeight: 'bold' }}>
                <ListItemIcon>
                    <Check />
                </ListItemIcon>
                <ListItemText
                    primary={(allOrNone ? 'Des' : 'S') + 'eleccionar todas'}
                    primaryTypographyProps={{ style: { fontWeight: 'bold' } }}
                />
            </MenuItem>
        </Select>
    )
}

export default PredioFilter
