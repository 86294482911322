/* React */
import React, { useContext, useEffect, useState } from 'react'

/* Material UI */
import { Grid } from '@material-ui/core'

/* Context */
import { FilterContext } from '../../contexts/produccion'

/* Components */
import { LiquidacionesFilter, ProductoresList } from '../../components/produccion/filter'
import { CartaLiquidacion } from '../../components/produccion'
import { SalasContext } from '../../contexts/administracion'

const Liquidaciones = props => {
    const { predioFilter } = useContext(FilterContext)
    const { salas } = useContext(SalasContext)
    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date())
    const [data, setData] = useState([])

    useEffect(() => {
        const endDate_ = new Date()
        endDate_.setDate(1)
        const startDate_ = new Date(endDate_)
        startDate_.setFullYear(startDate_.getFullYear() - 1)
        // startDate_.setMonth(startDate_.getMonth() + 1)
        setStartDate(startDate_)
        setEndDate(endDate_)
    }, [])

    useEffect(() => {
        if (predioFilter?.length >= 0) {
            const endDate_ = new Date(endDate)
            endDate_.setDate(1)
            endDate_.setMonth(endDate_.getMonth() - 1)
            const startDate_ = new Date(startDate)
            const dates = []
            for (
                let day = new Date(endDate_);
                day.getTime() >= startDate_.getTime();
                day.setMonth(day.getMonth() - 1)
            ) {
                dates.push({ year: day.getFullYear(), month: day.getMonth() + 1 })
            }
            const data_ = [...new Set(predioFilter)].map(predio => {
                const sala = salas?.find(x => x.producerCode === predio)
                return {
                    producerCode: predio,
                    salaId: sala?._id,
                    dates: [...dates],
                }
            })
            setData(data_)
        }
    }, [predioFilter, startDate, endDate])

    return (
        <div>
            <LiquidacionesFilter
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
            />
            <ProductoresList />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container spacing={2} style={{ marginTop: 8 }}>
                        {data?.map((producer, index) => {
                            return (
                                <Grid item xs={12} sm={6} lg={6} xl={4} key={index}>
                                    <CartaLiquidacion
                                        bg={index % 2 === 0 ? 'white' : '#f9f9f9'}
                                        data={producer.dates}
                                        producerCode={producer.producerCode}
                                        salaId={producer.salaId}
                                    />
                                </Grid>
                            )
                        })}
                    </Grid>
                    {/* <LiquidacionesTabla data={dataAnual} title={'Liquidaciones de leche'} subtitle={'Últimos 12 meses'} /> */}
                </Grid>
            </Grid>
        </div>
    )
}

export default Liquidaciones
