/* React */
import React, { useContext, useEffect, useState } from 'react'
import { Redirect, useLocation } from 'react-router-dom'

/* Material UI */
import { makeStyles, Paper } from '@material-ui/core'

/* Context */
import { AdminFilterContext, UsuariosContext } from '../../contexts/administracion'
import { AuthContext } from '../../contexts'

/* Components */
import { AdminFilter, AdminCreateItemForm } from '../../components/administracion'
import { TablaAdministracion } from '../../components/tabla'

const useStyles = makeStyles(theme => ({
    paperGeneralCard: {
        borderRadius: 12,
        '& .MuiDataGrid-root': {
            border: 0,
            // borderRadius: 12
        },
        marginTop: 10,
        marginBottom: 10,
    },
    dataGrid: {
        height: 650,
        width: '100%',
        padding: 8,
        '& .cell': {
            color: '#050505',
            fontSize: 14,
            fontWeight: 800,
            padding: '0px 4px 0px 4px',
            textAlign: 'left',
        },
        '& .header': {
            color: '#737373',
            fontSize: 16,
            fontWeight: 800,
            padding: '0px 4px 0px 4px',
            textAlign: 'left',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
}))

const UsersManagment = props => {
    const { permissions } = useContext(AuthContext)
    const {
        columns,
        users,
        formFields,
        handleClickCreateButton,
        editUserId,
        setEditUserId,
        mobileSelection,
        editUserEmail,
        redir,
        isCreate,
        setIsCreate,
    } = useContext(UsuariosContext)
    const classes = useStyles()
    const { search } = useContext(AdminFilterContext)
    const [filteredUsers, setFilteredUsers] = useState([])
    const location = useLocation()

    useEffect(() => {
        if (search.trim() !== '') {
            setFilteredUsers(
                users.filter(el => {
                    const keys = Object.keys(el)
                    let text = ''
                    keys.forEach((key, index) => {
                        if (key === 'name' || key === 'lastName') text += el[key]
                    })
                    // console.log(text)
                    return text.toLowerCase().indexOf(search.toLowerCase()) > -1
                })
            )
        } else {
            setFilteredUsers(users)
        }
    }, [search, users])

    useEffect(() => {
        setIsCreate(false)
    }, [location])

    useEffect(() => {
        console.log(formFields)
    }, [formFields])

    return (
        <Paper elevation={0} className={classes.paperGeneralCard} style={{ marginTop: 8 }}>
            {redir && <Redirect to="/permissionsassignment" />}
            {!isCreate ? (
                <>
                    <AdminFilter
                        createButtonText={'Crear usuario'}
                        onClick={() => setEditUserId('')}
                        setIsCreate={setIsCreate}
                        isOnlySearch={!(permissions?.administracion > 1)}
                    />
                    <TablaAdministracion
                        data={filteredUsers}
                        headCells={columns}
                        sortDefault={'lastName'}
                        defaultCell={'Nombres'}
                        fixedCell={'lastName'}
                        mobileSelection={mobileSelection}
                    />
                </>
            ) : (
                <AdminCreateItemForm
                    title={editUserId === '' ? 'Nuevo usuario' : `Editando ${editUserEmail}`}
                    fields={formFields}
                    createButtonText={editUserId === '' ? 'Guardar' : 'Actualizar'}
                    onClick={values => handleClickCreateButton(values, false)}
                    createButtonText2={editUserId === '' ? 'Guardar e ir a permisos' : null}
                    onClick2={values => handleClickCreateButton(values, true)}
                    setIsCreate={setIsCreate}
                    users={true}
                />
            )}
        </Paper>
    )
}

export default UsersManagment
