/* React */
import React, { useContext } from 'react'
import { Link } from 'react-router-dom'

/* Material UI */
import { makeStyles, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { AccountBalance, ExpandLess, ExpandMore } from '@material-ui/icons'

/* Context */
import { MenuContext } from '../../contexts'

/* Components */
import {
    AdminIcon,
    CheckListIcon,
    CowIcon,
    FodderIcon,
    LogoutIcon,
    RecolectionIcon,
    SettingsIcon,
    UploadInfoIcon,
    WeatherIcon,
} from '../icons'

/* Utilities */
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
    buttonMenu: {
        [theme.breakpoints.down('md')]: {
            paddingLeft: 50,
            paddingRight: 54,
        },
        [theme.breakpoints.up('lg')]: {
            // paddingLeft: 70,
            paddingLeft: theme.spacing(7),
            paddingRight: 64,
        },
        paddingTop: 6,
        paddingBottom: 6,
        height: 58,
        '&:hover': {
            background: theme.drawer.buttonMenuHoverColor,
        },
    },
    font: {
        [theme.breakpoints.down('md')]: {
            fontSize: 15,
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: 18,
        },
    },
    colorOn: {
        color: theme.palette.primary.main,
        fill: theme.palette.primary.main,
    },
    colorOff: {
        color: theme.palette.secondary.main,
        fill: theme.palette.secondary.main,
    },
}))

const ButtonMenu = ({ id, text, isCollapsible, isOpen, link }) => {
    const { selectedItem, handleClickMenu } = useContext(MenuContext)
    const classes = useStyles()
    const [hover, setHover] = React.useState(false)

    const handleMouseOverLeave = value => {
        setHover(value)
    }

    const DrawerMenuIcon = props => {
        const { id, className } = props
        if (id === 'produccion') return <CowIcon style={{ fontSize: 36, marginLeft: '5.35px' }} className={className} />
        if (id === 'facturacion')
            return <AccountBalance style={{ fontSize: 36, marginLeft: '5.35px' }} className={className} />
        if (id === 'situacion-sanitaria')
            return <CheckListIcon style={{ fontSize: '32.1px', marginLeft: '7.3px' }} className={className} />
        if (id === 'forraje')
            return <FodderIcon style={{ fontSize: '35.6px', marginLeft: '5.55px' }} className={className} />
        if (id === 'recoleccion')
            return <RecolectionIcon style={{ fontSize: 32, marginLeft: '7.35px' }} className={className} />
        if (id === 'clima') return <WeatherIcon style={{ fontSize: '46.7px' }} className={className} />
        if (id === 'admin') return <AdminIcon style={{ fontSize: 36, marginLeft: '5.35px' }} className={className} />
        if (id === 'cargar-info')
            return <UploadInfoIcon style={{ fontSize: '28.6', marginLeft: '11.6px' }} className={className} />
        if (id === 'perfil')
            return <SettingsIcon style={{ fontSize: 32, marginLeft: '7.35px' }} className={className} />
        if (id === 'cerrar-sesion')
            return <LogoutIcon style={{ fontSize: '28.6', marginLeft: '9.05px' }} className={className} />
    }

    return (
        <ListItem
            key={id}
            className={classes.buttonMenu}
            button
            {...(link && {
                component: Link,
                to: link,
            })}
            onClick={() => handleClickMenu(id)}
            onMouseOver={() => handleMouseOverLeave(true)}
            onMouseLeave={() => handleMouseOverLeave(false)}
        >
            <ListItemIcon style={{ minWidth: 60 }}>
                <DrawerMenuIcon
                    id={id}
                    className={
                        hover || isOpen || selectedItem.id === id || selectedItem.menuId === id
                            ? classes.colorOn
                            : classes.colorOff
                    }
                />
            </ListItemIcon>
            <ListItemText
                disableTypography
                primary={
                    <span
                        style={{ fontWeight: 'bold' }}
                        className={clsx(
                            classes.font,
                            hover || isOpen || selectedItem.id === id || selectedItem.menuId === id
                                ? classes.colorOn
                                : classes.colorOff
                        )}
                    >
                        {text}
                    </span>
                }
            />
            {isCollapsible ? (
                isOpen ? (
                    <ExpandLess
                        style={{ fontSize: 30 }}
                        className={
                            hover || isOpen || selectedItem.id === id || selectedItem.menuId === id
                                ? classes.colorOn
                                : classes.colorOff
                        }
                    />
                ) : (
                    <ExpandMore
                        style={{ fontSize: 30 }}
                        className={
                            hover || isOpen || selectedItem.id === id || selectedItem.menuId === id
                                ? classes.colorOn
                                : classes.colorOff
                        }
                    />
                )
            ) : (
                <></>
            )}
        </ListItem>
    )
}

export default ButtonMenu
