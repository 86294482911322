import React, { createContext, useContext, useEffect, useState } from 'react'
import { DataContext } from './DataContext'
import { FilterContext } from './FilterContext'
import { useLazyQuery, gql } from '@apollo/client'
import { saveAs } from 'file-saver'

const QUALITY_METRICS = gql`
    fragment fields on AggregatedMetric {
        _id {
            year
            month
            day @skip(if: $groupMonthly)
        }
        LITROS_TOTAL
        GRASA
        PROTEINA
        UREA
        UFC
        RCS
    }

    query qualityMetrics(
        $startCurrentYear: String!
        $endCurrentYear: String!
        $startLastYear: String!
        $endLastYear: String!
        $groupMonthly: Boolean!
        $producerCodes: [String]
    ) {
        lastYear: aggregatedMetrics(
            fechaInicio: $startLastYear
            fechaFin: $endLastYear
            groupMonthly: $groupMonthly
            producerCodes: $producerCodes
        ) {
            metrics {
                ...fields
            }
        }
        currentYear: aggregatedMetrics(
            fechaInicio: $startCurrentYear
            fechaFin: $endCurrentYear
            groupMonthly: $groupMonthly
            producerCodes: $producerCodes
        ) {
            resultSAP
            metrics {
                ...fields
            }
        }
    }
`

export const CalidadContext = createContext()

const groupData = dataIn => {
    return dataIn.reduce((acc, item) => {
        const fecha =
            (item._id.day != null ? `${item._id.day}/`.padStart(3, '0') : '') +
            `${item._id.month}/`.padStart(3, '0') +
            `${item._id.year % 100}`
        acc[fecha] = {
            litros: item.LITROS_TOTAL,
            grasa: item.GRASA,
            proteina: item.PROTEINA,
            RCS: item.RCS,
            UFC: item.UFC,
            UREA: item.UREA,
        }
        return acc
    }, {})
}

const generateData = (dataNew, dataOld) => {
    const data_ = {
        litros: [],
        grasa: [],
        proteina: [],
        UFC: [],
        RCS: [],
    }

    const dataNew_ = groupData(dataNew, false)
    const dataOld_ = groupData(dataOld, true)
    Object.keys(dataNew_).forEach(date => {
        const year = parseInt(date.slice(-2))
        const oldDate = date.slice(0, -2) + `${year - 1}`.padStart(2, '0')
        Object.keys(data_).forEach(key => {
            const currVal = dataNew_[date][key]
            const oldVal = dataOld_[oldDate] ? dataOld_[oldDate][key] : 0
            if (currVal !== null || oldVal !== 0) {
                data_[key].push({
                    name: date,
                    'año actual': Math.round(currVal * 100) / 100,
                    ...(oldVal !== 0 ? { 'año anterior': Math.round(oldVal * 100) / 100 } : {}),
                })
            }
        })
    })
    return data_
}

const CalidadContextProvider = props => {
    const { errorMessage, setErrorMessage } = useContext(DataContext)
    const { predioFilter } = useContext(FilterContext)
    const [calidadData, setData] = useState({ litros: [], grasa: [], proteina: [], RCS: [], UFC: [] })
    const [loadingGrasaCSV, setLoadingGrasaCSV] = useState(false)
    const [loadingProteinaCSV, setLoadingProteinaCSV] = useState(false)
    const [loadingUFCCSV, setLoadingUFCCSV] = useState(false)
    const [loadingRCSCSV, setLoadingRCSCSV] = useState(false)

    const [getData, { loading, error, data }] = useLazyQuery(QUALITY_METRICS, {
        fetchPolicy: 'cache-and-network',
        notifyOnNetworkStatusChange: true,
    })

    const queryServer = (fechaInicio, fechaFin) => {
        const newStart = new Date(fechaInicio)
        const newEnd = new Date(fechaFin)
        const oldStart = new Date(fechaInicio)
        const oldEnd = new Date(fechaFin)

        oldStart.setFullYear(oldStart.getFullYear() - 1)
        oldEnd.setFullYear(oldEnd.getFullYear() - 1)

        getData({
            variables: {
                startLastYear: oldStart.toISOString(),
                endLastYear: oldEnd.toISOString(),
                startCurrentYear: newStart.toISOString(),
                endCurrentYear: newEnd.toISOString(),
                groupMonthly: newEnd - newStart > 150 * 24 * 60 * 60 * 1000, // 150 days
                producerCodes: predioFilter,
            },
        })
    }

    useEffect(() => {
        if (data) {
            const { currentYear, lastYear } = data
            setErrorMessage(!currentYear?.resultSAP)

            const data_ = generateData(currentYear?.metrics, lastYear?.metrics)
            const maxValueGrasa = Math.max(...discardZeroes(data_.grasa))
            const maxValueProteina = Math.max(...discardZeroes(data_.proteina))
            const minValueGrasa = Math.min(...discardZeroes(data_.grasa))
            const minValueProteina = Math.min(...discardZeroes(data_.proteina))

            setMaxValues({
                grasa: maxValueGrasa + 0.1,
                proteina: maxValueProteina + 0.1,
            })
            setMinValues({
                grasa: minValueGrasa - 0.1,
                proteina: minValueProteina - 0.1,
            })
            setData(data_)
        }
    }, [data])

    const [maxValues, setMaxValues] = useState({ grasa: 0, proteina: 0 })
    const [minValues, setMinValues] = useState({ grasa: 0, proteina: 0 })

    const discardZeroes = data => {
        return [...data.map(item => item['año anterior']), ...data.map(item => item['año actual'])].filter(
            item => item > 0
        )
    }

    useEffect(() => {
        if (error ^ errorMessage) setErrorMessage(error)
    }, [error])

    const downloadCSV = async type => {
        let csvData = 'fecha; litros;'
        switch (type) {
            case 'grasa':
                csvData += 'grasa_gl'
                setLoadingGrasaCSV(true)
                break
            case 'proteina':
                csvData += 'proteina_gl'
                setLoadingProteinaCSV(true)
                break
            case 'UFC':
                csvData += 'UFC'
                setLoadingUFCCSV(true)
                break
            case 'RCS':
                csvData += 'RCS'
                setLoadingRCSCSV(true)
                break
        }

        calidadData.litros.forEach((item, index) => {
            csvData += `\n${item.name};` + `${item['año actual']};`
            switch (type) {
                case 'grasa':
                    csvData += `${calidadData?.grasa?.[index]?.['año actual']?.toLocaleString('es-ES')}`
                    break
                case 'proteina':
                    csvData += `${calidadData?.proteina?.[index]?.['año actual']?.toLocaleString('es-ES')}`
                    break
                case 'UFC':
                    csvData += `${calidadData?.UFC?.[index]?.['año actual']?.toLocaleString('es-ES')}`
                    break
                case 'RCS':
                    csvData += `${calidadData?.RCS?.[index]?.['año actual']?.toLocaleString('es-ES')}`
                    break
            }
        })

        const blob = new Blob([csvData], { type: 'text/plainl;charset=utf-8' })
        saveAs(blob, type + 'Calidad.csv')
        switch (type) {
            case 'grasa':
                setLoadingGrasaCSV(false)
                break
            case 'proteina':
                setLoadingProteinaCSV(false)
                break
            case 'UFC':
                setLoadingUFCCSV(false)
                break
            case 'RCS':
                setLoadingRCSCSV(false)
                break
        }
    }

    return (
        <CalidadContext.Provider
            value={{
                calidadData,
                queryServer,
                minValues,
                maxValues,
                loading,
                downloadCSV,
                loadingGrasaCSV,
                loadingProteinaCSV,
                loadingUFCCSV,
                loadingRCSCSV,
            }}
        >
            {props.children}
        </CalidadContext.Provider>
    )
}

export default CalidadContextProvider
