/* React */
import React, { createContext, useContext, useEffect, useState } from 'react'

/* GraphQL */
import { useMutation, useQuery } from '@apollo/client'
import { CREATE_HORARIO, DELETE_HORARIO, EDIT_HORARIO, HORARIOS } from '../../graphQL/horarios'

/* Material UI */
import { IconButton } from '@material-ui/core'
import { EditOutlined, Clear } from '@material-ui/icons'

/* Snackbar */
import { useSnackbar } from 'notistack'

/* Context */
import { AdminFilterContext } from './'
import { AuthContext } from '../'

/* Components */
import { TableSwitch } from '../../components/administracion'

export const HorariosContext = createContext()

const time0 = new Date()
time0.setHours(0)
time0.setMinutes(0)
time0.setSeconds(0)
time0.setMilliseconds(0)

const HorariosContextProvider = props => {
    const { permissions } = useContext(AuthContext)
    const { setIsCreate } = useContext(AdminFilterContext)
    const [editHorarioId, setEditHorarioId] = useState('')
    const [horarios, setHorarios] = useState([])
    const { enqueueSnackbar } = useSnackbar()

    const { data } = useQuery(HORARIOS, { fetchPolicy: 'cache-and-network' })
    const [editHorario] = useMutation(EDIT_HORARIO, {
        onCompleted(data) {
            if (data && data.editHorario) {
                enqueueSnackbar('Horario editado correctamente', {
                    variant: 'success',
                })
            }
        },
        onError(error) {
            enqueueSnackbar(error.message, { variant: 'error' })
        },
    })
    const [createHorario] = useMutation(CREATE_HORARIO, {
        onCompleted(data) {
            if (data && data.createHorario) {
                enqueueSnackbar('Horario creado correctamente', { variant: 'success' })
            }
        },
        onError(error) {
            enqueueSnackbar(error.message, { variant: 'error' })
        },
    })
    const [deleteHorario] = useMutation(DELETE_HORARIO, {
        onCompleted(data) {
            if (data && data.deleteHorario) {
                enqueueSnackbar('Horario eliminado correctamente', {
                    variant: 'success',
                })
            }
        },
        onError(error) {
            enqueueSnackbar(error.message, { variant: 'error' })
        },
    })

    const [mobileSelection, setMobileSelection] = useState([
        { name: 'Hora de término', cellFields: ['horaTermino'] },
        { name: 'Duración', cellFields: ['duracion'] },
        { name: 'Tiempo de enfriado', cellFields: ['horaEnfriado'] },
        ...(permissions?.administracion > 1 ? [{ name: 'Edición', cellFields: ['enabled', 'edit', 'remove'] }] : []),
    ])

    const [formFields, setFormFields] = useState([
        {
            name: 'horaInicio',
            type: 'time',
            label: 'Hora de inicio',
            placeHolder: '05:30',
            value: time0,
        },
        {
            name: 'horaTermino',
            type: 'time',
            label: 'Hora de término',
            placeHolder: '06:00',
            value: time0,
        },
        {
            name: 'horaCold',
            type: 'time',
            label: 'Tiempo de enfriado',
            placeHolder: '01:00',
            value: time0,
        },
    ])

    const [columns, setColumns] = useState([
        { field: 'horaInicio', headerName: 'Hora de inicio', sortable: true },
        {
            field: 'horaTermino',
            headerName: 'Hora de término',
            sortable: true,
        },
        { field: 'duracion', headerName: 'Duración', sortable: true },
        {
            field: 'horaCold',
            headerName: 'Tiempo de enfriado',
            sortable: true,
        },
        ...(permissions?.administracion > 1
            ? [
                  {
                      field: 'enabled',
                      headerName: 'Estado',
                      sortable: false,
                      disablePadding: true,
                      center: true,
                  },
                  {
                      field: 'edit',
                      headerName: 'Editar',
                      sortable: false,
                      disablePadding: true,
                      center: true,
                  },
                  {
                      field: 'remove',
                      headerName: 'Borrar',
                      sortable: false,
                      disablePadding: true,
                      center: true,
                  },
              ]
            : []),
    ])

    useEffect(() => {
        setMobileSelection([
            { name: 'Hora de término', cellFields: ['horaTermino'] },
            { name: 'Duración', cellFields: ['duracion'] },
            { name: 'Tiempo de enfriado', cellFields: ['horaEnfriado'] },
            ...(permissions?.administracion > 1
                ? [{ name: 'Edición', cellFields: ['enabled', 'edit', 'remove'] }]
                : []),
        ])
        setColumns([
            {
                field: 'horaInicio',
                headerName: 'Hora de inicio',
                sortable: true,
            },
            {
                field: 'horaTermino',
                headerName: 'Hora de término',
                sortable: true,
            },
            { field: 'duracion', headerName: 'Duración', sortable: true },
            {
                field: 'horaCold',
                headerName: 'Tiempo de enfriado',
                sortable: true,
            },
            ...(permissions?.administracion > 1
                ? [
                      {
                          field: 'enabled',
                          headerName: 'Estado',
                          sortable: false,
                          disablePadding: true,
                          center: true,
                      },
                      {
                          field: 'edit',
                          headerName: 'Editar',
                          sortable: false,
                          disablePadding: true,
                          center: true,
                      },
                      {
                          field: 'remove',
                          headerName: 'Borrar',
                          sortable: false,
                          disablePadding: true,
                          center: true,
                      },
                  ]
                : []),
        ])
    }, [permissions])

    const createData = horario => {
        return {
            _id: horario._id,
            horaInicio: horario.horaInicio,
            horaTermino: horario.horaTermino,
            duracion: horario.duracion,
            horaCold: horario.horaCold,
            enabled: switchEnabled(horario._id, horario.enabled),
            edit: iconEdit(horario._id),
            remove: iconClear(horario._id),
        }
    }

    useEffect(() => {
        if (data?.horarios?.length >= 0) {
            const _parsed = data.horarios.map(horario => {
                return createData(horario)
            })
            setHorarios(_parsed)
        }
    }, [data])

    const switchEnabled = (id, value) => {
        return (
            <TableSwitch
                name="estatus"
                checked={value}
                onChange={event => handleChangeSwitch(event, id)}
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
            />
        )
    }

    const iconEdit = id => {
        return (
            <IconButton aria-label="edit" onClick={() => handleClickIconEdit(id)}>
                <EditOutlined style={{ color: '#000' }} />
            </IconButton>
        )
    }

    /* const timeFormat = (value) => {
        const date = new Date(value)
        const [hour, minute] = date.toLocaleTimeString('en-GB').split(/:| /)

        return ('0' + hour).slice(-2) + ':' + ('0' + minute).slice(-2)
    } */

    const handleClickIconEdit = id => {
        setEditHorarioId(id)
        const _data = data.horarios.find(e => e._id === id)
        const horaInicio = new Date(time0)
        const horaTermino = new Date(time0)
        const horaCold = new Date(time0)
        horaInicio.setHours(_data.timeInicio.hour)
        horaInicio.setMinutes(_data.timeInicio.min)
        horaTermino.setHours(_data.timeTermino.hour)
        horaTermino.setMinutes(_data.timeTermino.min)
        horaCold.setHours(_data.timeCold.hour)
        horaCold.setMinutes(_data.timeCold.min)
        const toShow = {
            horaInicio,
            horaTermino,
            horaCold,
        }
        // actualizar campos del formulario
        setFormFields(formFields.map(item => ({ ...item, value: toShow[item.name] })))
        setIsCreate(true)
    }

    const handleClickCreateButton = values => {
        const inicio = new Date(values[0])
        const termino = new Date(values[1])
        const cold = new Date(values[2])
        const [aHour, aMinute] = inicio.toLocaleTimeString('en-GB').split(/:| /)
        const [bHour, bMinute] = termino.toLocaleTimeString('en-GB').split(/:| /)
        const [cHour, cMinute] = cold.toLocaleTimeString('en-GB').split(/:| /)
        // editar horarios
        if (editHorarioId !== '') {
            editHorario({
                variables: {
                    horarioId: editHorarioId,
                    inicioHour: parseInt(aHour),
                    inicioMinute: parseInt(aMinute),
                    terminoHour: parseInt(bHour),
                    terminoMinute: parseInt(bMinute),
                    coldHour: parseInt(cHour),
                    coldMinute: parseInt(cMinute),
                },
            })
        } else {
            // crear horarios
            createHorario({
                variables: {
                    inicioHour: parseInt(aHour),
                    inicioMinute: parseInt(aMinute),
                    terminoHour: parseInt(bHour),
                    terminoMinute: parseInt(bMinute),
                    coldHour: parseInt(cHour),
                    coldMinute: parseInt(cMinute),
                    enabled: true,
                },
                refetchQueries: () => [
                    {
                        query: HORARIOS,
                    },
                ],
            })
        }

        setIsCreate(false)
    }

    const iconClear = _id => (
        <IconButton
            aria-label="edit"
            onClick={() => {
                deleteHorario({
                    variables: { horarioId: _id },
                    refetchQueries: () => [
                        {
                            query: HORARIOS,
                        },
                    ],
                })
            }}
        >
            <Clear style={{ color: 'red' }} />
        </IconButton>
    )

    const handleChangeSwitch = (event, id) => {
        /* setData(data.map(item =>
            item.id === id
                ? { ...item, estatus: event.target.checked }
                : { ...item }
        )) */
        editHorario({
            variables: {
                horarioId: id,
                enabled: event.target.checked,
            },
        })
    }

    return (
        <HorariosContext.Provider
            value={{
                columns,
                data,
                horarios,
                formFields,
                handleClickCreateButton,
                editHorarioId,
                setEditHorarioId,
                mobileSelection,
            }}
        >
            {props.children}
        </HorariosContext.Provider>
    )
}

export default HorariosContextProvider
