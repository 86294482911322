/* React */
import React, { useEffect, useState, Fragment, useContext } from 'react'

/* Material UI */
import {
    Button,
    CircularProgress,
    Divider,
    Grid,
    Hidden,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    withStyles,
} from '@material-ui/core'

/* ReCharts */
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    LineChart,
    Line,
    Label,
} from 'recharts'

/* Context */
import { FilterContext } from '../../contexts/produccion'

/* Components */
import { ButtonVerTabla, SelectionPanel } from '../comun'

/* Utilites */
import domToImage from 'dom-to-image'
import JsPDF from 'jspdf'
import 'jspdf-autotable'

const capitalize = string => {
    return string.slice(0, 1).toUpperCase() + string.slice(1).toLowerCase()
}

const DataFormater = (number, magnitude, decimalPlaces) => {
    if (magnitude > 1000000) {
        return (number / 1000000).toLocaleString('es-CL', {
            minimumFractionDigits: decimalPlaces,
        })
    } else if (magnitude > 1000) {
        return (number / 1000).toLocaleString('es-CL', {
            minimumFractionDigits: decimalPlaces,
        })
    } else {
        return number.toLocaleString('es-CL', {
            minimumFractionDigits: decimalPlaces,
        })
    }
}

const DateFormatter = date => {
    const splitDate = date.split('/')
    if (splitDate.length > 2) {
        return date
    } else {
        switch (parseInt(splitDate[0])) {
            case 1:
                return 'Ene-' + splitDate[1]
            case 2:
                return 'Feb-' + splitDate[1]
            case 3:
                return 'Mar-' + splitDate[1]
            case 4:
                return 'Abr-' + splitDate[1]
            case 5:
                return 'May-' + splitDate[1]
            case 6:
                return 'Jun-' + splitDate[1]
            case 7:
                return 'Jul-' + splitDate[1]
            case 8:
                return 'Ago-' + splitDate[1]
            case 9:
                return 'Sep-' + splitDate[1]
            case 10:
                return 'Oct-' + splitDate[1]
            case 11:
                return 'Nov-' + splitDate[1]
            case 12:
                return 'Dic-' + splitDate[1]
            default:
                return splitDate[0]
        }
    }
}

const useStyles = makeStyles(theme => ({
    chart: {
        height: 300,
        margin: '8px 8px 8px 8px',
        alignContent: 'center',
        [theme.breakpoints.up('lg')]: {
            height: heightChart => heightChart,
        },
    },
    headerGraph: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: 20,
        marginLeft: 20,
        marginRight: 20,
        [theme.breakpoints.up('lg')]: {
            marginLeft: 45,
        },
    },
    subHeaderGraph: {
        display: 'inline-block',
        marginLeft: 20,
        marginRight: 20,
        [theme.breakpoints.up('lg')]: {
            marginLeft: 45,
        },
    },
    tableButtonDesktop: {
        display: 'none',
        [theme.breakpoints.up('lg')]: {
            display: 'inline-block',
        },
    },
    tableButtonMobile: {
        display: 'inline-block',
        [theme.breakpoints.up('lg')]: {
            display: 'none',
        },
        width: '100%',
    },
    tableHeadCell: {
        fontWeight: 'bold',
        fontSize: 18,
        backgroundColor: '#e4efff',
        borderBottom: 'none',
        borderRight: 'solid 1px #dfdfdf',
        borderLeft: 'solid 1px #dfdfdf',
        padding: '12px 16px',
    },
    tableBodyCell: {
        borderBottom: 'none',
        fontSize: 18,
        borderRight: 'solid 1px #dfdfdf',
        borderLeft: 'solid 1px #dfdfdf',
        padding: '12px 16px',
    },
    table: {
        marginTop: 18,
        maxHeight: 296,
        overflowY: 'auto',
        [theme.breakpoints.up('lg')]: {
            maxHeight: heightChart => heightChart,
        },
        '&::-webkit-scrollbar': {
            width: 8,
            height: 12,
            // background-color: #f5f5f5;
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: 10,
            backgroundColor: '#d8d8d8',
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: 'white',
            borderRadius: 10,
        },
    },
    gridMobile: {
        display: 'block',
        [theme.breakpoints.up('lg')]: {
            display: 'none',
        },
    },
    footerButtonCenter: {
        borderLeft: 'solid 1px #dfdfdf',
        borderRight: 'none',
        borderRadius: 0,
        [theme.breakpoints.up('lg')]: {
            borderRight: 'solid 1px #dfdfdf',
        },
    },
}))

const headCells = [
    { id: 'date', label: 'Fecha' },
    { id: 'data1', label: 'Año anterior' },
    { id: 'data2', label: 'Año actual' },
]

const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: '#f9f9f9',
        },
    },
}))(TableRow)

const CustomTooltip = ({ active, payload, label, forecast, decimalPlaces }) => {
    // console.log(active, payload, label, forecast)

    if (payload === null) {
        return null
    }

    let actual = null
    let anterior = null
    let forecast_ = null
    payload.forEach(e => {
        if (e.dataKey === 'año actual') actual = e
        if (e.dataKey === 'año anterior') anterior = e
        if (e.dataKey === 'forecast') forecast_ = e
    })

    if (active && payload) {
        const actualCond = actual && !isNaN(actual.value)
        const anteriorCond = anterior && !isNaN(anterior.value)
        const diferenciaCond = actualCond && anteriorCond && actual.value > 0 && anterior.value > 0
        const forecastCond = forecast && !isNaN(forecast_.value)
        return (
            <Paper elevation={8} style={{ borderRadius: 12, padding: '0.5px 10px', lineHeight: 0.6 }}>
                <p>{`${label}`}</p>
                {actualCond ? (
                    <p style={{ color: `${actual.color}`, fontWeight: 'bold' }}>{`${
                        actual.dataKey
                    } : ${actual.value.toLocaleString('es-CL', {
                        minimumFractionDigits: decimalPlaces,
                    })}`}</p>
                ) : (
                    <></>
                )}
                {anteriorCond ? (
                    <p style={{ color: `${anterior.color}` }}>{`${anterior.dataKey} : ${anterior.value.toLocaleString(
                        'es-CL',
                        {
                            minimumFractionDigits: decimalPlaces,
                        }
                    )}`}</p>
                ) : (
                    <></>
                )}
                {diferenciaCond ? (
                    anterior.value > 0 ? (
                        <p style={{ color: '#707070', fontWeight: 'bold' }}>{`Diferencia : ${(
                            Math.round(100 * ((actual.value - anterior.value) / (anterior.value + 0.00001)) * 100) / 100
                        ).toLocaleString('es-CL', {
                            minimumFractionDigits: decimalPlaces,
                            maximumFractionDigits: decimalPlaces,
                        })}%`}</p>
                    ) : (
                        <></>
                    )
                ) : (
                    <></>
                )}
                {forecastCond && actualCond ? (
                    <p style={{ color: '#b65a39' }}>{`Forecast : ${forecast_.value.toLocaleString('es-CL', {
                        minimumFractionDigits: decimalPlaces,
                    })}`}</p>
                ) : (
                    <></>
                )}
            </Paper>
        )
    }

    return null
}

const CartaTablaGraficoContent = ({
    type,
    view,
    title1,
    title2,
    subtitle1,
    subtitle2,
    data,
    heightChart,
    isSideSelector,
    handleClickSelectionPanel,
    forecast,
    buttons,
    maxValue,
    minValue,
    loadingCSV,
    downloadCSV,
    yLabel,
    decimalPlaces,
}) => {
    const [showGraph, setShowGraph] = useState(1) // 1: grafico, 2: tabla, 3: seleccion variable
    const classes = useStyles(heightChart)
    const { predioFilter } = useContext(FilterContext)
    const [parsedData, setParsedData] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [magnitude, setMagnitude] = useState(1)

    const createData = point => {
        return {
            date: point.name,
            data1: point['año anterior'],
            data2: point['año actual'],
        }
    }

    useEffect(() => {
        const newParsed = data.map(point => createData(point))
        setParsedData(newParsed)
        const magnitude_ = Math.max(
            ...data
                .map(item => [item['año actual'], item['año anterior']])
                .reduce((a, b) => a.concat(b), [])
                .filter(Boolean)
        )
        setMagnitude(magnitude_)
    }, [data])

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoaded(true)
        }, 0)
        return () => clearTimeout(timer)
    }, [])

    const handleClickSelectionPanel_ = value => {
        handleClickSelectionPanel(value)
        setShowGraph(1)
    }

    const downloadPDF = async (id1, id2) => {
        // await new Promise(resolve => setTimeout(resolve, 1000))
        const eleChart = document.getElementById(id1)
        const downloadDate = new Date()
        eleChart.style.width = '700px'
        eleChart.style.height = '300px'
        await new Promise(resolve => setTimeout(resolve, 1000))
        const scale = 2
        const blob = await domToImage.toPng(eleChart, {
            width: eleChart.clientWidth * scale,
            height: eleChart.clientHeight * scale,
            style: {
                transform: 'scale(' + scale + ')',
                transformOrigin: 'top left',
            },
        })
        eleChart.style.width = 'unset'
        eleChart.style.height = ''
        const img = new Image()
        img.src = blob
        const doc = new JsPDF()
        doc.setFontSize(20)
        doc.text(view + ' - ' + title1, 12, 15)
        doc.setFontSize(16)
        doc.text(subtitle1, 12, 22)
        doc.setFontSize(12)
        doc.text('Fecha de descarga: ' + downloadDate.toLocaleString('es-CL'), 12, 29)
        let finalY = 36
        const splitText = doc.splitTextToSize(
            'Productores: ' + [...new Set(predioFilter)].sort((a, b) => (a > b ? 1 : -1)).join(', '),
            180
        )
        splitText.forEach(text => {
            doc.text(text, 12, finalY)
            finalY += 5
        })
        doc.addImage(blob, 'PNG', 12, finalY + 5, 700 / (2 * scale), 300 / (2 * scale))
        doc.autoTable({
            html: `#${id2}`,
            styles: {
                cellWidth: 'wrap',
            },
            startY: finalY + 85,
        })
        doc.save(view + title1 + '.pdf')
    }

    return (
        <>
            <Grid
                container
                style={{
                    ...(showGraph !== 1 && { position: 'absolute', left: '-999em' }),
                }}
            >
                <Grid item xs={12}>
                    <div className={classes.headerGraph}>
                        <div style={{ display: 'inline-block', marginRight: 5 }}>
                            <span
                                style={{
                                    display: 'block',
                                    color: '#292929',
                                    fontSize: 32,
                                    fontWeight: 800,
                                }}
                            >
                                {title1}
                            </span>
                        </div>
                        <div className={classes.tableButtonDesktop}>
                            <ButtonVerTabla
                                variant="outlined"
                                color="primary"
                                size="large"
                                text="Tabla"
                                onClick={() => setShowGraph(2)}
                            />
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.subHeaderGraph}>
                        <span
                            style={{
                                display: 'block',
                                color: '#292929',
                                fontSize: 16,
                                fontWeight: 'normal',
                            }}
                        >
                            {subtitle1}
                        </span>
                    </div>
                </Grid>

                <Grid item xs={12}>
                    <div className={classes.chart} id={'chartContainer' + title1}>
                        {type === 'area' && loaded && (
                            <ResponsiveContainer width="100%">
                                <LineChart data={data} isAnimationActive={false}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" tickFormatter={DateFormatter} />
                                    <YAxis
                                        {...(maxValue && { domain: [minValue || 0, maxValue] })}
                                        tickFormatter={number => DataFormater(number, magnitude, decimalPlaces)}
                                    >
                                        <Label
                                            value={capitalize(
                                                (magnitude > 1000000
                                                    ? 'millones de '
                                                    : magnitude > 1000
                                                    ? 'miles de '
                                                    : '') + yLabel
                                            )}
                                            position="insideLeft"
                                            angle={-90}
                                            style={{ textAnchor: 'middle' }}
                                        />
                                    </YAxis>
                                    <Tooltip
                                        forecast={forecast}
                                        isAnimationActive={false}
                                        content={<CustomTooltip />}
                                        decimalPlaces={decimalPlaces}
                                    />
                                    <Legend />
                                    <Line
                                        type="linear"
                                        isAnimationActive={false}
                                        dataKey="año anterior"
                                        stroke="#4b9f4c"
                                        fill="#4b9f4c"
                                        fillOpacity={0.75}
                                        strokeWidth={4}
                                        activeDot={{ r: 6 }}
                                        connectNulls
                                    />
                                    <Line
                                        type="linear"
                                        isAnimationActive={false}
                                        dataKey="año actual"
                                        stroke="#00539b"
                                        fill="#00539b"
                                        fillOpacity={0.75}
                                        strokeWidth={4}
                                        activeDot={{ r: 6 }}
                                        connectNulls
                                    />
                                    {forecast && (
                                        <Line
                                            type="linear"
                                            isAnimationActive={false}
                                            dataKey="forecast"
                                            stroke="#b65a39"
                                            fill="#b65a39"
                                            fillOpacity={0.75}
                                            strokeWidth={2}
                                            activeDot={{ r: 3 }}
                                            connectNulls
                                        />
                                    )}
                                </LineChart>
                            </ResponsiveContainer>
                        )}
                        {type === 'bar' && loaded && (
                            <ResponsiveContainer width="100%">
                                <BarChart data={data} isAnimationActive={false}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" tickFormatter={DateFormatter} />
                                    <YAxis
                                        {...(maxValue && { domain: [minValue || 0, maxValue] })}
                                        tickFormatter={number => DataFormater(number, magnitude, decimalPlaces)}
                                    >
                                        <Label
                                            value={capitalize(
                                                (magnitude > 1000000
                                                    ? 'millones de '
                                                    : magnitude > 1000
                                                    ? 'miles de '
                                                    : '') + yLabel
                                            )}
                                            position="insideLeft"
                                            angle={-90}
                                            style={{ textAnchor: 'middle' }}
                                        />
                                    </YAxis>
                                    <Tooltip
                                        isAnimationActive={false}
                                        content={<CustomTooltip />}
                                        decimalPlaces={decimalPlaces}
                                    />
                                    <Legend />
                                    <Bar
                                        isAnimationActive={false}
                                        dataKey="año anterior"
                                        fill="#4b9f4c"
                                        fillOpacity={0.75}
                                    />
                                    <Bar
                                        isAnimationActive={false}
                                        dataKey="año actual"
                                        fill="#00539b"
                                        fillOpacity={0.75}
                                    />
                                </BarChart>
                            </ResponsiveContainer>
                        )}
                    </div>
                </Grid>
                <div className={classes.tableButtonMobile}>
                    <Divider />
                    {isSideSelector ? (
                        <Grid container>
                            <Grid item xs={6}>
                                <Button
                                    color="primary"
                                    size="large"
                                    style={{ width: '100%', padding: 12 }}
                                    onClick={() => setShowGraph(3)}
                                >
                                    Variables
                                </Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button
                                    color="primary"
                                    size="large"
                                    style={{ width: '100%', padding: 12 }}
                                    className={classes.footerButtonCenter}
                                    onClick={() => setShowGraph(2)}
                                >
                                    Ver Tabla
                                </Button>
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid container item xs={12}>
                            <Button
                                color="primary"
                                size="large"
                                style={{ width: '100%', padding: 12 }}
                                onClick={() => setShowGraph(2)}
                            >
                                Ver Tabla
                            </Button>
                        </Grid>
                    )}
                </div>
            </Grid>
            <Grid
                container
                style={{
                    ...(showGraph !== 2 && { display: 'none' }),
                    ...(!buttons && { paddingBottom: 20 }),
                }}
            >
                <Grid item xs={12}>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginTop: 20,
                            marginLeft: 20,
                            marginRight: 20,
                        }}
                    >
                        <div style={{ display: 'inline-block', marginRight: 5 }}>
                            <span
                                style={{
                                    display: 'block',
                                    color: '#292929',
                                    fontSize: 20,
                                    fontWeight: 800,
                                }}
                            >
                                {title2}
                            </span>
                            <span
                                style={{
                                    display: 'block',
                                    color: '#292929',
                                    fontSize: 16,
                                    fontWeight: 'normal',
                                }}
                            >
                                {subtitle2}
                            </span>
                        </div>
                        <div>
                            <ButtonVerTabla
                                variant="outlined"
                                color="primary"
                                size="large"
                                text="Gráfico"
                                onClick={() => setShowGraph(1)}
                            />
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.table}>
                        <Table stickyHeader id={('tableContainer' + title1).replace(/\s+/g, '')}>
                            <TableHead>
                                <TableRow>
                                    {headCells.map(head => {
                                        return (
                                            <TableCell
                                                className={classes.tableHeadCell}
                                                key={head.id}
                                                style={{ textAlign: 'center' }}
                                            >
                                                {head.label}
                                            </TableCell>
                                        )
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {parsedData.map((row, index) => {
                                    return (
                                        <StyledTableRow key={index}>
                                            <TableCell
                                                className={classes.tableBodyCell}
                                                style={{ textAlign: 'center' }}
                                            >
                                                {DateFormatter(row.date || [])}
                                            </TableCell>
                                            <TableCell
                                                className={classes.tableBodyCell}
                                                style={{ textAlign: 'center' }}
                                            >
                                                {(row.data1 || 0).toLocaleString('es-CL', {
                                                    minimumFractionDigits: decimalPlaces,
                                                    maximumFractionDigits: decimalPlaces,
                                                })}
                                            </TableCell>
                                            <TableCell
                                                className={classes.tableBodyCell}
                                                style={{ textAlign: 'center' }}
                                            >
                                                {(row.data2 || 0).toLocaleString('es-CL', {
                                                    minimumFractionDigits: decimalPlaces,
                                                    maximumFractionDigits: decimalPlaces,
                                                })}
                                            </TableCell>
                                        </StyledTableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </div>
                </Grid>
                <Grid container item xs={12}>
                    {buttons && (
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                    )}
                    <Hidden lgUp>
                        {isSideSelector ? (
                            <Grid item xs={12}>
                                <Button
                                    color="primary"
                                    size="large"
                                    style={{ width: '100%', padding: 12, fontWeight: 'bold' }}
                                    onClick={() => setShowGraph(3)}
                                >
                                    Variables
                                </Button>
                                <Divider />
                            </Grid>
                        ) : (
                            <></>
                        )}
                    </Hidden>
                    {buttons && (
                        <Fragment>
                            <Grid item xs={6} lg={4}>
                                <Button
                                    color="primary"
                                    size="large"
                                    style={{
                                        width: '100%',
                                        padding: 12,
                                        borderRadius: 0,
                                        fontWeight: 'bold',
                                    }}
                                    onClick={() =>
                                        downloadPDF(
                                            'chartContainer' + title1,
                                            ('tableContainer' + title1).replace(/\s+/g, '')
                                        )
                                    }
                                    // disabled={true}
                                >
                                    Descargar PDF
                                </Button>
                            </Grid>
                            <Grid item xs={6} lg={4}>
                                <Button
                                    color="primary"
                                    size="large"
                                    style={{ width: '100%', padding: 12, fontWeight: 'bold' }}
                                    className={classes.footerButtonCenter}
                                    onClick={downloadCSV}
                                    disabled={loadingCSV}
                                    startIcon={loadingCSV && <CircularProgress size={20} />}
                                >
                                    Descargar Excel
                                </Button>
                            </Grid>
                            <Grid item xs={12} className={classes.gridMobile}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <Button
                                    color="primary"
                                    size="large"
                                    style={{
                                        width: '100%',
                                        padding: 12,
                                        borderRadius: 0,
                                        fontWeight: 'bold',
                                    }}
                                    onClick={() => setShowGraph(2)}
                                    disabled={true}
                                >
                                    {/* Imprimir */}
                                </Button>
                            </Grid>
                        </Fragment>
                    )}
                </Grid>
            </Grid>
            {showGraph !== 1 && showGraph !== 2 && (
                <SelectionPanel
                    value_={title1}
                    handleClickSelectionPanel={value => handleClickSelectionPanel_(value)}
                />
            )}
        </>
    )
}

export default CartaTablaGraficoContent
