const init = () => {
    const indexedDb = window.indexedDB
    const conexion = indexedDb.open('Sociedades', 1)
    return new Promise(resolve => {
        conexion.onsuccess = () =>{
            const db = conexion.result
            resolve(
                {
                    message: "Base de datos abierta",
                    database: db,
                    error: null,
                    state: 'abierta'
                }
            )
        }
    
        conexion.onupgradeneeded = (e) =>{
            const db = e.target.result
            const coleccionObjetos = db.createObjectStore('SociedadesData',{
                keyPath: 'id'
            })
            coleccionObjetos.transaction.oncomplete = (event) => {
                resolve(
                    {
                        message: "Base de datos creada / actualizada",
                        database: db,
                        error: null,
                        state: 'actualizada'
                    }
                )
            }
            
        }
    
        conexion.onerror = (error) =>{
            resolve(
                {
                    message: "Error",
                    error: error,
                    state: 'error'
                }
            )
        }
    })
}

const actualizar = (data, database) => {
    return new Promise(resolve => {
        try {
            const trasaccion = database.transaction(['SociedadesData'],'readwrite')
            const coleccionObjetos = trasaccion.objectStore('SociedadesData')
            const conexion = coleccionObjetos.put(data)

            conexion.onsuccess = (ev) =>{
                resolve({
                    data: ev,
                    state: true,
                    err: null
                })
            }

            conexion.onerror = (ev) =>{
                resolve({
                    data: ev,
                    state: false,
                    err: null
                })
            }

        } catch (err) {
            resolve({
                err: err,
                state: false
            })
        }
    }) 
}

const eliminar = (clave, database) =>{      
    return new Promise(resolve => {
        const trasaccion = database.transaction(['SociedadesData'],'readwrite')
        const coleccionObjetos = trasaccion.objectStore('SociedadesData')
        const conexion = coleccionObjetos.delete(clave)

        conexion.onsuccess = (ev) =>{
            resolve({
                data: ev,
                state: true
            })
        }

        conexion.onerror = (ev) =>{
            resolve({
                data: ev,
                state: false
            })
        }
    })
}

const leerPorId = (id, database) => {
    return new Promise(resolve => {
        const trasaccion = database.transaction(['SociedadesData'],'readonly')
        const coleccionObjetos = trasaccion.objectStore('SociedadesData')
        const conexion = coleccionObjetos.openCursor()
    
        conexion.onsuccess = () =>{
            const objectResponse = coleccionObjetos.get(id)
            objectResponse.onsuccess = (ev) => {
                resolve({
                    data: ev.target.result,
                    state: true,
                    error: null
                })
            }
            objectResponse.onerror = (err) => {
                resolve({
                    error: err,
                    state: false
                })
            }
        }
    
        conexion.onerror = (err) =>{
            resolve({
                error: err,
                state: false
            })
        }
    })
}


const leerTodas = (database) => {
    return new Promise(resolve => {
        const trasaccion = database.transaction(['SociedadesData'],'readonly')
        const coleccionObjetos = trasaccion.objectStore('SociedadesData')
        const conexion = coleccionObjetos.openCursor()
    
        conexion.onsuccess = () =>{
            const allObject = coleccionObjetos.getAll()
            allObject.onsuccess = (ev) => {
                resolve({
                    data: ev.target.result,
                    state: true,
                    error: null
                })
            }
            allObject.onerror = (err) => {
                resolve({
                    error: err,
                    state: false
                })
            }
        }
    
        conexion.onerror = (err) =>{
            resolve({
                error: err,
                state: false
            })
        }
    })
}

export default {
    init,
    actualizar,
    eliminar,
    leerPorId,
    leerTodas
}