/* React */
import React, { createContext, useContext, useEffect, useState } from 'react'

/* GraphQL */
import { useLazyQuery, useMutation } from '@apollo/client'
import { EDIT_SALA, SALAS } from '../../graphQL/salas'

/* Material UI */
import { IconButton, Link } from '@material-ui/core'
import { EditOutlined } from '@material-ui/icons'

/* Snackbar */
import { useSnackbar } from 'notistack'

/* Context */
// import { AdminFilterContext } from './'
import { AuthContext } from '../'

/* Components */
import { TableSwitch } from '../../components/administracion'

/* Utilities */
import { get } from 'lodash'

export const SalasContext = createContext()

const SalasContextProvider = props => {
    const { permissions, sociedades } = useContext(AuthContext)
    // const { setIsCreate } = useContext(AdminFilterContext)
    // const { predios } = useContext(PrediosContext)
    // const classes = useStyles()
    const [editSalaId, setEditSalaId] = useState('')
    const [editSalaCode, setEditSalaCode] = useState('')
    const [salas, setSalas] = useState([])
    const [isCreate, setIsCreate] = useState()
    const [sociedadDeSala, setSociedadDeSala] = useState()
    const { enqueueSnackbar } = useSnackbar()

    const [getSalas, { data }] = useLazyQuery(SALAS, {
        fetchPolicy: 'cache-and-network',
    })
    const [editSala] = useMutation(EDIT_SALA, {
        onCompleted(data) {
            if (data && data.editSala) {
                enqueueSnackbar('Sala editada correctamente', { variant: 'success' })
            }
        },
        onError(error) {
            enqueueSnackbar(error.message, { variant: 'error' })
        },
    })

    const [mobileSelection, setMobileSelection] = useState([
        { name: 'Productor', cellFields: ['name'] },
        { name: 'Planta', cellFields: ['sector'] },
        { name: 'Dirección', cellFields: ['direction'] },
        ...(permissions?.administracion > 1 ? [{ name: 'Edición', cellFields: ['edit'] }] : []),
    ])

    const [formFields, setFormFields] = useState([
        {
            name: 'GPS.lat',
            type: 'floatInput',
            label: 'Latitud GPS',
            placeHolder: 'Latitud GPS',
            value: '',
            max: 90,
            min: -90,
            enabled: false
        },
        {
            name: 'GPS.lon',
            type: 'floatInput',
            label: 'Longitud GPS',
            placeHolder: 'Longitud GPS',
            value: '',
            max: 180,
            min: -180,
            enabled: false
        },
        {
            name: 'Sociedad',
            type: 'select',
            label: 'Sociedad',
            placeHolder: 'Sociedad',
            value: '',
            menuItems: sociedades,
            length: 100,
            required: true,
            enabled: true
        }
    ])

    const [columns, setColumns] = useState([
        { field: 'producerCode', headerName: 'Código', sortable: true },
        { field: 'name', headerName: 'Productor', sortable: true },
        { field: 'sector', headerName: 'Planta', sortable: true },
        { field: 'direction', headerName: 'Dirección', sortable: true },
        ...(permissions?.administracion > 1
            ? [{ field: 'edit', headerName: 'Editar', sortable: false, disablePadding: true, center: true }]
            : []),
    ])

    useEffect(() => {
        setMobileSelection([
            { name: 'Productor', cellFields: ['name'] },
            { name: 'Planta', cellFields: ['sector'] },
            ...(permissions?.administracion > 1
                ? [{ name: 'Edición', cellFields: ['edit'] }]
                : [{ name: 'Dirección', cellFields: ['direction'] }]),
        ])
        setColumns([
            { field: 'producerCode', headerName: 'Código', sortable: true },
            { field: 'name', headerName: 'Productor', sortable: true },
            { field: 'sector', headerName: 'Planta', sortable: true },
            { field: 'direction', headerName: 'Dirección', sortable: true },
            ...(permissions?.administracion > 1
                ? [{ field: 'edit', headerName: 'Editar', sortable: false, disablePadding: true, center: true }]
                : []),
        ])
    }, [permissions])

    const createData = sala => {
        return {
            id: sala._id,
            _id: sala._id,
            producerCode: sala.producerCode,
            name: sala.name,
            description: sala.description,
            // unidades: sala.unidades,
            livestock: sala.livestock,
            horarios: sala.horarios,
            sector: sala.sector,
            kpi: sala.kpi,
            direction: sala.direction,
            GPSlink: link(sala.GPSlink),
            sociedad: sala.sociedad.name,
            enabled: switchEnabled(sala._id, sala.enabled),
            edit: iconEdit(sala._id, sala.producerCode),
        }
    }

    useEffect(() => {
        if (sociedades?.length > 0) {
            getSalas({
                variables: {
                    sociedadesIds: sociedades.map(sociedad => sociedad._id),
                },
            })
            const formFieldsCache = [...formFields]
            formFieldsCache[2].menuItems = sociedades
            setFormFields(formFieldsCache)
        }
    }, [sociedades])

    useEffect(() => {
        if (data && data.salas?.length >= 0) {
            const _parsed = data.salas.map(sala => {
                return createData(sala)
            })
            setSalas(_parsed)
        }
    }, [data])

    const link = value =>
        value ? (
            <Link href={`${value}`} target="_blank" rel="noreferrer">
                link
            </Link>
        ) : (
            'Sin coordenadas'
        )

    const switchEnabled = (id, value) => {
        return (
            <TableSwitch
                name="estatus"
                checked={value}
                onChange={event => handleChangeSwitch(event, id)}
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
            />
        )
    }

    const iconEdit = (id, code) => {
        return (
            <IconButton aria-label="edit" onClick={() => handleClickIconEdit(id, code)}>
                <EditOutlined style={{ color: '#000' }} />
            </IconButton>
        )
    }

    const handleClickIconEdit = (id, code) => {
        console.log(id, code)
        setEditSalaId(id)
        setEditSalaCode(code)
        const _data = data.salas.find(e => e._id === id)
        console.log(_data)
        setSociedadDeSala(_data.sociedad)
        // actualizar campos del formulario
        console.log(formFields.map(item => ({ ...item, value: get(_data, item.name) })))
        setFormFields(formFields.map(item => ({ ...item, value: get(_data, item.name) })))
        setIsCreate(true)
    }

    const handleClickCreateButton = values => {
        console.log(values)
        console.log(editSalaId)
        // editar salas
        if (editSalaId !== '' && values[2]._id) {
            editSala({
                variables: {
                    salaId: editSalaId,
                    description: 'Xxxxxxxxxxxxx',
                    livestock: 0/* parseInt(values[1]) */,
                    lat: null/* parseFloat(values[2]) */,
                    lon: null/* parseFloat(values[3]) */,
                    sociedadId: values[2]._id
                },
            })
        }

        setIsCreate(false)
    }

    const handleChangeSwitch = (event, id) => {
        editSala({
            variables: {
                salaId: id,
                enabled: event.target.checked,
            },
        })
    }

    return (
        <SalasContext.Provider
            value={{
                columns,
                data: data?.salas || [],
                salas,
                formFields,
                handleClickCreateButton,
                editSalaId,
                setEditSalaId,
                editSalaCode,
                setEditSalaCode,
                mobileSelection,
                isCreate,
                setIsCreate,
                sociedadDeSala
            }}
        >
            {props.children}
        </SalasContext.Provider>
    )
}

export default SalasContextProvider
