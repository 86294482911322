/* React */
import React, { createContext, useContext, useEffect, useState } from 'react'

/* GraphQL */
import { useMutation, useQuery } from '@apollo/client'
import { CREATE_FORRAJE, EDIT_FORRAJE, FORRAJES } from '../../graphQL/forrajes'

/* Context */
import { AuthContext } from '../../contexts'

/* Snackbar */
import { useSnackbar } from 'notistack'
import { SalasContext } from '../administracion'

export const ForrajeContext = createContext()

const ForrajeContextProvider = props => {
    const { enqueueSnackbar } = useSnackbar()
    const [isCreate, setIsCreate] = useState(false)
    const { permissions, sociedades } = useContext(AuthContext)
    const [parsedForrajes, setParsedForrajes] = useState([])
    const [parsedSalas, setParsedSalas] = useState([])
    const [fields, setFields] = useState({
        locacion: {
            label: 'Nombre',
            placeHolder: 'Nombre',
            value: '',
            type: 'textInput',
        },
        direction: {
            label: 'Dirección',
            placeHolder: 'Dirección',
            value: '',
            type: 'textInput',
        },
        sociedades: {
            label: 'Sociedades',
            placeHolder: 'Sociedades',
            value: [],
            type: 'multiple',
        },
        image: {
            label: 'Imagen',
            placeHolder: 'Imagen',
            value: null,
            type: 'imageInput',
        },
        lat: {
            label: 'Latitud GPS',
            placeHolder: 'Latitud GPS',
            value: 0,
            type: 'floatInput',
        },
        lon: {
            label: 'Longitud GPS',
            placeHolder: 'Longitud GPS',
            value: 0,
            type: 'floatInput',
        },
        dryMatter: {
            label: 'Materia Seca',
            placeHolder: 'Materia Seca',
            fullWidth: true,
            value: [],
            type: 'arrayInput',
        },
    })

    const { data: forrajes } = useQuery(FORRAJES, {
        fetchPolicy: 'cache-and-network',
    })
    const { data: salas } = useContext(SalasContext)
    const [createForraje] = useMutation(CREATE_FORRAJE, {
        onCompleted(data) {
            if (data?.createForraje?.message) {
                enqueueSnackbar(data.createForraje.message, {
                    variant: 'success',
                    persist: true,
                })
                setIsCreate(false)
            }
        },
        onError(error) {
            enqueueSnackbar(error.message, { variant: 'error' })
        },
    })

    const [editForraje] = useMutation(EDIT_FORRAJE, {
        onCompleted(data) {
            if (data?.editForraje?.message) {
                enqueueSnackbar(data.editForraje.message, {
                    variant: 'success',
                    persist: true,
                })
            }
        },
        onError(error) {
            enqueueSnackbar(error.message, { variant: 'error' })
        },
    })

    useEffect(() => {
        if (forrajes?.forrajes?.length >= 0) {
            const data_ = forrajes.forrajes.map(forraje => {
                const newForraje = { ...forraje }
                newForraje.cantidad = newForraje.dryMatter[0]?.cantidad
                newForraje.lastUpdate = newForraje.dryMatter[0]?.fecha

                if (permissions.forraje >= 2) {
                    let permittedFlag = false
                    for (const sociedad of forraje.sociedades) {
                        const findIndex = sociedades?.findIndex(data => data._id.toString() === sociedad.toString())
                        if (findIndex > -1) {
                            permittedFlag = true
                            break
                        }
                    }
                    newForraje.permitted = permittedFlag
                }

                if (permissions.forraje === 3 && forraje.public) {
                    newForraje.permitted = true
                }
                return newForraje
            })
            setParsedForrajes(data_)
        }
    }, [forrajes])

    useEffect(() => {
        const data_ = salas.filter(sala => (sala.GPS.lat || sala.GPS.lat === 0) && (sala.GPS.lon || sala.GPS.lon === 0))
        setParsedSalas(data_)
    }, [salas])

    const createCartaForraje = (locacion, direction, sociedades, image, lat, lon, dryMatter, publicCard) => {
        createForraje({
            variables: {
                locacion,
                direction,
                sociedades,
                image,
                lat,
                lon,
                dryMatter,
                publicCard,
            },
            refetchQueries: () => [
                {
                    query: FORRAJES,
                },
            ],
        })
    }

    const handleChangeTextInput = (fieldName, value) => {
        // const data_ = { ...fields }
        switch (fieldName) {
            case 'locacion': {
                setFields({ ...fields, locacion: { ...fields.locacion, value } })
                return
            }
            case 'direction': {
                setFields({ ...fields, direction: { ...fields.direction, value } })
                return
            }
            case 'lat': {
                setFields({ ...fields, lat: { ...fields.lat, value } })
                return
            }
            case 'lon': {
                setFields({ ...fields, lon: { ...fields.lon, value } })
                return
            }
            case 'sociedades': {
                setFields({ ...fields, sociedades: { ...fields.sociedades, value } })
                return
            }
            default: {
                console.log('default')
            }
        }
    }

    const handleChangeDryMatter = (fieldName, index, value) => {
        const cells = [...fields.dryMatter.value]
        if (fieldName === 'fecha') {
            cells[index].fecha = value
        } else if (fieldName === 'cantidad') {
            cells[index].cantidad = value
        }
        setFields({ ...fields, dryMatter: { ...fields.dryMatter, value: cells } })
    }

    const addDryMatter = () => {
        const cells = [...fields.dryMatter.value, { fecha: new Date(), cantidad: 0 }]
        setFields({ ...fields, dryMatter: { ...fields.dryMatter, value: cells } })
    }

    const deleteDryMatter = index => {
        const cells = [...fields.dryMatter.value]
        cells.splice(index, 1)
        setFields({ ...fields, dryMatter: { ...fields.dryMatter, value: cells } })
    }

    const handleImage = event => {
        if (event.target && event.target.files[0]) {
            const img = event.target.files[0]
            if (img.size > 500000) {
                setFields({ ...fields, image: { ...fields.image, value: null } })
                return enqueueSnackbar('La imagen no debe superar los 500KB.', {
                    variant: 'error',
                })
            }
            setFields({
                ...fields,
                image: {
                    ...fields.image,
                    value: { file: img, validity: event.target.validity },
                },
            })
        } else {
            setFields({ ...fields, image: { ...fields.image, value: null } })
        }
    }

    const handleSubmitData = publicCard => {
        if (!fields.locacion.value || !fields.direction.value || !fields.lat.value || !fields.lon.value) {
            return enqueueSnackbar('Debe rellenar todos los campos.', {
                variant: 'error',
            })
        }
        createCartaForraje(
            fields.locacion.value,
            fields.direction.value,
            fields.sociedades.value?.map(sociedades => sociedades._id) || [],
            fields.image.value?.validity ? fields.image.value.file : null,
            parseFloat(fields.lat.value),
            parseFloat(fields.lon.value),
            fields.dryMatter.value
                .filter(dryMatter => dryMatter.fecha)
                .map(dryMatter => ({
                    fecha: dryMatter.fecha,
                    cantidad: parseFloat(dryMatter.cantidad || 0),
                })),
            publicCard
        )
    }

    const finishDryMatterEdit = (forrajeId, dryMatter) => {
        editForraje({
            variables: {
                forrajeId,
                dryMatter: dryMatter.map(point => ({
                    fecha: point.fecha,
                    cantidad: parseFloat(point.cantidad),
                })),
            },
            refetchQueries: () => [
                {
                    query: FORRAJES,
                },
            ],
        })
    }

    return (
        <ForrajeContext.Provider
            value={{
                fields,
                createCartaForraje,
                isCreate,
                setIsCreate,
                handleChangeTextInput,
                handleChangeDryMatter,
                addDryMatter,
                deleteDryMatter,
                handleImage,
                handleSubmitData,
                parsedForrajes,
                parsedSalas,
                finishDryMatterEdit,
            }}
        >
            {props.children}
        </ForrajeContext.Provider>
    )
}

export default ForrajeContextProvider
