/* React */
import React, { createContext, useContext, useEffect, useState } from 'react'

/* GraphQL */
import { useMutation } from '@apollo/client'
import { EDIT_USER_PERMISSIONS } from '../../graphQL/users'

/* Snackbar */
import { useSnackbar } from 'notistack'

/* Context */
import { UsuariosContext } from './'
import { AuthContext } from '../'

/* Components */
import { TableSwitch } from '../../components/administracion'

export const AsignacionPermisosContext = createContext()

const AsignacionPermisosContextProvider = props => {
    const { permissions } = useContext(AuthContext)
    const { users } = useContext(UsuariosContext)
    const [selectedId, setSelectedId] = useState(null)
    const { enqueueSnackbar } = useSnackbar()

    const [editUserPermissions] = useMutation(EDIT_USER_PERMISSIONS, {
        onCompleted(data) {
            if (data && data.editUserPermissions) {
                enqueueSnackbar('Asignación de permisos realizado correctamente', {
                    variant: 'success',
                })
            }
        },
        onError(error) {
            enqueueSnackbar(error.message, { variant: 'error' })
        },
    })
    const [permissionStructure, setPermissionStructure] = useState({
        produccion: {
            vistaGeneral: 0,
            calidad: 0,
            productividad: 0,
            inviernoVerano: 0,
            kpi: 0
        },
        facturacion: {
            resumenPrecios: 0,
            liquidaciones: 0,
        },
        situacionSanitaria: 0,
        forraje: 0,
        recoleccion: 0,
        administracion: 0,
    })
    const [rows, setRows] = useState([])

    const usersColumns = [
        { field: 'name', headerName: 'Nombres', sortable: true },
        { field: 'lastName', headerName: 'Apellidos', sortable: true },
    ]

    const menusColumns = [
        {
            field: 'permissionType',
            headerName: 'Tipo de permisos',
            sortable: false,
        },
        {
            field: 'view',
            headerName: 'Ver',
            sortable: false,
            disablePadding: true,
            center: true,
        },
        {
            field: 'edit',
            headerName: 'Editar',
            sortable: false,
            disablePadding: true,
            center: true,
        },
    ]

    useEffect(() => {
        const findUser = users?.find(user => user._id.toString() === selectedId)
        if (findUser) {
            console.log(findUser)
            setPermissionStructure({
                ...permissionStructure,
                ...findUser.permissions,
            })
        } else {
            setPermissionStructure({ ...permissionStructure })
        }
    }, [selectedId, users])

    useEffect(() => {
        const prod = { ...permissionStructure.produccion }
        const fact = { ...permissionStructure.facturacion }
        delete prod.__typename
        delete fact.__typename
        const prodValues = Object.values(prod)
        const factValues = Object.values(fact)
        const produccion = prodValues.every(val => val === 2) ? 2 : prodValues.every(val => val >= 1) ? 1 : 0
        const facturacion = factValues.every(val => val === 2) ? 2 : factValues.every(val => val >= 1) ? 1 : 0

        const rows = [
            {
                _id: 1,
                permissionType: renderPermisos('Producción', true),
                view: switchStatus(produccion > 0, 'produccion', 1, permissions?.administracion > 1),
                edit: switchStatus(produccion > 1, 'produccion', 2, permissions?.administracion > 1),
            },
            {
                _id: 2,
                permissionType: renderPermisos('Vista General', false),
                view: switchStatus(prod.vistaGeneral > 0, 'vistaGeneral', 1, permissions?.administracion > 1),
                edit: switchStatus(prod.vistaGeneral > 1, 'vistaGeneral', 2, permissions?.administracion > 1),
            },
            {
                _id: 3,
                permissionType: renderPermisos('Calidad', false),
                view: switchStatus(prod.calidad > 0, 'calidad', 1, permissions?.administracion > 1),
                edit: switchStatus(prod.calidad > 1, 'calidad', 2, permissions?.administracion > 1),
            },
            {
                _id: 4,
                permissionType: renderPermisos('Productividad', false),
                view: switchStatus(prod.productividad > 0, 'productividad', 1, permissions?.administracion > 1),
                edit: switchStatus(prod.productividad > 1, 'productividad', 2, permissions?.administracion > 1),
            },
            {
                _id: 5,
                permissionType: renderPermisos('Invierno/Verano', false),
                view: switchStatus(prod.inviernoVerano > 0, 'inviernoVerano', 1, permissions?.administracion > 1),
                edit: switchStatus(prod.inviernoVerano > 1, 'inviernoVerano', 2, permissions?.administracion > 1),
            },
            {
                _id: 13,
                permissionType: renderPermisos('KPI', false),
                view: switchStatus(prod.kpi > 0, 'kpi', 1, permissions?.administracion > 1),
                edit: switchStatus(prod.kpi > 1, 'kpi', 2, permissions?.administracion > 1),
            },
            {
                _id: 6,
                permissionType: renderPermisos('Facturación', true),
                view: switchStatus(facturacion > 0, 'facturacion', 1, permissions?.administracion > 1),
                edit: switchStatus(facturacion > 1, 'facturacion', 2, permissions?.administracion > 1),
            },
            {
                _id: 7,
                permissionType: renderPermisos('Resumen de precios', false),
                view: switchStatus(fact.resumenPrecios > 0, 'resumenPrecios', 1, permissions?.administracion > 1),
                edit: switchStatus(fact.resumenPrecios > 1, 'resumenPrecios', 2, permissions?.administracion > 1),
            },
            {
                _id: 8,
                permissionType: renderPermisos('Liquidaciones', false),
                view: switchStatus(fact.liquidaciones > 0, 'liquidaciones', 1, permissions?.administracion > 1),
                edit: switchStatus(fact.liquidaciones > 1, 'liquidaciones', 2, permissions?.administracion > 1),
            },
            {
                _id: 9,
                permissionType: renderPermisos('Situación sanitaria', true),
                view: switchStatus(
                    permissionStructure.situacionSanitaria > 0,
                    'situacionSanitaria',
                    1,
                    permissions?.administracion > 1
                ),
                edit: switchStatus(
                    permissionStructure.situacionSanitaria > 1,
                    'situacionSanitaria',
                    2,
                    permissions?.administracion > 1
                ),
            },
            {
                _id: 10,
                permissionType: renderPermisos('Forraje', true),
                view: switchStatus(permissionStructure.forraje > 0, 'forraje', 1, permissions?.administracion > 1),
                edit: switchStatus(permissionStructure.forraje > 1, 'forraje', 2, permissions?.administracion > 1),
            },
            {
                _id: 11,
                permissionType: renderPermisos('Recolección', true),
                view: switchStatus(
                    permissionStructure.recoleccion > 0,
                    'recoleccion',
                    1,
                    permissions?.administracion > 1
                ),
                edit: switchStatus(
                    permissionStructure.recoleccion > 1,
                    'recoleccion',
                    2,
                    permissions?.administracion > 1
                ),
            },
            {
                _id: 12,
                permissionType: renderPermisos('Administración', true),
                view: switchStatus(
                    permissionStructure.administracion > 0,
                    'administracion',
                    1,
                    permissions?.administracion > 1
                ),
                edit: switchStatus(
                    permissionStructure.administracion > 1,
                    'administracion',
                    2,
                    permissions?.administracion > 1
                ),
            },
        ]
        setRows(rows)
    }, [permissionStructure])

    const renderPermisos = (value, isMenu) => {
        if (isMenu) {
            return <p style={{ margin: 0, fontWeight: 800 }}>{value}</p>
        } else {
            return <p style={{ margin: 0, marginLeft: 16 }}>{value}</p>
        }
    }

    const switchStatus = (value, property, sendValue, enabled) => {
        return (
            <TableSwitch
                name="estatusver"
                checked={value}
                onChange={event => handleChangeValue(event, property, sendValue)}
                color="primary"
                disabled={!enabled}
                inputProps={{ 'aria-label': 'primary checkbox' }}
            />
        )
    }

    const handleChangeValue = (event, property, sendValue) => {
        if (selectedId) {
            if (permissions?.administracion > 1) {
                editUserPermissions({
                    variables: {
                        userId: selectedId,
                        ...(property === 'produccion'
                            ? {
                                  vistaGeneral: event.target.checked ? sendValue : sendValue - 1,
                                  calidad: event.target.checked ? sendValue : sendValue - 1,
                                  productividad: event.target.checked ? sendValue : sendValue - 1,
                                  inviernoVerano: event.target.checked ? sendValue : sendValue - 1,
                                  kpi: event.target.checked ? sendValue : sendValue - 1,
                              }
                            : {}),
                        ...(property === 'facturacion'
                            ? {
                                  resumenPrecios: event.target.checked ? sendValue : sendValue - 1,
                                  liquidaciones: event.target.checked ? sendValue : sendValue - 1,
                              }
                            : {}),
                        ...(!['produccion', 'facturacion'].includes(property) && {
                            [property]: event.target.checked ? sendValue : sendValue - 1,
                        }),
                    },
                })
            }
        } else {
            if (property === 'produccion') {
                setPermissionStructure({
                    ...permissionStructure,
                    produccion: {
                        vistaGeneral: event.target.checked ? sendValue : sendValue - 1,
                        calidad: event.target.checked ? sendValue : sendValue - 1,
                        productividad: event.target.checked ? sendValue : sendValue - 1,
                        inviernoVerano: event.target.checked ? sendValue : sendValue - 1,
                        kpi: event.target.checked ? sendValue : sendValue - 1,
                    },
                })
            } else if (property === 'facturacion') {
                setPermissionStructure({
                    ...permissionStructure,
                    facturacion: {
                        resumenPrecios: event.target.checked ? sendValue : sendValue - 1,
                        liquidaciones: event.target.checked ? sendValue : sendValue - 1,
                    },
                })
            } else {
                setPermissionStructure({
                    ...permissionStructure,
                    ...(property in permissionStructure.produccion && {
                        produccion: {
                            ...permissionStructure.produccion,
                            [property]: event.target.checked ? sendValue : sendValue - 1,
                        },
                    }),
                    ...(property in permissionStructure.facturacion && {
                        facturacion: {
                            ...permissionStructure.facturacion,
                            [property]: event.target.checked ? sendValue : sendValue - 1,
                        },
                    }),
                    ...(!['produccion', 'facturacion'].includes(property) && {
                        [property]: event.target.checked ? sendValue : sendValue - 1,
                    }),
                })
            }
        }
    }

    const handleSelectPreset = preset => {
        console.log(preset)
        if (selectedId) {
            if (permissions?.administracion > 1) {
                editUserPermissions({
                    variables: {
                        userId: selectedId,
                        vistaGeneral: preset.produccion.vistaGeneral,
                        calidad: preset.produccion.calidad,
                        productividad: preset.produccion.productividad,
                        inviernoVerano: preset.produccion.inviernoVerano,
                        kpi: preset.produccion.kpi,
                        resumenPrecios: preset.facturacion.resumenPrecios,
                        liquidaciones: preset.facturacion.liquidaciones,
                        situacionSanitaria: preset.situacionSanitaria,
                        forraje: preset.forraje,
                        recoleccion: preset.recoleccion,
                        administracion: preset.administracion,
                    },
                })
            }
        } else {
            setPermissionStructure({
                produccion: {
                    vistaGeneral: preset.produccion.vistaGeneral,
                    calidad: preset.produccion.calidad,
                    productividad: preset.produccion.productividad,
                    inviernoVerano: preset.produccion.inviernoVerano,
                    kpi: preset.produccion.kpi,
                },
                facturacion: {
                    resumenPrecios: preset.facturacion.resumenPrecios,
                    liquidaciones: preset.facturacion.liquidaciones,
                },
                situacionSanitaria: preset.situacionSanitaria,
                forraje: preset.forraje,
                recoleccion: preset.recoleccion,
                administracion: preset.administracion,
            })
        }
    }

    const handleSelected = id => {
        console.log(id)
        setSelectedId(id)
    }

    return (
        <AsignacionPermisosContext.Provider
            value={{
                usersColumns,
                menusColumns,
                users,
                rows,
                selectedId,
                handleSelected,
                handleSelectPreset,
            }}
        >
            {props.children}
        </AsignacionPermisosContext.Provider>
    )
}

export default AsignacionPermisosContextProvider
