import { Button, Fab, FormControl, InputLabel, MenuItem, Popover, Select, TextField } from "@material-ui/core"
import { Close } from "@material-ui/icons"
import axios from "axios"
import React, { useEffect, useState } from "react"

const NuevoDatoVacasPopover = ({id, anchorEl, filtered, elementDataVacas, openVacaData, handleClose, setElementDataVacas, tipoDatoSeleccionado, toModoEditor, isKPIPage}) => {
    const [idData, setId] = useState()
    const [fechaInicio, setFechaInicio] = useState('')
    const [fechaTermino, setFechaTermino] = useState('')
    const [vOrdenas, setVOrdenas] = useState(0)
    const [vReparto, setVReparto] = useState(0)
    const [vSecas, setVSecas] = useState(0)
    /* const [vPrenadas, setVPrenadas] = useState(0) */
    const [vMasa, setVMasa] = useState(0)
    const [salaSeleccionada, setSalaSeleccionada] = useState('')
    const [dataFiltered, setDataFiltered] = useState([])

    useEffect(() => {
        if (filtered.length > 0) {
            const filteredCache = [...filtered]
            setDataFiltered(filteredCache.sort((a, b) => {
                if (a.code<b.code) {
                    return -1
                } else if (a.code > b.code) {
                    return 1
                } else {
                    return 0
                }
            }))
        }
    }, [filtered])
   
    useEffect(() => {
        console.log(elementDataVacas)
        if (elementDataVacas) {
            setId(elementDataVacas._id)
            const fechaI = new Date(elementDataVacas.fechaInicio)
            const fechaT = new Date(elementDataVacas.fechaTermino)
            const mInicio = ((fechaI.getMonth()+ 1) > 9) ? (fechaI.getMonth() + 1) : `0${fechaI.getMonth() + 1}`
            const mFinal = ((fechaT.getMonth()+1) > 9) ? (fechaT.getMonth() + 1) : `0${fechaT.getMonth() + 1}`
            setFechaInicio(fechaI.getFullYear() + "-" + mInicio + "-" + fechaI.getDate())
            setFechaTermino(fechaT.getFullYear() + "-" + mFinal + "-" + fechaT.getDate())
            setVOrdenas(Number(elementDataVacas.vOrdenas))
            setVReparto(Number(elementDataVacas.vReparto))
            setVSecas(Number(elementDataVacas.vSecas))
            /* setVPrenadas(Number(elementDataVacas.vPrenadas)) */
            setVMasa(Number(elementDataVacas.vMasa))
            setSalaSeleccionada(elementDataVacas.sala._id)
        } else {
            setFechaInicio('')
            setFechaTermino('')
            setVOrdenas(0)
            setVReparto(0)
            setVSecas(0)
            /* setVPrenadas(0) */
            setVMasa(0)
            setSalaSeleccionada('')
        }
    }, [openVacaData])

    const getNumber = (e) => {
        const {value, name} = e.target
        console.log(value, name)
        if (name === 'fechaInicio') {
            setFechaInicio(value)
        } else if (name === 'fechaTermino') {
            setFechaTermino(value)
        } else if (name === 'vacasOreñas') {
            setVOrdenas(Math.abs((value.includes('-')) ? 0 : value))
        } else if (name === 'vacasPreparto') {
            setVReparto(Math.abs((value.includes('-')) ? 0 : value))
        } else if (name === 'vacasSecas') {
            setVSecas(Math.abs((value.includes('-')) ? 0 : value))
        }/*  else if (name === 'vacasPreñadas') {
            setVPrenadas(Math.abs(value))
        } */ else if (name === 'vacaMasa') {
            setVMasa(Math.abs((value.includes('-')) ? 0 : value))
        }
    }

    useEffect(() => {
        console.log(fechaInicio)
    },[fechaInicio])

    useEffect(() => {
        const total = Number(vOrdenas) + Number(vReparto) + Number(vSecas)
        setVMasa(total)
    }, [vOrdenas, vReparto, vSecas])

    const saveKpi = async () => {
        if (vMasa > 0) {
            const kpiVacas = {
                _id: idData,
                fechaInicio: fechaInicio,
                fechaTermino: fechaTermino,
                sala: salaSeleccionada,
                tipo: tipoDatoSeleccionado,
                vOrdenas: vOrdenas,
                vReparto: vReparto,
                vSecas: vSecas,
                vMasa: vMasa,
            }
            const response = await axios.post('/kpi/saveKpiVacas', {kpiVacas: kpiVacas})
            if (response)
            handleClose()
        } else {
            alert('Valor Vaca Masa debe ser mayor a 0')
        }
    }
    return (
        <Popover
            id={id}
            open={openVacaData}
            anchorEl={anchorEl}
            /* onClose={() => {handleClose(); setElementDataVacas(undefined)}} */
            anchorOrigin={{
                vertical: 'center',
                horizontal: 'center',
            }}
            >
            <div className={ 'popover-container-vacas'} style={{position: 'relative'}}>
                <Fab onClick={() => {handleClose(); setElementDataVacas(undefined)}} size="small" color="secondary" aria-label="add" style={{position: 'absolute', top: 10, right: 10}}>
                    <Close />
                </Fab>
                <div className={'popover-container'} style={{ marginTop: 30 }}>
                    <div style={{ width: '100%', display: 'inline-block', padding: 5 }}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Seleccione Sala</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={salaSeleccionada}
                            onChange={(e) => { setSalaSeleccionada(e.target.value) }}
                        >
                            {
                                dataFiltered.map((el, i) => {
                                    return (
                                        <MenuItem key={i} value={el._id}>{el.code} - {el.name}</MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>
                    </div>
                    <div style={{ width: '50%', display: 'inline-block', padding: 5 }}>
                        <p>Fecha inicio</p>
                        <TextField onClick={(e) => {e.target.value = ''}} value={fechaInicio} onChange={getNumber} name={'fechaInicio'} variant='outlined' type={'date'} style={{ width: '100%' }}/>
                    </div>
                    <div style={{ width: '50%', display: 'inline-block', padding: 5 }}>
                        <p>Fecha Término</p>
                        <TextField onClick={(e) => {e.target.value = ''}} value={fechaTermino} onChange={getNumber} name={'fechaTermino'} variant='outlined' type={'date'} style={{ width: '100%' }}/>
                    </div>
                    <br />
                    <div style={{ width: '50%', display: 'inline-block', padding: 5 }}>
                        <TextField color={'secondary'} onClick={(e) => {e.target.value = ''}} value={vOrdenas} onChange={getNumber} name={'vacasOreñas'} variant='outlined' label={'Vacas Ordeñas'} type={'text'} style={{ width: '100%' }}/>
                    </div>
                    <div style={{ width: '50%', display: 'inline-block', padding: 5 }}>
                        <TextField onClick={(e) => {e.target.value = ''}} value={vReparto} onChange={getNumber} name={'vacasPreparto'} variant='outlined' label={'Vacas Preparto'} type={'text'} style={{ width: '100%' }}/>
                    </div>
                    <br />
                    <div style={{ width: '50%', display: 'inline-block', padding: 5 }}>
                        <TextField onClick={(e) => {e.target.value = ''}} value={vSecas} onChange={getNumber} name={'vacasSecas'} variant='outlined' label={'Vacas secas'} type={'text'} style={{ width: '100%' }}/>
                    </div>
                    {/* <div style={{ width: '50%', display: 'inline-block', padding: 5 }}>
                        <TextField onClick={(e) => {e.target.value = ''}} value={vPrenadas} onChange={getNumber} name={'vacasPreñadas'} variant='outlined' label={'Vacas preñadas'} type={'number'} style={{ width: '100%' }}/>
                    </div> */}
                    <div style={{ width: '100%', display: 'inline-block', padding: 5 }}>
                        <TextField disabled={true} onClick={(e) => {e.target.value = ''}} value={vMasa} onChange={getNumber} name={'vacaMasa'} variant='outlined' label={'Vaca Masa'} type={'text'} style={{ width: '100%' }}/>
                    </div>
                </div>
            </div>
            <div style={{ width: '100%', padding: 10 }}>
                <Button variant={'contained'} color={'primary'} style={{ width: '100%' }} onClick={saveKpi}>
                    Guardar Dato
                </Button>
            </div>
            {isKPIPage && <div style={{ width: '100%', padding: 10 }}>
                <Button variant={'contained'} color={'primary'} style={{ width: '100%' }} onClick={toModoEditor}>
                    KPI Modo Editor
                </Button>
            </div>}
        </Popover>
    )
}

export default NuevoDatoVacasPopover