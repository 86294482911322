import axios from 'axios'
import { salasIdb } from '../indexedDb'
const getSalasFromRoutes = async () => {
    const response = await axios.get('/salas/getSalas')
    console.log(response.data)
    const {database} = await salasIdb.init()
    response.data.salas.forEach(async (sala, index) => {
        const newSala = sala
        newSala.id = index
        await salasIdb.actualizar(sala, database)
    })
}

export default getSalasFromRoutes