import React, { createContext, useContext, useEffect, useState } from 'react'
import { DataContext } from './DataContext'
import { FilterContext } from './FilterContext'
import { useLazyQuery, gql } from '@apollo/client'
import { saveAs } from 'file-saver'

export const ProductividadContext = createContext()

const PRODUCTIVITY_METRICS = gql`
    fragment fields on AggregatedMetric {
        _id {
            year
            month
            day @skip(if: $groupMonthly)
        }
        LITROS_TOTAL
    }

    query productivity(
        $startCurrentYear: String!
        $endCurrentYear: String!
        $startLastYear: String!
        $endLastYear: String!
        $groupMonthly: Boolean!
        $producerCodes: [String]
    ) {
        lastYear: aggregatedMetrics(
            fechaInicio: $startLastYear
            fechaFin: $endLastYear
            groupMonthly: $groupMonthly
            producerCodes: $producerCodes
        ) {
            metrics {
                ...fields
            }
        }
        currentYear: aggregatedMetrics(
            fechaInicio: $startCurrentYear
            fechaFin: $endCurrentYear
            groupMonthly: $groupMonthly
            producerCodes: $producerCodes
        ) {
            resultSAP
            metrics {
                ...fields
            }
        }
    }
`

const generateData5Days = data => {
    const { lastYear, currentYear } = data
    const data_ = []

    currentYear?.metrics.forEach(item => {
        const date =
            `${item._id.day}/`.padStart(3, '0') + `${item._id.month}/`.padStart(3, '0') + `${item._id.year % 100}`
        const oldItem = lastYear?.metrics?.find(item2 => item2._id.day === item._id.day)

        data_.push({
            name: date,
            'año actual': item.LITROS_TOTAL,
            'año anterior': oldItem ? oldItem.LITROS_TOTAL : null,
            forecast: 0,
        })
    })

    return data_
}

const generateDataAnual = data => {
    const { lastYear, currentYear } = data
    const data_ = []

    currentYear?.metrics?.forEach(item => {
        const date = `${item._id.month}/`.padStart(3, '0') + `${item._id.year % 100}`
        const oldItem = lastYear?.metrics?.find(item2 => item2._id.month === item._id.month)

        data_.push({
            name: date,
            'año actual': item.LITROS_TOTAL,
            'año anterior': oldItem ? oldItem.LITROS_TOTAL : null,
            forecast: 0,
        })
    })

    return data_
}

const ProductividadContextProvider = props => {
    const { errorMessage, setErrorMessage } = useContext(DataContext)
    const { predioFilter } = useContext(FilterContext)
    const [annualData, setAnnualData] = useState([])
    const [fiveDaysData, setFiveDaysData] = useState([])
    const [loading12MesesCSV, setLoading12MesesCSV] = useState(false)
    const [loading5DiasCSV, setLoading5DiasCSV] = useState(false)
    const [getAnnualData, { loading: loadingAnnual, error: errorAnnual, data: receivedAnnualData }] = useLazyQuery(
        PRODUCTIVITY_METRICS,
        {
            fetchPolicy: 'cache-and-network',
            notifyOnNetworkStatusChange: true,
        }
    )
    const [get5DaysData, { loading: loading5Days, error: error5Days, data: received5DaysData }] = useLazyQuery(
        PRODUCTIVITY_METRICS,
        {
            fetchPolicy: 'cache-and-network',
            notifyOnNetworkStatusChange: true,
        }
    )

    const queryServer = () => {
        const today = new Date()
        const initial5DaysNew = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 4)
        const end5DaysNew = new Date(today.getFullYear(), today.getMonth(), today.getDate())
        const initial5DaysOld = new Date(today.getFullYear() - 1, today.getMonth(), today.getDate() - 4)
        const end5DaysOld = new Date(today.getFullYear() - 1, today.getMonth(), today.getDate())
        const initialAnnualNew = new Date(today.getFullYear() - 1, today.getMonth(), 1)
        const endAnnualNew = new Date(today.getFullYear(), today.getMonth() + 1, 0)
        const initialAnnualOld = new Date(today.getFullYear() - 2, today.getMonth(), 1)
        const endAnnualOld = new Date(today.getFullYear() - 1, today.getMonth() + 1, 0)

        get5DaysData({
            variables: {
                startLastYear: initial5DaysOld.toISOString(),
                endLastYear: end5DaysOld.toISOString(),
                startCurrentYear: initial5DaysNew.toISOString(),
                endCurrentYear: end5DaysNew.toISOString(),
                groupMonthly: false,
                producerCodes: predioFilter,
            },
        })
        getAnnualData({
            variables: {
                startLastYear: initialAnnualOld.toISOString(),
                endLastYear: endAnnualOld.toISOString(),
                startCurrentYear: initialAnnualNew.toISOString(),
                endCurrentYear: endAnnualNew.toISOString(),
                groupMonthly: true,
                producerCodes: predioFilter,
            },
        })
    }

    useEffect(() => {
        const error = errorAnnual || error5Days
        if (error ^ errorMessage) setErrorMessage(error)
    }, [error5Days, errorAnnual])

    useEffect(() => {
        if (!received5DaysData?.currentYear?.resultSAP ^ errorMessage)
            setErrorMessage(!received5DaysData?.currentYear?.resultSAP)
        if (received5DaysData?.currentYear?.metrics?.length > 0) setFiveDaysData(generateData5Days(received5DaysData))
    }, [received5DaysData])

    useEffect(() => {
        if (!receivedAnnualData?.currentYear?.resultSAP ^ errorMessage)
            setErrorMessage(!receivedAnnualData?.currentYear?.resultSAP)
        if (receivedAnnualData?.currentYear?.metrics?.length > 0) setAnnualData(generateDataAnual(receivedAnnualData))
        console.log(receivedAnnualData)
        console.log(annualData)
    }, [receivedAnnualData])

    const downloadCSV = async type => {
        let csvData = 'fecha; año actual; año anterior'
        switch (type) {
            case '5Dias':
                setLoading5DiasCSV(true)
                fiveDaysData.forEach(item => {
                    csvData += `\n${item.name}; ${item['año actual']}; ${item['año anterior']}`
                })
                break
            case '12Meses':
                setLoading12MesesCSV(true)
                annualData.forEach(item => {
                    csvData += `\n${item.name}; ${item['año actual']}; ${item['año anterior']}`
                })
                break
        }

        const blob = new Blob([csvData], { type: 'text/plainl;charset=utf-8' })
        saveAs(blob, type + 'Productividad.csv')
        switch (type) {
            case '5Dias':
                setLoading5DiasCSV(false)
                break
            case '12Meses':
                setLoading12MesesCSV(false)
                break
        }
    }

    return (
        <ProductividadContext.Provider
            value={{
                fiveDaysData,
                annualData,
                predioFilter,
                queryServer,
                loadingAnnual,
                loading5Days,
                downloadCSV,
                loading5DiasCSV,
                loading12MesesCSV,
            }}
        >
            {props.children}
        </ProductividadContext.Provider>
    )
}

export default ProductividadContextProvider
