import React, { useRef, useEffect, useContext, useState } from 'react'
import { Box, Button, /* IconButton, */ Grid/*, makeStyles , Paper */ } from '@material-ui/core'
/* import { ArrowDropDown } from '@material-ui/icons' */
import './Welcome.style.css'
import { Link } from 'react-router-dom'
import { AuthContext } from '../contexts'
import SonrisaCircularComponent from './contenedoresDeDatos/SonrisaCircular.component'
import EquipoAgricolaComponent from './contenedoresDeDatos/EquipoAgricola.component'
import ProgramaSustentabilidadComponent from './contenedoresDeDatos/ProgramaSustentabilidad.component'
import PautasDePagoComponent from './contenedoresDeDatos/PautasDePago.component'
import ActividadesComponent from './contenedoresDeDatos/Actividades.component'

/* const baseStyle = {
    borderRadius: 25,
    margin: 25,
    color: '#00539b',
    fontFamily: 'MarkOT, Verdana, sans-serif',
    height: 300,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
} */
const buttonLogin = {
    backgroundColor: '#00539b',
    color:'white',
}
/* const useStyles = makeStyles(theme => ({
    current: {
        borderRadius: 12,
        padding: 10,
    },
    agricultural: {
        ...baseStyle,
        height: 'auto',
        borderRadius: 50,
        padding: 50,
    },
})) */

const Welcome = props => {
    /* const nodeEnv = process.env.NODE_ENV */
    const mounted = useRef()
    /* const {userInfo} = useContext(AuthContext) */
    const { isAuthenticated, userInfo } = useContext(AuthContext)
    const [typeElement, setTypeElement] = useState(null)
    const [posibleToSee, setPosibleToSee] = useState(false)
    /* const posibleToSee = (nodeEnv === 'development' || userInfo.email==='pia.mckenzie@soprole.cl' || userInfo.email==='gonzalo.orellana@kauel.com') && true */
    const [classNameText, setClassNameText] = useState('welcome-text-content-init')

    useEffect(() => {
        if (typeElement==='sonrisaCircular') {
            setClassNameText('welcome-text-content heightSonrisaCircular')
        } else if (typeElement==='equipoAgricola') {
            setClassNameText('welcome-text-content heightEquipoAgricola')
        } else if (typeElement==='programaSustentabilidad') {
            setClassNameText('welcome-text-content heightProgramaSustentabilidad')
        } else if (typeElement==='pautasDePago') {
            setClassNameText('welcome-text-content heightPautasDePago')
        } else if (typeElement==='actividades') {
            setClassNameText('welcome-text-content heightActividades')
        } 
    },[typeElement])

    useEffect(() => {
        if (userInfo) {
            console.log(userInfo)
            if (userInfo.email === 'gonzalo.orellana@kauel.com') {
                setPosibleToSee(true)
            } else if (userInfo.email === 'robinson.ampuero@soprole.cl') {
                setPosibleToSee(true)
            }
        }
    }, [userInfo])

    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true
        }
    })
    const selectButton = (type) => {
        setTypeElement(type)
        setTimeout(() => {
            window.scrollTo({top: 150, behavior: 'smooth'})
        }, 500);
    }

    /* const toBottom = () => {
        const el = document.getElementById('content-welcome')
        setTimeout(() => {
            el.scrollTop = el.scrollHeight
        }, 500);
    } */

    return (
        <div>
            {!isAuthenticated ? 
            <div>
                <Grid container>
                    <Grid item xs/>
                    <Grid item xs={3} style={{textAlign:'center'}}>
                        <Box sx={{mt:2}}>
                            <Link to="/login"><Button style={buttonLogin} variant="contained">Ingresar</Button></Link>
                        </Box>
                    </Grid>
                </Grid>
                
            </div>
            :<div></div>
            }
            <Grid id={'content-welcome'} container style={{ paddingTop:0,/* paddingRight:10,paddingLeft:10, */paddingBottom:25 }}>
                <Grid item xs={12}>
                    {/* <Paper elevation={0} className={classes.banner0} style={{ border: '#197bce 1px solid' }}> */}
                        <div className="welcomMessageBox">
                            <p className='welcomMessageBoxTitle'>Bienvenido a Soprole</p>
                            <p className='welcomMessageBoxText'>Llevamos al mundo lo mejor de los campos lecheros de Chile.</p>
                        </div>
                    {/* </Paper> */}
                </Grid>
                <Grid spacing={2} xs={12} style={{margin: '10px 0px'}}>
                {
                    (typeElement==='sonrisaCircular') && <SonrisaCircularComponent classNameText={classNameText} />
                }
                {
                    (typeElement==='equipoAgricola') && <EquipoAgricolaComponent classNameText={classNameText} />
                }
                {
                    (typeElement==='programaSustentabilidad') && <ProgramaSustentabilidadComponent classNameText={classNameText} />
                }
                {
                    (typeElement==='pautasDePago') && <PautasDePagoComponent classNameText={classNameText} />
                }
                {
                    (typeElement==='actividades') && <ActividadesComponent classNameText={classNameText} />
                }
                {
                    (typeElement===null) && <></>
                }
                </Grid>
                {
                    posibleToSee
                    ?
                    <>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={6}>
                                    <div className='button-welcome' onClick={() => { selectButton('sonrisaCircular') }}>
                                        <div className={`${typeElement==='sonrisaCircular' ? 'button-welcome-container-selected' : 'button-welcome-container'} sonriza-circular`}>
                                            <div className='button-welcome-container-title'>
                                                <h2>Sonrisa circular</h2>
                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={6}>
                                    <div className='button-welcome' onClick={() => { selectButton('equipoAgricola') }}>
                                        <div className={`${typeElement==='equipoAgricola' ? 'button-welcome-container-selected' : 'button-welcome-container'} equipo-agricola-button`}>
                                            <div className='button-welcome-container-title'>
                                                <h2>Equipo agrícola</h2>
                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={6}>
                                    <div className='button-welcome' onClick={() => { selectButton('programaSustentabilidad') }}>
                                        <div className={`${typeElement==='programaSustentabilidad' ? 'button-welcome-container-selected' : 'button-welcome-container'} programa-sustentabilidad`}>
                                            <div className='button-welcome-container-title'>
                                                <h2>Programa de sustentabilidad</h2>
                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={6}>
                                    <div className='button-welcome' onClick={() => { selectButton('pautasDePago') }}>
                                        <div className='button-welcome-container pautas-pago'>
                                            <div className='button-welcome-container-title'>
                                                <h2>Pautas de pago</h2>
                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <div className='button-welcome' onClick={() => { selectButton('actividades') }}>
                                        <div className='button-welcome-container actividades'>
                                            <div className='button-welcome-container-title'>
                                                <h2>Actividades</h2>
                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                    :
                    <>
                    </>
                }
                {/* <IconButton onClick={toBottom} variant={'contained'} style={{ position: 'absolute', bottom: 30, right: 30, borderColor: '#00539B', borderStyle: 'solid', borderWidth: 2 }} >
                    <ArrowDropDown style={{ color: '#00539B' }} />
                </IconButton> */}
            </Grid>
        </div>
    )
}

export default Welcome
