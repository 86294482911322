/* React */
import React, { Fragment, useContext, /* useEffect, */ useState } from 'react'

/* Material UI */
import {
    Button,
    Divider,
    Grid,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    withStyles,
} from '@material-ui/core'
import { Clear, Public } from '@material-ui/icons'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'

/* Components */
import { ButtonVerTabla } from '../comun'

/* Context */
import { ForrajeContext } from '../../contexts/forraje'

/* Utilities */
import DateFnsUtils from '@date-io/date-fns'
import esLocale from 'date-fns/locale/es'
import 'date-fns'

const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' }

const useStyles = makeStyles(theme => ({
    paperGeneralCard: {
        borderRadius: 12,
        position: 'relative',
    },
    chart: {
        height: 300,
        margin: '10px 20px',
        marginLeft: 0,
        [theme.breakpoints.up('lg')]: {
            margin: '10px 20px',
        },
    },
    headerImage: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: 20,
        marginLeft: 20,
        marginRight: 20,
        [theme.breakpoints.up('lg')]: {
            marginLeft: 45,
        },
    },
    tableHeadCell: {
        fontWeight: 'bold',
        fontSize: 18,
        backgroundColor: '#e4efff',
        borderBottom: 'none',
        borderRight: 'solid 1px #dfdfdf',
        borderLeft: 'solid 1px #dfdfdf',
        padding: '12px 16px',
    },
    tableBodyCell: {
        borderBottom: 'none',
        fontSize: 18,
        borderRight: 'solid 1px #dfdfdf',
        borderLeft: 'solid 1px #dfdfdf',
        padding: '12px 16px',
    },
    table: {
        '&::-webkit-scrollbar': {
            width: 8,
            height: 12,
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: 10,
            backgroundColor: '#d8d8d8',
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: 'white',
            borderRadius: 10,
        },
    },
    buttonBorder: {
        borderLeft: 'solid 1px #dfdfdf',
        borderRight: 'none',
        borderRadius: 0,
    },
}))

const headCells = [
    { id: 'date', label: 'Fecha' },
    { id: 'weight', label: 'Cantidad*' },
]

const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: '#f9f9f9',
        },
    },
}))(TableRow)

const CartaForraje = ({ dryMatter, locacion, image, cantidad, lastUpdate, _id, editionPermission, publicCard }) => {
    const { finishDryMatterEdit } = useContext(ForrajeContext)
    const [showTable, setShowTable] = useState(false)
    const [editTable, setEditTable] = useState(false)
    const classes = useStyles()

    const [editDryMatter, setEditDryMatter] = useState(dryMatter)

    const initiateEditDryMatter = () => {
        setEditDryMatter(dryMatter || [])
    }

    const changeDryMatterEdit = (data, dryMatterId, type) => {
        const newDryMatter = [...editDryMatter]
        const indexChange = newDryMatter.findIndex(x => x._id === dryMatterId)
        if (indexChange > -1) {
            if (type === 'fecha') {
                newDryMatter[indexChange] = {
                    ...newDryMatter[indexChange],
                    fecha: data,
                }
            } else if (type === 'cantidad') {
                newDryMatter[indexChange] = {
                    ...newDryMatter[indexChange],
                    cantidad: data,
                }
            }
            setEditDryMatter(newDryMatter)
        }
    }

    const addDryMatterEdit = () => {
        const newDryMatter = [...editDryMatter]
        newDryMatter.push({
            fecha: new Date().toISOString(),
            cantidad: 0,
            _id: new Date().getTime().toString(),
        })
        setEditDryMatter(newDryMatter)
    }

    const deleteDryMatterEdit = dryMatterId => {
        const newDryMatter = [...editDryMatter]
        const findIndex = newDryMatter.findIndex(x => x._id === dryMatterId)
        if (findIndex > -1) {
            newDryMatter.splice(findIndex, 1)
            setEditDryMatter(newDryMatter)
        }
    }

    return (
        <Paper
            elevation={0}
            className={classes.paperGeneralCard}
            style={
                !showTable
                    ? {
                          background: image
                              ? `white url('data:image/${image?.name?.split('.').pop()})};base64,${image?.img}')`
                              : null,
                          backgroundPosition: 'center',
                          backgroundSize: 'cover',
                          overflow: 'hidden',
                      }
                    : { background: 'white' }
            }
        >
            {!showTable ? (
                <Grid container>
                    <Grid item xs={12}>
                        <div className={classes.headerImage}>
                            <div style={{ display: 'inline-block' }}>
                                <ButtonVerTabla
                                    variant="outlined"
                                    color="primary"
                                    size="large"
                                    text="Ver tabla"
                                    onClick={() => setShowTable(true)}
                                />
                            </div>
                        </div>
                    </Grid>
                    <Grid
                        container
                        item
                        xs={12}
                        style={{
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            marginBottom: 0,
                            marginTop: 78,
                            paddingLeft: 20,
                        }}
                    >
                        <Grid item xs={8}>
                            <span style={{ color: 'white', fontSize: 32, fontWeight: 800 }}>
                                {publicCard && <Public />}
                                {` ${locacion}`}
                            </span>
                            <div style={{ color: '#babbc8', fontSize: 16, fontWeight: 'bold' }}>
                                Última actualización:
                            </div>
                            <div style={{ color: 'white', fontSize: 16, paddingBottom: 10 }}>
                                {lastUpdate && new Date(lastUpdate).toLocaleString('es-CL', dateOptions)}
                            </div>
                        </Grid>
                        <Grid container item xs={4} justifyContent="flex-end" alignItems="center">
                            <span
                                style={{
                                    color: 'white',
                                    marginRight: 20,
                                    fontSize: 32,
                                    fontWeight: 'bold',
                                    textAlign: 'end' /*, whiteSpace: 'nowrap' */,
                                }}
                            >
                                {cantidad} kg
                            </span>
                        </Grid>
                    </Grid>
                </Grid>
            ) : (
                <Fragment>
                    <Grid container>
                        <Grid container item xs={12} justifyContent="center">
                            <span
                                style={{
                                    color: '#292929',
                                    fontSize: 32,
                                    fontWeight: 800,
                                    marginTop: 10,
                                }}
                            >
                                {publicCard && <Public />}
                                {` ${locacion}`}
                            </span>
                        </Grid>
                        <Grid container item xs={12} justifyContent="center">
                            <span
                                style={{
                                    color: '#8B8B8B',
                                    fontSize: 18,
                                    backgroundColor: '#FFECB7',
                                    borderRadius: 12,
                                    padding: '2px 8px',
                                    textAlign: 'center',
                                    margin: '0px 4px',
                                }}
                            >
                                (*) expresado en kilos de materia seca
                            </span>
                        </Grid>
                        <Grid item xs={12}>
                            <div style={{ marginTop: 18, maxHeight: 286, overflowY: 'auto' }} className={classes.table}>
                                {!editTable ? (
                                    <Table stickyHeader>
                                        <TableHead>
                                            <TableRow>
                                                {headCells.map(head => {
                                                    return (
                                                        <TableCell
                                                            className={classes.tableHeadCell}
                                                            key={head.id}
                                                            style={{ textAlign: 'center' }}
                                                        >
                                                            {head.label}
                                                        </TableCell>
                                                    )
                                                })}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {dryMatter.map((row, index) => {
                                                return (
                                                    <StyledTableRow key={index}>
                                                        <TableCell
                                                            className={classes.tableBodyCell}
                                                            style={{ textAlign: 'center' }}
                                                        >
                                                            {new Date(row.fecha).toLocaleDateString('es-CL')}
                                                        </TableCell>
                                                        <TableCell
                                                            className={classes.tableBodyCell}
                                                            style={{ textAlign: 'center' }}
                                                        >
                                                            {row.cantidad}
                                                        </TableCell>
                                                    </StyledTableRow>
                                                )
                                            })}
                                        </TableBody>
                                    </Table>
                                ) : (
                                    <Table stickyHeader style={{ height: '100%' }}>
                                        <TableHead>
                                            <TableRow>
                                                {headCells.map(head => {
                                                    return (
                                                        <TableCell
                                                            className={classes.tableHeadCell}
                                                            key={head.id}
                                                            style={{ textAlign: 'center' }}
                                                        >
                                                            {head.label}
                                                        </TableCell>
                                                    )
                                                })}
                                                <TableCell
                                                    className={classes.tableHeadCell}
                                                    style={{ textAlign: 'center' }}
                                                ></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {editDryMatter.map((row, index) => {
                                                return (
                                                    <StyledTableRow key={index}>
                                                        <TableCell
                                                            className={classes.tableBodyCell}
                                                            style={{ textAlign: 'center', padding: 2 }}
                                                        >
                                                            <MuiPickersUtilsProvider
                                                                utils={DateFnsUtils}
                                                                locale={esLocale}
                                                            >
                                                                <KeyboardDatePicker
                                                                    variant="inline"
                                                                    inputVariant="outlined"
                                                                    format="dd/MM/yyyy"
                                                                    value={row.fecha}
                                                                    onChange={data =>
                                                                        changeDryMatterEdit(data, row._id, 'fecha')
                                                                    }
                                                                    fullWidth
                                                                    disableFuture
                                                                    autoOk
                                                                />
                                                            </MuiPickersUtilsProvider>
                                                        </TableCell>
                                                        <TableCell
                                                            className={classes.tableBodyCell}
                                                            style={{
                                                                textAlign: 'center',
                                                                paddingBottom: 0,
                                                                paddingTop: 0,
                                                            }}
                                                        >
                                                            <TextField
                                                                value={row.cantidad}
                                                                onChange={data =>
                                                                    changeDryMatterEdit(
                                                                        data.target.value,
                                                                        row._id,
                                                                        'cantidad'
                                                                    )
                                                                }
                                                                inputProps={{
                                                                    type: 'number',
                                                                    step: 'any',
                                                                    min: '0',
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell
                                                            className={classes.tableBodyCell}
                                                            style={{ textAlign: 'center', padding: 0 }}
                                                        >
                                                            <Button
                                                                style={{
                                                                    height: '100%',
                                                                    width: '100%',
                                                                    padding: 0,
                                                                    minWidth: 60,
                                                                }}
                                                                onClick={() => deleteDryMatterEdit(row._id)}
                                                            >
                                                                <Clear />
                                                            </Button>
                                                        </TableCell>
                                                    </StyledTableRow>
                                                )
                                            })}
                                        </TableBody>
                                    </Table>
                                )}
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        {editTable && (
                            <Fragment>
                                <Grid item xs={12}>
                                    <Button
                                        color="primary"
                                        size="large"
                                        style={{ width: '100%', padding: 12 }}
                                        onClick={() => {
                                            addDryMatterEdit()
                                        }}
                                        className={classes.buttonBorder}
                                    >
                                        Agregar Línea
                                    </Button>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                            </Fragment>
                        )}
                        <Grid item xs={editionPermission ? 6 : 12}>
                            {editTable ? (
                                <Button
                                    color="primary"
                                    size="large"
                                    style={{ width: '100%', padding: 12 }}
                                    onClick={() => {
                                        setEditTable(false)
                                    }}
                                >
                                    Cancelar
                                </Button>
                            ) : (
                                <Button
                                    color="primary"
                                    size="large"
                                    style={{ width: '100%', padding: 12 }}
                                    onClick={() => {
                                        setShowTable(false)
                                        setEditTable(false)
                                    }}
                                >
                                    Volver
                                </Button>
                            )}
                        </Grid>
                        {editionPermission && (
                            <Grid item xs={6}>
                                {editTable ? (
                                    <Button
                                        color="primary"
                                        size="large"
                                        style={{ width: '100%', padding: 12 }}
                                        onClick={() => {
                                            finishDryMatterEdit(_id, editDryMatter)
                                            setEditTable(false)
                                        }}
                                        className={classes.buttonBorder}
                                    >
                                        Aceptar
                                    </Button>
                                ) : (
                                    <Button
                                        color="primary"
                                        size="large"
                                        style={{ width: '100%', padding: 12 }}
                                        onClick={() => {
                                            initiateEditDryMatter()
                                            setEditTable(true)
                                        }}
                                        className={classes.buttonBorder}
                                    >
                                        Editar
                                    </Button>
                                )}
                            </Grid>
                        )}
                    </Grid>
                </Fragment>
            )}
        </Paper>
    )
}

export default CartaForraje
