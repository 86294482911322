/* React */
import React from 'react'

/* Material UI */
import { FormControl, Grid, makeStyles } from '@material-ui/core'
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'

/* Utilities */
import DateFnsUtils from '@date-io/date-fns'
import esLocale from 'date-fns/locale/es'
import 'date-fns'

/* Components */
import { PredioFilterOnlyOne } from './'

const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1, 'auto'),
        '& .MuiInputBase-input': {
            background: 'white',
            fontSize: 16,
            fontWeight: 600,
            borderRadius: 12,
        },
        '& .MuiInputBase-root': {
            background: 'white',
            fontSize: 16,
            fontWeight: 600,
            borderRadius: 12,
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: 12,
        },
        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            borderWidth: 0,
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(14px, -14px) scale(0.75)',
        },
    },
}))

const ResumenPreciosFilter = ({ startDate, endDate, setStartDate, setEndDate, selectedPredio, setSelectedPredio }) => {
    const classes = useStyles()

    return (
        <Grid container spacing={2} style={{ marginTop: 8 }}>
            <Grid item xs={6} sm={3}>
                <FormControl fullWidth variant="outlined" className={classes.formControl}>
                    <PredioFilterOnlyOne selected={selectedPredio} setSelected={setSelectedPredio} />
                </FormControl>
            </Grid>
            <Grid item xs={6} sm={3}>
                <FormControl fullWidth variant="outlined" className={classes.formControl}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                        <DatePicker
                            views={['year', 'month']}
                            variant="inline"
                            inputVariant="outlined"
                            // format="MM/yyyy"
                            label="Inicio"
                            value={startDate}
                            onChange={setStartDate}
                            fullWidth
                            disableFuture
                            autoOk
                        />
                    </MuiPickersUtilsProvider>
                </FormControl>
            </Grid>
            <Grid item xs={6} sm={3}>
                <FormControl fullWidth variant="outlined" className={classes.formControl}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                        <DatePicker
                            views={['year', 'month']}
                            variant="inline"
                            inputVariant="outlined"
                            // format="MM/yyyy"
                            label="Término"
                            value={endDate}
                            onChange={setEndDate}
                            fullWidth
                            disableFuture
                            autoOk
                        />
                    </MuiPickersUtilsProvider>
                </FormControl>
            </Grid>
        </Grid>
    )
}

export default ResumenPreciosFilter
