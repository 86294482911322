import React, {useState, useEffect} from "react"
import { Grid } from "@material-ui/core"

const SonrisaCircularComponent = ({classNameText}) => {
    const [openData, setOpenData] = useState(false)
    useEffect(() => {
        setTimeout(() => {
            setOpenData(true)
        }, 1000);
    }, [])
    const toPage = () => {
        window.open('https://www.soprole.cl/nutriendo-sonrisas/ssc/en-que-consiste', '_blank')
    }
    return (
        <Grid container>
            <Grid item xs={12}>
                <div className='welcome-text'>
                    <div className='welcome-text-title'>
                        <p>Sonrisa Circular</p>
                    </div>
                    <div className={classNameText}>
                        {
                            openData && 
                            <>
                                <Grid container>
                                    <Grid item xs={10}>
                                        <div className="container-text">
                                            <h4>Nuestro Compromiso</h4>
                                            <p>
                                            Por más de 70 años hemos estado preocupados por la alimentación y la salud de las personas. Tenemos una responsabilidad social ineludible: entregar sonrisas con productos sanos, ricos y nutritivos a miles de familias de todo el país.  Pero también tenemos un compromiso con la sostenibilidad donde hemos tenido una gran preocupación por buscar diferentes soluciones para reciclar nuestros envases. Y hoy, luego de generar alianzas con distintos expertos para articular y avanzar en una propuesta real y sostenible, nos alegra contarles sobre nuestro programa: Soprole Sonrisa Circular, el cual consta de 3 pilares que buscan entregar una solución integral para contribuir a una problemática ambiental urgente. <br />Por eso, hoy queremos compartirlo contigo. Porque solo juntos le entregaremos un mejor planeta a nuestros niños. Súmate!
                                            </p>
                                        </div>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <div className="button-container">
                                            <button className="button-element" onClick={toPage}> <strong>Ver más</strong> </button>
                                        </div>
                                    </Grid>
                                </Grid>
                                <div style={{ width: '100%', textAlign: 'center' }}>
                                    <h2 style={{ color: '#00539B' }}>Nuestros 3 pilares</h2>
                                </div>
                                <Grid container style={{ padding: '10px 30px' }}>
                                    <Grid item xs={4} spacing={2} style={{ padding: 30 }}>
                                        <div className="item-content">
                                            <div className="item-content-up">
                                                <img src="./landing/soprole-productos-1.png" width={'100%'} alt="" />
                                            </div>
                                            <div className="item-divider" />
                                            <div className="item-content-down">
                                                <p className="item-title">
                                                    <strong>Alternativas de reciclaje</strong>
                                                </p>
                                                <p className="item-text-content">
                                                Además de fomentar el reciclaje, queremos darle una segunda vida a todo el material que recolectemos. Nuestra meta es transformarlo y valorizarlo para poder impulsar un ciclo completo de economía circular.
                                                <br />
                                                Con los envases de yoghurt y postres vamos a hacer ecomodaderas.
                                                </p>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={4} spacing={2} style={{ padding: 30 }}>
                                        <div className="item-content">
                                            <div className="item-content-up">
                                                <img src="./landing/soprole-productos-2.png" width={'100%'} alt="" />
                                            </div>
                                            <div className="item-divider" />
                                            <div className="item-content-down">
                                                <p className="item-title">
                                                    <strong>Mas envases reciclables</strong>
                                                </p>
                                                <p className="item-text-content">
                                                En el 2022 llegaremos al 91% del volumen de nuestro material de envases reciclado. Para esto, implementamos por primera vez puntos limpios para reunir nuestros envases de yoghurt y postres y posibilitar su revalorización.
                                                <br />
                                                Según el Informe del Estado del Medio Ambiente 2020, del Ministerio del Medio Ambiente, en Chile se generan 8,1 millones de toneladas de residuos domiciliarios al año. De este total, apenas el 1% se recicla. Esta situación nos motivó a ir más allá y aportar con una solución concreta y con un impacto en el corto y largo plazo en torno al reciclaje y cuidado del medio ambiente.
                                                </p>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={4} spacing={2} style={{ padding: 30 }}>
                                        <div className="item-content">
                                            <div className="item-content-up">
                                                <img src="./landing/soprole-productos-3.png" width={'100%'} alt="" />
                                            </div>
                                            <div className="item-divider" />
                                            <div className="item-content-down">
                                                <p className="item-title">
                                                    <strong>Compromiso circular</strong>
                                                </p>
                                                <p className="item-text-content">
                                                    Somos pioneros en la industria láctea, reciclando nuestros envases de yoghurt y postres para producir nuestras propias bandejas plásticas con las que despachamos nuestros productos a lo largo del país.
                                                </p>
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </>
                        }
                    </div>
                </div>
            </Grid>
        </Grid>
    )
}

export default SonrisaCircularComponent
