/* React */
import React, { Fragment, useContext } from 'react'

/* Material UI */
import { CircularProgress, Grid, makeStyles, Paper } from '@material-ui/core'

/* Context */
import { FilterContext } from '../../../contexts/produccion'

import ShowMoreText from 'react-show-more-text'

const useStyles = makeStyles(theme => ({
    paperGeneralCard: { borderRadius: 12 },
}))

const ProductoresList = () => {
    const classes = useStyles()
    const { predioFilter } = useContext(FilterContext)

    return predioFilter.length > 0 ? (
        <Paper elevation={0} className={classes.paperGeneralCard} style={{ marginTop: 8, padding: 18 }}>
            <Fragment>
                <Grid container>
                    <Grid container item xs={12} justifyContent="center">
                        <div
                            style={{
                                color: '#292929',
                                fontSize: 24,
                                fontWeight: 800,
                                marginBottom: 18,
                            }}
                        >
                            Productores seleccionados:
                        </div>
                    </Grid>
                    <Grid container item xs={12} justifyContent="center">
                        <ShowMoreText more="mostrar más" less="mostrar menos">
                            {[...new Set(predioFilter)].sort((a, b) => (a > b ? 1 : -1)).join(', ')}
                        </ShowMoreText>
                    </Grid>
                </Grid>
            </Fragment>
        </Paper>
    ) : (
        <Paper elevation={0} className={classes.paperGeneralCard} style={{ marginTop: 8, padding: 18 }}>
            <Fragment>
                <Grid container>
                    <Grid container item xs={12} justifyContent="center">
                        <div
                            style={{
                                color: '#292929',
                                fontSize: 24,
                                fontWeight: 800,
                                marginBottom: 18,
                            }}
                        >
                            Cargando productores...
                        </div>
                        <br />
                        <CircularProgress />
                    </Grid>
                </Grid>
            </Fragment>
        </Paper>
    )
}

export default ProductoresList
