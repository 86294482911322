/* React */
import React, { useContext, useEffect, useState } from 'react'

/* Material UI */
import {
    Button,
    Divider,
    Grid,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    withStyles,
} from '@material-ui/core'
import { CloudDownload } from '@material-ui/icons'

/* Snackbar */
import { useSnackbar } from 'notistack'

/* Utilities */
import fileDownload from 'js-file-download'
import axios from 'axios'
import { FilterContext } from '../../contexts/produccion'

const useStyles = makeStyles(theme => ({
    paperGeneralCard: {
        // margin: theme.spacing(2, 'auto'),
        // padding: theme.spacing(2),
        borderRadius: 12,
    },
    chart: {
        height: 300,
        margin: '10px 20px',
        marginLeft: 0,
        [theme.breakpoints.up('lg')]: {
            margin: '10px 20px',
        },
    },
    headerImage: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: 20,
        marginLeft: 20,
        marginRight: 20,
        [theme.breakpoints.up('lg')]: {
            marginLeft: 45,
        },
    },
    tableHeadCell: {
        fontWeight: 'bold',
        fontSize: 18,
        backgroundColor: '#e4efff',
        borderBottom: 'none',
        borderRight: 'solid 1px #dfdfdf',
        borderLeft: 'solid 1px #dfdfdf',
        padding: '12px 16px',
    },
    tableBodyCell: {
        borderBottom: 'none',
        fontSize: 18,
        borderRight: 'solid 1px #dfdfdf',
        borderLeft: 'solid 1px #dfdfdf',
        padding: '12px 16px',
    },
    table: {
        '&::-webkit-scrollbar': {
            width: 8,
            height: 12,
            // background-color: #f5f5f5;
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: 10,
            backgroundColor: '#d8d8d8',
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: 'white',
            borderRadius: 10,
        },
    },
    getCertButton: {
        borderRadius: 12,
        width: '100%',
        textTransform: 'none',
        lineHeight: 1,
        padding: 8,
    },
}))

const DownloadCertificate = async (salaId, certificateType) => {
    const response = await axios.get(`/ftp/getcertificate/${salaId}/${certificateType}`, {
        responseType: 'blob',
    })
    return response
}

const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: '#f9f9f9',
        },
    },
}))(TableRow)

const headCells = [
    { id: 'producerCode', label: 'Productor' },
    { id: 'endDate', label: 'Fecha caducidad' },
    { id: 'state', label: 'Estado' },
]

const CartaSituacionSanitaria = ({ documentType, abbreviation, data }) => {
    const classes = useStyles()
    const [open, setOpen] = useState(false)
    const [endDate, setEndDate] = useState(null)
    const [state, setState] = useState(null)
    const [showData, setShowData] = useState([])
    const { predioFilter } = useContext(FilterContext)
    const { enqueueSnackbar } = useSnackbar()

    useEffect(() => {
        if (data?.length >= 0) {
            const date = new Date()
            const actualDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
            const data_ = data
                .sort((a, b) => (a.endDate < b.endDate ? -1 : 1))
                .filter(cert => predioFilter.includes(cert.producerCode))
                .map(item => {
                    const split = item.endDate.split('T')[0].split('-')
                    const endDateParsed = new Date(
                        Date.UTC(parseInt(split[0]), parseInt(split[1]) - 1, parseInt(split[2]))
                    )
                    const daysDiff = Math.ceil((endDateParsed - actualDate) / (1000 * 60 * 60 * 24))
                    const newItem = { ...item }
                    newItem.state = daysDiff > 60 ? 'Vigente' : daysDiff > 0 ? 'Próximo a vencer' : 'Vencido'

                    return newItem
                })
            setState(data_[0]?.state)
            setShowData(data_)
            setEndDate(data_[0]?.endDate)
        }
    }, [data, predioFilter])

    const handleDownloadCertificate = (salaId, certificateType, producerCode) => {
        DownloadCertificate(salaId, certificateType)
            .then(response => {
                if (response.data.type === 'application/json') {
                    const reader = new FileReader()
                    reader.onload = e => {
                        if (e.target.readyState === 2) {
                            const message = JSON.parse(e.target.result)
                            return enqueueSnackbar(message.error, { variant: 'error' })
                        }
                    }
                    reader.readAsText(response.data)
                } else {
                    fileDownload(response.data, `${producerCode}_${certificateType}.pdf`)
                }
            })
            .catch(err => {
                console.log(err)
                enqueueSnackbar('Error en la red.', { variant: 'error' })
            })
    }

    return (
        <Paper elevation={0} className={classes.paperGeneralCard}>
            <Grid container style={{ padding: '24px 32px 0px 32px' }}>
                <Grid item xs={12}>
                    <span
                        style={{
                            color: '#404040',
                            fontSize: 24,
                            fontWeight: 'normal',
                            lineHeight: 1.25,
                        }}
                    >
                        Reporte
                    </span>
                </Grid>
                <Grid item xs={12} style={{ marginBottom: 10 }}>
                    <span
                        style={{
                            color: '#292929',
                            fontSize: 24,
                            fontWeight: 800,
                            marginBottom: 10,
                            lineHeight: 1.25,
                        }}
                    >
                        {documentType}
                    </span>
                </Grid>
                {showData?.length > 0 ? (
                    <Grid container item spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <span
                                style={{
                                    color: '#303030',
                                    fontSize: 14,
                                    fontWeight: 'normal',
                                    marginTop: 20,
                                }}
                            >
                                Estado
                            </span>
                            <div
                                style={{
                                    background:
                                        state === 'Vigente'
                                            ? '#7cd452'
                                            : state === 'Próximo a vencer'
                                            ? '#fcb510'
                                            : state === 'Vencido'
                                            ? 'red'
                                            : 'white',
                                    borderRadius: 12,
                                    height: 32,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginBottom: 10,
                                    lineHeight: 0.8,
                                }}
                            >
                                <span
                                    style={{
                                        color: '#fff',
                                        fontSize: 16,
                                        fontWeight: 'bold',
                                        textAlign: 'center',
                                    }}
                                >
                                    {state}
                                </span>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <span
                                style={{
                                    color: '#303030',
                                    fontSize: 14,
                                    fontWeight: 'normal',
                                    marginTop: 20,
                                }}
                            >
                                Fecha caducidad
                            </span>
                            <div
                                style={{
                                    background: '#f5f7f9',
                                    borderRadius: 12,
                                    height: 32,
                                    textAlign: 'center',
                                    verticalAlign: 'center',
                                    padding: 5,
                                }}
                            >
                                <span style={{ color: '#000', fontSize: 16, fontWeight: 'bold' }}>
                                    {endDate ? new Date(endDate).toLocaleDateString('es-ES') : ''}
                                </span>
                            </div>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container item spacing={2}>
                        <Grid item xs={12} style={{ marginBottom: 18 }}>
                            <span style={{ color: '#303030', fontSize: 14, fontWeight: 'normal' }}>
                                Información de certificado sanitario <b>{documentType}</b> no disponible para
                                productores seleccionados.
                            </span>
                        </Grid>
                    </Grid>
                )}
            </Grid>
            {open && showData?.length > 0 && (
                <Grid item xs={12}>
                    <div style={{ marginTop: 18, maxHeight: 286, overflowY: 'auto' }} className={classes.table}>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    {headCells.map(head => {
                                        return (
                                            <TableCell
                                                className={classes.tableHeadCell}
                                                key={head.id}
                                                style={{ textAlign: 'center' }}
                                            >
                                                {head.label}
                                            </TableCell>
                                        )
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {showData?.map((row, index) => {
                                    return (
                                        <StyledTableRow key={index}>
                                            <TableCell
                                                className={classes.tableBodyCell}
                                                style={{ textAlign: 'center' }}
                                            >
                                                {row.producerCode}
                                            </TableCell>
                                            <TableCell
                                                className={classes.tableBodyCell}
                                                style={{ textAlign: 'center' }}
                                            >
                                                {row.endDate ? new Date(row.endDate).toLocaleDateString('es-ES') : ''}
                                            </TableCell>
                                            <TableCell
                                                className={classes.tableBodyCell}
                                                style={{ textAlign: 'center' }}
                                            >
                                                <Button
                                                    onClick={() =>
                                                        handleDownloadCertificate(
                                                            row.salaId,
                                                            abbreviation,
                                                            row.producerCode
                                                        )
                                                    }
                                                    className={classes.getCertButton}
                                                    style={{
                                                        background:
                                                            row.state === 'Vigente'
                                                                ? '#7cd452'
                                                                : row.state === 'Próximo a vencer'
                                                                ? '#fcb510'
                                                                : row.state === 'Vencido'
                                                                ? 'red'
                                                                : 'white',
                                                    }}
                                                    startIcon={<CloudDownload style={{ color: 'white' }} />}
                                                >
                                                    <span
                                                        style={{
                                                            color: '#fff',
                                                            fontSize: 16,
                                                            fontWeight: 'bold',
                                                            textAlign: 'center',
                                                        }}
                                                    >
                                                        {row.state}
                                                    </span>
                                                </Button>
                                            </TableCell>
                                        </StyledTableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </div>
                </Grid>
            )}
            {showData?.length > 0 && (
                <div className={classes.tableButtonMobile}>
                    <Divider />
                    <Grid container item xs={12}>
                        <Button
                            color="primary"
                            size="large"
                            style={{ width: '100%', padding: 12 }}
                            onClick={() => setOpen(!open)}
                        >
                            {open ? 'Ver menos' : 'Ver más'}
                        </Button>
                    </Grid>
                </div>
            )}
        </Paper>
    )
}

export default CartaSituacionSanitaria
