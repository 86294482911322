/* Material UI */
import { Button, withStyles } from '@material-ui/core'

const PrimaryButton = withStyles(theme => ({
    root: {
        color: 'white',
        borderRadius: 12,
        padding: '15px 18px 15px 18px',
        width: 220,
        height: 50,
        fontSize: 20,
        fontWeight: 'bold',
        lineHeight: 1,
        textTransform: 'none',
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: '#75a6e8',
        },
    },
}))(Button)

export default PrimaryButton
