/* React */
import React from 'react'
import { render } from 'react-dom'

/* Styles */
import './index.css'

/* MainApp */
import App from './App'

/* ServiceWorker */
import * as serviceWorkerRegistration from './serviceWorkerRegistration'

render(
        <App />,
    document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
    onUpdate: registration => {
        const waitingServiceWorker = registration.waiting

        if (waitingServiceWorker) {
            waitingServiceWorker.addEventListener('statechange', event => {
                if (event.target.state === 'activated') {
                    window.location.reload()
                }
            })
            waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' })
        }
    },
})
