import React, { useEffect, useState } from 'react'

const UsuarioData = () => {
    const [sociedades, setSociedades] = useState()

    useEffect(() => {
        init()
    }, [])

    useEffect(() => {
        console.log(sociedades)
    }, [sociedades])

    const init = () => {
        console.log('Obteniendo sociedades')
        getSociedades()
        .then(response => {
            return response.json()
        })
        .then(response => {
            setSociedades(response)
        })
    }

    const getSociedades = () => {
        return fetch('/development/getSociedades', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include'
        })
    }
    
    return (
        <div style={{ margin: 50 }}>
            <h2>Hello!!</h2>
        </div>
    )
}

export default UsuarioData
