/* React */
import React, { useContext, useEffect, useState } from 'react'

/* Material UI */
import { Button, CircularProgress, Divider, Grid, makeStyles, Paper } from '@material-ui/core'

/* Context */
import { AdminFilterContext } from '../../contexts/administracion'
import { RecoleccionContext } from '../../contexts/recoleccion'
import { FilterContext } from '../../contexts/produccion'

/* Components */
import { AdminFilter } from '../../components/administracion'
import { CartaRecoleccion, Filter } from '../../components/recoleccion'
import { TablaAdministracion } from '../../components/tabla'
import { ProductoresList } from '../../components/produccion/filter'

const useStyles = makeStyles(theme => ({
    paperGeneralCard: {
        borderRadius: 12,
        '& .MuiDataGrid-root': {
            border: 0,
            // borderRadius: 12
        },
        marginTop: 16,
        marginBottom: 16,
    },
    gridMobile: {
        display: 'block',
        [theme.breakpoints.up('lg')]: {
            display: 'none',
        },
    },
    footerButtonCenter: {
        borderLeft: 'solid 1px #dfdfdf',
        borderRight: 'none',
        borderRadius: 0,
        [theme.breakpoints.up('lg')]: {
            borderRight: 'solid 1px #dfdfdf',
        },
    },
}))

const RecoleccionManagment = props => {
    const {
        columns,
        recoleccion,
        mobileSelection,
        cardFormat,
        queryServer,
        loadingOld,
        loadingNew,
        loadingCSV,
        downloadCSV,
    } = useContext(RecoleccionContext)
    const { startTimeFilter, endTimeFilter } = useContext(FilterContext)
    const classes = useStyles()
    const { search } = useContext(AdminFilterContext)
    const [recoleccion_, setRecoleccion_] = useState()

    useEffect(() => {
        queryServer(startTimeFilter, endTimeFilter)
    }, [startTimeFilter, endTimeFilter])

    useEffect(() => {
        const rec = []
        recoleccion.map((e, i) =>
            e.data.map((e2, i2) =>
                rec.push({
                    // id: e.id,
                    FECHA_AV: e2.FECHA_AV,
                    QMEL_LIFNR: e.QMEL_LIFNR,
                    N_GUIA: e2.N_GUIA,
                    CAMION: e2.CAMION,
                    CANTIDAD: e2.CANTIDAD,
                })
            )
        )
        setRecoleccion_(rec)
    }, [recoleccion])

    const filterItems = query => {
        if (query.trim() === '') return recoleccion_
        else
            return recoleccion_.filter(el => {
                const keys = Object.keys(el)
                let text = ''
                keys.forEach((key, index) => {
                    if (key !== 'id' && key !== 'estatus' && key !== 'rol') text += el[key]
                })
                // console.log(text)
                return text.toLowerCase().indexOf(query.toLowerCase()) > -1
            })
    }

    return (
        <>
            <Filter />
            <ProductoresList />
            {loadingNew || loadingOld ? (
                <Grid
                    container
                    item
                    xs={12}
                    justifyContent="center"
                    alignItems="center"
                    style={{ paddingTop: 100, paddingBottom: 100 }}
                >
                    <Grid item>
                        <CircularProgress size={100} />
                    </Grid>
                </Grid>
            ) : cardFormat ? (
                <Grid container spacing={2} style={{ marginTop: 8 }}>
                    {recoleccion?.map((e, i) => {
                        return (
                            <Grid item xs={12} sm={6} lg={6} xl={4} key={i}>
                                <CartaRecoleccion bg={i % 2 === 0 ? '#3383EE' : '#5D9CF1'} data={e} />
                            </Grid>
                        )
                    })}
                </Grid>
            ) : (
                <Paper elevation={0} className={classes.paperGeneralCard}>
                    <AdminFilter isOnlySearch />
                    <TablaAdministracion
                        data={filterItems(search)}
                        headCells={columns}
                        sortDefault={'FECHA_AV'}
                        defaultCell={'Cantidad (L)'}
                        orderDefault={'desc'}
                        fixedCell={'FECHA_AV'}
                        mobileSelection={mobileSelection}
                    />
                    <Grid container item xs={12}>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={6} lg={4}>
                            <Button
                                color="primary"
                                size="large"
                                style={{
                                    width: '100%',
                                    padding: 12,
                                    borderRadius: 0,
                                    fontWeight: 'bold',
                                }}
                                /* onClick={() => setShowGraph(2)} */
                                disabled={true}
                            >
                                {/* Descargar PDF */}
                            </Button>
                        </Grid>
                        <Grid item xs={6} lg={4}>
                            <Button
                                color="primary"
                                size="large"
                                style={{ width: '100%', padding: 12, fontWeight: 'bold' }}
                                className={classes.footerButtonCenter}
                                onClick={downloadCSV}
                                disabled={loadingCSV}
                                startIcon={loadingCSV && <CircularProgress size={20} />}
                            >
                                Descargar Excel
                            </Button>
                        </Grid>
                        <Grid item xs={12} className={classes.gridMobile}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <Button
                                color="primary"
                                size="large"
                                style={{
                                    width: '100%',
                                    padding: 12,
                                    borderRadius: 0,
                                    fontWeight: 'bold',
                                }}
                                /* onClick={() => setShowGraph(2)} */
                                disabled={true}
                            >
                                {/* Imprimir */}
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            )}
        </>
    )
}

export default RecoleccionManagment
