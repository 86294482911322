import { Grid } from "@material-ui/core"
import React, {useState, useEffect} from "react"
const ProgramaSustentabilidadComponent = ({classNameText}) => {
    const [openData, setOpenData] = useState(false)
    useEffect(() => {
        setTimeout(() => {
            setOpenData(true)
        }, 1000);
    }, [])
    return (
        <Grid container>
            <Grid item xs={12}>
                <div className='welcome-text'>
                    <div className='welcome-text-title'>
                        <p>Programa de sustentabilidad</p>
                    </div>
                    <div className={classNameText}>
                        {openData && 
                        <Grid container  /* style={{ position: 'relative' }} */>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <img src="./landing/Soprole-lecheria-sustentable.png" width={'100%'} alt="grafico-lecheria" />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div className="programa-sustentabilidad-container">
                                            <p className="programa-sustentabilidad-text">
                                                Prolesur tiene un compromiso permanente con el desarrollo del sector lácteo en el
                                                sur de Chile y promueve prácticas de producción sustentables, ofreciendo cursos
                                                de capacitación y transferencia de tecnologías a través de sus campos
                                                demostrativos. Además cumple un rol social relevante en las comunidades en las
                                                que se inserta.
                                            </p>
                                        </div>
                                        <Grid container>
                                            <Grid item xs={2}>

                                            </Grid>
                                            <Grid item xs={8}>
                                                <div className="programa-sustentabilidad-mensaje">
                                                    <p>
                                                        <strong>68%</strong> de Nuestra leche se
                                                        encuentra certificada bajo el
                                                        Estándar de Sustentabilidad.
                                                    </p>
                                                </div>
                                            </Grid>
                                            <Grid item xs={2}>

                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <br />
                                        {/* <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br /> */}
                                        <img width={'100%'} src="./images-landing/pasos-para-certificacion.png" />
                                        {/* <div className="programa-sustentabilidad-images">
                                            <p>
                                                <img src="./landing/soprole-origen-consciente.png" height={50} alt="soprole-origen-consciente" />
                                                <img src="./landing/Soprole-consorcio-lechero.png" height={50} alt="soprole-consorcio-lechero" />
                                            </p>
                                        </div> */}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>}
                    </div>
                </div>
            </Grid>
        </Grid>
    )
}

export default ProgramaSustentabilidadComponent
