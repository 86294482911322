/* React */
import React, { Fragment, useEffect, useState } from 'react'

/* Material UI */
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    makeStyles,
    TableSortLabel,
    withWidth,
    Grid,
    FormControl,
    MenuItem,
    Select,
    InputLabel,
} from '@material-ui/core'
import { Pagination } from '@material-ui/lab'

/* Components */
import { getComparator, stableSort } from './'

/* Utilities */
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
    tabla: {
        overflowX: 'auto',
        marginLeft: 10,
        marginRight: 10,
        height: 'calc(100vh - 350px)'
    },
    headCell: {
        fontWeight: 'bold',
    },
    centered: {
        textAlign: 'center',
    },
    pagination: {
        padding: 15,
    },
    selectContainer: {
        padding: 15,
        width: '100%',
        display: 'flex',
    },
    formControlSelect: {
        margin: theme.spacing(1, 'auto'),
        maxWidth: 300,
        width: '100%',
        textAlign: 'start',
        '& .MuiInputBase-input': {
            // background: 'white',
            // fontSize: 14,
            // fontWeight: 800,
            // borderRadius: 12
        },
        '& .MuiOutlinedInput-root': {
            // borderRadius: 12
        },
        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            // borderWidth: 0
        },
    },
}))

const rowsPerPage = 10

const TablaAdministracion = ({
    data,
    headCells,
    sortDefault,
    orderDefault,
    width,
    fixedCell,
    defaultCell,
    mobileSelection,
}) => {
    const classes = useStyles()

    const [order, setOrder] = useState(orderDefault || 'asc')
    const [orderBy, setOrderBy] = useState(sortDefault)
    const [page, setPage] = useState(1)
    const [toShow, setToShow] = useState(defaultCell)
    const [displayCells, setDisplayCells] = useState([])

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
    }

    const handleChangeSelect = event => {
        setToShow(event.target.value)
    }

    const createSortHandler = property => event => {
        handleRequestSort(event, property)
    }

    useEffect(() => {
        const fields = mobileSelection.find(e => e.name === toShow)
        if (!fields) {
            return null
        }
        const fixedHeadCell = headCells.find(e => e.field === fixedCell) || []
        const selectedHeadCells = headCells.filter(a => fields.cellFields.includes(a.field)) || []
        const _displayCells = (fixedHeadCell ? [fixedHeadCell] : []).concat(selectedHeadCells)
        setDisplayCells(_displayCells)
    }, [toShow])

    return (
        <Fragment>
            {['md', 'lg', 'xl'].includes(width) ? (
                <Fragment>
                    <div className={classes.tabla}>
                        <Table aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    {headCells.map((headCell, index) => {
                                        return (
                                            <TableCell
                                                key={index}
                                                align={'left'}
                                                // padding={headCell.disablePadding ? 'none' : 'normal'}
                                                sortDirection={orderBy === headCell.field ? order : false}
                                                className={clsx(classes.headCell, headCell.center && classes.centered)}
                                            >
                                                {headCell.sortable ? (
                                                    <TableSortLabel
                                                        active={orderBy === headCell.field}
                                                        direction={orderBy === headCell.field ? order : 'asc'}
                                                        onClick={createSortHandler(headCell.field)}
                                                    >
                                                        {headCell.headerName}
                                                    </TableSortLabel>
                                                ) : (
                                                    <span>{headCell.headerName}</span>
                                                )}
                                            </TableCell>
                                        )
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {stableSort(data, getComparator(order, orderBy))
                                    .slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage)
                                    .map((row, index) => {
                                        const labelId = `enhanced-table-checkbox-${index}`

                                        return (
                                            <TableRow hover tabIndex={-1} key={index}>
                                                {headCells.map((headCell, index) => {
                                                    const data =
                                                        headCell.field === 'FECHA_AV'
                                                            ? new Date(row[headCell.field]).toLocaleDateString('ES')
                                                            : row[headCell.field]
                                                    return (
                                                        <TableCell
                                                            key={index}
                                                            id={labelId}
                                                            scope="row"
                                                            padding={headCell.disablePadding ? 'none' : 'normal'}
                                                            className={headCell.center && classes.centered}
                                                        >
                                                            {data}
                                                        </TableCell>
                                                    )
                                                })}
                                                {/* <TableCell id={labelId} scope='row' className={classes.cellPadding}>
                                                            {row.apellidos}
                                                        </TableCell>
                                                        <TableCell id={labelId} scope='row' className={classes.cellPadding}>
                                                            {row.nombres}
                                                        </TableCell>
                                                        <TableCell id={labelId} scope='row' className={classes.cellPadding}>
                                                            {row.rut}
                                                        </TableCell>
                                                        <TableCell id={labelId} scope='row' className={classes.cellPadding}>
                                                            {row.cargo}
                                                        </TableCell>
                                                        <TableCell id={labelId} scope='row' className={classes.cellPadding}>
                                                            {row.email}
                                                        </TableCell>
                                                        <TableCell id={labelId} scope='row' className={classes.cellPadding}>
                                                            {row.telefono}
                                                        </TableCell>
                                                        <TableCell id={labelId} scope='row' className={classes.cellPadding}>
                                                            {row.sociedad}
                                                        </TableCell>
                                                        <TableCell id={labelId} scope='row' className={classes.cellPadding}>
                                                            {row.rol}
                                                        </TableCell>
                                                        <TableCell id={labelId} scope='row' className={classes.cellPadding}>
                                                            {row.estatus}
                                                        </TableCell>
                                                        <TableCell id={labelId} scope='row' className={classes.cellPadding}>
                                                            {row.edit}
                                                        </TableCell>
                                                        <TableCell id={labelId} scope='row' className={classes.cellPadding}>
                                                            {row.remove}
                                                        </TableCell> */}
                                            </TableRow>
                                        )
                                    })}
                            </TableBody>
                        </Table>
                    </div>
                    {/* <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component='div'
                            count={data.length}
                            rowsPerPage={rowsPerPage}
                            labelRowsPerPage='Mostrar:'
                            labelDisplayedRows={({ from, to, count }) => `${from} - ${to === -1 ? count : to} de ${count}`}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        /> */}
                    <Grid container justifyContent="flex-end">
                        <Pagination
                            className={classes.pagination}
                            count={Math.ceil(data.length / rowsPerPage)}
                            page={page}
                            onChange={handleChangePage}
                            color="primary"
                        />
                    </Grid>
                </Fragment>
            ) : (
                <Fragment>
                    <div className={classes.selectContainer}>
                        <FormControl className={classes.formControlSelect}>
                            <InputLabel id="select-mobile-fields-label">Mostrar</InputLabel>
                            <Select
                                labelId="select-mobile-fields-label"
                                name="selectionFields"
                                value={toShow}
                                onChange={handleChangeSelect}
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    },
                                    getContentAnchorEl: null,
                                }}
                                // fullWidth
                                displayEmpty
                            >
                                {mobileSelection.map((selection, index) => {
                                    return (
                                        <MenuItem key={index} value={selection.name}>
                                            {selection.name}
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </div>
                    <Grid container justifyContent="flex-end">
                        <Pagination
                            className={classes.pagination}
                            count={Math.ceil(data.length / rowsPerPage)}
                            page={page}
                            onChange={handleChangePage}
                            color="primary"
                        />
                    </Grid>
                    <div className={classes.tabla}>
                        <Table aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    {displayCells.map((headCell, index) => {
                                        return (
                                            <TableCell
                                                key={index}
                                                align={'left'}
                                                sortDirection={orderBy === headCell.field ? order : false}
                                                className={classes.headCell}
                                            >
                                                {headCell.sortable ? (
                                                    <TableSortLabel
                                                        active={orderBy === headCell.field}
                                                        direction={orderBy === headCell.field ? order : 'asc'}
                                                        onClick={createSortHandler(headCell.field)}
                                                    >
                                                        {headCell.headerName}
                                                    </TableSortLabel>
                                                ) : (
                                                    <span>{headCell.headerName}</span>
                                                )}
                                            </TableCell>
                                        )
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {stableSort(data, getComparator(order, orderBy))
                                    .slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage)
                                    .map((row, index) => {
                                        const labelId = `enhanced-table-checkbox-${index}`

                                        return (
                                            <TableRow hover tabIndex={-1} key={index}>
                                                {displayCells.map((headCell, index) => {
                                                    return (
                                                        <TableCell
                                                            key={index}
                                                            id={labelId}
                                                            scope="row"
                                                            padding={headCell.disablePadding ? 'none' : 'normal'}
                                                            className={headCell.center && classes.centered}
                                                        >
                                                            {row[headCell.field]}
                                                        </TableCell>
                                                    )
                                                })}
                                            </TableRow>
                                        )
                                    })}
                            </TableBody>
                        </Table>
                    </div>
                    {/* <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component='div'
                        count={data.length}
                        rowsPerPage={rowsPerPage}
                        labelRowsPerPage='Mostrar:'
                        labelDisplayedRows={({ from, to, count }) => `${from} - ${to === -1 ? count : to} de ${count}`}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    /> */}
                    <Grid container justifyContent="flex-end">
                        <Pagination
                            className={classes.pagination}
                            count={Math.ceil(data.length / rowsPerPage)}
                            page={page}
                            onChange={handleChangePage}
                            color="primary"
                        />
                    </Grid>
                </Fragment>
            )}
        </Fragment>
    )
}

export default withWidth()(TablaAdministracion)
