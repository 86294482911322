/* React */
import React from 'react'

/* Material UI */
import { SvgIcon } from '@material-ui/core'

const CheckListIcon = props => (
    <SvgIcon {...props} viewBox="0 0 27.947 32.12">
        <g transform="translate(-555.647 -391.766)">
            <path d="M579.872,391.766h-20.5a3.726,3.726,0,0,0-3.722,3.722v24.676a3.726,3.726,0,0,0,3.722,3.722h20.5a3.726,3.726,0,0,0,3.722-3.722V395.488A3.726,3.726,0,0,0,579.872,391.766Zm1.528,28.4a1.53,1.53,0,0,1-1.528,1.528h-20.5a1.53,1.53,0,0,1-1.528-1.528V395.488a1.53,1.53,0,0,1,1.528-1.529h20.5a1.53,1.53,0,0,1,1.528,1.529Z" />
            <path
                d="M587.226,415.007h4.5a3.061,3.061,0,0,0,3.058-3.058V410.69a.99.99,0,0,0-1.98,0v1.259a1.079,1.079,0,0,1-1.078,1.078h-4.5a1.079,1.079,0,0,1-1.078-1.078v-4.5a1.079,1.079,0,0,1,1.078-1.078h4.5a.99.99,0,1,0,0-1.98h-4.5a3.062,3.062,0,0,0-3.058,3.058v4.5A3.062,3.062,0,0,0,587.226,415.007Z"
                transform="translate(-20.149 -8.497)"
            />
            <path
                d="M597.584,413.427l-1.506-1.506-1.4,1.4,2.206,2.206a.99.99,0,0,0,1.4,0l4.614-4.614-1.4-1.4Z"
                transform="translate(-27.827 -12.751)"
            />
            <path
                d="M593.8,458.706a.99.99,0,0,0-.99.99v1.259a1.079,1.079,0,0,1-1.078,1.078h-4.5a1.079,1.079,0,0,1-1.078-1.078v-4.5a1.079,1.079,0,0,1,1.078-1.078h4.5a.99.99,0,1,0,0-1.98h-4.5a3.062,3.062,0,0,0-3.058,3.058v4.5a3.061,3.061,0,0,0,3.058,3.058h4.5a3.061,3.061,0,0,0,3.058-3.058V459.7A.99.99,0,0,0,593.8,458.706Z"
                transform="translate(-20.149 -44.252)"
            />
            <path
                d="M597.584,462.433l-1.506-1.506-1.4,1.4,2.206,2.206a.99.99,0,0,0,1.4,0l4.614-4.614-1.4-1.4Z"
                transform="translate(-27.827 -48.957)"
            />
        </g>
    </SvgIcon>
)

export default CheckListIcon
