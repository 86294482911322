/* React */
import React from 'react'

/* Material UI */
import { withStyles, Switch } from '@material-ui/core'

const TableSwitch = withStyles(theme => ({
    root: {
        width: 52,
        height: 28,
        padding: 0,
        margin: theme.spacing(1),
    },
    switchBase: {
        padding: 1,
        '&$checked': {
            transform: 'translateX(24px)',
            color: theme.palette.common.white,
            '& + $track': {
                backgroundColor: '#52d869',
                opacity: 1,
                border: 'none',
            },
        },
        '&$focusVisible $thumb': {
            color: '#52d869',
            border: '6px solid #fff',
        },
    },
    thumb: {
        width: 26,
        height: 26,
    },
    track: {
        borderRadius: 28 / 2,
        border: `1px solid ${theme.palette.grey[400]}`,
        backgroundColor: theme.palette.grey[50],
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border']),
    },
    disabled: {
        color: '#bdbdbd !important',
        '& + $track': {
            backgroundColor: 'rgba(0, 0, 0) !important',
            opacity: '0.12 !important',
        },
    },
    checked: {},
    focusVisible: {},
}))(({ classes, ...props }) => {
    return (
        <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
                disabled: classes.disabled,
            }}
            {...props}
        />
    )
})

export default TableSwitch
