/* React */
import React from 'react'

const AvatarMask = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xlink="http://www.w3.org/1999/xlink"
        width="126"
        height="124"
        viewBox="0 0 126 124"
    >
        {/* <defs>
            <clipPath id="a">
                <ellipse cx="63" cy="62" rx="63" ry="62" transform="translate(-0.208)" fill="#00539b" />
            </clipPath>
        </defs>
        <g transform="translate(0.208)" clipPath="url(#a)">
            <ellipse cx="63" cy="62" rx="63" ry="62" transform="translate(-0.208)" fill="#72bf44" />
            <ellipse cx="62.5" cy="62" rx="62.5" ry="62" transform="translate(6.792 5)" fill="#00539b" />
        </g> */}
    </svg>
)

export default AvatarMask
