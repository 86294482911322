/* React */
import React, { Fragment, useState, useEffect } from 'react'

/* Material UI */
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    makeStyles,
    TableSortLabel,
    withWidth,
    Grid,
} from '@material-ui/core'
import { Pagination } from '@material-ui/lab'

/* Components */
import { getComparator, stableSort } from './'

/* Utilities */
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
    tabla: {
        overflowX: 'auto',
        marginLeft: 10,
        marginRight: 10,
        height: 'calc(100vh - 350px)'
    },
    headCell: {
        fontWeight: 'bold',
    },
    centered: {
        textAlign: 'center',
    },
    pagination: {
        padding: 15,
    },
    selectContainer: {
        padding: 15,
        width: '100%',
        display: 'flex',
    },
    formControlSelect: {
        margin: theme.spacing(1, 'auto'),
        maxWidth: 300,
        width: '100%',
        textAlign: 'start',
        '& .MuiInputBase-input': {
            // background: 'white',
            // fontSize: 14,
            // fontWeight: 800,
            // borderRadius: 12
        },
        '& .MuiOutlinedInput-root': {
            // borderRadius: 12
        },
        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            // borderWidth: 0
        },
    },
}))

const rowsPerPage = 12

const TablaManagement = ({ data, headCells, sortDefault, selectedId, handleSelected, noSort, noPagination }) => {
    const classes = useStyles()

    const [order, setOrder] = useState('asc')
    const [orderBy, setOrderBy] = useState(sortDefault)
    const [page, setPage] = useState(1)

    useEffect(() => {
      setPage(1)
    }, [])
    
    useEffect(() => {
        if (selectedId) {
            setPage(1)
        }
    },[selectedId])

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
    }

    const createSortHandler = property => event => {
        handleRequestSort(event, property)
    }

    return (
        <Fragment>
            <Fragment>
                <div className={classes.tabla} style={{ paddingBottom: noPagination && 15 }}>
                    <Table aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                {headCells.map((headCell, index) => {
                                    return (
                                        <TableCell
                                            key={index}
                                            align={'left'}
                                            // padding={headCell.disablePadding ? 'none' : 'normal'}
                                            sortDirection={orderBy === headCell.field ? order : false}
                                            className={clsx(classes.headCell, headCell.center && classes.centered)}
                                        >
                                            {headCell.sortable ? (
                                                <TableSortLabel
                                                    active={orderBy === headCell.field}
                                                    direction={orderBy === headCell.field ? order : 'asc'}
                                                    onClick={createSortHandler(headCell.field)}
                                                >
                                                    {headCell.headerName}
                                                </TableSortLabel>
                                            ) : (
                                                <span>{headCell.headerName}</span>
                                            )}
                                        </TableCell>
                                    )
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(noSort ? data : stableSort(data, getComparator(order, orderBy)))
                                .slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    const labelId = `enhanced-table-checkbox-${index}`

                                    return (
                                        <TableRow
                                            hover
                                            tabIndex={-1}
                                            key={index}
                                            selected={selectedId === row._id}
                                            onClick={() =>
                                                handleSelected &&
                                                handleSelected(selectedId === row._id ? null : row._id)
                                            }
                                        >
                                            {headCells.map((headCell, index) => {
                                                return (
                                                    <TableCell
                                                        key={index}
                                                        id={labelId}
                                                        scope="row"
                                                        padding={headCell.disablePadding ? 'none' : 'normal'}
                                                        className={headCell.center && classes.centered}
                                                    >
                                                        {row[headCell.field]}
                                                    </TableCell>
                                                )
                                            })}
                                        </TableRow>
                                    )
                                })}
                        </TableBody>
                    </Table>
                </div>
                {!noPagination && (
                    <Grid container justifyContent="flex-end">
                        <Pagination
                            className={classes.pagination}
                            count={Math.ceil(data.length / rowsPerPage)}
                            page={page}
                            onChange={handleChangePage}
                            color="primary"
                        />
                    </Grid>
                )}
            </Fragment>
        </Fragment>
    )
}

export default withWidth()(TablaManagement)
