/* GraphQL */
import { gql } from '@apollo/client'

export const CREATE_FORRAJE = gql`
    mutation Forrajes(
        $locacion: String!
        $direction: String!
        $lat: Float!
        $lon: Float!
        $publicCard: Boolean!
        $sociedades: [ID]
        $dryMatter: [ForrajeDataInput]
        $image: Upload
    ) {
        createForraje(
            locacion: $locacion
            direction: $direction
            lat: $lat
            lon: $lon
            publicCard: $publicCard
            sociedades: $sociedades
            dryMatter: $dryMatter
            image: $image
        ) {
            message
        }
    }
`

export const FORRAJES = gql`
    query Forrajes {
        forrajes {
            _id
            locacion
            direction
            dryMatter {
                _id
                fecha
                cantidad
            }
            GPS {
                lat
                lon
            }
            public
            sociedades
            image {
                name
                img
            }
            createdAt
            updatedAt
        }
    }
`

export const EDIT_FORRAJE = gql`
    mutation Forrajes($forrajeId: ID!, $dryMatter: [ForrajeDataInput]) {
        editForraje(forrajeId: $forrajeId, dryMatter: $dryMatter) {
            message
        }
    }
`
