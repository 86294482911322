/* React */
import React, { useContext, useEffect } from 'react'

/* Material UI */
import { Grid, Paper, makeStyles, CircularProgress } from '@material-ui/core'

/* Context */
import { FilterContext, InviernoVeranoContext } from '../../contexts/produccion'

/* Components */
import { InviernoVeranoFilter, ProductoresList } from '../../components/produccion/filter'
import { CartaTablaGrafico } from '../../components/produccion'

const useStyles = makeStyles(theme => ({
    paperRelacion: {
        // margin: theme.spacing(2, 'auto'),
        // padding: theme.spacing(2),
        borderRadius: 12,
        maxWidth: 380,
        overflow: 'hidden',
    },
}))

const InviernoVerano = props => {
    const classes = useStyles()
    const yearToGet = new Date().getMonth() + 1 < 5 ? new Date().getFullYear() - 1 : new Date().getFullYear()
    const { predioFilter, yearFilter } = useContext(FilterContext)
    const {
        inviernoVeranoData,
        inviernoVeranoRatioOld,
        inviernoVeranoRatio,
        complete,
        queryServer,
        loading,
        maxValue,
        loadingCSV,
        downloadCSV,
    } = useContext(InviernoVeranoContext)

    useEffect(() => {
        queryServer(yearFilter ? parseInt(yearFilter) : yearToGet)
    }, [predioFilter, yearFilter])

    return (
        <div>
            <InviernoVeranoFilter />
            <ProductoresList />
            <Grid container spacing={2} style={{ marginTop: 8 }}>
                <Grid item xs={12} container>
                    <Paper elevation={0} className={classes.paperRelacion}>
                        <div
                            style={{
                                backgroundColor: '#68df45',
                                padding: '10px 25px',
                                textAlign: 'center',
                            }}
                        >
                            <span style={{ color: '#ffffff', fontSize: 20, fontWeight: 'bold' }}>
                                Relación Invierno-Verano {yearFilter ? parseInt(yearFilter) : yearToGet}
                            </span>
                        </div>
                        <Grid style={{ padding: '10px 25px', textAlign: 'center' }} item container spacing={2}>
                            <Grid item xs={6}>
                                <span style={{ color: '#292929', fontSize: 20 }}>Año anterior</span>
                                <br />
                                {loading ? (
                                    <CircularProgress />
                                ) : (
                                    <span
                                        style={{
                                            color: '#292929',
                                            fontSize: 40,
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        {!isNaN(inviernoVeranoRatioOld)
                                            ? inviernoVeranoRatioOld.toString().replace('.', ',')
                                            : '--'}
                                    </span>
                                )}
                            </Grid>
                            <Grid item xs={6}>
                                <span style={{ color: '#292929', fontSize: 20 }}>Año actual</span>
                                <br />
                                {loading ? (
                                    <CircularProgress />
                                ) : (
                                    <span
                                        style={{
                                            color: complete ? '#292929' : 'red',
                                            fontSize: 40,
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        {inviernoVeranoRatio.toString().replace('.', ',') + (complete ? '' : '*')}
                                    </span>
                                )}
                            </Grid>
                            {!complete && (
                                <Grid item xs={12}>
                                    * Dato provisorio. El dato final estará disponible una vez termine el período de
                                    medición.
                                </Grid>
                            )}
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <CartaTablaGrafico
                        type="bar"
                        title1="Meses de invierno"
                        title2="Invierno"
                        subtitle1="Mayo, junio, julio y agosto"
                        subtitle2="May, jun, jul y ago"
                        data={inviernoVeranoData.invierno}
                        forecast={false}
                        buttons={true}
                        yLabel={'Litros'}
                        downloadCSV={() => downloadCSV(yearFilter ? parseInt(yearFilter) : yearToGet)}
                        loadingCSV={loadingCSV}
                        maxValue={maxValue}
                        minValue={0}
                        decimalPlaces={0}
                        loading={loading}
                        view={'Relación Invierno-Verano ' + (yearFilter ? parseInt(yearFilter) : yearToGet)}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <CartaTablaGrafico
                        type="bar"
                        title1="Meses de verano"
                        title2="Verano"
                        subtitle1="Octubre, noviembre, diciembre y enero"
                        subtitle2="Oct, nov, dic y ene"
                        data={inviernoVeranoData.verano}
                        forecast={false}
                        buttons={true}
                        yLabel={'Litros'}
                        downloadCSV={() => downloadCSV(yearFilter ? parseInt(yearFilter) : yearToGet)}
                        loadingCSV={loadingCSV}
                        maxValue={maxValue}
                        minValue={0}
                        decimalPlaces={0}
                        loading={loading}
                        view={'Relación Invierno-Verano ' + (yearFilter ? parseInt(yearFilter) : yearToGet)}
                    />
                </Grid>
            </Grid>
        </div>
    )
}

export default InviernoVerano
