/* React */
import React, { useContext, useEffect, useState } from 'react'

/* Material UI */
import { Grid } from '@material-ui/core'

/* Components */
import { CartaTablaGrafico } from '../../components/produccion'
import { ResumenPreciosFilter } from '../../components/produccion/filter'
import { SalasContext } from '../../contexts/administracion'

const ResumenPrecios = props => {
    const { data: salas } = useContext(SalasContext)
    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date())
    const [selected, setSelected] = useState(new Date())
    const [parsedData, setParsedData] = useState([])

    useEffect(() => {
        const endDate_ = new Date()
        endDate_.setDate(1)
        const startDate_ = new Date(endDate_)
        startDate_.setFullYear(startDate_.getFullYear() - 1)
        setStartDate(startDate_)
        setEndDate(endDate_)
    }, [])

    useEffect(() => {
        if (salas?.length >= 0) {
            if (!selected) {
                setParsedData([])
            } else {
                const findSala = salas.find(x => x._id.toString() === selected)
                if (!findSala) {
                    setParsedData([])
                } else {
                    const data_ = []
                    console.log(findSala.kpi)
                    for (
                        let day = new Date(startDate);
                        day.getTime() <= endDate.getTime();
                        day.setMonth(day.getMonth() + 1)
                    ) {
                        const isoDateSplit = day.toISOString().split('T')[0].split('-')
                        const prevYear = `${parseInt(isoDateSplit[0]) - 1}`
                        const dataPointActual = findSala.kpi.resumenPrecios?.find(
                            x => x.month === isoDateSplit[1] && x.year === isoDateSplit[0]
                        )
                        const dataPointAnterior = findSala.kpi.resumenPrecios?.find(
                            x => x.month === isoDateSplit[1] && x.year === prevYear
                        )
                        data_.push({
                            name: isoDateSplit[1] + '/' + isoDateSplit[0],
                            'año actual': dataPointActual?.value,
                            'año anterior': dataPointAnterior?.value,
                        })
                    }
                    setParsedData(data_)
                }
            }
        }
    }, [startDate, endDate, selected, salas])

    return (
        <div>
            <ResumenPreciosFilter
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                selectedPredio={selected}
                setSelectedPredio={setSelected}
            />
            <Grid container spacing={2} style={{ marginTop: 8 }}>
                <Grid item xs={12}>
                    <CartaTablaGrafico
                        type="area"
                        title1="Precio Leche"
                        title2="Precio Leche"
                        subtitle1="Últimos 12 meses vs año anterior"
                        subtitle2="Últimos 12 meses"
                        data={parsedData}
                        forecast={false}
                        buttons={false}
                        yLabel={'$/Lt'}
                        decimalPlaces={2}
                        view="ResumenPrecios"
                    />
                </Grid>
            </Grid>
        </div>
    )
}

export default ResumenPrecios
