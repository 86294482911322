/* React */
import React, { createContext, useState } from 'react'

export const AdminFilterContext = createContext()

const AdminFilterContextProvider = props => {
    const [isCreate, setIsCreate] = useState(false)
    const [search, setSearch] = useState('')
    const [resetPage, setResetPage] = useState(false)

    return (
        <AdminFilterContext.Provider
            value={{
                search,
                setSearch,
                isCreate,
                setIsCreate,
                resetPage,
                setResetPage,
            }}
        >
            {props.children}
        </AdminFilterContext.Provider>
    )
}

export default AdminFilterContextProvider
