import axios from 'axios'
import { sociedadIdb } from '../indexedDb'
const getSociedadesFromRoutes = async () => {
    const response = await axios.get('/sociedades/getSociedades')
    /* console.log(response.data) */
    const {database} = await sociedadIdb.init()
    response.data.sociedades.forEach(async (sociedad, index) => {
        const newSociedad = sociedad
        newSociedad.id = index
        /* const responseData =  */await sociedadIdb.actualizar(sociedad, database)
        /* console.log(responseData) */
    })
}

export default getSociedadesFromRoutes