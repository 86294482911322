/* React */
import React, { useContext } from 'react'

/* Material UI */
import { Button, FormControl, Grid, makeStyles } from '@material-ui/core'

/* Context */
import { RecoleccionContext } from '../../contexts/recoleccion'

/* Components */
import { MyDatePicker, PredioFilter } from '../produccion/filter'

const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1, 'auto'),
        '& .MuiInputBase-input': {
            background: 'white',
            fontSize: 16,
            fontWeight: 600,
            borderRadius: 12,
        },
        '& .MuiInputBase-root': {
            background: 'white',
            fontSize: 16,
            fontWeight: 600,
            borderRadius: 12,
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: 12,
        },
        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            borderWidth: 0,
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(14px, -14px) scale(0.75)',
        },
    },
    button: {
        borderRadius: 12,
        WebkitBoxShadow: 'none',
        '&:hover': {
            WebkitBoxShadow: 'none',
        },
        width: '100%',
        height: '100%',
    },
}))

const Filter = props => {
    const { cardFormat, setCardFormat } = useContext(RecoleccionContext)
    const classes = useStyles()
    // const theme = useTheme()

    return (
        <FormControl fullWidth variant="outlined" className={classes.formControl}>
            <Grid container spacing={2} style={{ marginTop: 8 }}>
                <Grid item xs={6} sm={3}>
                    <PredioFilter />
                </Grid>
                <Grid item xs={6} sm={3}>
                    <MyDatePicker label="Inicio" />
                </Grid>
                <Grid item xs={6} sm={3}>
                    <MyDatePicker label="Termino" />
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Button
                        color="primary"
                        className={classes.button}
                        variant="contained"
                        onClick={() => setCardFormat(!cardFormat)}
                    >
                        {cardFormat ? 'Ver tabla' : 'Ver tarjetas'}
                    </Button>
                </Grid>
            </Grid>
        </FormControl>
    )
}

export default Filter
