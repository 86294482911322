/* React */
import React, { useContext, useEffect, useState } from 'react'

/* Material UI */
import { Button, Checkbox, Grid, makeStyles, Paper, Popover, Switch } from '@material-ui/core'

/* Context */
import { AdminFilterContext, AsignacionSalasContext } from '../../contexts/administracion'

/* Components */
import { AdminFilter } from '../../components/administracion'
import { TablaManagement } from '../../components/tabla'
import axios from 'axios'
import SearchBox from '../../components/SearchBox'
import { sociedadIdb } from '../../indexedDb'

const useStyles = makeStyles(theme => ({
    paperGeneralCard: {
        borderRadius: 12,
        '& .MuiDataGrid-root': {
            border: 0,
        },
    },
    dataGrid: {
        height: 650,
        width: '100%',
        padding: 8,
        '& .cell': {
            color: '#050505',
            fontSize: 14,
            fontWeight: 800,
            padding: '0px 4px 0px 4px',
            textAlign: 'left',
        },
        '& .header': {
            color: '#737373',
            fontSize: 16,
            fontWeight: 800,
            padding: '0px 4px 0px 4px',
            textAlign: 'left',
        },
    },
}))

const AsignacionSalasManagment = props => {
    const { usersColumns, sociedadesSalasColumns, users, sociedadesSalas, selectedId, handleSelected } =
        useContext(AsignacionSalasContext)
    const [filteredUsers, setFilteredUsers] = useState([])
    const classes = useStyles()
    const { search } = useContext(AdminFilterContext)
    const [sociedades, setSociedades] = useState([])
    const [sociedad, setSociedad] = useState()
    const [isAdmin, setIsAdmin] = useState(false)
    const [sociedadesParaMostrar, setSociedadesParaMostrar] = useState([])
    const [sociedadesParaMostrarCache, setSociedadesParaMostrarCache] = useState([])
    const [sociedadesNoAsignadas, setSociedadesNoAsignadas] = useState([])
    const [sociedadesSalasSearch, setSociedadesSalasSearch] = useState('')
    const [dataSociedadesSalas, setDataSociedadesSalas] = useState(sociedadesSalas)
    const [buttonsSelection, setButtons] = useState([])
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [salas, setSalas] = useState([])
    const [asignadasColumna, setAsignadasColumnas] = useState(true)
    const [listaDescargada, setListaDescargada] = useState(false)
    const [userData, setUserData] = useState()
    const [isVistaAdmin, setVistaAdmin] = useState(false)
    const [filterSociedad, setFilterSociedad] = useState()
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    useState(() => {
        console.log(filteredUsers)
    },[filteredUsers])

    const buttons = [
        {
            name: 'Sociedades asignadas',
            state: true,
            id: 0
        },
        {
            name: 'Sociedades no asignadas',
            state: false,
            id: 1
        }
    ]

    useEffect(() => {
        if (filterSociedad) {
            if (sociedadesParaMostrarCache.length > 0) {
                if (filterSociedad.length > 0) {
                    const sociedadesFiltradas = sociedadesParaMostrarCache.filter((sociedad) => {
                        if (sociedad.name.includes(filterSociedad) || sociedad.masterCode.includes(filterSociedad)) {
                            return sociedad
                        } else {
                            return null
                        }
                    })
                    setSociedadesParaMostrar(sociedadesFiltradas)
                } else {
                    setSociedadesParaMostrar(sociedadesParaMostrarCache)
                }
            }
        } else {
            setSociedadesParaMostrar(sociedadesParaMostrarCache)
        }
    }, [filterSociedad])

    useEffect(() => {
        setButtons(buttons)
        if(localStorage.getItem('isAdmin') === 'admin') {
            setIsAdmin(true)
        }
    }, [])

    useEffect(() => {
        handleSelected(null)
    }, [search])

    /* useEffect(() => {
        console.log(sociedadesNoAsignadas)
    }, [sociedadesNoAsignadas]) */

    useEffect(() => {
        if (search.trim() !== '') {
            setFilteredUsers(
                users.filter(el => {
                    const keys = Object.keys(el)
                    let text = ''
                    keys.forEach((key, index) => {
                        if (key === 'name' || key === 'lastName') text += el[key]
                    })
                    return text.toLowerCase().indexOf(search.toLowerCase()) > -1
                })
            )
        } else {
            setFilteredUsers(users)
        }
    }, [search, users])

    useEffect(() => {
        console.log(selectedId)
        gestUserById(selectedId)
    }, [selectedId])

    const gestUserById = async (selectedId) => {
        selectLista(0)
        const res = await axios.post('/users/getUserById', {id: selectedId})
        setUserData(res.data.user)
        const sociedadesData = res.data.user.sociedades.sort((a, b) => (a.masterCode > b.masterCode) ? 1 : -1)
        setSociedades(sociedadesData)
        setSociedadesParaMostrar(sociedadesData)
        setSociedadesParaMostrarCache(sociedadesData)
        const {database} = await sociedadIdb.init()
        const data = await sociedadIdb.leerTodas(database)
        const socNoAsignadas = []
        data.data.forEach((sociedad, index) => {
            let state = true
            sociedadesData.forEach((sociedadAsignada, i) => {
                if (sociedad.masterCode === sociedadAsignada.masterCode) {
                    console.log(index, sociedad.masterCode, sociedadAsignada.masterCode)
                    state = false
                }
                if (i === (sociedadesData.length - 1)) {
                    if (state) {
                        socNoAsignadas.push(sociedad)
                    }
                }
            })
            if (index === (data.data.length - 1)) {
                setSociedadesNoAsignadas(socNoAsignadas.sort((a, b) => (a.masterCode > b.masterCode) ? 1 : -1))
            }
        })
    }

    const selectLista = (id) => {
        const buttonsCache = [...buttons]
        if (id === 0) {
            setAsignadasColumnas(true)
            setSociedadesParaMostrar(sociedades)
            buttonsCache[0].state = true
            buttonsCache[1].state = false
        } else if (id === 1) {
            setSociedadesParaMostrar(sociedadesNoAsignadas)
            setAsignadasColumnas(false)
            buttonsCache[1].state = true
            buttonsCache[0].state = false
        }
        setButtons(buttonsCache)
    }
    
    useEffect(() => {
        if (sociedadesSalasSearch.trim() !== '') {
            const filteredSociedadesSalas = sociedadesSalas.filter(el =>
                new RegExp(sociedadesSalasSearch, 'i').test(el.name.props.children.join(''))
            )
            setDataSociedadesSalas(filteredSociedadesSalas)
        } else {
            setDataSociedadesSalas(sociedadesSalas)
        }
    }, [sociedadesSalasSearch, sociedadesSalas])

    const handleClick = async (event, sociedad) => {
        setSociedad(sociedad)
        setAnchorEl(event.currentTarget)
        const res = await axios.post('/salas/getSalasPorSociedadId', {id: sociedad._id})
        /* console.log(res.data) */
        const salas = res.data.salas
        salas.forEach((sala, i) => {
            sala.assigned = false
            userData.salas.forEach((salaUser, n) => {
                if (salaUser.sala === sala._id) {
                    sala.assigned = true
                }
            })
            if (i === (salas.length - 1)) {
                console.log(salas)
                setTimeout(() => {
                    setListaDescargada(true)
                    setSalas(salas.sort((a, b) => (a.producerCode > b.producerCode) ? 1 : -1))
                }, 500);
            }
        })
    }

    const handleClose = () => {
        setAnchorEl(null)
        setListaDescargada(false)
        setSalas([])
    }

    const seleccionarSociedad = async () => {
        if (window.confirm('Confirme que asignará la sociedad')) {
            const res = await axios.post('/users/guardarSociedadEnUsuario', {userId: userData._id, sociedadId: sociedad._id})
            console.log(res.data)
            gestUserById(userData._id)
            handleClose()
        }
    }

    const borrarSociedad = async () => {
        if (window.confirm('Confirme que quitará la sociedad')) {
            const res = await axios.post('/users/quitarSociedadDeUsuario', {userId: userData._id, sociedadId: sociedad._id})
            console.log(res.data)
            gestUserById(userData._id)
            handleClose()
        }
    }

    const seleccionarSala = async (sala, number) => {
        if (asignadasColumna) {
            if (!sala.assigned) {
                console.log(sala, number)
                const res = await axios.post('/users/guardarSalaEnUsuario', {userId: userData._id, salaId: sala._id})
                if (res.data.state) {
                    const salasCache = [...salas]
                    salasCache[number].assigned = true
                    setSalas(salasCache)
                } else {
                    alert('Error al cargar sala')
                }
            } else {
                const res = await axios.post('/users/quitarSalaDeUsuario', {userId: userData._id, salaId: sala._id})
                if (res.data.state) {
                    const salasCache = [...salas]
                    salasCache[number].assigned = false
                    setSalas(salasCache)
                } else {
                    alert('Error al eliminar sala')
                }
            }
        } else {
            alert('Primero asigne la sociedad')
        }
    }

    const vistaAdmin = () => {
        if (isVistaAdmin) {
            setVistaAdmin(false)
        } else {
            setVistaAdmin(true)
        }
    }

    return (
        <Grid container spacing={2} className={classes.filters} style={{ marginTop: 8 }}>
            <Grid item xs={12} md={6}>
                <Paper elevation={0} className={classes.paperGeneralCard}>
                    <Grid container>
                        {isAdmin && <Grid item xs={12} md={6} style={{padding: 24}}>
                            <Switch checked={isVistaAdmin} onChange={vistaAdmin} /> Vista Admin {isVistaAdmin ? 'Activada' : 'Desactivada'}
                        </Grid>}
                        <Grid item xs={12} md={isAdmin ? 6 : 12}>
                            <AdminFilter isOnlySearch />
                        </Grid>
                    </Grid>
                    <TablaManagement
                        data={filteredUsers}
                        headCells={usersColumns}
                        sortDefault={'lastName'}
                        selectedId={selectedId}
                        handleSelected={handleSelected}
                    />
                </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
                <Paper elevation={0} className={classes.paperGeneralCard}>
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <div style={{ maxHeight: 'calc(100vh - 400px)', padding: 10 }}>
                            {
                                !asignadasColumna ? 
                                <div
                                    style={{ textAlign: 'center', width: '100%', marginBottom: 10 }}
                                >
                                    <Button
                                        color='primary'
                                        variant={'outlined'}
                                        onClick={() => { seleccionarSociedad() }}    
                                    >
                                        Asignar sociedad a usuario
                                    </Button>
                                </div>
                                :
                                <div
                                    style={{ textAlign: 'center', width: '100%', marginBottom: 10 }}
                                >
                                    <Button
                                        color={'secondary'}
                                        variant={'outlined'}
                                        onClick={() => { borrarSociedad() }}    
                                    >
                                        Quitar sociedad de usuario
                                    </Button>
                                </div>
                            }
                            <div
                                style={{
                                    borderColor: '#3383ee',
                                    borderWidth: 1,
                                    borderStyle: 'solid',
                                    borderRadius: 8,
                                    padding: 5
                                }}
                            >
                                <div
                                    style={{ textAlign: 'center', width: '100%' }}
                                >
                                    <p style={{ fontSize: 16 }}>Lista de salas</p>
                                </div>
                                {
                                    (listaDescargada) 
                                    ?
                                    (
                                        (salas.length > 0)
                                        ?
                                        salas.map((sala, i) => {
                                            return (
                                                <div className='sala-item' key={i}>
                                                    <Checkbox
                                                        color={'primary'}
                                                        checked={sala.assigned}
                                                        onChange={() => seleccionarSala(sala, i)}
                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                    />{sala.producerCode} - {sala.name}
                                                </div>
                                            )
                                        })
                                        :
                                        <div>
                                            <p style={{color: 'red'}}>Sociedad sin sala</p>
                                        </div>
                                    )
                                    :
                                    <div>
                                        Loading list...
                                    </div>
                                }
                            </div>
                        </div>
                    </Popover>
                    <Grid container>
                        {
                            buttonsSelection.map((button, index) => {
                                return (
                                    <Grid xs={6} key={index} style={{textAlign: 'center', margin: '10px 0px'}}>
                                        {isVistaAdmin && <Button variant={'contained'} color={button.state ? 'primary' : 'secondary'} onClick={() => {  selectLista(button.id) }}>
                                            {button.name}
                                        </Button>}
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                    {
                        isVistaAdmin
                        ?
                        <div>
                            <div style={{padding: '0px 10px'}}>
                                <input value={filterSociedad} placeholder='Busque Sociedad' onChange={(e) => {setFilterSociedad(e.target.value)}} />
                            </div>
                            <div className='sociedades-list'>
                                
                                {
                                    (sociedadesParaMostrar.length > 0) ? sociedadesParaMostrar.map((sociedad, index) => {
                                        return (
                                            <div className='sociedad-item' key={index} onClick={(e) => { handleClick(e, sociedad) }}>
                                                <p>{index + 1}.- cod. {sociedad.masterCode} - {sociedad.name}</p>
                                            </div>
                                        )
                                    }) : 
                                    <div
                                        style={{ width: '100%', textAlign: 'center', fontSize: 16, padding: 24 }}
                                    >
                                        <p>Seleccione usuario.</p>
                                    </div>
                                }
                            </div>
                        </div>
                        :
                        <div>
                            <SearchBox search={sociedadesSalasSearch} setSearch={setSociedadesSalasSearch} />
                            <TablaManagement data={dataSociedadesSalas} headCells={sociedadesSalasColumns} noSort={true} />
                        </div>
                    }
                </Paper>
            </Grid>
        </Grid>
    )
}

export default AsignacionSalasManagment
