/* React */
import React from 'react'

/* Material UI */
import { FormControl, Grid, makeStyles } from '@material-ui/core'

/* Components */
import { YearFilter } from './'

const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1, 'auto'),
        '& .MuiInputBase-input': {
            background: 'white',
            fontSize: 16,
            fontWeight: 600,
            borderRadius: 12,
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: 12,
        },
        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            borderWidth: 0,
        },
    },
}))

const FilterSituacionSanitaria = props => {
    const classes = useStyles()

    return (
        <FormControl fullWidth variant="outlined" className={classes.formControl}>
            <Grid container spacing={2} style={{ marginTop: 8 }}>
                <Grid item xs={6} sm={3}>
                    <YearFilter label="Termino" />
                </Grid>
            </Grid>
        </FormControl>
    )
}

export default FilterSituacionSanitaria
