/* React */
import React, { useContext, useEffect, useState } from 'react'

/* Material UI */
import { Grid, makeStyles, Paper } from '@material-ui/core'

/* Context */
import { AdminFilterContext, AsignacionPermisosContext, PermissionPresetsContext } from '../../contexts/administracion'

/* Components */
import { AdminFilter } from '../../components/administracion'
import { ButtonVerTabla } from '../../components/comun'
import { TablaManagement } from '../../components/tabla'

const useStyles = makeStyles(theme => ({
    paperGeneralCard: {
        borderRadius: 12,
        '& .MuiDataGrid-root': {
            border: 0,
            // borderRadius: 12
        },
    },
    table: {
        height: 650,
        width: '100%',
        padding: 8,
        '& .cell': {
            color: '#050505',
            fontSize: 14,
            fontWeight: 800,
            padding: '0px 4px 0px 4px',
            textAlign: 'left',
        },
        '& .header': {
            color: '#737373',
            fontSize: 16,
            fontWeight: 800,
            padding: '0px 4px 0px 4px',
            textAlign: 'left',
        },
    },
}))

const AsignacionPermisosManagment = props => {
    const { usersColumns, menusColumns, users, rows, selectedId, handleSelected, handleSelectPreset } =
        useContext(AsignacionPermisosContext)
    const { presets } = useContext(PermissionPresetsContext)
    const [filteredUsers, setFilteredUsers] = useState([])
    const classes = useStyles()
    const { search } = useContext(AdminFilterContext)

    useEffect(() => {
        handleSelected(null)
    }, [search])

    useEffect(() => {
        console.log(selectedId)
    },[selectedId])

    useEffect(() => {
        if (search.trim() !== '') {
            setFilteredUsers(
                users.filter(el => {
                    const keys = Object.keys(el)
                    let text = ''
                    keys.forEach((key, index) => {
                        if (key === 'name' || key === 'lastName') text += el[key]
                    })
                    // console.log(text)
                    return text.toLowerCase().indexOf(search.toLowerCase()) > -1
                })
            )
        } else {
            setFilteredUsers(users)
        }
    }, [search, users])

    return (
        <Grid container spacing={2} className={classes.filters} style={{ marginTop: 8 }}>
            <Grid item xs={12} md={6} llg={12} xl={6}>
                <Paper elevation={0} className={classes.paperGeneralCard}>
                    <AdminFilter isOnlySearch />
                    <TablaManagement
                        data={filteredUsers}
                        headCells={usersColumns}
                        sortDefault={'lastName'}
                        selectedId={selectedId}
                        handleSelected={handleSelected}
                    />
                </Paper>
            </Grid>
            <Grid item xs={12} md={6} llg={12} xl={6}>
                <Paper elevation={0} className={classes.paperGeneralCard}>
                    <div style={{ padding: 15 }}>
                        <Grid container spacing={2}>
                            {presets.map((preset, index) => {
                                return (
                                    <Grid key={index} item xs={12} md={6}>
                                        <ButtonVerTabla
                                            variant="outlined"
                                            color={preset.color}
                                            size="large"
                                            width={'100%'}
                                            text={preset.name}
                                            onClick={() => handleSelectPreset(preset)}
                                        />
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </div>
                    <TablaManagement
                        data={rows}
                        headCells={menusColumns}
                        sortDefault={'lastName'}
                        noSort={true}
                        noPagination={true}
                    />
                </Paper>
            </Grid>
        </Grid>
    )
}

export default AsignacionPermisosManagment
