/* React */
import React, { createContext, useContext, useEffect, useRef, useState } from 'react'

/* GraphQL */
import { useMutation } from '@apollo/client'
import { EDIT_USER } from '../../graphQL/users'

/* Snackbar */
import { useSnackbar } from 'notistack'

/* Context */
import { SalasContext, UsuariosContext } from './'
import { AuthContext } from '../AuthContext'

/* Components */
import { TableSwitch } from '../../components/administracion'

export const AsignacionSalasContext = createContext()

const AsignacionSalasContextProvider = props => {
    const { permissions, sociedades } = useContext(AuthContext)
    const { data: users } = useContext(UsuariosContext)
    const [selectedId, setSelectedId] = useState(null)
    const { data: salas } = useContext(SalasContext)
    const [sociedadesSalas, setSociedadesSalas] = useState([])
    const { enqueueSnackbar } = useSnackbar()

    const [editUser] = useMutation(EDIT_USER, {
        onCompleted(data) {
            if (data && data.editUser) {
                enqueueSnackbar('Asignación de sala realizada correctamente', {
                    variant: 'success',
                })
            }
        },
        onError(error) {
            enqueueSnackbar(error.message, { variant: 'error' })
        },
    })

    const sociedadesSalasRef = useRef([])

    const usersColumns = [
        { field: 'name', headerName: 'Nombres', sortable: true },
        { field: 'lastName', headerName: 'Apellidos', sortable: true },
    ]

    const sociedadesSalasColumns = [
        { field: 'name', headerName: 'Sociedades y Salas', sortable: false },
        {
            field: 'assign',
            headerName: 'Asignar',
            sortable: false,
            disablePadding: true,
            center: true,
        },
    ]

    const createData = (info, isSociedad, value) => {
        return {
            _id: info._id,
            name: isSociedad ? (
                <p style={{ margin: 0, fontWeight: 800 }}>
                    {info.masterCode} - {info.name}
                </p>
            ) : (
                <p style={{ margin: '0px 0px 0px 16px' }}>
                    {info.producerCode} - {info.name}
                </p>
            ),
            assign: switchAssign(info._id, value, isSociedad),
        }
    }

    useEffect(() => {
        sociedadesSalasRef.current = sociedadesSalas
    }, [sociedadesSalas])

    useEffect(() => {
        const findUser = users.find(user => user._id.toString() === selectedId)
        if (findUser) {
            const _parsed = []
            const varSociedades = sociedades ? [...sociedades] : []
            varSociedades
                .sort((a, b) => (a.masterCode < b.masterCode ? -1 : 1))
                .forEach(sociedad => {
                    if (findUser.sociedades?.find(sociedadInside => sociedadInside._id === sociedad._id)) {
                        const salasOfSociedad = salas.filter(sala => sala.sociedad?._id === sociedad._id)
                        const allSalas = salasOfSociedad.map(salaOfSociedad => {
                            const findSala = findUser.salas?.find(salaOfUser => salaOfUser.sala === salaOfSociedad._id)
                            return findSala?.enabled || false
                        })
                        const allSalasEnabled = allSalas.every(a => a)
                        _parsed.push(createData(sociedad, true, allSalasEnabled))
                        salasOfSociedad
                            .sort((a, b) => (a.producerCode < b.producerCode ? -1 : 1))
                            .forEach(sala => {
                                const findSalaPermission = findUser.salas?.find(salaUser => salaUser.sala === sala._id)
                                if (sala.sociedad?._id?.toString() === sociedad._id) {
                                    _parsed.push(createData(sala, false, findSalaPermission?.enabled || false))
                                }
                            })
                    }
                })
            setSociedadesSalas(_parsed)
        } else {
            const _parsed = []
            const varSociedades = sociedades ? [...sociedades] : []
            varSociedades
                .sort((a, b) => (a.masterCode < b.masterCode ? -1 : 1))
                .forEach(sociedad => {
                    _parsed.push(createData(sociedad, true, false))
                    const varSalas = salas ? [...salas] : []
                    varSalas
                        .sort((a, b) => (a.producerCode < b.producerCode ? -1 : 1))
                        .forEach(sala => {
                            if (sala.sociedad?._id?.toString() === sociedad._id) {
                                _parsed.push(createData(sala, false, false))
                            }
                        })
                })
            setSociedadesSalas(_parsed)
        }
    }, [sociedades, salas, users, selectedId])

    const switchAssign = (id, value, isSociedad) => {
        return (
            <TableSwitch
                name="estatus"
                checked={value}
                onChange={event => handleChangeSwitch(event, id, isSociedad)}
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
            />
        )
    }

    const handleChangeSwitch = (event, id, isSociedad) => {
        if (selectedId) {
            if (permissions?.administracion > 1) {
                if (isSociedad) {
                    const salasToSend = []
                    salas.forEach(sala => {
                        if (sala.sociedad._id === id) {
                            salasToSend.push({
                                sala: sala._id,
                                enabled: event.target.checked,
                            })
                        }
                    })
                    editUser({
                        variables: {
                            userId: selectedId,
                            salas: salasToSend,
                        },
                    })
                } else {
                    editUser({
                        variables: {
                            userId: selectedId,
                            salas: [
                                {
                                    sala: id,
                                    enabled: event.target.checked,
                                },
                            ],
                        },
                    })
                }
            }
        } else {
            const findIndex = sociedadesSalasRef.current.findIndex(sociedadSala => sociedadSala._id.toString() === id)
            const _parsed = [...sociedadesSalasRef.current]
            let sociedadSala = {}
            if (isSociedad) {
                sociedadSala = sociedades.find(sociedad => sociedad._id.toString() === id)
                salas.forEach(sala => {
                    if (sala.sociedad._id === id) {
                        const findSubIndex = sociedadesSalasRef.current.findIndex(
                            sociedadSala => sociedadSala._id.toString() === sala._id
                        )
                        _parsed[findSubIndex] = createData(sala, false, event.target.checked)
                    }
                })
            } else {
                sociedadSala = salas.find(sala => sala._id.toString() === id)
            }
            _parsed[findIndex] = createData(sociedadSala, isSociedad, event.target.checked)
            setSociedadesSalas(_parsed)
        }
    }

    const handleSelected = id => {
        setSelectedId(id)
    }

    return (
        <AsignacionSalasContext.Provider
            value={{
                usersColumns,
                sociedadesSalasColumns,
                users,
                sociedadesSalas,
                selectedId,
                handleSelected,
                sociedades
            }}
        >
            {props.children}
        </AsignacionSalasContext.Provider>
    )
}

export default AsignacionSalasContextProvider
