/* React */
import React, { useContext, useEffect, useState } from 'react'

/* Material UI */
import { Grid } from '@material-ui/core'

/* Context */
import { CalidadContext, FilterContext } from '../../contexts/produccion'

/* Components */
import { Filter, ProductoresList } from '../../components/produccion/filter'
import { CartaTablaGrafico } from '../../components/produccion'

const Calidad = props => {
    const {
        calidadData,
        queryServer,
        loading,
        maxValues,
        minValues,
        loadingGrasaCSV,
        loadingProteinaCSV,
        loadingUFCCSV,
        loadingRCSCSV,
        downloadCSV,
    } = useContext(CalidadContext)
    const { startTimeFilter, endTimeFilter, temporalidadFilter, predioFilter } = useContext(FilterContext)
    const [days, setDays] = useState(0)
    const [temporalidad, setTemporalidad] = useState(null)

    useEffect(() => {
        const diffTime = Math.abs(endTimeFilter - startTimeFilter)
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
        setDays(diffDays)
        queryServer(startTimeFilter, endTimeFilter)
    }, [startTimeFilter, endTimeFilter, temporalidadFilter, predioFilter])

    useEffect(() => {
        switch (temporalidadFilter) {
            case 'Semanal':
                setTemporalidad('semana')
                break
            case 'Quincenal':
                setTemporalidad('quincena')
                break
            case 'Mensual':
                setTemporalidad('mes')
                break
            case 'Anual':
                setTemporalidad('año')
                break
            default:
                setTemporalidad(null)
                break
        }
    }, [temporalidadFilter])

    return (
        <div>
            <Filter />
            <ProductoresList />
            <Grid container spacing={2} style={{ marginTop: 8 }}>
                <Grid item xs={12} sm={6}>
                    <CartaTablaGrafico
                        type="area"
                        title1="Grasa"
                        title2="Grasa"
                        subtitle1={
                            temporalidad
                                ? `Últim${
                                      temporalidad === 'año' || temporalidad === 'mes' ? 'o' : 'a'
                                  } ${temporalidad} vs el año anterior.`
                                : `Últimos ${days} días vs el año anterior`
                        }
                        subtitle2={
                            temporalidad
                                ? `Últim${temporalidad === 'año' || temporalidad === 'mes' ? 'o' : 'a'} ${temporalidad}`
                                : `Últimos ${days} días`
                        }
                        data={calidadData.grasa}
                        forecast={false}
                        buttons={true}
                        yLabel={'gr/Lt'}
                        downloadCSV={() => downloadCSV('grasa')}
                        loadingCSV={loadingGrasaCSV}
                        maxValue={maxValues.grasa}
                        minValue={minValues.grasa}
                        loading={loading}
                        view="Calidad"
                        decimalPlaces={2}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <CartaTablaGrafico
                        type="area"
                        title1="Proteína"
                        title2="Proteína"
                        subtitle1={
                            temporalidad
                                ? `Últim${
                                      temporalidad === 'año' || temporalidad === 'mes' ? 'o' : 'a'
                                  } ${temporalidad} vs el año anterior.`
                                : `Últimos ${days} días vs el año anterior`
                        }
                        subtitle2={
                            temporalidad
                                ? `Últim${temporalidad === 'año' || temporalidad === 'mes' ? 'o' : 'a'} ${temporalidad}`
                                : `Últimos ${days} días`
                        }
                        data={calidadData.proteina}
                        forecast={false}
                        buttons={true}
                        yLabel={'gr/Lt'}
                        downloadCSV={() => downloadCSV('proteina')}
                        loadingCSV={loadingProteinaCSV}
                        maxValue={maxValues.proteina}
                        minValue={minValues.proteina}
                        loading={loading}
                        view="Calidad"
                        decimalPlaces={2}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <CartaTablaGrafico
                        type="bar"
                        title1="RCS"
                        title2="RCS"
                        subtitle1={
                            temporalidad
                                ? `Últim${
                                      temporalidad === 'año' || temporalidad === 'mes' ? 'o' : 'a'
                                  } ${temporalidad} vs el año anterior.`
                                : `Últimos ${days} días vs el año anterior`
                        }
                        subtitle2={
                            temporalidad
                                ? `Últim${temporalidad === 'año' || temporalidad === 'mes' ? 'o' : 'a'} ${temporalidad}`
                                : `Últimos ${days} días`
                        }
                        data={calidadData.RCS}
                        forecast={false}
                        buttons={true}
                        yLabel={'células/mLt'}
                        downloadCSV={() => downloadCSV('RCS')}
                        loadingCSV={loadingRCSCSV}
                        loading={loading}
                        view="Calidad"
                        decimalPlaces={0}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <CartaTablaGrafico
                        type="bar"
                        title1="UFC"
                        title2="UFC"
                        subtitle1={
                            temporalidad
                                ? `Últim${
                                      temporalidad === 'año' || temporalidad === 'mes' ? 'o' : 'a'
                                  } ${temporalidad} vs el año anterior.`
                                : `Últimos ${days} días vs el año anterior`
                        }
                        subtitle2={
                            temporalidad
                                ? `Últim${temporalidad === 'año' || temporalidad === 'mes' ? 'o' : 'a'} ${temporalidad}`
                                : `Últimos ${days} días`
                        }
                        data={calidadData.UFC}
                        forecast={false}
                        buttons={true}
                        yLabel={'células/mLt'}
                        downloadCSV={() => downloadCSV('UFC')}
                        loadingCSV={loadingUFCCSV}
                        loading={loading}
                        view="Calidad"
                        decimalPlaces={0}
                    />
                </Grid>
            </Grid>
        </div>
    )
}

export default Calidad
