/*  React */
import React, { useContext, useState } from 'react'

/* GraphQL */
import { useMutation } from '@apollo/client'
import { MODIFY_SELF, SET_AVATAR } from '../graphQL/users'

/* Material UI */
import { Grid, makeStyles, Paper, InputBase, withStyles } from '@material-ui/core'

/* Snackbar */
import { useSnackbar } from 'notistack'

/* Context */
import { AuthContext } from '../contexts'

/* Components */
import { PrimaryButton } from '../components/comun'

const useStyles = makeStyles(theme => ({
    root: {
        padding: '50px 70px',
        [theme.breakpoints.down('md')]: {
            padding: '10px 20px',
        },
        borderRadius: 12,
    },
    current: {
        borderRadius: 12,
        padding: 10,
    },
    projection: {
        borderRadius: 12,
        padding: 20,
    },
}))

const BootstrapInput = withStyles(theme => ({
    root: {
        height: 50,
        // 'label + &': {
        // marginTop: 10,
        // marginBottom: 20,
        // },
    },
    input: {
        borderRadius: 12,
        backgroundColor: theme.palette.common.white,
        border: '1px solid #ced4da',
        fontSize: 16,
        fontWeight: 600,
        height: 30,
        padding: '10px 12px',
        '&:focus': {
            borderColor: theme.palette.primary.main,
        },
        '&:hover': {
            borderColor: theme.palette.common.black,
        },
    },
}))(InputBase)

const Profile = props => {
    const classes = useStyles()
    const [password1, setPassword1] = useState('')
    const [password2, setPassword2] = useState('')
    const { userInfo } = useContext(AuthContext)
    const [imgUpload, setImageUpload] = useState(null)
    const [modifyUser, setModifyUser] = useState({ ...userInfo })
    const { enqueueSnackbar } = useSnackbar()

    const [modifySelf] = useMutation(MODIFY_SELF, {
        onCompleted(data) {
            if (data?.modifySelf?.message?.includes('Refrescar')) {
                return false
            }
            if (data && data.modifySelf) {
                enqueueSnackbar(data.modifySelf.message, { variant: 'success' })
                setPassword1('')
                setPassword2('')
            }
        },
        onError(error) {
            enqueueSnackbar(error.message, { variant: 'error' })
        },
    })

    const [setAvatar] = useMutation(SET_AVATAR, {
        onCompleted(data) {
            if (data?.setAvatar?.message?.includes('Refrescar')) {
                return false
            }
        },
    })

    const handleSubmitPassword = event => {
        event.preventDefault()
        if (password1 === '' || password2 === '') {
            return enqueueSnackbar('Debe rellenar ambos campos.', {
                variant: 'error',
            })
        }
        if (password1 !== password2) {
            return enqueueSnackbar('Ambos campos deben coincidir.', {
                variant: 'error',
            })
        }
        modifySelf({
            variables: {
                password: password1,
            },
        })
    }

    const handleSubmitProfile = event => {
        event.preventDefault()
        modifySelf({
            variables: modifyUser,
        })
    }

    const handleImage = event => {
        if (event.target && event.target.files[0]) {
            const img = event.target.files[0]
            if (img.size > 500000) {
                setImageUpload(null)
                return enqueueSnackbar('La imagen no debe superar los 500KB.', {
                    variant: 'error',
                })
            }
            setImageUpload({ file: img, validity: event.target.validity })
        } else {
            setImageUpload(null)
        }
    }

    const handleSubmitFile = event => {
        event.preventDefault()
        if (!imgUpload) {
            return enqueueSnackbar('Debe seleccionar una imagen.', {
                variant: 'error',
            })
        }
        setAvatar({
            variables: {
                file: imgUpload.file,
            },
        })
    }

    return (
        <div>
            <Grid container style={{ marginTop: 8 }}>
                <Grid item xs={12} md={9}>
                    <Paper elevation={0} className={classes.root}>
                        <form onSubmit={handleSubmitPassword}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <div style={{ display: 'inline-block' }}>
                                        <span
                                            style={{
                                                display: 'block',
                                                color: '#292929',
                                                fontSize: 32,
                                                fontWeight: 800,
                                            }}
                                        >
                                            Cambiar tu clave
                                        </span>
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <div style={{ marginTop: 15, marginBottom: 10 }}>
                                        <span
                                            style={{
                                                fontSize: 18,
                                                fontWeight: 'bold',
                                                color: '#737373',
                                            }}
                                        >
                                            Contraseña
                                        </span>
                                    </div>
                                    <BootstrapInput
                                        fullWidth
                                        value={password1}
                                        placeholder="*************"
                                        onChange={event => setPassword1(event.target.value)}
                                        type="password"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <div style={{ marginTop: 15, marginBottom: 10 }}>
                                        <span
                                            style={{
                                                fontSize: 18,
                                                fontWeight: 'bold',
                                                color: '#737373',
                                            }}
                                        >
                                            Repetir Contraseña
                                        </span>
                                    </div>
                                    <BootstrapInput
                                        fullWidth
                                        value={password2}
                                        placeholder="*************"
                                        onChange={event => setPassword2(event.target.value)}
                                        type="password"
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} style={{ marginTop: 30 }} justifyContent="center">
                                <Grid item>
                                    <PrimaryButton style={{ width: 250 }} type="submit">
                                        Cambiar contraseña
                                    </PrimaryButton>
                                </Grid>
                            </Grid>
                        </form>
                    </Paper>
                    <Paper elevation={0} className={classes.root} style={{ marginTop: 16 }}>
                        <form onSubmit={handleSubmitProfile}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <div style={{ display: 'inline-block' }}>
                                        <span
                                            style={{
                                                display: 'block',
                                                color: '#292929',
                                                fontSize: 32,
                                                fontWeight: 800,
                                            }}
                                        >
                                            Editar tu perfil
                                        </span>
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <div style={{ marginTop: 15, marginBottom: 10 }}>
                                        <span
                                            style={{
                                                fontSize: 18,
                                                fontWeight: 'bold',
                                                color: '#737373',
                                            }}
                                        >
                                            Nombres
                                        </span>
                                    </div>
                                    <BootstrapInput
                                        fullWidth
                                        value={modifyUser.name}
                                        // placeholder='Nombres'
                                        onChange={event => setModifyUser({ ...modifyUser, name: event.target.value })}
                                        type="text"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <div style={{ marginTop: 15, marginBottom: 10 }}>
                                        <span
                                            style={{
                                                fontSize: 18,
                                                fontWeight: 'bold',
                                                color: '#737373',
                                            }}
                                        >
                                            Apellidos
                                        </span>
                                    </div>
                                    <BootstrapInput
                                        fullWidth
                                        value={modifyUser.lastName}
                                        // placeholder='Apellidos'
                                        onChange={event =>
                                            setModifyUser({
                                                ...modifyUser,
                                                lastName: event.target.value,
                                            })
                                        }
                                        type="text"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <div style={{ marginTop: 15, marginBottom: 10 }}>
                                        <span
                                            style={{
                                                fontSize: 18,
                                                fontWeight: 'bold',
                                                color: '#737373',
                                            }}
                                        >
                                            RUT
                                        </span>
                                    </div>
                                    <BootstrapInput
                                        fullWidth
                                        value={modifyUser.RUT}
                                        placeholder="12345678-9"
                                        onChange={event => setModifyUser({ ...modifyUser, RUT: event.target.value })}
                                        type="text"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <div style={{ marginTop: 15, marginBottom: 10 }}>
                                        <span
                                            style={{
                                                fontSize: 18,
                                                fontWeight: 'bold',
                                                color: '#737373',
                                            }}
                                        >
                                            Teléfono
                                        </span>
                                    </div>
                                    <BootstrapInput
                                        fullWidth
                                        value={modifyUser.phone}
                                        placeholder="+56XXXXXXXXX"
                                        onChange={event =>
                                            setModifyUser({
                                                ...modifyUser,
                                                phone: event.target.value,
                                            })
                                        }
                                        type="text"
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} style={{ marginTop: 30 }} justifyContent="center">
                                <Grid item>
                                    <PrimaryButton style={{ width: 250 }} type="submit">
                                        Guardar cambios
                                    </PrimaryButton>
                                </Grid>
                            </Grid>
                        </form>
                    </Paper>
                    <Paper elevation={0} className={classes.root} style={{ marginTop: 16, marginBottom: 16 }}>
                        <form onSubmit={handleSubmitFile}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <div style={{ display: 'inline-block' }}>
                                        <span
                                            style={{
                                                display: 'block',
                                                color: '#292929',
                                                fontSize: 32,
                                                fontWeight: 800,
                                            }}
                                        >
                                            Cambiar foto de perfil
                                        </span>
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <div style={{ marginTop: 15, marginBottom: 10 }}>
                                        <span
                                            style={{
                                                fontSize: 18,
                                                fontWeight: 'bold',
                                                color: '#737373',
                                            }}
                                        >
                                            Subir imagen
                                        </span>
                                    </div>
                                    <BootstrapInput
                                        fullWidth
                                        // placeholder='Nombres'
                                        {...(imgUpload === null && { value: '' })}
                                        onChange={handleImage}
                                        type="file"
                                        inputProps={{
                                            accept: '.jpg,.jpeg,.png',
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} style={{ marginTop: 30 }} justifyContent="center">
                                <Grid item>
                                    <PrimaryButton style={{ width: 250 }} type="submit">
                                        Subir imagen
                                    </PrimaryButton>
                                </Grid>
                            </Grid>
                        </form>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    )
}

export default Profile
