/* React */
import React, { createContext, useContext, useEffect, useState } from 'react'

/* GraphQL */
import { useLazyQuery, gql } from '@apollo/client'

/* Context */
import { FilterContext } from './FilterContext'
/* import { useSnackbar } from 'notistack' */

export const DataContext = createContext()

const METRICS = gql`
    query Metrics($fechaInicio: String!, $fechaFin: String!) {
        metrics(fechaInicio: $fechaInicio, fechaFin: $fechaFin) {
            resultSAP
            metrics {
                FECHA_AV
                CAMION
                N_GUIA
                QMEL_LIFNR
                CANTIDAD
                GRASA
                GRASA_GL
                PROTEINAS
                PROTEINAS_GL
                CELULAS
                BACTOSCAN
                UREA
            }
        }
    }
`

const DataContextProvider = props => {
    const [dataNew, setDataNew] = useState([])
    const [dataOld, setDataOld] = useState([])
    const [errorMessage, setErrorMessage] = useState(null)
    /* const { enqueueSnackbar } = useSnackbar() */
    const { predioFilter } = useContext(FilterContext)
    const [getDataNew, { loading: loadingNew, error: errorNew, data: dataReceivedNew }] = useLazyQuery(METRICS, {
        fetchPolicy: 'cache-and-network',
        notifyOnNetworkStatusChange: true,
    })
    const [getDataOld, { loading: loadingOld, error: errorOld, data: dataReceivedOld }] = useLazyQuery(METRICS, {
        fetchPolicy: 'cache-and-network',
        notifyOnNetworkStatusChange: true,
    })

    const queryServerDate = (fechaInicio, fechaFin) => {
        const newStart = new Date(fechaInicio)
        const newEnd = new Date(fechaFin)
        const oldStart = new Date(fechaInicio)
        const oldEnd = new Date(fechaFin)

        oldStart.setFullYear(oldStart.getFullYear() - 1)
        oldEnd.setFullYear(oldEnd.getFullYear() - 1)

        getDataNew({
            variables: {
                fechaInicio: newStart.toString(),
                fechaFin: newEnd.toString(),
            },
        })
        getDataOld({
            variables: {
                fechaInicio: oldStart.toString(),
                fechaFin: oldEnd.toString(),
            },
        })
    }

    const queryServerYear = year => {
        queryServerDate(year + '-04-28 12:00:00', year + 1 + '-02-02 12:00:00')
    }

    useEffect(() => {
        if (dataReceivedNew?.metrics?.resultSAP === false && !errorMessage) {
            setErrorMessage(true)
        } else if (dataReceivedNew?.metrics?.resultSAP === true && errorMessage) {
            setErrorMessage(false)
        }
        if (dataReceivedNew?.metrics?.metrics?.length >= 0) {
            const filtered = dataReceivedNew.metrics.metrics.filter(data => predioFilter.includes(data.QMEL_LIFNR))
            setDataNew(filtered)
        }
        if (dataReceivedOld?.metrics?.metrics?.length >= 0) {
            const filtered = dataReceivedOld.metrics.metrics.filter(data => predioFilter.includes(data.QMEL_LIFNR))
            setDataOld(filtered)
        }
    }, [dataReceivedNew, dataReceivedOld, predioFilter])

    useEffect(() => {
        if (errorMessage === false) {
            /* enqueueSnackbar('Conexión con SAP reestablecida', { variant: 'success' }) */
        } else if (errorMessage) {
            /* enqueueSnackbar('Conexión con SAP caída, datos mostrados pueden no ser los más actuales', {
                variant: 'error',
            }) */
        }
    }, [errorMessage])

    return (
        <DataContext.Provider
            value={{
                dataNew,
                dataOld,
                queryServerDate,
                queryServerYear,
                errorMessage,
                setErrorMessage,
                loadingNew,
                errorNew,
                loadingOld,
                errorOld,
            }}
        >
            {props.children}
        </DataContext.Provider>
    )
}

export default DataContextProvider
