/* Material UI */
import { createTheme } from '@material-ui/core/styles'
import { esES } from '@material-ui/core/locale'

const theme = createTheme(
    {
        overrides: {
            MuiCssBaseline: {
                '@global': {
                    '*': {
                        'scrollbar-width': 'thin',
                    },
                    '*::-webkit-scrollbar': {
                        width: '8px',
                        height: '12px',
                    },
                    '*::-webkit-scrollbar-thumb': {
                        borderRadius: 10,
                        backgroundColor: '#d8d8d8',
                    },
                    '*::-webkit-scrollbar-track': {
                        backgroundColor: 'white',
                        borderRadius: 10,
                    },
                    '.MuiPaper-root': {
                        color: '#5e7490',
                    },
                },
            },
        },
        // shadows: ["none"],
        typography: {
            fontFamily: 'Open Sans',
        },
        palette: {
            background: { default: '#f3f3f3' },
            primary: {
                main: '#3383ee',
            },
            secondary: {
                main: '#303c4b',
            },
        },
        titleColor: '#00539b',
        subTitleColor: '#7e8792',
        drawer: {
            collapseBgColor: '#f3f3f3',
            nestedTextColor: '#5e7490',
            buttonMenuHoverColor: '#f9f9f9',
        },
    },
    esES
)

export default theme
