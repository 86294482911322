function descendingComparator(a, b, orderBy) {
    if (b[orderBy].toString().toLowerCase() < a[orderBy].toString().toLowerCase()) {
        return -1
    }
    if (b[orderBy].toString().toLowerCase() > a[orderBy].toString().toLowerCase()) {
        return 1
    }
    return 0
}

export function getComparator(sort, orderBy) {
    return sort === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy)
}

export function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index])
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0])
        if (order !== 0) return order
        return a[1] - b[1]
    })
    return stabilizedThis.map(el => el[0])
}
