/* React */
import React from 'react'

/* Material UI */
import { Hidden, useTheme, CssBaseline, Paper, Grid, makeStyles } from '@material-ui/core'

/* Components */
import { LoginForm } from '../components/login'
import BgImage from '../bg_login.jpg'

const useStyles = makeStyles(theme => ({
    root: {
        height: '100vh',
        [theme.breakpoints.down('xs')]: {
            padding: '48px 14px',
        },
        [theme.breakpoints.up('sm')]: {
            padding: '48px 18%',
        },
        [theme.breakpoints.up('lg')]: {
            padding: '96px 18%',
        },
    },
    bgImage: {
        background: `linear-gradient(rgba(255,255,255,.8), rgba(255,255,255,.8)), url(${BgImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: '1000px',
    },
    loginImage: {
        backgroundImage: `url(${BgImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    paper: {
        borderTopLeftRadius: theme.spacing(2),
        borderBottomLeftRadius: theme.spacing(2),
    },
    divPaper: {
        margin: '64px 18%',
        // display: 'flex',
        // flexDirection: 'column',
    },
    paperMobil: {
        // height: '100%',
        borderRadius: 12,
    },
    paperMobilContent: {
        padding: theme.spacing(4, 4),
    },
}))

const Login = () => {
    const theme = useTheme()
    const classes = useStyles()

    return (
        <div className={classes.bgImage}>
            <CssBaseline />
            <Hidden mdDown>
                <Grid container component="main" className={classes.root}>
                    <Grid item xs={6} component={Paper} elevation={0} square className={classes.paper}>
                        <div className={classes.divPaper}>
                            <LoginForm />
                        </div>
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        className={classes.loginImage}
                        style={{
                            borderTopRightRadius: theme.spacing(2),
                            borderBottomRightRadius: theme.spacing(2),
                        }}
                    />
                </Grid>
            </Hidden>
            <Hidden lgUp>
                <div className={classes.root}>
                    <Paper elevation={0} className={classes.paperMobil}>
                        <div
                            className={classes.loginImage}
                            style={{
                                width: '100%',
                                height: '20%',
                                borderTopLeftRadius: theme.spacing(2),
                                borderTopRightRadius: theme.spacing(2),
                            }}
                        />
                        <div className={classes.paperMobilContent}>
                            <LoginForm />
                        </div>
                    </Paper>
                </div>
            </Hidden>
        </div>
    )
}

export default Login
