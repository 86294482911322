/* React */
import React from 'react'

/* Material UI */
import { SvgIcon } from '@material-ui/core'

const UploadInfoIcon = props => (
    <SvgIcon {...props} viewBox="0 0 23.458 28.571">
        <g transform="translate(-1056.563 -397.766)">
            <rect width="20.523" height="2.656" transform="translate(1058.03 397.766)" />
            <path
                d="M1080.021,425.668l-11.729-11.729-11.729,11.729,1.878,1.878,8.523-8.523v18.118h2.656V419.023l8.523,8.523Z"
                transform="translate(0 -10.804)"
            />
        </g>
    </SvgIcon>
)

export default UploadInfoIcon
