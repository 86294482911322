/* React */
import React from 'react'

/* Material UI */
import { SvgIcon } from '@material-ui/core'

const WeatherIcon = props => (
    <SvgIcon {...props} viewBox="0 0 46.712 35.387">
        <g transform="translate(-659.116 -377.269)">
            <path
                d="M698.285,413.767a9.863,9.863,0,0,0-19.222-3.111c-.191-.01-.382-.021-.571-.021a10.331,10.331,0,0,0-9.744,6.793,7.432,7.432,0,1,0-2.2,14.531h23.965a7.43,7.43,0,0,0,5.684-12.126A9.8,9.8,0,0,0,698.285,413.767Zm-9.862-7.662a7.662,7.662,0,0,1,6.142,12.24,7.433,7.433,0,0,0-6.331-.916,10.492,10.492,0,0,0-6.963-6.416A7.725,7.725,0,0,1,688.423,406.1Zm5.73,22.1a5.2,5.2,0,0,1-3.652,1.549H666.548a5.231,5.231,0,1,1,0-10.461,5.143,5.143,0,0,1,2.4.584l1.258.655.322-1.381a8.143,8.143,0,0,1,7.965-6.317,8.5,8.5,0,0,1,1.22.09,8.235,8.235,0,0,1,6.747,6.227l.323,1.381,1.258-.655a5.145,5.145,0,0,1,2.4-.584,5.218,5.218,0,0,1,3.536,1.375h0a5.255,5.255,0,0,1,1.694,3.854A5.2,5.2,0,0,1,694.154,428.208Z"
                transform="translate(0 -19.303)"
            />
            <path
                d="M762.413,383.6a1.1,1.1,0,0,0,1.1-1.1v-4.13a1.1,1.1,0,1,0-2.2,0v4.13A1.1,1.1,0,0,0,762.413,383.6Z"
                transform="translate(-74.068 0)"
            />
            <path
                d="M733.763,390.472a1.1,1.1,0,1,0,1.906-1.1l-2.065-3.577a1.1,1.1,0,1,0-1.907,1.1Z"
                transform="translate(-52.498 -5.78)"
            />
            <path
                d="M710.314,409.085l3.577,2.065a1.1,1.1,0,0,0,1.1-1.907l-3.577-2.065a1.1,1.1,0,0,0-1.1,1.907Z"
                transform="translate(-36.708 -21.57)"
            />
            <path
                d="M805.092,461.262l-3.577-2.065a1.1,1.1,0,0,0-1.1,1.907l3.577,2.065a1.1,1.1,0,0,0,1.1-1.907Z"
                transform="translate(-102.009 -59.272)"
            />
            <path
                d="M811.06,436.791h-4.13a1.1,1.1,0,0,0,0,2.2h4.13a1.1,1.1,0,0,0,0-2.2Z"
                transform="translate(-106.333 -43.139)"
            />
            <path
                d="M800.966,411.3a1.1,1.1,0,0,0,.549-.148l3.577-2.065a1.1,1.1,0,0,0-1.1-1.907l-3.577,2.065a1.1,1.1,0,0,0,.552,2.055Z"
                transform="translate(-102.009 -21.57)"
            />
            <path
                d="M784.12,390.875a1.1,1.1,0,0,0,1.5-.4l2.065-3.577a1.1,1.1,0,1,0-1.907-1.1l-2.065,3.577A1.1,1.1,0,0,0,784.12,390.875Z"
                transform="translate(-90.2 -5.78)"
            />
        </g>
    </SvgIcon>
)

export default WeatherIcon
