/* React */
import React, { useContext, useEffect, useState } from 'react'

/* Material UI */
import { Select } from '@material-ui/core'

/* Context */
import { AuthContext } from '../../../contexts'
import { SalasContext } from '../../../contexts/administracion'

const PredioFilterOnlyOne = ({ selected, setSelected }) => {
    const { sociedades, salas: salasPermission } = useContext(AuthContext)
    const { data: salas } = useContext(SalasContext)
    const [sociedadSala, setSociedadSala] = useState([])

    useEffect(() => {
        if (sociedades?.length >= 0) {
            const data_ = []
            sociedades.forEach(sociedad => {
                const salas_ = salas
                    .filter(sala => sala.sociedad._id === sociedad._id)
                    .filter(
                        sala =>
                            salasPermission.findIndex(
                                salaPermission => salaPermission.sala === sala._id && salaPermission.enabled
                            ) > -1
                    )
                if (salas_?.length > 0) {
                    const newSociedad = {
                        name: sociedad.name,
                        _id: sociedad._id,
                        masterId: sociedad._id,
                        code: sociedad.masterCode,
                        salas: [],
                    }
                    newSociedad.salas = salas_
                        .map(sala => ({
                            name: sala.name,
                            _id: sala._id,
                            masterId: sala.sociedad._id,
                            code: sala.producerCode,
                        }))
                        .sort((a, b) => (a.code > b.code ? 1 : -1))
                    data_.push(newSociedad)
                }
            })
            setSociedadSala(data_.sort((a, b) => (a.code > b.code ? 1 : -1)))
        }
    }, [sociedades, salas, salasPermission])

    return (
        <Select
            id="PredioOnlyFilter"
            native
            value={selected}
            onChange={event => setSelected(event.target.value)}
            fullWidth
        >
            <option aria-label="None" value="" />
            {sociedadSala.map((sociedad, index) => {
                return (
                    <optgroup key={index} label={sociedad.code}>
                        {sociedad.salas.map((sala, index2) => {
                            return (
                                <option key={index2} value={sala._id}>
                                    {sala.code}
                                </option>
                            )
                        })}
                    </optgroup>
                )
            })}
        </Select>
    )
}

export default PredioFilterOnlyOne
