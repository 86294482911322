/* React */
import React, { useContext } from 'react'

/* Material UI */
import { MenuItem, Select } from '@material-ui/core'

/* Context */
import { FilterContext } from '../../../contexts/produccion'
// import { AuthContext } from '../../../contexts'

const TemporalidadFilter = props => {
    // const { lastDate } = useContext(AuthContext)
    const { temporalidadFilter, setTemporalidadFilter, setStartTimeFilter, setEndTimeFilter } =
        useContext(FilterContext)

    const handleChangeTemporalidad = event => {
        const value = event.target.value
        setTemporalidadFilter(value)
        // setear fechas inicio termino segun temporalidad
        // const endDate = new Date(currentDate)
        // const startDate = new Date(lastDate)
        const endDate = new Date()
        endDate.setHours(14, 59, 59, 999)
        const startDate = new Date()
        startDate.setHours(0, 0, 0, 0)

        setEndTimeFilter(endDate)
        if (value === 'Semanal') {
            startDate.setDate(startDate.getDate() - 6)
            setStartTimeFilter(startDate)
        } else if (value === 'Quincenal') {
            startDate.setDate(startDate.getDate() - 14)
            setStartTimeFilter(startDate)
        } else if (value === 'Mensual') {
            startDate.setDate(startDate.getDate() - 29)
            setStartTimeFilter(startDate)
        } else if (value === 'Anual') {
            startDate.setDate(startDate.getDate() - 364)
            setStartTimeFilter(startDate)
        }
    }

    return (
        <Select
            value={temporalidadFilter}
            onChange={handleChangeTemporalidad}
            MenuProps={{
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                },
                getContentAnchorEl: null,
            }}
            renderValue={selected => {
                if (selected.length === 0) {
                    return <span>Temporalidad</span>
                }

                return selected
            }}
            fullWidth
            displayEmpty
        >
            <MenuItem value={'Semanal'}>Semana</MenuItem>
            <MenuItem value={'Quincenal'}>Quincena</MenuItem>
            <MenuItem value={'Mensual'}>Mes</MenuItem>
            <MenuItem value={'Anual'}>Año</MenuItem>
        </Select>
    )
}

export default TemporalidadFilter
