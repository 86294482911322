/* React */
import React from 'react'

/* Material UI */
import { FormControl, Grid, makeStyles } from '@material-ui/core'

/* Components */
import { PredioFilter, YearFilter } from './'

const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1, 'auto'),
        '& .MuiInputBase-input': {
            background: 'white',
            fontSize: 16,
            // fontFamily: 'Open Sans',
            fontWeight: 600,
            borderRadius: 12,
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: 12,
        },
        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            borderWidth: 0,
        },
        // '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
        //     borderWidth: 1
        // },
        // '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
        //     borderWidth: 2
        // }
        // '& .MuiInputBase-input .MuiMenu-paper .MuiPopover-paper .MuiPaper-elevation8': {
        //     boxShadow: ''
        // }
    },
}))

const InviernoVeranoFilter = props => {
    const classes = useStyles()

    return (
        <Grid container spacing={2} style={{ marginTop: 8 }}>
            <Grid item xs={6} sm={3}>
                <FormControl fullWidth variant="outlined" className={classes.formControl}>
                    <PredioFilter />
                </FormControl>
            </Grid>
            <Grid item xs={6} sm={3}>
                <FormControl fullWidth variant="outlined" className={classes.formControl}>
                    <YearFilter />
                </FormControl>
            </Grid>
        </Grid>
    )
}

export default InviernoVeranoFilter
