/* React */
import React, { Fragment, useCallback, useContext, useEffect, useState } from 'react'

/* Material UI */
import {
    Button,
    Divider,
    FormControl,
    Grid,
    IconButton,
    InputBase,
    makeStyles,
    Paper,
    TextField,
    Tooltip,
    withStyles,
} from '@material-ui/core'
import { Add, Clear, Info, LocationOn } from '@material-ui/icons'
import { Autocomplete } from '@material-ui/lab'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'

/* Snackbar */
// import { useSnackbar } from 'notistack'

/* Context */
import { ForrajeContext } from '../../contexts/forraje'
import { AuthContext } from '../../contexts'

/* Components */
import { CartaForraje } from '../../components/forraje'
import { OutlineButton, PrimaryButton } from '../../components/comun'

/* Google Maps */
import GoogleMapReact, { fitBounds } from 'google-map-react'

/* Utilities */
import DateFnsUtils from '@date-io/date-fns'
import esLocale from 'date-fns/locale/es'
import 'date-fns'

const useStyles = makeStyles(theme => ({
    paperGeneralCard: {
        borderRadius: 12,
        '& .MuiDataGrid-root': {
            border: 0,
            // borderRadius: 12
        },
        marginTop: 10,
        marginBottom: 10,
    },
    root: {
        padding: '50px 70px',
        [theme.breakpoints.down('md')]: {
            padding: '10px 20px',
        },
    },
    title: {
        marginBottom: 35,
        [theme.breakpoints.down('md')]: {
            marginBottom: 0,
        },
    },
    formControl: {
        '& .MuiInputBase-root': {
            height: 52,
        },
        '& .MuiInputBase-input': {
            display: 'flex',
            alignItems: 'center',
            borderRadius: 12,
            backgroundColor: theme.palette.common.white,
            border: 0,
            fontSize: 16,
            fontWeight: 600,
            height: 32,
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: 12,
        },
        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            borderRadius: 12,
            borderWidth: 1,
        },
    },
    autocomplete: {
        '& .MuiInputBase-input': {
            display: 'flex',
            alignItems: 'center',
            borderRadius: 12,
            backgroundColor: theme.palette.common.white,
            border: 0,
            fontSize: 16,
            fontWeight: 600,
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: 12,
        },
        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            borderRadius: 12,
            borderWidth: 1,
        },
    },
    button: {
        // margin: theme.spacing(2, 'auto'),
        // padding: theme.spacing(2),
        borderRadius: 12,
        WebkitBoxShadow: 'none',
        '&:hover': {
            WebkitBoxShadow: 'none',
        },
    },
    projection: {
        borderRadius: 12,
        '& .MuiDataGrid-root': {
            border: 0,
            // borderRadius: 12
        },
        marginTop: 10,
        marginBottom: 10,
    },
    addCard: {
        overflow: 'hidden',
        borderRadius: 12,
        height: 248.67,
        borderWidth: 7,
        borderColor: '#3383ee',
        borderStyle: 'solid',
    },
}))

const BootstrapInput = withStyles(theme => ({
    root: {
        height: 50,
    },
    input: {
        borderRadius: 12,
        backgroundColor: theme.palette.common.white,
        border: '1px solid #ced4da',
        fontSize: 16,
        fontWeight: 600,
        height: 30,
        padding: '10px 12px',
        '&:focus': {
            borderColor: theme.palette.primary.main,
        },
        '&:hover': {
            borderColor: theme.palette.common.black,
        },
    },
}))(InputBase)

const CustomAutocomplete = ({ placeHoder, items, value, onChange }) => {
    const classes = useStyles()

    return (
        <Autocomplete
            className={classes.autocomplete}
            multiple
            options={items.slice().sort((a, b) => -b.name.localeCompare(a.name))}
            getOptionLabel={option => option.name}
            onChange={(event, options) => {
                onChange(options)
            }}
            value={value || []}
            filterSelectedOptions
            getOptionSelected={(option, value) => option._id === value._id && option.name === value.name}
            renderInput={params => <TextField {...params} variant="outlined" label="Buscar" />}
        />
    )
}

const CustomDatePicker = ({ placeHoder, value, onChange }) => {
    const classes = useStyles()

    return (
        <FormControl fullWidth variant="outlined" className={classes.formControl}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                <KeyboardDatePicker
                    variant="inline"
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    label="Fecha"
                    value={value}
                    onChange={onChange}
                    fullWidth
                    disableFuture
                    autoOk
                />
            </MuiPickersUtilsProvider>
        </FormControl>
    )
}

const Marker = ({ locacion, cantidad, direction }) => {
    const size = Math.min(Math.max(0.02 * cantidad + 18, 20), 200)
    return (
        <Tooltip
            arrow
            placement="top"
            title={
                <div style={{ padding: 5, maxWidth: 200, textAlign: 'center' }}>
                    <div style={{ fontSize: 20, marginBottom: 10 }}>{locacion}</div>
                    <div style={{ fontSize: 14, marginBottom: 10 }}>{direction}</div>
                    <Divider style={{ backgroundColor: 'rgba(255, 255, 255, 0.12)' }} />
                    <div style={{ fontSize: 14, marginBottom: 10, marginTop: 10 }}>Materia Seca:</div>
                    <div
                        style={{
                            fontSize: 18,
                            marginBottom: 5,
                            textAlign: 'center',
                            fontWeight: 'bold',
                        }}
                    >
                        {cantidad} Kg.
                    </div>
                </div>
            }
        >
            {/* <LocationOn fontSize='large' style={{ color: '#de4028', transform: 'translateZ(0) translate(-50%, -100%)' }}/> */}
            <div
                style={{
                    transform: 'translateZ(0) translate(-50%, -50%)',
                    width: size,
                    height: size,
                    borderRadius: '50%',
                    backgroundColor: 'rgba(184, 134, 11, 0.4)',
                    display: 'inline-block',
                }}
            ></div>
        </Tooltip>
    )
}
const SalaMarker = ({ producerCode, direction }) => {
    return (
        <Tooltip
            arrow
            placement="top"
            title={
                <div style={{ padding: 5, maxWidth: 200, textAlign: 'center' }}>
                    <div style={{ fontSize: 20, marginBottom: 10, fontWeight: 'bold' }}>{producerCode}</div>
                    <div style={{ fontSize: 14, marginBottom: 10 }}>{direction}</div>
                </div>
            }
        >
            <LocationOn
                fontSize="large"
                style={{
                    color: 'blue',
                    transform: 'translateZ(0) translate(-50%, -100%)',
                }}
            />
        </Tooltip>
    )
}

const places = [
    {
        GPS: {
            lat: -40.2725,
            lon: -73.5353,
        },
        name: 'Osorno 2',
        cantidad: 1000,
        direction: 'Avenida de Prueba, 11103',
    },
    {
        GPS: {
            lat: -40.5725,
            lon: -73.1353,
        },
        name: 'Osorno',
        cantidad: 11002,
        direction: 'Avenida de Prueba, 11103',
    },
    {
        GPS: {
            lat: -40.1725,
            lon: -73.4353,
        },
        name: 'Osorno 3',
        cantidad: 100,
        direction: 'Avenida de Prueba, 11103',
    },
]
const Forraje = props => {
    const classes = useStyles()
    const [dimensions, setDimensions] = useState({ height: null, width: null })
    const mapRef = useCallback(node => {
        if (node !== null) {
            const dimensions_ = node.getBoundingClientRect()
            setDimensions({ height: dimensions_.height, width: dimensions_.width })
        }
    }, [])
    const {
        fields,
        isCreate,
        setIsCreate,
        handleChangeTextInput,
        handleChangeDryMatter,
        addDryMatter,
        deleteDryMatter,
        handleImage,
        handleSubmitData,
        parsedSalas,
        parsedForrajes,
    } = useContext(ForrajeContext)

    const { mapKey, sociedades, permissions } = useContext(AuthContext)
    const [seeMap, setSeeMap] = useState(false)
    const [centerPosition, setCenterPosition] = useState({
        lat: -40.5725,
        lng: -73.1353,
    })
    const [zoom, setZoom] = useState(8)

    useEffect(() => {
        if (parsedSalas?.length > 0) {
            const nw = { lat: -1000, lng: 1000 }
            const se = { lat: 1000, lng: -1000 }

            parsedSalas.forEach(sala => {
                if (sala.GPS.lat > nw.lat) {
                    nw.lat = sala.GPS.lat
                }
                if (sala.GPS.lat < se.lat) {
                    se.lat = sala.GPS.lat
                }
                if (sala.GPS.lon < nw.lng) {
                    nw.lng = sala.GPS.lon
                }
                if (sala.GPS.lon > se.lng) {
                    se.lng = sala.GPS.lon
                }
            })
            places.forEach(place => {
                if (place.GPS.lat > nw.lat) {
                    nw.lat = place.GPS.lat
                }
                if (place.GPS.lat < se.lat) {
                    se.lat = place.GPS.lat
                }
                if (place.GPS.lon < nw.lng) {
                    nw.lng = place.GPS.lon
                }
                if (place.GPS.lon > se.lng) {
                    se.lng = place.GPS.lon
                }
            })
            const { center, zoom } = fitBounds({ nw, se }, dimensions)
            setCenterPosition(center)
            setZoom(zoom)
        }
    }, [dimensions, parsedSalas])

    return (
        <Fragment>
            {!isCreate ? (
                <div>
                    <Grid container spacing={2} style={{ marginTop: 8 }}>
                        <Grid item xs={12} sm={6} lg={6} xl={4}>
                            <Button
                                color="primary"
                                className={classes.button}
                                variant="contained"
                                size="large"
                                style={{ width: '100%' }}
                                onClick={() => setSeeMap(!seeMap)}
                            >
                                {seeMap ? 'Ver Tarjetas' : 'Ver Mapa'}
                            </Button>
                        </Grid>
                    </Grid>
                    {seeMap ? (
                        <Paper elevation={0} className={classes.projection}>
                            <div ref={mapRef} style={{ width: '100%', height: 700 }}>
                                <GoogleMapReact
                                    bootstrapURLKeys={{ key: mapKey }}
                                    defaultCenter={centerPosition}
                                    defaultZoom={zoom}
                                >
                                    {parsedForrajes
                                        .sort((a, b) => (a.cantidad < b.cantidad ? 1 : -1))
                                        .map((place, index) => {
                                            return (
                                                <Marker
                                                    key={index}
                                                    lat={place.GPS.lat}
                                                    lng={place.GPS.lon}
                                                    locacion={place.locacion}
                                                    cantidad={place.cantidad}
                                                    direction={place.direction}
                                                />
                                            )
                                        })}
                                    {parsedSalas.map((sala, index) => {
                                        return (
                                            <SalaMarker
                                                key={index}
                                                lat={sala.GPS.lat}
                                                lng={sala.GPS.lon}
                                                producerCode={sala.producerCode}
                                                direction={sala.direction}
                                            />
                                        )
                                    })}
                                </GoogleMapReact>
                            </div>
                        </Paper>
                    ) : (
                        <Grid container spacing={2} style={{ marginTop: 8 }}>
                            {parsedForrajes
                                ?.sort((a, b) => a.locacion.localeCompare(b.locacion))
                                .map((forraje, index) => {
                                    return (
                                        <Grid item xs={12} sm={6} lg={6} xl={4} key={index}>
                                            <CartaForraje
                                                dryMatter={forraje.dryMatter}
                                                locacion={forraje.locacion}
                                                image={forraje.image}
                                                cantidad={forraje.cantidad}
                                                lastUpdate={forraje.lastUpdate}
                                                editionPermission={forraje.permitted}
                                                publicCard={forraje.public}
                                                _id={forraje._id}
                                            />
                                        </Grid>
                                    )
                                })}
                            {permissions.forraje >= 2 && (
                                <Grid item xs={12} sm={6} lg={6} xl={4}>
                                    <Paper elevation={0} className={classes.addCard}>
                                        <Button
                                            style={{ display: 'flex', height: '100%', width: '100%' }}
                                            onClick={() => setIsCreate(true)}
                                        >
                                            <Add style={{ fontSize: 150, margin: 'auto' }} color="primary" />
                                        </Button>
                                    </Paper>
                                </Grid>
                            )}
                        </Grid>
                    )}
                </div>
            ) : (
                <Paper elevation={0} className={classes.paperGeneralCard} style={{ marginTop: 8 }}>
                    <div className={classes.root}>
                        <div className={classes.title}>
                            <span
                                style={{
                                    fontSize: 32,
                                    fontWeight: 800,
                                    color: '#252525',
                                }}
                            >
                                Nueva carta de forraje
                            </span>
                        </div>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <div style={{ marginTop: 15, marginBottom: 10 }}>
                                    <span
                                        style={{
                                            fontSize: 18,
                                            fontWeight: 'bold',
                                            color: '#737373',
                                        }}
                                    >
                                        {fields.locacion.label}
                                    </span>
                                </div>
                                <BootstrapInput
                                    fullWidth
                                    placeholder={fields.locacion.placeHolder}
                                    id={fields.locacion.label}
                                    value={fields.locacion.value}
                                    onChange={event => handleChangeTextInput('locacion', event.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div style={{ marginTop: 15, marginBottom: 10 }}>
                                    <span
                                        style={{
                                            fontSize: 18,
                                            fontWeight: 'bold',
                                            color: '#737373',
                                        }}
                                    >
                                        {fields.direction.label}
                                    </span>
                                </div>
                                <BootstrapInput
                                    fullWidth
                                    placeholder={fields.direction.placeHolder}
                                    id={fields.direction.label}
                                    value={fields.direction.value}
                                    onChange={event => handleChangeTextInput('direction', event.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div style={{ marginTop: 15, marginBottom: 10 }}>
                                    <span
                                        style={{
                                            fontSize: 18,
                                            fontWeight: 'bold',
                                            color: '#737373',
                                        }}
                                    >
                                        {fields.sociedades.label}
                                    </span>
                                </div>
                                <CustomAutocomplete
                                    items={sociedades}
                                    placeHoder={fields.sociedades.placeHolder}
                                    id={fields.sociedades.label}
                                    value={fields.sociedades.value}
                                    onChange={value => handleChangeTextInput('sociedades', value)}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div style={{ marginTop: 15, marginBottom: 10 }}>
                                    <span
                                        style={{
                                            fontSize: 18,
                                            fontWeight: 'bold',
                                            color: '#737373',
                                        }}
                                    >
                                        {fields.image.label}
                                    </span>
                                </div>
                                <BootstrapInput
                                    fullWidth
                                    // placeholder='Buscar'
                                    {...(fields.image.value === null && { value: '' })}
                                    onChange={handleImage}
                                    type="file"
                                    inputProps={{
                                        accept: '.jpg,.jpeg,.png',
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div style={{ marginTop: 15, marginBottom: 10 }}>
                                    <span
                                        style={{
                                            fontSize: 18,
                                            fontWeight: 'bold',
                                            color: '#737373',
                                        }}
                                    >
                                        {fields.lat.label}
                                    </span>
                                </div>
                                <BootstrapInput
                                    fullWidth
                                    placeholder={fields.lat.placeHolder}
                                    id={fields.lat.label}
                                    value={fields.lat.value}
                                    onChange={event => handleChangeTextInput('lat', event.target.value)}
                                    inputProps={{
                                        type: 'number',
                                        step: 'any',
                                        max: '100',
                                        min: '-100',
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div style={{ marginTop: 15, marginBottom: 10 }}>
                                    <span
                                        style={{
                                            fontSize: 18,
                                            fontWeight: 'bold',
                                            color: '#737373',
                                        }}
                                    >
                                        {fields.lon.label}
                                    </span>
                                </div>
                                <BootstrapInput
                                    fullWidth
                                    placeholder={fields.lon.placeHolder}
                                    id={fields.lon.label}
                                    value={fields.lon.value}
                                    onChange={event => handleChangeTextInput('lon', event.target.value)}
                                    inputProps={{
                                        type: 'number',
                                        step: 'any',
                                        max: '100',
                                        min: '-100',
                                    }}
                                />
                            </Grid>
                            <Grid container item spacing={2}>
                                <div style={{ marginTop: 15, marginBottom: 10, marginLeft: 8 }}>
                                    <span
                                        style={{
                                            fontSize: 18,
                                            fontWeight: 'bold',
                                            color: '#737373',
                                        }}
                                    >
                                        {fields.dryMatter.label}
                                    </span>
                                </div>
                                {fields?.dryMatter?.value?.map((dryMatter, index) => {
                                    return (
                                        <Grid key={index} container item spacing={2}>
                                            <Grid item xs={12} sm={6}>
                                                <CustomDatePicker
                                                    placeHoder={'Fecha'}
                                                    value={dryMatter.fecha}
                                                    onChange={value => handleChangeDryMatter('fecha', index, value)}
                                                />
                                            </Grid>
                                            <Grid item xs={10} sm={5}>
                                                <BootstrapInput
                                                    fullWidth
                                                    placeholder={'Materia Seca'}
                                                    value={dryMatter.cantidad}
                                                    onChange={event =>
                                                        handleChangeDryMatter('cantidad', index, event.target.value)
                                                    }
                                                    inputProps={{
                                                        type: 'number',
                                                        step: 'any',
                                                        min: '0',
                                                    }}
                                                />
                                            </Grid>
                                            <Grid container item xs={2} sm={1} justifyContent="center">
                                                <IconButton
                                                    onClick={() => deleteDryMatter(index)}
                                                    style={{ width: 52 }}
                                                >
                                                    <Clear />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    )
                                })}
                                <Grid container item xs={12} justifyContent="center">
                                    <OutlineButton startIcon={<Add />} onClick={addDryMatter}>
                                        Agregar
                                    </OutlineButton>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} style={{ marginTop: 30 }} justifyContent="center">
                            <Grid item xs={12}>
                                <div style={{ maxWidth: 800, margin: 'auto', textAlign: 'center' }}>
                                    <Info /> La diferencia entre una carta normal y una pública, es que la primera solo
                                    la podrán ver aquellos que tengan permisos de visualización de forrajes para las
                                    sociedades seleccionadas. La carta pública en cambio puede ser vista por cualquier
                                    usuario de la plataforma con permisos de visualización de forrajes. En ambos casos
                                    solo aquellos con permisos de edición de forrajes en las sociedades que se
                                    seleccionaron para la carta pueden editar datos de esta.
                                </div>
                            </Grid>
                            <Grid item>
                                <PrimaryButton style={{ width: 250 }} onClick={() => handleSubmitData(false)}>
                                    Crear carta
                                </PrimaryButton>
                            </Grid>
                            <Grid item>
                                <PrimaryButton style={{ width: 250 }} onClick={() => handleSubmitData(true)}>
                                    Crear carta pública
                                </PrimaryButton>
                            </Grid>
                            <Grid item>
                                <OutlineButton onClick={() => setIsCreate(false)} style={{ width: 250 }}>
                                    {'Cancelar'}
                                </OutlineButton>
                            </Grid>
                        </Grid>
                    </div>
                </Paper>
            )}
        </Fragment>
    )
}

export default Forraje
