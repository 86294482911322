/* React */
import React from 'react'

const ProlesurIcon = props => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="165" height="62.848" viewBox="0 0 165 62.848">
        <g transform="translate(-51.024 -257.953)">
            <path
                d="M370.374,277.379a31.064,31.064,0,0,0-15.169-13.936c.034.018.063.037.1.055,11.685,6.1,16.946,17.753,13.078,31.709A24.96,24.96,0,0,1,357.3,310.921s-.244.15-.726.415c-.543.295-1.386.735-2.511,1.261-1.764.828-2.217,1.786-3.021,4.206a18.427,18.427,0,0,1-3.062,5.96.206.206,0,0,0-.009.291,27.884,27.884,0,0,0,9.6-3.441,31.205,31.205,0,0,0,12.806-42.234"
                transform="translate(-158.044 -2.923)"
                fill="#00539b"
            />
            <path
                d="M325.138,276.043a26.842,26.842,0,0,1,1.922,15.8c3.622-13.676-1.553-25.1-12.96-31.162a31.127,31.127,0,0,0-27.444.952c-13.064,6.983-19.321,23.2-15.422,35.653h.114c.086,0,.181-.011.172-.167-.322-7.347.348-12.515,2.9-18.066,2.741-5.964,6.6-10.822,12.119-13.9,14.7-8.211,32.628-2.65,38.6,10.891"
                transform="translate(-116.615 0)"
                fill="#72bf44"
            />
            <path
                d="M59.352,353.985c3.042,0,4.41-1.079,4.41-3.4,0-2.191-1.331-3.269-4.258-3.269H57.146v6.673Zm-8.328-10.752H59.96c5.627,0,9.924,2.292,9.924,7.28,0,5.023-4.182,7.551-10.571,7.551H57.146v7.617H51.024Z"
                transform="translate(0 -45.397)"
                fill="#00539b"
            />
            <path
                d="M232.772,369.9l1.965-3.734a10.322,10.322,0,0,0,4.855,1.422c1.925,0,2.968-.426,2.968-1.28,0-1.031-2.407-1.244-5.175-2.489-2.608-1.137-4.052-2.489-4.052-5.05,0-3.414,3.088-5.512,7.662-5.512a14.532,14.532,0,0,1,6.459,1.315l-2.006,3.663a9.817,9.817,0,0,0-4.172-1.031c-1.806,0-2.528.5-2.528,1.28,0,1.03,1.886,1.244,4.132,2.026,3.05,1.1,4.605,2.382,4.605,5.3,0,3.8-2.519,5.725-7.533,5.725a14,14,0,0,1-7.182-1.636"
                transform="translate(-96.75 -50.732)"
                fill="#00539b"
            />
            <path
                d="M134.586,366.929c-2.432,0-3.886-1.736-3.886-4.672,0-2.967,1.454-4.732,3.886-4.732s3.889,1.765,3.889,4.732c0,2.936-1.454,4.672-3.889,4.672m0-13.811c-6.291,0-10.534,3.657-10.534,9.139,0,5.454,4.244,9.107,10.534,9.107,6.323,0,10.536-3.653,10.536-9.107,0-5.482-4.213-9.139-10.536-9.139"
                transform="translate(-38.875 -50.66)"
                fill="#00539b"
            />
            <path
                d="M197.122,360.453c.208-2.1,1.4-3.356,3.3-3.356,2.787,0,3.266,2.07,3.3,3.356Zm12.435,2.4c0-6.2-3.354-9.74-9.2-9.74-5.878,0-9.675,3.567-9.675,9.08,0,5.482,4.211,9.169,10.506,9.169a18.829,18.829,0,0,0,6.619-1.108l.086-.028v-4.5l-.207.091a11.791,11.791,0,0,1-5.4,1.316c-3.088,0-4.721-.989-5.078-3.056h12.347Z"
                transform="translate(-74.346 -50.656)"
                fill="#00539b"
            />
            <path
                d="M325.122,353.686a5.261,5.261,0,0,0-5.226,3.027,2.856,2.856,0,0,0-3.091-2.935h-2.794v17.471h6.417v-7.371c0-3.356,1.634-4.8,4.458-5.035l2.167-.123-.03-4.974Z"
                transform="translate(-139.997 -50.962)"
                fill="#00539b"
            />
            <path
                d="M105.468,353.686a5.261,5.261,0,0,0-5.226,3.027,2.856,2.856,0,0,0-3.091-2.935H94.358v17.471h6.416v-7.371c0-3.356,1.634-4.8,4.458-5.035l2.167-.123-.03-4.974Z"
                transform="translate(-23.068 -50.962)"
                fill="#00539b"
            />
            <path
                d="M172.9,365.577h6.4v-22.54h-3.393c-1.845,0-3.007.895-3.007,2.9Z"
                transform="translate(-64.881 -45.293)"
                fill="#00539b"
            />
            <path
                d="M281.041,354.081v7.365c0,3.563-1.183,5.27-3.7,5.27-1.837,0-2.726-1.38-2.726-4.194v-5.566a2.805,2.805,0,0,0-2.969-2.876h-3.461V364.92c0,3.382,1.837,6.977,6.963,6.977,2.964,0,5.245-1.171,6.458-3.324,0,.625,0,1.665,0,2.883h5.87V354.081Z"
                transform="translate(-115.6 -51.172)"
                fill="#00539b"
            />
        </g>
    </svg>
)

export default ProlesurIcon
