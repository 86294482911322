import React from 'react'
import { FormControl, Grid, IconButton, Input, InputAdornment, InputLabel, makeStyles } from '@material-ui/core'
import { Search } from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
    filters: {
        padding: '24px 16px 24px 16px',
    },
    hide: {
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
}))

const SearchBox = ({ search, setSearch }) => {
    const classes = useStyles()

    const handleOnChangeSearch = event => setSearch(event.target.value)

    return (
        <Grid container spacing={1} className={classes.filters}>
            <Grid item xs={12} sm={4} className={classes.hide} />
            <Grid item xs={12} sm={8} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <FormControl style={{ display: 'flex', marginRight: 8, width: '100%' }}>
                    <InputLabel htmlFor="lookforfilter">Buscador</InputLabel>
                    <Input
                        type="text"
                        value={search}
                        onChange={event => handleOnChangeSearch(event)}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton aria-label="toggle password visibility">
                                    <Search />
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>
            </Grid>
        </Grid>
    )
}

export default SearchBox
