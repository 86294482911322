/* GraphQL */
import { gql } from '@apollo/client'

export const SALAS = gql`
    query Salas($sociedadesIds: [ID]!) {
        salas(sociedadesIds: $sociedadesIds) {
            _id
            producerCode
            name
            RUT
            sociedad {
                _id
                masterCode
                name
            }
            telefono
            email
            kpi {
                resumenPrecios {
                    month
                    year
                    value
                }
            }
            horarios {
                horario
                enabled
            }
            description
            livestock
            sector
            direction
            GPSlink
            GPS {
                lat
                lon
            }
            certificates {
                LEU {
                    zCondicion
                    fechaActivacion
                    fechaCaducidad
                }
                TBC {
                    zCondicion
                    fechaActivacion
                    fechaCaducidad
                }
                BRU {
                    zCondicion
                    fechaActivacion
                    fechaCaducidad
                }
                PAB {
                    zCondicion
                    fechaActivacion
                    fechaCaducidad
                }
            }
            enabled
            createdAt
            updatedAt
        }
    }
`

export const EDIT_SALA = gql`
    mutation Salas($salaId: ID!, $lat: Float, $lon: Float, $enabled: Boolean, $sociedadId: ID!) {
        editSala(salaId: $salaId, lat: $lat, lon: $lon, enabled: $enabled, sociedadId: $sociedadId) {
            _id
            producerCode
            name
            RUT
            sociedad {
                _id
                masterCode
                name
            }
            telefono
            email
            kpi {
                resumenPrecios {
                    month
                    year
                    value
                }
            }
            horarios {
                horario
                enabled
            }
            description
            livestock
            sector
            direction
            GPSlink
            GPS {
                lat
                lon
            }
            certificates {
                LEU {
                    zCondicion
                    fechaActivacion
                    fechaCaducidad
                }
                TBC {
                    zCondicion
                    fechaActivacion
                    fechaCaducidad
                }
                BRU {
                    zCondicion
                    fechaActivacion
                    fechaCaducidad
                }
                PAB {
                    zCondicion
                    fechaActivacion
                    fechaCaducidad
                }
            }
            enabled
            createdAt
            updatedAt
        }
    }
`

/* export const CREATE_SALA = gql`
    mutation Salas (
        $code: String!
        $name: String!
        $predio: ID!
        $description: String
        $livestock: Int
        $sector: String
        $direction: String
        $lat: Float
        $lon: Float
        $enabled: Boolean
    ) {
        createSala (code: $code, name: $name, predio: $predio, description: $description,livestock: $livestock,
            sector: $sector, direction: $direction, lat: $lat, lon: $lon, enabled: $enabled)
        {
            _id
            producerCode
            name
            sociedad {
                _id
                masterCode
                name
            }
            horarios {
                horario
                enabled
            }
            description
            livestock
            sector
            direction
            GPSlink
            GPS {
                lat
                lon
            }
            enabled
            createdAt
            updatedAt
        }
    }
` */

/* export const DELETE_SALA = gql`
    mutation Salas (
        $salaId: ID!
    ) {
        deleteSala (salaId: $salaId)
        {
            _id
            producerCode
            name
            sociedad {
                _id
                masterCode
                name
            }
            horarios {
                horario
                enabled
            }
            description
            livestock
            sector
            direction
            GPSlink
            GPS {
                lat
                lon
            }
            enabled
            createdAt
            updatedAt
        }
    }
` */

/* export const SET_HORARIO_SALA = gql`
    mutation Salas (
        $salaId: ID!
        $horarioId: ID!
        $horarioEnabled: Boolean!
    ) {
        setHorarioSala (salaId: $salaId, horarioId: $horarioId, horarioEnabled: $horarioEnabled)
        {
            _id
            producerCode
            name
            RUT
            sociedad {
                _id
                masterCode
                name
            }
            telefono
            email
            horarios {
                horario
                enabled
            }
            description
            livestock
            sector
            direction
            GPSlink
            GPS {
                lat
                lon
            }
            certificates {
                LEU {
                    zCondicion
                    fechaActivacion
                    fechaCaducidad
                }
                TBC {
                    zCondicion
                    fechaActivacion
                    fechaCaducidad
                }
                BRU {
                    zCondicion
                    fechaActivacion
                    fechaCaducidad
                }
                PAB {
                    zCondicion
                    fechaActivacion
                    fechaCaducidad
                }
            }
            enabled
            createdAt
            updatedAt
        }
    }
` */
