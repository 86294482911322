/* React */
import React, { createContext, useEffect, useState } from 'react'

/* GraphQL */
import { useQuery } from '@apollo/client'
import { GET_PRESETS } from '../../graphQL/permissionPresets'

export const PermissionPresetsContext = createContext()

const PermissionPresetsContextProvider = props => {
    const { data } = useQuery(GET_PRESETS)
    const [presets, setPresets] = useState([])

    useEffect(() => {
        if (data?.permissionPresets) {
            setPresets(data.permissionPresets)
        }
    }, [data])

    return <PermissionPresetsContext.Provider value={{ presets }}>{props.children}</PermissionPresetsContext.Provider>
}

export default PermissionPresetsContextProvider
