import React, { createContext, useContext, useEffect, useState } from 'react'
import { DataContext } from './DataContext'
import { FilterContext } from './FilterContext'
import { useLazyQuery, gql } from '@apollo/client'
import { saveAs } from 'file-saver'

export const InviernoVeranoContext = createContext()
const WINTER_SUMMER_RATIO = gql`
    fragment fields on AggregatedMetricsAndMessage {
        metrics {
            _id {
                year
                month
                day @skip(if: $groupMonthly)
            }
            LITROS_TOTAL
        }
    }

    query winterSummerRatio(
        $summerStartLastYear: String!
        $summerEndLastYear: String!
        $winterStartLastYear: String!
        $winterEndLastYear: String!
        $summerStartCurrentYear: String!
        $summerEndCurrentYear: String!
        $winterStartCurrentYear: String!
        $winterEndCurrentYear: String!
        $groupMonthly: Boolean!
        $producerCodes: [String]
    ) {
        summerLastYear: aggregatedMetrics(
            fechaInicio: $summerStartLastYear
            fechaFin: $summerEndLastYear
            groupMonthly: $groupMonthly
            producerCodes: $producerCodes
        ) {
            ...fields
        }
        winterLastYear: aggregatedMetrics(
            fechaInicio: $winterStartLastYear
            fechaFin: $winterEndLastYear
            groupMonthly: $groupMonthly
            producerCodes: $producerCodes
        ) {
            ...fields
        }
        summerCurrentYear: aggregatedMetrics(
            fechaInicio: $summerStartCurrentYear
            fechaFin: $summerEndCurrentYear
            groupMonthly: $groupMonthly
            producerCodes: $producerCodes
        ) {
            ...fields
        }
        winterCurrentYear: aggregatedMetrics(
            fechaInicio: $winterStartCurrentYear
            fechaFin: $winterEndCurrentYear
            groupMonthly: $groupMonthly
            producerCodes: $producerCodes
        ) {
            ...fields
        }
    }
`

const InviernoVeranoContextProvider = props => {
    const { errorMessage, setErrorMessage } = useContext(DataContext)
    const { predioFilter } = useContext(FilterContext)
    const [loadingCSV, setLoadingCSV] = useState(false)
    const [inviernoVeranoData, setInviernoVeranoData] = useState({
        invierno: [
            { name: 'may.', 'año actual': 0, 'año anterior': 0 },
            { name: 'jun.', 'año actual': 0, 'año anterior': 0 },
            { name: 'jul.', 'año actual': 0, 'año anterior': 0 },
            { name: 'ago.', 'año actual': 0, 'año anterior': 0 },
        ],
        verano: [
            { name: 'oct.', 'año actual': 0, 'año anterior': 0 },
            { name: 'nov.', 'año actual': 0, 'año anterior': 0 },
            { name: 'dic.', 'año actual': 0, 'año anterior': 0 },
            { name: 'ene.', 'año actual': 0, 'año anterior': 0 },
        ],
    })
    const [inviernoVeranoRatio, setRatio] = useState(0)
    const [inviernoVeranoRatioOld, setRatioOld] = useState(0)
    const [complete, setComplete] = useState(false)
    const [maxValue, setMaxValue] = useState(0)
    const [getData, { loading, error, data }] = useLazyQuery(WINTER_SUMMER_RATIO, {
        fetchPolicy: 'cache-and-network',
        notifyOnNetworkStatusChange: true,
    })

    const queryServer = year => {
        getData({
            variables: {
                summerStartCurrentYear: new Date(year, 9, 1).toISOString(),
                summerEndCurrentYear: new Date(year + 1, 0, 31).toISOString(),
                winterStartCurrentYear: new Date(year, 4, 1).toISOString(),
                winterEndCurrentYear: new Date(year, 7, 31).toISOString(),
                summerStartLastYear: new Date(year - 1, 9, 1).toISOString(),
                summerEndLastYear: new Date(year, 0, 31).toISOString(),
                winterStartLastYear: new Date(year - 1, 4, 1).toISOString(),
                winterEndLastYear: new Date(year - 1, 7, 31).toISOString(),
                groupMonthly: true,
                producerCodes: predioFilter,
            },
        })
    }

    useEffect(() => {
        if (error ^ errorMessage) setErrorMessage(error)
    }, [error])

    const winterMonths = { 'may.': 5, 'jun.': 6, 'jul.': 7, 'ago.': 8 }
    const summerMonths = { 'oct.': 10, 'nov.': 11, 'dic.': 12, 'ene.': 1 }
    useEffect(() => {
        if (data) {
            const { winterCurrentYear, winterLastYear, summerCurrentYear, summerLastYear } = data
            setInviernoVeranoData({
                invierno: Object.entries(winterMonths).map(([name, month]) => ({
                    name,
                    'año actual': winterCurrentYear?.metrics?.find(el => el._id.month === month)?.LITROS_TOTAL,
                    'año anterior': winterLastYear?.metrics?.find(el => el._id.month === month)?.LITROS_TOTAL,
                })),
                verano: Object.entries(summerMonths).map(([name, month]) => ({
                    name,
                    'año actual': summerCurrentYear?.metrics?.find(el => el._id.month === month)?.LITROS_TOTAL,
                    'año anterior': summerLastYear?.metrics?.find(el => el._id.month === month)?.LITROS_TOTAL,
                })),
            })
        }
    }, [data])

    useEffect(() => {
        const tempVerano = inviernoVeranoData.verano.map(x =>
            x['año actual'] > 0 ? x['año actual'] : x['año anterior']
        )
        const tempInvierno = inviernoVeranoData.invierno.map(x =>
            x['año actual'] > 0 ? x['año actual'] : x['año anterior']
        )
        const totalVerano = tempVerano.reduce((a, b) => a + b, 0)
        const totalInvierno = tempInvierno.reduce((a, b) => a + b, 0)
        const ratio = totalVerano / totalInvierno
        setRatio(ratio.toFixed(2))

        const totalVeranoOld = inviernoVeranoData.verano.reduce((a, b) => a + b['año anterior'], 0)
        const totalInviernoOld = inviernoVeranoData.invierno.reduce((a, b) => a + b['año anterior'], 0)
        const ratioOld = totalVeranoOld / totalInviernoOld
        setRatioOld(ratioOld.toFixed(2))

        const complete = !(
            inviernoVeranoData.invierno.find(x => x['año actual'] === undefined) ||
            inviernoVeranoData.verano.find(x => x['año actual'] === undefined)
        )

        let maxValue_ = 0
        for (const [, value] of Object.entries(inviernoVeranoData.invierno)) {
            if (value['año actual'] > maxValue_) {
                maxValue_ = value['año actual']
            }
            if (value['año anterior'] > maxValue_) {
                maxValue_ = value['año anterior']
            }
        }
        for (const [, value] of Object.entries(inviernoVeranoData.verano)) {
            if (value['año actual'] > maxValue_) {
                maxValue_ = value['año actual']
            }
            if (value['año anterior'] > maxValue_) {
                maxValue_ = value['año anterior']
            }
        }
        if (maxValue_ > parseFloat(maxValue_.toPrecision(2))) {
            const split = maxValue_.toPrecision(2).split('e')
            split[0] = parseFloat(split[0]) + 0.1
            maxValue_ = parseFloat(split.join('e'))
        } else {
            maxValue_ = parseFloat(maxValue_.toPrecision(2))
        }
        setMaxValue(Math.round(maxValue_))
        setComplete(complete)
    }, [inviernoVeranoData])

    const downloadCSV = async year => {
        setLoadingCSV(true)
        let csvData = 'fecha; año actual;  año anterior'
        inviernoVeranoData.invierno.forEach(item => {
            csvData += `\n${item.name}; ${item['año actual']}; ${item['año anterior']}`
        })
        inviernoVeranoData.verano.forEach(item => {
            csvData += `\n${item.name}; ${item['año actual']}; ${item['año anterior']}`
        })

        const blob = new Blob([csvData], { type: 'text/plainl;charset=utf-8' })
        saveAs(blob, year + 'Invierno-Verano.csv')
        setLoadingCSV(false)
    }

    return (
        <InviernoVeranoContext.Provider
            value={{
                inviernoVeranoData,
                inviernoVeranoRatio,
                inviernoVeranoRatioOld,
                maxValue,
                complete,
                queryServer,
                loading,
                downloadCSV,
                loadingCSV,
            }}
        >
            {props.children}
        </InviernoVeranoContext.Provider>
    )
}

export default InviernoVeranoContextProvider
