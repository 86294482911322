/* React */
import React, { useContext } from 'react'
import { Link } from 'react-router-dom'

/* Material UI */
import { ListItem, makeStyles } from '@material-ui/core'

/* Context */
import { MenuContext } from '../../contexts'

/* Utilities */
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
    nestedChild: {
        paddingTop: 7,
        paddingBottom: 7,
    },
    font: {
        [theme.breakpoints.down('md')]: {
            fontSize: 15,
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: 20,
        },
    },
    colorOn: {
        color: theme.palette.primary.main,
        fill: theme.palette.primary.main,
    },
    colorOff: {
        color: theme.drawer.nestedTextColor,
        fill: theme.drawer.nestedTextColor,
    },
}))

const ButtonSubMenu = ({ id, text, link }) => {
    const { selectedItem, handleClickSubMenu } = useContext(MenuContext)
    const classes = useStyles()
    /* const theme = useTheme()
    const colorOn = theme.palette.primary.main
    const colorOff = theme.drawer.nestedTextColor */
    const [hover, setHover] = React.useState(false)

    const handleMouseOverLeave = value => {
        setHover(value)
    }

    return (
        <ListItem
            key={id}
            button
            {...(link && {
                component: Link,
                to: link,
            })}
            className={classes.nestedChild}
            onClick={event => handleClickSubMenu(id)}
            onMouseOver={() => handleMouseOverLeave(true)}
            onMouseLeave={() => handleMouseOverLeave(false)}
        >
            <span
                style={{
                    fontWeight: hover || selectedItem.id === id ? 'bold' : 'normal',
                }}
                className={clsx(classes.font, hover || selectedItem.id === id ? classes.colorOn : classes.colorOff)}
            >
                {text}
            </span>
        </ListItem>
    )
}

export default ButtonSubMenu
