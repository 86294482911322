/* React */
import React, { useContext, useEffect, useState } from 'react'

/* Material UI */
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'

/* Context */
import { FilterContext } from '../../../contexts/produccion/FilterContext'

/* Utilities */
import DateFnsUtils from '@date-io/date-fns'
import esLocale from 'date-fns/locale/es'
import 'date-fns'

export default function MyDatePicker({ label }) {
    const { startTimeFilter, setStartTimeFilter, endTimeFilter, setEndTimeFilter, setTemporalidadFilter } =
        useContext(FilterContext)

    const [date, setDate] = useState(label === 'Inicio' ? startTimeFilter : endTimeFilter)

    useEffect(() => {
        setDate(label === 'Inicio' ? startTimeFilter : endTimeFilter)
    }, [label, startTimeFilter, endTimeFilter])

    const handleDateChange = date => {
        const setDate = new Date(date)
        if (label === 'Inicio') {
            setDate.setHours(0, 0, 0, 0)
        } else {
            setDate.setHours(14, 59, 59, 999)
        }

        label === 'Inicio' ? setStartTimeFilter(setDate) : setEndTimeFilter(setDate)
        setTemporalidadFilter('')
    }

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <KeyboardDatePicker
                variant="inline"
                inputVariant="outlined"
                format="dd/MM/yyyy"
                label={label}
                value={date}
                onChange={handleDateChange}
                fullWidth
                disableFuture
                autoOk
            />
        </MuiPickersUtilsProvider>
    )
}
