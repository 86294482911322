/* React */
import React, { Fragment, useState } from 'react'

/* Material UI */
import {
    Divider,
    Hidden,
    makeStyles,
    useTheme,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'block',
    },
    formControl: {
        margin: theme.spacing(1, 'auto'),
        width: '100%',
    },
    formControlLabel: {
        padding: '4px 16px',
        margin: 0,
    },
}))

const variables = ['Litros totales', 'Grasa', 'Proteína', 'Úrea en leche', 'UFC', 'RCS']

export default function SelectionPanel({ handleClickSelectionPanel, value_ }) {
    const classes = useStyles()
    const theme = useTheme()
    const [value, setValue] = useState(value_)

    const handleChange = event => {
        const value = event.target.value
        setValue(value)
        handleClickSelectionPanel(value)
    }

    return (
        <div className={classes.root}>
            <span
                style={{
                    display: 'flex',
                    color: theme.palette.primary.main,
                    fontSize: 20,
                    fontWeight: 'bold',
                    wordWrap: 'break-word',
                    textAlign: 'center',
                    padding: 16,
                }}
            >
                Selección variable
            </span>
            <FormControl className={classes.formControl}>
                <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleChange}>
                    {variables.map((item, index) => (
                        <Fragment key={index}>
                            <FormControlLabel
                                value={item}
                                control={<Radio color="primary" />}
                                label={item}
                                className={classes.formControlLabel}
                            />
                            <Hidden lgUp>
                                <Divider />
                            </Hidden>
                        </Fragment>
                    ))}
                </RadioGroup>
            </FormControl>
        </div>
    )
}
