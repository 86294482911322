import React, { /* useEffect,  */useEffect, useState } from 'react'
import { FormControl, InputBase, makeStyles, MenuItem, Select, TextField, withStyles, Grid } from '@material-ui/core'
import { KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { Autocomplete } from '@material-ui/lab'
import { OutlineButton, PrimaryButton } from '../comun'
import DateFnsUtils from '@date-io/date-fns'

const useStyles = makeStyles(theme => ({
    root: {
        padding: '50px 70px',
        [theme.breakpoints.down('md')]: {
            padding: '10px 20px',
        },
    },
    title: {
        marginBottom: 35,
        [theme.breakpoints.down('md')]: {
            marginBottom: 0,
        },
    },
    formControl: {
        '& .MuiInputBase-root': {
            height: 52,
        },
        '& .MuiInputBase-input': {
            display: 'flex',
            alignItems: 'center',
            borderRadius: 12,
            backgroundColor: theme.palette.common.white,
            border: 0,
            fontSize: 16,
            fontWeight: 600,
            height: 32,
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: 12,
        },
        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            borderRadius: 12,
            borderWidth: 1,
        },
    },
    autocomplete: {
        '& .MuiInputBase-input': {
            display: 'flex',
            alignItems: 'center',
            borderRadius: 12,
            backgroundColor: theme.palette.common.white,
            border: 0,
            fontSize: 16,
            fontWeight: 600,
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: 12,
        },
        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            borderRadius: 12,
            borderWidth: 1,
        },
    },
}))

const BootstrapInput = withStyles(theme => ({
    root: {
        height: 50,
    },
    input: {
        borderRadius: 12,
        backgroundColor: theme.palette.common.white,
        border: '1px solid #ced4da',
        fontSize: 16,
        fontWeight: 600,
        height: 30,
        padding: '10px 12px',
        '&:focus': {
            borderColor: theme.palette.primary.main,
        },
        '&:hover': {
            borderColor: theme.palette.common.black,
        },
    },
}))(InputBase)

const CustomSelect = ({ placeHoder, items, value, onChange, defaultValue }) => {
    const classes = useStyles()

    return (
        <FormControl fullWidth variant="outlined" className={classes.formControl}>
            <Select
                value={value}
                onChange={event => onChange(event.target.value)}
                MenuProps={{
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                }}
                fullWidth
                displayEmpty
            >
                {defaultValue && (
                    <MenuItem value={''} disabled>
                        {defaultValue}
                    </MenuItem>
                )}
                {items.map((item, index) => (
                    <MenuItem key={index} value={item._id}>
                        {item.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

const CustomOtherAutocomplete = ({ placeHoder, items, value, onChange, property }) => {
    const classes = useStyles()
    const [allOrNone, setAllOrNone] = useState(null)

    const handleSelectAll = (event, value) => {
        if (allOrNone) onChange([]) // All items are selected, so select none
        else onChange(items) // None of the items are selected, so select all
        setAllOrNone(!allOrNone)
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={7} lg={9} xl={11}>
                <Autocomplete
                    className={classes.autocomplete}
                    multiple
                    options={items.slice().sort((a, b) => -b[property || 'name'].localeCompare(a[property || 'name']))}
                    getOptionLabel={option => (property ? `${option[property]} - ${option.name}` : option.name)}
                    onChange={(event, options) => {
                        if (options.length === 0) setAllOrNone(false)
                        else if (options.length < items.length) setAllOrNone(null)
                        else setAllOrNone(true)
                        onChange(options)
                    }}
                    value={value || []}
                    filterSelectedOptions
                    getOptionSelected={(option, value) =>
                        option._id === value._id && option[property || 'name'] === value[property || 'name']
                    }
                    renderInput={params => <TextField {...params} variant="outlined" label="Buscar" />}
                />
            </Grid>
            <Grid item xs={5} lg={3} xl={1}>
                <OutlineButton
                    onClick={handleSelectAll}
                    style={{ width: '7rem', fontWeight: 15, padding: '0px 10px 0px 10px' }}
                >
                    {!allOrNone ? 'Todas' : 'Ninguna'}
                </OutlineButton>
            </Grid>
        </Grid>
    )
}

const CustomAutocomplete = ({ placeHoder, items, value, onChange, property }) => {
    const classes = useStyles()
    const [allOrNone, setAllOrNone] = useState(null)

    useEffect(() => {
        console.log(allOrNone)
    }, [allOrNone])

    /* const handleSelectAll = (event, value) => {
        if (allOrNone) onChange([]) // All items are selected, so select none
        else onChange(items) // None of the items are selected, so select all
        setAllOrNone(!allOrNone)
    } */

    return (
        <Grid container spacing={2}>
            <Grid item xs={7} lg={9} xl={11}>
                <Autocomplete
                    className={classes.autocomplete}
                    /* multiple */
                    options={items.slice().sort((a, b) => -b[property || 'name'].localeCompare(a[property || 'name']))}
                    getOptionLabel={option => (property ? `${option[property]} - ${option.name}` : `${option.masterCode} ${option.name}`)}
                    onChange={(event, options) => {
                        if (options.length === 0) setAllOrNone(false)
                        else if (options.length < items.length) setAllOrNone(null)
                        else setAllOrNone(true)
                        onChange(options)
                    }}
                    value={value || []}
                    filterSelectedOptions
                    getOptionSelected={(option, value) =>
                        option._id === value._id && option[property || 'name'] === value[property || 'name']
                    }
                    renderInput={params => <TextField {...params} variant="outlined" label="Buscar" />}
                />
            </Grid>
            <Grid item xs={5} lg={3} xl={1}>
                {/* <OutlineButton
                    onClick={handleSelectAll}
                    style={{ width: '7rem', fontWeight: 15, padding: '0px 10px 0px 10px' }}
                >
                    {!allOrNone ? 'Todas' : 'Ninguna'}
                </OutlineButton> */}
            </Grid>
        </Grid>
    )
}

const CustomTimePicker = ({ placeHoder, value, onChange }) => {
    const classes = useStyles()
    const [selectedDate, setDateChange] = useState(value)

    const handleDateChange = date => {
        setDateChange(date)
        onChange(date)
    }

    return (
        <FormControl fullWidth variant="outlined" className={classes.formControl}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardTimePicker
                    ampm={false}
                    inputVariant="outlined"
                    variant="inline"
                    value={selectedDate}
                    onChange={handleDateChange}
                />
            </MuiPickersUtilsProvider>
        </FormControl>
    )
}

const AdminCreateItemForm = ({
    title,
    fields,
    createButtonText,
    onClick,
    createButtonText2,
    onClick2,
    setIsCreate,
    sociedadDeSala,
    sociedades,
    sala
}) => {
    /* console.log(setIsCreate) */
    // const { setIsCreate } = useContext(AdminFilterContext)

    // setear estado de campos del formulario
    

    const [values, setValues] = useState([])
    const classes = useStyles()
    const [haveValue, setHaveValue] = useState(
        fields.reduce((acc, curr) => {
            if (curr.required) acc[curr.label] = curr.value && curr.value.length > 0
            return acc
        }, {})
    )

    useEffect(() => {
        console.log(haveValue)
    }, [haveValue])

    useEffect(() => {
        if (values && fields) {
            console.log(fields)
            console.log(values)
            console.log(values[fields.filter(el => el.label !== 'Sociedad').length])
        }
    }, [values, fields])

    useEffect(() => {
        if (sociedades)
        if (fields.length > 0) {
            const values_ = []
            fields.forEach((e, i) => {
                console.log(e)
                if (e.label==="Sociedad") {
                    const sociedadToValue = sociedades.filter(sociedad => {if (sociedad._id === sociedadDeSala._id) {return sociedad} else {return null}})
                    e.value = sociedadToValue[0]
                }
                values_.push(e.value)
            })
            setValues(values_)
        }
    },[fields, sociedades])

    const handleChangeTextInput = (index, value, item) => {
        console.log(index, value, item)
        const values_ = [...values]
        values_[index] = value
        setValues(values_)
        if (item.required) {
            if (value && value.length > 0) setHaveValue({ ...haveValue, [item.label]: true })
            else setHaveValue({ ...haveValue, [item.label]: false })
        }
    }

    return (
        <div className={classes.root}>
            <div className={classes.title}>
                <span
                    style={{
                        fontSize: 32,
                        fontWeight: 800,
                        color: '#252525',
                    }}
                >
                    {title}
                </span>
            </div>
            <Grid container spacing={2}>
                {
                    sala ?
                    fields
                    .filter(el => el.label === 'Sociedad')
                    .map((item, i) => {
                        if (item.enabled) {
                            return (
                                <Grid key={i} item xs={12} md={6}>
                                <div >
                                    <div style={{ marginTop: 15, marginBottom: 10 }}>
                                        <span style={{ fontSize: 18, fontWeight: 'bold', color: '#737373' }}>
                                            <span style={{ color: 'red' }}>{item.label} (*)</span>
                                        </span>
                                    </div>
                                    <CustomAutocomplete
                                        items={item.menuItems}
                                        id={item.label}
                                        value={values[fields.filter(el => el.label !== 'Sociedad').length]}
                                        onChange={value =>
                                            handleChangeTextInput(
                                                item.length,
                                                value,
                                                item
                                            )
                                        }
                                    />
                                </div>
                                </Grid>
                            )
                        } else {
                            return null
                        }
                    })
                    :
                    fields
                    /* .filter(el => el.label !== 'Sociedad') */
                    .map((item, index) => {
                        if (item.enabled) {
                            return (
                                <Grid key={index} item xs={12} md={6}>
                                    <div style={{ marginTop: 15, marginBottom: 10 }}>
                                        <span style={{ fontSize: 18, fontWeight: 'bold', color: '#737373' }}>
                                            {item.label} {item.required && <span style={{ color: 'red' }}>(*)</span>}
                                        </span>{' '}
                                    </div>
                                    {
                                    item.type === 'textInput' ? (
                                        <BootstrapInput
                                            fullWidth
                                            placeholder={item.placeHolder}
                                            id={item.label}
                                            value={values[index]}
                                            onChange={event => handleChangeTextInput(index, event.target.value, item)}
                                        />
                                    ) : item.type === 'floatInput' ? (
                                        <BootstrapInput
                                            fullWidth
                                            placeholder={item.placeHolder}
                                            id={item.label}
                                            value={values[index]}
                                            onChange={event => handleChangeTextInput(index, event.target.value, item)}
                                            inputProps={{
                                                type: 'number',
                                                step: 'any',
                                                max: item.max || '100',
                                                min: item.min || '-100',
                                            }}
                                        />
                                    ) : item.type === 'select' ? (
                                        <CustomSelect
                                            placeHoder={item.placeHolder}
                                            items={item.menuItems}
                                            defaultValue={item.defaultValue}
                                            id={item.label}
                                            value={values[index]}
                                            onChange={value => handleChangeTextInput(index, value, item)}
                                        />
                                    ) : item.type === 'multiple' ? (
                                        <CustomOtherAutocomplete
                                            items={item.menuItems}
                                            id={item.label}
                                            value={values[index]}
                                            onChange={value => handleChangeTextInput(index, value, item)}
                                            property={item.property}
                                        />
                                    ) : (
                                        <CustomTimePicker
                                            id={item.label}
                                            placeHoder={item.placeHolder}
                                            value={values[index]}
                                            onChange={value => handleChangeTextInput(index, value, item)}
                                        />
                                    )}
                                </Grid>
                            )
                        } else {
                            return null
                        }
                    })}
            </Grid>
            

            {!Object.values(haveValue).every(el => el === true) && (
                <span style={{ fontSize: 18, fontWeight: 'bold', color: '#737373' }}>
                    Debes completar todos los campos requeridos
                </span>
            )}
            <Grid container spacing={2} style={{ marginTop: 30 }} justifyContent="center">
                {/* {createButtonText2 && onClick2 && (
                    <Grid item>
                        <PrimaryButton
                            style={{ width: 250 }}
                            onClick={e => onClick2(values)}
                        >
                            {createButtonText2}
                        </PrimaryButton>
                    </Grid>
                )} */}
                <Grid item>
                    <PrimaryButton
                        style={{ width: 250 }}
                        onClick={e => onClick(values)}
                    >
                        {createButtonText}
                    </PrimaryButton>
                </Grid>
                <Grid item>
                    <OutlineButton onClick={() => setIsCreate(false)} style={{ width: 250 }}>
                        {'Cancelar'}
                    </OutlineButton>
                </Grid>
            </Grid>
        </div>
    )
}

export default AdminCreateItemForm
