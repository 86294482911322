/* React */
import React, { Fragment, useEffect, useState } from 'react'

/* Material UI */
import { Box, CircularProgress, Grid, Hidden, makeStyles, Paper } from '@material-ui/core'

/* Components */
import { CartaTablaGraficoContent } from './'
import { SelectionPanel } from '../comun'

const useStyles = makeStyles(theme => ({
    paperGeneralCard: {
        // margin: theme.spacing(1, 'auto'),
        // padding: theme.spacing(2),
        borderRadius: 12,
    },
}))

const CartaTablaGrafico = ({
    type,
    view,
    title1,
    title2,
    subtitle1,
    subtitle2,
    data,
    isSideSelector,
    forecast,
    buttons,
    maxValue,
    minValue,
    loading,
    loadingCSV,
    downloadCSV,
    yLabel,
    decimalPlaces,
}) => {
    const classes = useStyles()
    const [title, setTitle] = useState(title1)
    const [showData, setShowData] = useState([])
    const [selected, setSelected] = useState('Litros totales')

    const handleClickSelectionPanel = value => {
        switch (value) {
            case 'Litros totales':
                setShowData(data.litros)
                setSelected('Litros totales')
                break
            case 'Grasa':
                setShowData(data.grasa)
                setSelected('Grasa')
                break
            case 'Proteína':
                setShowData(data.proteina)
                setSelected('Proteína')
                break
            case 'Úrea en leche':
                setShowData(data.urea)
                setSelected('Úrea en leche')
                break
            case 'UFC':
                setShowData(data.UFC)
                setSelected('UFC')
                break
            case 'RCS':
                setShowData(data.RCS)
                setSelected('RCS')
                break
            default:
                setShowData(data.litros)
                setSelected('Litros totales')
                break
        }
        setTitle(value)
    }

    useEffect(() => {
        switch (selected) {
            case 'Litros totales':
                setShowData(data?.litros || [])
                break
            case 'Grasa':
                setShowData(data?.grasa || [])
                break
            case 'Proteína':
                setShowData(data?.proteina || [])
                break
            case 'Úrea en leche':
                setShowData(data?.urea || [])
                break
            case 'UFC':
                setShowData(data?.UFC || [])
                break
            case 'RCS':
                setShowData(data?.RCS || [])
                break
            default:
                setShowData(data?.litros || [])
                break
        }
    }, [data, selected])

    return (
        <Paper elevation={0} className={classes.paperGeneralCard}>
            {isSideSelector ? (
                <Grid container>
                    {loading ? (
                        <Grid
                            container
                            item
                            xs={12}
                            lg={9}
                            justifyContent="center"
                            alignItems="center"
                            style={{ paddingTop: 100, paddingBottom: 100 }}
                        >
                            <Grid item>
                                <CircularProgress size={100} />
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid item xs={12} lg={9}>
                            <CartaTablaGraficoContent
                                type={
                                    selected === 'UFC' || selected === 'RCS' || selected === 'Úrea en leche'
                                        ? 'bar'
                                        : type
                                }
                                view={view}
                                title1={title}
                                title2={title}
                                subtitle1={subtitle1}
                                subtitle2={subtitle2}
                                data={showData}
                                heightChart={500}
                                isSideSelector={isSideSelector}
                                handleClickSelectionPanel={value => handleClickSelectionPanel(value)}
                                forecast={forecast /* selected === 'Litros totales' */}
                                buttons={buttons}
                                yLabel={
                                    selected === 'Litros totales'
                                        ? 'Litros'
                                        : selected === 'Grasa'
                                        ? 'gr/Lt'
                                        : selected === 'Proteína'
                                        ? 'gr/Lt'
                                        : selected === 'Úrea en leche'
                                        ? 'mg/Lt'
                                        : selected === 'RCS'
                                        ? 'células/mLt'
                                        : selected === 'UFC'
                                        ? 'células/mLt'
                                        : yLabel
                                }
                                loadingCSV={loadingCSV}
                                downloadCSV={downloadCSV}
                                maxValue={
                                    selected === 'Litros totales'
                                        ? maxValue.litros
                                        : selected === 'Grasa'
                                        ? maxValue.grasa
                                        : selected === 'Proteína'
                                        ? maxValue.proteina
                                        : null
                                }
                                minValue={
                                    selected === 'Litros totales'
                                        ? minValue.litros
                                        : selected === 'Grasa'
                                        ? minValue.grasa
                                        : selected === 'Proteína'
                                        ? minValue.proteina
                                        : null
                                }
                                decimalPlaces={selected === 'Grasa' || selected === 'Proteína' ? 2 : 0}
                            />
                        </Grid>
                    )}
                    <Hidden mdDown>
                        <Grid item xs={12} lg={3}>
                            <Box
                                display="flex"
                                justifyContent="center"
                                borderLeft={2}
                                borderColor={'#d2dae0'}
                                style={{ height: '100%' }}
                            >
                                <SelectionPanel
                                    value_={title}
                                    handleClickSelectionPanel={value => handleClickSelectionPanel(value)}
                                />
                            </Box>
                        </Grid>
                    </Hidden>
                </Grid>
            ) : (
                <Fragment>
                    {loading ? (
                        <Grid
                            container
                            item
                            xs={12}
                            justifyContent="center"
                            alignItems="center"
                            style={{ paddingTop: 100, paddingBottom: 100 }}
                        >
                            <Grid item>
                                <CircularProgress size={100} />
                            </Grid>
                        </Grid>
                    ) : (
                        <CartaTablaGraficoContent
                            type={type}
                            view={view}
                            title1={title}
                            title2={title}
                            subtitle1={subtitle1}
                            subtitle2={subtitle2}
                            data={data}
                            forecast={forecast}
                            buttons={buttons}
                            yLabel={yLabel}
                            loadingCSV={loadingCSV}
                            downloadCSV={downloadCSV}
                            maxValue={maxValue}
                            minValue={minValue}
                            decimalPlaces={decimalPlaces}
                        />
                    )}
                </Fragment>
            )}
        </Paper>
    )
}

export default CartaTablaGrafico
