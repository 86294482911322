/* React */
import React, { createContext, useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

/* Context */
import { AuthContext } from './'

export const MenuContext = createContext()

const MenuContextProvider = props => {
    const { initLogout, permissions } = useContext(AuthContext)
    const location = useLocation()
    const [open, setOpen] = useState(false)

    const [selectedItem, setSelectedItem] = useState({
        /* text: 'Vista general', id: 21, menuId: 'produccion' */
    })

    const [menuItems, setMenuItems] = useState([
        ...(permissions.produccion.vistaGeneral > 0 ||
        permissions.produccion.calidad > 0 ||
        permissions.produccion.productividad > 0 ||
        permissions.produccion.inviernoVerano > 0 ||
        permissions.produccion.kpi > 0
            ? [{ text: 'Producción', id: 'produccion', open: false, isCollapsible: true }]
            : []),
        ...(permissions.facturacion.resumenPrecios > 0 || permissions.facturacion.liquidaciones > 0
            ? [{ text: 'Facturación', id: 'facturacion', open: false, isCollapsible: true }]
            : []),
        ...(permissions.situacionSanitaria > 0
            ? [
                  {
                      text: 'Situación sanitaria',
                      link: '/situacion-sanitaria',
                      id: 'situacion-sanitaria',
                      open: false,
                      isCollapsible: false,
                  },
              ]
            : []),
        ...(permissions.forraje > 0
            ? [
                  {
                      text: 'Forraje',
                      link: '/forraje',
                      id: 'forraje',
                      open: false,
                      isCollapsible: false,
                  },
              ]
            : []),
        ...(permissions.recoleccion > 0
            ? [
                  {
                      text: 'Recolección',
                      link: '/recoleccion',
                      id: 'recoleccion',
                      open: false,
                      isCollapsible: false,
                  },
              ]
            : []),
        /* {
            text: 'Clima',
            link: '/clima',
            id: 'clima',
            open: false,
            isCollapsible: false,
        }, */
        ...(permissions.administracion > 0
            ? [{ text: 'Administración', id: 'admin', open: false, isCollapsible: true }]
            : []),
        // { text: 'Cargar información', id: 6, open: false, isCollapsible: false },
        {
            text: 'Perfil',
            link: '/perfil',
            id: 'perfil',
            open: false,
            isCollapsible: false,
        },
        {
            text: 'Cerrar sesión',
            link: '/',
            id: 'cerrar-sesion',
            open: false,
            isCollapsible: false,
        },
    ])

    const [subMenuItems, setSubMenuItems] = useState([
        // const subMenuItems = [
        // Producción
        ...(permissions.produccion.vistaGeneral > 0
            ? [{ text: 'Vista general', link: '/vista-general', id: 21, menuId: 'produccion' }]
            : []),
        ...(permissions.produccion.calidad > 0
            ? [{ text: 'Calidad', link: '/calidad', id: 22, menuId: 'produccion' }]
            : []),
        ...(permissions.produccion.productividad > 0
            ? [{ text: 'Productividad', link: '/productividad', id: 23, menuId: 'produccion' }]
            : []),
        ...(permissions.produccion.inviernoVerano > 0
            ? [
                  {
                      text: 'Relación Invierno-Verano',
                      link: '/invierno-verano',
                      id: 24,
                      menuId: 'produccion',
                  },
              ]
            : []),
        ...(permissions.facturacion.kpi > 0
            ? [{ text: 'KPI (Modo prueba)', link: '/kpi', id: 33, menuId: 'produccion' }]
            : []),
        ...(permissions.facturacion.resumenPrecios > 0
            ? [{ text: 'Resumen precios', link: '/resumen-precios', id: 25, menuId: 'facturacion' }]
            : []),
        ...(permissions.facturacion.liquidaciones > 0
            ? [{ text: 'Liquidaciones', link: '/liquidaciones', id: 26, menuId: 'facturacion' }]
            : []),
        // Administración
        { text: 'Usuarios', link: '/usuarios', id: 27, menuId: 'admin' },
        // { text: 'Predios', link: '/predios', id: 28, menuId: 'admin' },
        { text: 'Salas de ordeñas', link: '/salas', id: 29, menuId: 'admin' },
        // { text: 'Estanques', link: '/tanks', id: 30, menuId: 'admin' },
        // { text: 'Horarios de ordeñas', link: '/schedule', id: 31, menuId: 'admin' },
        { text: 'Asignación de salas', link: '/asignacion-salas', id: 32, menuId: 'admin' },
        {
            text: 'Asignación de permisos',
            link: '/asignacion-permisos',
            id: 33,
            menuId: 'admin',
        },
        // { text: 'Asignación de horarios', link: '/scheduleassignment', id: 34, menuId: 'admin' }
    ])

    useEffect(() => {
        setMenuItems([
            ...(permissions.produccion.vistaGeneral > 0 ||
                permissions.produccion.calidad > 0 ||
                permissions.produccion.productividad > 0 ||
                permissions.produccion.inviernoVerano > 0 ||
                permissions.produccion.kpi > 0
                    ? [{ text: 'Producción', id: 'produccion', open: false, isCollapsible: true }]
                    : []
                ),
            ...(permissions.facturacion.resumenPrecios > 0 || permissions.facturacion.liquidaciones > 0
                ? [{ text: 'Facturación', id: 'facturacion', open: false, isCollapsible: true }]
                : []),
            ...(permissions.situacionSanitaria > 0
                ? [
                      {
                          text: 'Situación sanitaria',
                          link: '/situacion-sanitaria',
                          id: 'situacion-sanitaria',
                          open: false,
                          isCollapsible: false,
                      },
                  ]
                : []),
            ...(permissions.forraje > 0
                ? [
                      {
                          text: 'Forraje',
                          link: '/forraje',
                          id: 'forraje',
                          open: false,
                          isCollapsible: false,
                      },
                  ]
                : []),
            ...(permissions.recoleccion > 0
                ? [
                      {
                          text: 'Recolección',
                          link: '/recoleccion',
                          id: 'recoleccion',
                          open: false,
                          isCollapsible: false,
                      },
                  ]
                : []),
            /* {
                text: 'Clima',
                link: '/clima',
                id: 'clima',
                open: false,
                isCollapsible: false,
            }, */
            ...(permissions.administracion > 0
                ? [{ text: 'Administración', id: 'admin', open: false, isCollapsible: true }]
                : []),
            // { text: 'Cargar información', id: 6, open: false, isCollapsible: false },
            {
                text: 'Perfil',
                link: '/perfil',
                id: 'perfil',
                open: false,
                isCollapsible: false,
            },
            {
                text: 'Cerrar sesión',
                link: '/',
                id: 'cerrar-sesion',
                open: false,
                isCollapsible: false,
            },
        ])
        setSubMenuItems([
            // const subMenuItems = [
            // Producción
            ...(permissions.produccion.vistaGeneral > 0
                ? [{ text: 'Vista general', link: '/vista-general', id: 21, menuId: 'produccion' }]
                : []),
            ...(permissions.produccion.calidad > 0
                ? [{ text: 'Calidad', link: '/calidad', id: 22, menuId: 'produccion' }]
                : []),
            ...(permissions.produccion.productividad > 0
                ? [{ text: 'Productividad', link: '/productividad', id: 23, menuId: 'produccion' }]
                : []),
            ...(permissions.produccion.inviernoVerano > 0
                ? [
                      {
                          text: 'Relación Invierno-Verano',
                          link: '/invierno-verano',
                          id: 24,
                          menuId: 'produccion',
                      },
                  ]
                : []),
            ...(permissions.produccion.kpi > 0
                ? [{ text: 'KPI (Modo prueba)', link: '/kpi', id: 33, menuId: 'produccion' }]
                : []),
            ...(permissions.facturacion.resumenPrecios > 0
                ? [
                      {
                          text: 'Resumen precios',
                          link: '/resumen-precios',
                          id: 25,
                          menuId: 'facturacion',
                      },
                  ]
                : []),
            ...(permissions.facturacion.liquidaciones > 0
                ? [{ text: 'Liquidaciones', link: '/liquidaciones', id: 26, menuId: 'facturacion' }]
                : []),
            // Administración
            { text: 'Usuarios', link: '/usuarios', id: 27, menuId: 'admin' },
            // { text: 'Predios', link: '/predios', id: 28, menuId: 'admin' },
            { text: 'Salas de ordeñas', link: '/salas', id: 29, menuId: 'admin' },
            // { text: 'Estanques', link: '/tanks', id: 30, menuId: 'admin' },
            // { text: 'Horarios de ordeñas', link: '/schedule', id: 31, menuId: 'admin' },
            {
                text: 'Asignación de salas',
                link: '/asignacion-salas',
                id: 32,
                menuId: 'admin',
            },
            {
                text: 'Asignación de permisos',
                link: '/asignacion-permisos',
                id: 33,
                menuId: 'admin',
            },
            // { text: 'Asignación de horarios', link: '/scheduleassignment', id: 34, menuId: 'admin' }
        ])
    }, [permissions])

    useEffect(() => {
        if (location.pathname === '/') {
            setSelectedItem({
                /*  text: 'Vista general', id: 21, menuId: 'produccion'  */
            })
        } else {
            const findMenu = menuItems.find(item => item.link === location.pathname)
            if (findMenu) {
                setSelectedItem({
                    text: findMenu.text,
                    id: findMenu.id,
                    menuId: findMenu.menuId,
                })
                return
            }
            const findSubMenu = subMenuItems.find(item => item.link === location.pathname)
            if (findSubMenu) {
                setSelectedItem({
                    text: findSubMenu.text,
                    id: findSubMenu.id,
                    menuId: findSubMenu.menuId,
                })
            }
        }
    }, [location])

    const handleClickMenu = id => {
        const menuItems_ = [...menuItems].map(item =>
            item.id === id ? { ...item, open: !item.open } : { ...item, open: false }
        )
        setMenuItems(menuItems_)

        const menuItem = menuItems.find(x => x.id === id)
        if (!menuItem.isCollapsible) {
            // setSelectedItem({ text: menuItem.text, id: menuItem.id, menuId: 0 })
            handleDrawerToggle(false)
        }
        if (id === 'cerrar-sesion') {
            initLogout()
        }
    }

    const handleClickSubMenu = id => {
        // const subMenuItem = subMenuItems.find(x => x.id === id)
        // setSelectedItem({ text: subMenuItem.text, id: subMenuItem.id, menuId: subMenuItem.menuId })
        handleDrawerToggle(false)
    }

    const handleDrawerToggle = value => {
        typeof value === 'boolean' ? setOpen(value) : setOpen(!open)
    }

    return (
        <MenuContext.Provider
            value={{
                selectedItem,
                menuItems,
                subMenuItems,
                handleClickMenu,
                handleClickSubMenu,
                open,
                handleDrawerToggle,
                location,
            }}
        >
            {props.children}
        </MenuContext.Provider>
    )
}

export default MenuContextProvider
