/* React */
import React, { useContext } from 'react'

/* Material UI */
import { makeStyles, Paper } from '@material-ui/core'

/* Context */
import { AdminFilterContext, HorariosContext } from '../../contexts/administracion'
import { AuthContext } from '../../contexts'

/* Components */
import { AdminFilter, AdminCreateItemForm } from '../../components/administracion'
import { TablaAdministracion } from '../../components/tabla'

const useStyles = makeStyles(theme => ({
    paperGeneralCard: {
        borderRadius: 12,
        '& .MuiDataGrid-root': {
            border: 0,
            // borderRadius: 12
        },
        marginTop: 10,
        marginBottom: 10,
    },
    dataGrid: {
        height: 650,
        width: '100%',
        padding: 8,
        '& .cell': {
            color: '#050505',
            fontSize: 14,
            fontWeight: 800,
            padding: '0px 4px 0px 4px',
            textAlign: 'left',
        },
        '& .header': {
            color: '#737373',
            fontSize: 16,
            fontWeight: 800,
            padding: '0px 4px 0px 4px',
            textAlign: 'left',
        },
    },
}))

const HorariosManagment = props => {
    const { permissions } = useContext(AuthContext)
    const { columns, horarios, formFields, handleClickCreateButton, editHorarioId, setEditHorarioId, mobileSelection } =
        useContext(HorariosContext)
    const classes = useStyles()
    const { search, isCreate } = useContext(AdminFilterContext)

    const filterItems = query =>
        query.trim() === ''
            ? horarios
            : horarios.filter(el => {
                  const keys = Object.keys(el)
                  let text = ''
                  keys.forEach((key, index) => {
                      if (key !== 'id' && key !== 'estatus' && key !== 'rol') text += el[key]
                  })
                  return text.toLowerCase().indexOf(query.toLowerCase()) > -1
              })

    return (
        <Paper elevation={0} className={classes.paperGeneralCard}>
            {!isCreate ? (
                <>
                    <AdminFilter
                        createButtonText={'Crear horario'}
                        onClick={() => setEditHorarioId('')}
                        isOnlySearch={!(permissions?.administracion > 1)}
                    />
                    <TablaAdministracion
                        data={filterItems(search)}
                        headCells={columns}
                        sortDefault={'horaInicio'}
                        defaultCell={'Hora de término'}
                        fixedCell={'horaInicio'}
                        mobileSelection={mobileSelection}
                    />
                </>
            ) : (
                <AdminCreateItemForm
                    title={editHorarioId === '' ? 'Nuevo horario' : 'horario existente'}
                    fields={formFields}
                    createButtonText={editHorarioId === '' ? 'Crear horario' : 'Editar horario'}
                    onClick={values => handleClickCreateButton(values)}
                    sala={false}
                />
            )}
        </Paper>
    )
}

export default HorariosManagment
