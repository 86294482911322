/* React */
import React, { useContext, useEffect, useState } from 'react'

/* Material UI */
import { Grid } from '@material-ui/core'

/* Components */
import { CartaSituacionSanitaria } from '../../components/situacionSanitaria'
import { SalasContext } from '../../contexts/administracion'
import { AuthContext } from '../../contexts'
import { ProductoresList, SoloPredioFilter } from '../../components/produccion/filter'

const SituacionSanitaria = props => {
    const { data } = useContext(SalasContext)
    const { salas } = useContext(AuthContext)
    const [parsedCertificates, setParsedCertificates] = useState({
        BRU: {
            documentType: 'Brucelosis',
            abbreviation: 'BRU',
            data: [],
        },
        TBC: {
            documentType: 'Tuberculosis',
            abbreviation: 'TBC',
            data: [],
        },
        LEU: {
            documentType: 'Leucosis',
            abbreviation: 'LEU',
            data: [],
        },
        PAB: {
            documentType: 'Pabco lechero',
            abbreviation: 'PAB',
            data: [],
        },
    })

    useEffect(() => {
        const newCertificates = {
            BRU: {
                documentType: 'Brucelosis',
                abbreviation: 'BRU',
                data: [],
            },
            TBC: {
                documentType: 'Tuberculosis',
                abbreviation: 'TBC',
                data: [],
            },
            LEU: {
                documentType: 'Leucosis',
                abbreviation: 'LEU',
                data: [],
            },
            PAB: {
                documentType: 'Pabco lechero',
                abbreviation: 'PAB',
                data: [],
            },
        }

        data.forEach(sala => {
            const findPermission = salas.findIndex(x => x.sala === sala._id && x.enabled)
            if (findPermission < 0) {
                return
            }

            if (sala.certificates?.BRU?.zCondicion) {
                newCertificates.BRU.data.push({
                    salaId: sala._id,
                    producerCode: sala.producerCode,
                    startDate: sala.certificates.BRU.fechaActivacion,
                    endDate: sala.certificates.BRU.fechaCaducidad,
                })
            }
            if (sala.certificates?.TBC?.zCondicion) {
                newCertificates.TBC.data.push({
                    salaId: sala._id,
                    producerCode: sala.producerCode,
                    startDate: sala.certificates.TBC.fechaActivacion,
                    endDate: sala.certificates.TBC.fechaCaducidad,
                })
            }
            if (sala.certificates?.LEU?.zCondicion) {
                newCertificates.LEU.data.push({
                    salaId: sala._id,
                    producerCode: sala.producerCode,
                    startDate: sala.certificates.LEU.fechaActivacion,
                    endDate: sala.certificates.LEU.fechaCaducidad,
                })
            }
            if (sala.certificates?.PAB?.zCondicion) {
                newCertificates.PAB.data.push({
                    salaId: sala._id,
                    producerCode: sala.producerCode,
                    startDate: sala.certificates.PAB.fechaActivacion,
                    endDate: sala.certificates.PAB.fechaCaducidad,
                })
            }
        })

        setParsedCertificates(newCertificates)
    }, [data])
    return (
        <div>
            {/* <FilterSituacionSanitaria /> */}
            * Considera tanto certificados como protocolos en la descarga.
            <SoloPredioFilter />
            <ProductoresList />
            <Grid container spacing={2} style={{ marginTop: 8 }}>
                {Object.values(parsedCertificates)?.map((value, index) => {
                    return (
                        <Grid item xs={12} sm={6} lg={6} xl={4} key={index}>
                            <CartaSituacionSanitaria
                                documentType={value.documentType}
                                abbreviation={value.abbreviation}
                                data={value.data}
                            />
                        </Grid>
                    )
                })}
            </Grid>
        </div>
    )
}

export default SituacionSanitaria
