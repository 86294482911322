/* React */
import { Button, withStyles } from '@material-ui/core'

const OutlineButton = withStyles(theme => ({
    root: {
        color: theme.palette.primary.main,
        borderRadius: 12,
        padding: '15px 18px 15px 18px',
        width: 151,
        height: 50,
        fontSize: 20,
        fontWeight: 'bold',
        lineHeight: 1,
        border: '1px solid',
        textTransform: 'none',
        backgroundColor: 'white',
        '&:hover': {
            backgroundColor: '#f3f3f3',
        },
    },
}))(Button)

export default OutlineButton
