/* React */
import React, { Fragment, useContext, useEffect, useState } from 'react'

/* Material UI */
import {
    makeStyles,
    withStyles,
    Button,
    Link,
    TextField,
    Typography,
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Grid,
    FormControl,
    InputAdornment,
    IconButton,
    InputLabel,
    Input,
} from '@material-ui/core'

/* Context */
import { AuthContext } from '../../contexts'

/* Components */
/* import { ProlesurIcon } from '../icons' */
import { useSnackbar } from 'notistack'
import { Visibility, VisibilityOff } from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
    prolesur: {
        [theme.breakpoints.down('md')]: {
            marginTop: 0,
            marginBottom: 30,
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: 0,
            marginBottom: 120,
        },
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'flex',
    },
    welcome: {
        display: 'flex',
        color: '#7e8792',
        fontSize: 13,
        fontWeight: 'normal',
        textAlign: 'left',
    },
    title: {
        display: 'flex',
        color: '#292929',
        [theme.breakpoints.down('md')]: {
            fontSize: 24,
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: 32,
        },
        fontWeight: 800,
        textAlign: 'left',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        // marginTop: theme.spacing(1),
    },
    textField: {
        // '& .MuiFormLabel-root': {
        //     color: '#7e8792'
        // },
        // "& .MuiFormLabel-root.Mui-focused": {
        //     color: 'primary'
        // },
        '& .MuiInputBase-input': {
            color: '#292929',
            [theme.breakpoints.down('md')]: {
                fontSize: 12,
            },
            [theme.breakpoints.up('lg')]: {
                fontSize: 16,
            },
            // fontFamily: 'Open Sans',
            fontWeight: 600,
        },
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    forgetPass: {
        textAlign: 'center',
    },
}))

const MyButton = withStyles(theme => ({
    root: {
        color: '#fff',
        backgroundColor: '#72bf44',
        [theme.breakpoints.down('md')]: {
            fontSize: 12,
            height: 44,
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: 16,
            height: 57,
        },
        fontWeight: 600,
        borderRadius: 24,
        '&:hover': {
            backgroundColor: '#729e44',
        },
    },
}))(Button)

const LoginForm = () => {
    /* const { toggleAuth, user, setUser, setLastDate } = useContext(AuthContext);
    const [pass, setPass] = useState('')
    const classes = useStyles();

    const [lastDateInMetrics, { loading, data }] = useLazyQuery(LAST_DATE_IN_METRICS);

    const onClickIniciarSesion = (e) => {
        e.preventDefault()
        if (pass !== 'kauel')
            return

        lastDateInMetrics({
            // fetchPolicy: "network-only",
            variables: { codigoProductor: user },
            // onCompleted: (data) => console.log(data)
        })
    }

    const okPass = () => {
        const lastdate = new Date(data?.lastDateInMetrics[0]?.fecha)
        console.log(lastdate)
        setLastDate(lastdate)
        toggleAuth()
    } */
    const classes = useStyles()

    const { loading, initLogin, resetPassword, salas } = useContext(AuthContext)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [showPassword, setShowPassword] = useState(false)
    const [open, setOpen] = useState(false)
    const { enqueueSnackbar } = useSnackbar()

    const onClickIniciarSesion = e => {
        e.preventDefault()
        initLogin(email, password)
    }

    const handleSubmitReset = event => {
        event.preventDefault()
        setOpen(false)
        resetPassword(email)
            .then(response => {
                if (!response.ok) {
                    return Promise.reject(response)
                }
                return response.json()
            })
            .then(response => {
                enqueueSnackbar(response.message, { variant: 'success' })
            })
            .catch(err => {
                console.log(err)
                enqueueSnackbar('Hubo un error en el servidor', { variant: 'error' })
            })
    }

    useEffect(() => {
        console.log(salas)
    }, [salas])

    return (
        <Fragment>
            {/* <ProlesurIcon className={classes.prolesur} /> */}
            <div style={{ textAlign: 'center', width: '100%', marginBottom: 50 }}>
                <img src="/logo.sorpole.png"/>
            </div>
            <span className={classes.welcome}>Bienvenido otra vez</span>
            <Typography className={classes.title} component="h1" variant="h5">
                Inicio de sesión
            </Typography>
            <form className={classes.form} noValidate onSubmit={e => onClickIniciarSesion(e)}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Correo electrónico"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    className={classes.textField}
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                />
                <FormControl style={{ display: 'flex', marginRight: 8, width: '100%' }}>
                    <InputLabel htmlFor="password">Contraseña</InputLabel>
                    <Input
                        name="password"
                        required
                        value={password}
                        type={showPassword ? 'text' : 'password'}
                        placeholder="Contraseña"
                        autoComplete="current-password"
                        onChange={e => setPassword(e.target.value)}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => setShowPassword(!showPassword)}
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>
                <MyButton
                    type="submit"
                    fullWidth
                    variant="contained"
                    // color="primary"
                    disableElevation
                    className={classes.submit}
                    disabled={loading}
                    startIcon={loading && <CircularProgress />}
                    // onClick={() => onClickIniciarSesion()}
                >
                    {loading ? 'INICIANDO SESIÓN' : 'INICIAR SESIÓN'}
                </MyButton>
                <div className={classes.forgetPass}>
                    <Link onClick={() => setOpen(true)}>¿Olvidaste tu contraseña?</Link>
                </div>
            </form>
            {/* <div>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <Button
                    fullWidth
                    variant="contained"
                    color="secondary"
                    href="http://productor.prolesur.cl"
                    style={{ borderRadius: 24 }}
                >
                    Ir a la página antigua
                </Button>
            </div> */}

            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>¿Olvidaste tu contraseña?</DialogTitle>
                <form onSubmit={handleSubmitReset}>
                    <DialogContent>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Correo electrónico"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            className={classes.textField}
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <Button fullWidth variant="contained" color="primary" type="submit">
                                    Enviar
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Button fullWidth onClick={() => setOpen(false)} variant="contained" color="primary">
                                    Cancelar
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogActions>
                </form>
            </Dialog>
        </Fragment>
    )
}

export default LoginForm
