/* React */
import React from 'react'

/* Material UI */
import { SvgIcon } from '@material-ui/core'

const SettingsIcon = props => (
    <SvgIcon {...props} viewBox="0 0 31.983 31.983">
        <g transform="translate(-1198.234 -406.723)">
            <g transform="translate(1198.234 406.723)">
                <path
                    d="M1217.358,438.706h-6.265l-.707-4.238a12.269,12.269,0,0,1-1.758-.728l-3.5,2.5-4.429-4.43,2.5-3.5a12.278,12.278,0,0,1-.728-1.759l-4.238-.706v-6.264l4.238-.707a12.275,12.275,0,0,1,.728-1.759l-2.5-3.5,4.43-4.43,3.5,2.5a12.255,12.255,0,0,1,1.758-.728l.707-4.238h6.265l.707,4.238a12.238,12.238,0,0,1,1.759.728l3.5-2.5,4.429,4.43-2.5,3.5a12.262,12.262,0,0,1,.728,1.759l4.238.707v6.264l-4.238.706a12.223,12.223,0,0,1-.728,1.759l2.5,3.5-4.429,4.43-3.5-2.5a12.219,12.219,0,0,1-1.759.728Zm-3.946-2.737h1.628l.616-3.692.875-.215a9.553,9.553,0,0,0,2.675-1.107l.771-.467,3.045,2.175,1.151-1.151L1222,428.466l.467-.771a9.552,9.552,0,0,0,1.107-2.675l.215-.875,3.693-.616V421.9l-3.693-.616-.215-.875a9.55,9.55,0,0,0-1.107-2.675l-.467-.771,2.175-3.045-1.151-1.151-3.045,2.175-.771-.467a9.545,9.545,0,0,0-2.675-1.107l-.875-.215-.616-3.693h-1.628l-.616,3.693-.875.215a9.546,9.546,0,0,0-2.675,1.107l-.771.467-3.045-2.175-1.151,1.151,2.175,3.045-.467.771a9.561,9.561,0,0,0-1.107,2.676l-.215.875-3.692.616v1.628l3.692.616.215.875a9.562,9.562,0,0,0,1.107,2.676l.467.771-2.175,3.046,1.151,1.151,3.045-2.175.771.467a9.555,9.555,0,0,0,2.675,1.107l.875.215Z"
                    transform="translate(-1198.234 -406.723)"
                />
            </g>
            <g transform="translate(1208.755 417.244)">
                <path
                    d="M1234.458,448.418a5.471,5.471,0,1,1,5.471-5.471A5.477,5.477,0,0,1,1234.458,448.418Zm0-8.2a2.734,2.734,0,1,0,2.734,2.734A2.737,2.737,0,0,0,1234.458,440.213Z"
                    transform="translate(-1228.987 -437.476)"
                />
            </g>
        </g>
    </SvgIcon>
)

export default SettingsIcon
