/* React */
import React from 'react'

/* Material UI */
import { SvgIcon } from '@material-ui/core'

const AdminIcon = props => (
    <SvgIcon {...props} viewBox="0 0 36.11 28.213">
        <g transform="translate(-870.234 -398.617)">
            <path
                d="M917.292,405.307A6.69,6.69,0,1,0,910.6,412,6.7,6.7,0,0,0,917.292,405.307Zm-6.69,4.011a4.011,4.011,0,1,1,4.011-4.011A4.016,4.016,0,0,1,910.6,409.319Z"
                transform="translate(-22.4)"
            />
            <path
                d="M876.924,442.632a6.69,6.69,0,1,0,6.69,6.69A6.7,6.7,0,0,0,876.924,442.632Zm0,10.7a4.011,4.011,0,1,1,4.011-4.011A4.016,4.016,0,0,1,876.924,453.334Z"
                transform="translate(0 -29.277)"
            />
            <path
                d="M944.8,442.913a6.69,6.69,0,1,0,6.69,6.69A6.7,6.7,0,0,0,944.8,442.913Zm0,10.7a4.011,4.011,0,1,1,4.011-4.011A4.016,4.016,0,0,1,944.8,453.615Z"
                transform="translate(-45.15 -29.464)"
            />
            <rect width="2.679" height="3.863" transform="matrix(0.808, -0.589, 0.589, 0.808, 891.871, 411.882)" />
            <rect width="3.816" height="2.679" transform="translate(880.173 413.382) rotate(-53.818)" />
        </g>
    </SvgIcon>
)

export default AdminIcon
