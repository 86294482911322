/* React */
import React, { useContext, useEffect, useState } from 'react'

/* Material UI */
import { Grid, makeStyles, Paper } from '@material-ui/core'

/* Context */
import { AdminFilterContext, AsignacionHorariosContext, SalasContext } from '../../contexts/administracion'

/* Components */
import { AdminFilter } from '../../components/administracion'
import { TablaManagement } from '../../components/tabla'

const useStyles = makeStyles(theme => ({
    paperGeneralCard: {
        borderRadius: 12,
        '& .MuiDataGrid-root': {
            border: 0,
            // borderRadius: 12
        },
    },
    dataGrid: {
        height: 650,
        width: '100%',
        padding: 8,
        '& .cell': {
            color: '#050505',
            fontSize: 14,
            fontWeight: 800,
            padding: '0px 4px 0px 4px',
            textAlign: 'left',
        },
        '& .header': {
            color: '#737373',
            fontSize: 16,
            fontWeight: 800,
            padding: '0px 4px 0px 4px',
            textAlign: 'left',
        },
    },
}))

const AsignacionHorariosManagment = props => {
    const { salasColumns, horariosColumns, parsedHorarios, selectedId, handleSelected } =
        useContext(AsignacionHorariosContext)
    const { salas } = useContext(SalasContext)
    const [filteredSalas, setFilteredSalas] = useState([])
    const classes = useStyles()
    const { search } = useContext(AdminFilterContext)

    useEffect(() => {
        handleSelected(null)
        if (search.trim() !== '') {
            setFilteredSalas(
                salas.filter(el => {
                    const keys = Object.keys(el)
                    let text = ''
                    keys.forEach((key, index) => {
                        if (key === 'name' || key === 'lastName') text += el[key]
                    })
                    // console.log(text)
                    return text.toLowerCase().indexOf(search.toLowerCase()) > -1
                })
            )
        } else {
            setFilteredSalas(salas)
        }
    }, [search])

    return (
        <Grid container spacing={2} className={classes.filters} style={{ marginTop: 8 }}>
            <Grid item xs={12} md={6}>
                <Paper elevation={0} className={classes.paperGeneralCard}>
                    <AdminFilter isOnlySearch />
                    <TablaManagement
                        data={filteredSalas}
                        headCells={salasColumns}
                        sortDefault={'name'}
                        selectedId={selectedId}
                        handleSelected={handleSelected}
                    />
                    {/* <div className={classes.dataGrid}>
                        <DataGrid
                            rows={filterItems(search)}
                            columns={salasColumns}
                            pagination
                            pageSize={10}
                            components={{
                                pagination: CustomPagination
                            }}
                            // checkboxSelection
                            disableMultipleSelection
                        // hideFooter={true}
                        // hideFooterPagination={true}
                        // hideFooterRowCount={true}
                        // disableSelectionOnClick={true}
                        />
                    </div> */}
                </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
                <Paper elevation={0} className={classes.paperGeneralCard}>
                    <TablaManagement data={parsedHorarios} headCells={horariosColumns} sortDefault={'horaInicio'} />
                </Paper>
            </Grid>
        </Grid>
    )
}

export default AsignacionHorariosManagment
