/* React */
import React, { useContext } from 'react'

/* Material UI */
import { MenuItem, Select } from '@material-ui/core'

/* Context */
import { FilterContext } from '../../../contexts/produccion'

const startYear = 2017

const date = new Date()
const currentYear = date.getFullYear()

// generar lista de items
const items = []
for (let i = currentYear; i >= startYear; i -= 1) {
    items.push(
        <MenuItem key={i} value={i}>
            {i}
        </MenuItem>
    )
}

const YearFilter = props => {
    const { yearFilter, setYearFilter } = useContext(FilterContext)

    const handleChangeYear = event => {
        setYearFilter(event.target.value)
    }

    return (
        <Select
            value={yearFilter}
            onChange={handleChangeYear}
            MenuProps={{
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                },
                getContentAnchorEl: null,
            }}
            renderValue={selected => {
                if (selected.length === 0) {
                    return <span>Año</span>
                }

                return selected
            }}
            fullWidth
            displayEmpty
        >
            {items}
        </Select>
    )
}

export default YearFilter
