/* GraphQL */
import { gql } from '@apollo/client'

export const USERS = gql`
    query Users($isAdmin: [ID]) {
        users(isAdmin:$isAdmin) {
            _id
            name
            RUT
            lastName
            fullName
            email
            phone
            position
            role
            enabled
            sociedades {
                _id
                masterCode
                RUT
                name
                description
                enabled
                createdAt
                updatedAt
            }
            permissions {
                produccion {
                    vistaGeneral
                    calidad
                    productividad
                    inviernoVerano
                    kpi
                }
                facturacion {
                    resumenPrecios
                    liquidaciones
                }
                situacionSanitaria
                forraje
                recoleccion
                administracion
            }
            salas {
                sala
                enabled
            }
            createdAt
            updatedAt
        }
    }
`
export const EDIT_USER_PERMISSIONS = gql`
    mutation editUserPermissions(
        $userId: ID!
        $vistaGeneral: Int
        $calidad: Int
        $productividad: Int
        $inviernoVerano: Int
        $kpi: Int
        $resumenPrecios: Int
        $liquidaciones: Int
        $situacionSanitaria: Int
        $forraje: Int
        $recoleccion: Int
        $administracion: Int
    ) {
        editUser(
            userId: $userId
            vistaGeneral: $vistaGeneral
            calidad: $calidad
            productividad: $productividad
            inviernoVerano: $inviernoVerano
            kpi: $kpi
            resumenPrecios: $resumenPrecios
            liquidaciones: $liquidaciones
            situacionSanitaria: $situacionSanitaria
            forraje: $forraje
            recoleccion: $recoleccion
            administracion: $administracion
        ) {
            _id
            permissions {
                produccion {
                    productividad
                    inviernoVerano
                    kpi
                }
                facturacion {
                    resumenPrecios
                    liquidaciones
                }
                situacionSanitaria
                forraje
                recoleccion
                administracion
            }
        }
    }
`

export const EDIT_USER = gql`
    mutation Users(
        $userId: ID!
        $name: String
        $email: String
        $lastName: String
        $phone: String
        $position: String
        $RUT: String
        $role: ID
        $salas: [SalaAccessInput]
        $sociedadesIds: [ID]
        $enabled: Boolean
        $vistaGeneral: Int
        $calidad: Int
        $productividad: Int
        $inviernoVerano: Int
        $kpi: Int
        $resumenPrecios: Int
        $liquidaciones: Int
        $situacionSanitaria: Int
        $forraje: Int
        $recoleccion: Int
        $administracion: Int
        $password: String
    ) {
        editUser(
            userId: $userId
            name: $name
            email: $email
            lastName: $lastName
            phone: $phone
            position: $position
            RUT: $RUT
            role: $role
            salas: $salas
            sociedadesIds: $sociedadesIds
            enabled: $enabled
            vistaGeneral: $vistaGeneral
            calidad: $calidad
            productividad: $productividad
            inviernoVerano: $inviernoVerano
            kpi: $kpi
            resumenPrecios: $resumenPrecios
            liquidaciones: $liquidaciones
            situacionSanitaria: $situacionSanitaria
            forraje: $forraje
            recoleccion: $recoleccion
            administracion: $administracion
            password: $password
        ) {
            _id
            name
            RUT
            lastName
            fullName
            email
            phone
            position
            role
            enabled
            sociedades {
                _id
                masterCode
                RUT
                name
                description
                enabled
                createdAt
                updatedAt
            }
            permissions {
                produccion {
                    productividad
                    inviernoVerano
                    kpi
                }
                facturacion {
                    resumenPrecios
                    liquidaciones
                }
                situacionSanitaria
                forraje
                recoleccion
                administracion
            }
            salas {
                sala
                enabled
            }
            createdAt
            updatedAt
        }
    }
`
/*  
    $sociedadesIds: [ID]
    sociedadesIds: $sociedadesIds
    $role: ID
    role: $role
*/
export const CREATE_USER = gql`
    mutation Users(
        $email: String!
        $RUT: String
        $name: String
        $lastName: String
        $phone: String
        $position: String
        $enabled: Boolean
    ) {
        createUser(
            email: $email
            RUT: $RUT
            name: $name
            lastName: $lastName
            phone: $phone
            position: $position
            enabled: $enabled
        ) {
            message
        }
    }
`

export const MODIFY_SELF = gql`
    mutation Users($password: String, $RUT: String, $name: String, $lastName: String, $phone: String) {
        modifySelf(password: $password, RUT: $RUT, name: $name, lastName: $lastName, phone: $phone) {
            message
        }
    }
`

export const SET_AVATAR = gql`
    mutation Users($file: Upload!) {
        setAvatar(file: $file) {
            message
        }
    }
`

export const DELETE_USER = gql`
    mutation Users($userId: ID!) {
        deleteUser(userId: $userId) {
            _id
            name
            RUT
            lastName
            fullName
            email
            phone
            position
            role
            enabled
            sociedades {
                _id
                masterCode
                RUT
                name
                description
                enabled
                createdAt
                updatedAt
            }
            permissions {
                produccion {
                    productividad
                    inviernoVerano
                    kpi
                }
                facturacion {
                    resumenPrecios
                    liquidaciones
                }
                situacionSanitaria
                forraje
                recoleccion
                administracion
            }
            salas {
                sala
                enabled
            }
            createdAt
            updatedAt
        }
    }
`
