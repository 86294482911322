/*  React */
import React, { useContext, Fragment, useRef, useEffect } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

/* GraphQL */
import { ApolloClient, ApolloProvider, ApolloLink, InMemoryCache } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { createUploadLink } from 'apollo-upload-client'

/* Material UI */
import { Button, ThemeProvider } from '@material-ui/core'
import theme from './themeConfig'

/* Snackbar */
import { SnackbarProvider } from 'notistack'

/* Pages */
import { Login, MainContent, ResetPassword, /* Welcome,  */UsuarioData } from './pages'

/* Navigation */
import { MenuNavigation } from './components/menu'

/* Context */
import { AuthContextProvider, AuthContext, MenuContextProvider } from './contexts'
import { FilterContextProvider } from './contexts/produccion'

import BgImage from './bg.jpg'

/* GraphQL Configuration */
const errorLink = onError(({ graphQLErrors, networkError, response }) => {
    if (networkError?.statusCode === 401) {
        console.log(`[Network error]: ${networkError.statusCode}`)
    }
})

const httpLink = createUploadLink({
    uri: '/graphql',
    credentials: 'include',
})


const link = ApolloLink.from([errorLink, httpLink])


const client = new ApolloClient({
    link: link,
    cache: new InMemoryCache(),
})

/* const clientNoLogin = new ApolloClient({
    uri: '/graphql/noLogin',
    cache: new InMemoryCache(),
}) */

/* Auxiliary component to be able to provide authentication for path selection */
const OnApp = () => {
    const nodeEnv = process.env.NODE_ENV
    const nodeEnvReact = process.env.REACT_APP_NODE_ENV
    const { isAuthenticated } = useContext(AuthContext)
    const background = {
        background: `linear-gradient(rgba(255,255,255,.8), rgba(255,255,255,.8)), url(${BgImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundPositionY: 'top',
        height: '100%',
    }
    useEffect(() => {
        console.log(nodeEnv)
        console.log(nodeEnvReact)
    },[nodeEnv, nodeEnvReact])
    return (
        <Switch>
            <Route exact path="/login" component={Login} />
            <Route exact path={['/passwordreset/:token']} render={() => <ResetPassword />} />
            <Route
                path={['/']}
                render={() =>
                    isAuthenticated ? (
                        <Fragment>
                            <MenuNavigation />
                            <MainContent />
                        </Fragment>
                    ) : (
                        <div style={background}>
                            <Login />
                            {/* <Welcome client={clientNoLogin}/> */}
                        </div>
                    )
                }
            />
            <Route exact path="/user-data" component={UsuarioData} />
            <Route path={!isAuthenticated ? ['/'] : []} render={() => <Login />} />
        </Switch>
    )
}

/*  Main app component, activates providers for the whole app (Theme, Snackbar, Apollo and AuthContext) */
function App() {
    const notistackRef = useRef()

    const onClickDismiss = key => () => {
        notistackRef.current.closeSnackbar(key)
    }

    return (
        <ThemeProvider theme={theme}>
            <SnackbarProvider
                ref={notistackRef}
                maxSnack={3}
                anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
                action={key => (
                    <Button onClick={onClickDismiss(key)} color="inherit">
                        Cerrar
                    </Button>
                )}
            >
                <ApolloProvider client={client}>
                    <Router>
                        <AuthContextProvider>
                            <MenuContextProvider>
                                <FilterContextProvider>
                                    <OnApp />
                                </FilterContextProvider>
                            </MenuContextProvider>
                        </AuthContextProvider>
                    </Router>
                </ApolloProvider>
            </SnackbarProvider>
        </ThemeProvider>
    )
}

export default App
