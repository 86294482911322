/* React */
import React, { createContext, useContext, useEffect, useState } from 'react'

/* Context */
import { DataContext } from '../produccion'

/* File Downloading */
import { saveAs } from 'file-saver'

export const RecoleccionContext = createContext()

const RecoleccionContextProvider = props => {
    const { dataNew, queryServerDate: queryServer, loadingOld, loadingNew } = useContext(DataContext)
    const [loadingCSV, setLoadingCSV] = useState(false)
    const [recoleccion, setRecoleccion] = useState([])

    const mobileSelection = [
        {
            name: 'Productor',
            cellFields: ['QMEL_LIFNR'],
        },
        {
            name: 'Cantidad (L)',
            cellFields: ['CANTIDAD'],
        },
        {
            name: 'Camión',
            cellFields: ['CAMION'],
        },
        {
            name: 'Nro. Guía',
            cellFields: ['N_GUIA'],
        },
    ]

    const columns = [
        {
            field: 'FECHA_AV',
            headerName: 'Fecha de retiro',
            headerClassName: 'header',
            sortable: true,
        },
        {
            field: 'QMEL_LIFNR',
            headerName: 'Productor',
            headerClassName: 'header',
            sortable: true,
        },
        {
            field: 'CANTIDAD',
            headerName: 'Cantidad (L)',
            headerClassName: 'header',
            sortable: true,
        },
        {
            field: 'CAMION',
            headerName: 'Camión',
            headerClassName: 'header',
            sortable: true,
        },
        {
            field: 'N_GUIA',
            headerName: 'Nro. Guía',
            headerClassName: 'header',
            sortable: true,
        },
    ]

    const [cardFormat, setCardFormat] = useState(true)

    useEffect(() => {
        if (dataNew?.length >= 0) {
            const data_ = []
            dataNew.forEach(item => {
                const index = data_.findIndex(x => x.QMEL_LIFNR === item.QMEL_LIFNR)
                const date = item.FECHA_AV.split('T')[0]
                const saveDate = date.split('-').join('/')
                if (index > -1) {
                    data_[index].data.push({
                        FECHA_AV: saveDate,
                        CAMION: item.CAMION,
                        CANTIDAD: item.CANTIDAD,
                        N_GUIA: item.N_GUIA,
                    })
                } else {
                    data_.push({
                        QMEL_LIFNR: item.QMEL_LIFNR,
                        data: [
                            {
                                FECHA_AV: saveDate,
                                CAMION: item.CAMION,
                                CANTIDAD: item.CANTIDAD,
                                N_GUIA: item.N_GUIA,
                            },
                        ],
                    })
                }
            })
            setRecoleccion(data_)
        }
    }, [dataNew])

    const downloadCSV = async () => {
        setLoadingCSV(true)
        let csvData = 'productor, fecha, camion, nroguia, litros'
        recoleccion.forEach(producer => {
            producer.data.forEach(item => {
                csvData +=
                    `\n${producer.QMEL_LIFNR},` +
                    `${item.FECHA_AV},` +
                    `${item.CAMION},` +
                    `${item.N_GUIA},` +
                    `${item.CANTIDAD}`
            })
        })

        const blob = new Blob([csvData], { type: 'text/plainl;charset=utf-8' })
        saveAs(blob, 'RecoleccionGeneral.csv')
        setLoadingCSV(false)
    }

    const downloadProducerCSV = async code => {
        const dataDownload = recoleccion.find(x => x.QMEL_LIFNR === code)
        if (!dataDownload) {
            return
        }
        let csvData = 'productor, fecha, camion, nroguia, litros'
        dataDownload.data.forEach(item => {
            csvData +=
                `\n${dataDownload.QMEL_LIFNR},` +
                `${item.FECHA_AV},` +
                `${item.CAMION},` +
                `${item.N_GUIA},` +
                `${item.CANTIDAD}`
        })

        const blob = new Blob([csvData], { type: 'text/plainl;charset=utf-8' })
        saveAs(blob, dataDownload.QMEL_LIFNR + 'Recoleccion.csv')
    }

    return (
        <RecoleccionContext.Provider
            value={{
                columns,
                queryServer,
                recoleccion,
                setRecoleccion,
                mobileSelection,
                cardFormat,
                setCardFormat,
                loadingOld,
                loadingNew,
                downloadCSV,
                loadingCSV,
                downloadProducerCSV,
            }}
        >
            {props.children}
        </RecoleccionContext.Provider>
    )
}

export default RecoleccionContextProvider
