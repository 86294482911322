import React, { createContext, useContext, useEffect, useState } from 'react'
import { DataContext } from './DataContext'
import { FilterContext } from './FilterContext'
import { useLazyQuery, gql } from '@apollo/client'
import { saveAs } from 'file-saver'

export const VistaGeneralContext = createContext()

const GENERAL_VIEW_METRICS = gql`
    fragment fields on AggregatedMetric {
        _id {
            year
            month
            day @skip(if: $groupMonthly)
        }
        LITROS_TOTAL
        GRASA
        PROTEINA
        UREA
        UFC
        RCS
    }

    query generalViewMetrics(
        $startCurrentYear: String!
        $endCurrentYear: String!
        $startLastYear: String!
        $endLastYear: String!
        $groupMonthly: Boolean!
        $producerCodes: [String]
    ) {
        lastYear: aggregatedMetrics(
            fechaInicio: $startLastYear
            fechaFin: $endLastYear
            groupMonthly: $groupMonthly
            producerCodes: $producerCodes
        ) {
            metrics {
                ...fields
            }
        }
        currentYear: aggregatedMetrics(
            fechaInicio: $startCurrentYear
            fechaFin: $endCurrentYear
            groupMonthly: $groupMonthly
            producerCodes: $producerCodes
        ) {
            resultSAP
            metrics {
                ...fields
            }
        }
    }
`

const magnitude = n => {
    const order = Math.floor(Math.log(n) / Math.LN10 + 0.000000001) // because float math sucks like that
    return Math.pow(10, order)
}

const groupData = dataIn => {
    return dataIn.reduce((acc, item) => {
        const fecha =
            (item._id.day != null ? `${item._id.day}/`.padStart(3, '0') : '') +
            `${item._id.month}/`.padStart(3, '0') +
            `${item._id.year % 100}`
        acc[fecha] = {
            litros: item.LITROS_TOTAL,
            grasa: item.GRASA,
            proteina: item.PROTEINA,
            RCS: item.RCS,
            UFC: item.UFC,
            urea: item.UREA,
        }
        return acc
    }, {})
}

const generateData = (dataNew, dataOld) => {
    const data_ = {
        litros: [],
        grasa: [],
        proteina: [],
        urea: [],
        UFC: [],
        RCS: [],
    }

    const dataNew_ = groupData(dataNew, false)
    const dataOld_ = groupData(dataOld, true)
    Object.keys(dataNew_).forEach(date => {
        const year = parseInt(date.slice(-2))
        const oldDate = date.slice(0, -2) + `${year - 1}`.padStart(2, '0')
        Object.keys(data_).forEach(key => {
            const currVal = dataNew_[date][key]
            const oldVal = dataOld_[oldDate] ? dataOld_[oldDate][key] : 0
            if (currVal !== null || oldVal !== 0) {
                data_[key].push({
                    name: date,
                    'año actual': Math.round(currVal * 100) / 100,
                    ...(oldVal !== 0 ? { 'año anterior': Math.round(oldVal * 100) / 100 } : {}),
                })
            }
        })
    })

    return data_
}

const VistaGeneralContextProvider = props => {
    const { errorMessage, setErrorMessage } = useContext(DataContext)
    const { predioFilter } = useContext(FilterContext)
    const [loadingCSV, setLoadingCSV] = useState(false)
    const [litrosTotales, setLitrosTotales] = useState({currentYear: 0, lastYear: 0})
    const [grasaTotal, setGrasaTotal] = useState({currentYear: 0, lastYear: 0})
    const [proteinaTotal, setProteinaTotal] = useState({currentYear: 0, lastYear: 0})
    const [dateSelected, setDateSelected] = useState('')
    /* const [solidosTotal, setSolidosTotal] = useState({currentYear: 0, lastYear: 0}) */
    const [generalData, setGeneralData] = useState({
        litros: [],
        grasa: [],
        proteina: [],
        urea: [],
        UFC: [],
        RCS: [],
    })

    const [maxValues, setMaxValues] = useState({
        grasa: 0,
        proteina: 0,
        litros: 0,
    })
    const [minValues, setMinValues] = useState({
        grasa: 0,
        proteina: 0,
        litros: 0,
    })

    const discardZeroes = data => {
        return [...data.map(item => item['año anterior']), ...data.map(item => item['año actual'])].filter(
            item => item > 0
        )
    }

    const [getData, { loading, error, data }] = useLazyQuery(GENERAL_VIEW_METRICS, {
        fetchPolicy: 'cache-and-network',
        notifyOnNetworkStatusChange: true,
    })

    const queryServer = (fechaInicio, fechaFin) => {
        const newStart = new Date(fechaInicio)
        const newEnd = new Date(fechaFin)
        const oldStart = new Date(fechaInicio)
        const oldEnd = new Date(fechaFin)

        oldStart.setFullYear(oldStart.getFullYear() - 1)
        oldEnd.setFullYear(oldEnd.getFullYear() - 1)

        console.log(newStart, newEnd, oldStart, oldEnd)

        getData({
            variables: {
                startLastYear: oldStart.toISOString(),
                endLastYear: oldEnd.toISOString(),
                startCurrentYear: newStart.toISOString(),
                endCurrentYear: newEnd.toISOString(),
                groupMonthly: newEnd - newStart > 150 * 24 * 60 * 60 * 1000, // 150 days
                producerCodes: predioFilter,
            },
        })
    }

    useEffect(() => {
        console.log(predioFilter)
    },[predioFilter])

    useEffect(() => {
        if (data) {
            const { currentYear, lastYear } = data
            console.log(currentYear, lastYear)
            if (!currentYear?.resultSAP ^ errorMessage) setErrorMessage(!currentYear?.resultSAP)

            const data_ = generateData(currentYear?.metrics, lastYear?.metrics)

            if (predioFilter.length === 1) {
                let litrosTotalesCurrent = 0
                let litrosTotalesLast = 0
                let grasaTotalCurrent = 0
                let grasaTotalLast = 0
                let proteinaTotalCurrent = 0
                let proteinaTotalLast = 0
    
                if (dateSelected==='dia') {
                    litrosTotalesCurrent = currentYear?.metrics[currentYear?.metrics.length - 1].LITROS_TOTAL + litrosTotalesCurrent
                    grasaTotalCurrent = currentYear?.metrics[currentYear?.metrics.length - 1].GRASA + grasaTotalCurrent
                    proteinaTotalCurrent = currentYear?.metrics[currentYear?.metrics.length - 1].PROTEINA + proteinaTotalCurrent
                    litrosTotalesLast = lastYear?.metrics[lastYear?.metrics.length - 1].LITROS_TOTAL + litrosTotalesLast
                    grasaTotalLast = lastYear?.metrics[lastYear?.metrics.length - 1].GRASA + grasaTotalLast
                    proteinaTotalLast = lastYear?.metrics[lastYear?.metrics.length - 1].PROTEINA + proteinaTotalLast
                console.log(litrosTotalesCurrent,
                    grasaTotalCurrent,
                    proteinaTotalCurrent,
                    litrosTotalesLast,
                    grasaTotalLast,
                    proteinaTotalLast)
                } else {
                    currentYear?.metrics.forEach((metric) => {
                        console.log(metric.GRASA)
                        litrosTotalesCurrent = metric.LITROS_TOTAL + litrosTotalesCurrent
                        grasaTotalCurrent = ((metric.GRASA*metric.LITROS_TOTAL)/1000) + grasaTotalCurrent
                        proteinaTotalCurrent = ((metric.PROTEINA*metric.LITROS_TOTAL)/1000) + proteinaTotalCurrent
                    })
        
                    lastYear?.metrics.forEach((metric) => {
                        litrosTotalesLast = metric.LITROS_TOTAL + litrosTotalesLast
                        grasaTotalLast = metric.GRASA + grasaTotalLast
                        proteinaTotalLast = metric.PROTEINA + proteinaTotalLast
                    })
                }
    
                setLitrosTotales({currentYear: litrosTotalesCurrent, lastYear: litrosTotalesLast})
                setGrasaTotal({currentYear: grasaTotalCurrent, lastYear: grasaTotalLast})
                setProteinaTotal({currentYear: proteinaTotalCurrent, lastYear: proteinaTotalLast})
            }

            const maxValueGrasa = Math.max(...discardZeroes(data_.grasa))
            const maxValueProteina = Math.max(...discardZeroes(data_.proteina))
            const maxValueLitros = Math.min(...discardZeroes(data_.litros))
            const minValueGrasa = Math.min(...discardZeroes(data_.grasa))
            const minValueProteina = Math.min(...discardZeroes(data_.proteina))
            const minValueLitros = Math.min(...discardZeroes(data_.litros))

            const litersMagnitude = magnitude(maxValueLitros)
            setGeneralData(data_)
            setMaxValues({
                grasa: maxValueGrasa + 0.1,
                proteina: maxValueProteina + 0.1,
                litros: Math.ceil(maxValueLitros / (litersMagnitude / 10)) * (litersMagnitude / 10),
            })
            setMinValues({
                grasa: minValueGrasa - 0.1,
                proteina: minValueProteina - 0.1,
                litros: Math.floor(minValueLitros / (litersMagnitude / 10)) * (litersMagnitude / 10),
            })
        }
    }, [data])

    useEffect(() => {
        setErrorMessage(error)
    }, [error])

    const downloadCSV = async () => {
        setLoadingCSV(true)
        let csvData = 'fecha; litros; grasa_gl; proteina_gl; urea; UFC; RCS\n'
        generalData.litros.forEach((item, index) => {
            csvData += `${item.name};${Object.keys(generalData)
                .map(key => generalData?.[key]?.[index]?.['año actual'] || 0)
                .join(';')}\n`
        })

        const blob = new Blob([csvData], { type: 'text/plainl;charset=utf-8' })
        saveAs(blob, 'VistaGeneral.csv')
        setLoadingCSV(false)
    }

    return (
        <VistaGeneralContext.Provider
            value={{
                generalData,
                queryServer,
                minValues,
                maxValues,
                loading,
                error,
                downloadCSV,
                loadingCSV,
                litrosTotales,
                grasaTotal,
                proteinaTotal,
                setDateSelected
            }}
        >
            {props.children}
        </VistaGeneralContext.Provider>
    )
}

export default VistaGeneralContextProvider
