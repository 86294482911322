/* React */
import React from 'react'

/* Material UI */
import { makeStyles, Button } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    root: {
        borderRadius: 12,
        padding: '15px 18px 15px 18px',
        height: 50,
        fontSize: 20,
        fontWeight: 'bold',
        border: '1px solid',
        textTransform: 'none',
        backgroundColor: 'white',
        '&:hover': {
            backgroundColor: '#f3f3f3',
        },
    },
}))

const ButtonVerTabla = ({ variant, color, size, text, onClick, width }) => {
    const classes = useStyles()

    return (
        <div>
            <Button
                variant={variant}
                size={size}
                onClick={() => onClick()}
                className={classes.root}
                style={{ color: color, minWidth: width || 100 }}
            >
                {text}
            </Button>
        </div>
    )
}

export default ButtonVerTabla
