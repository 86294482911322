import React, { useContext, useEffect, useState } from 'react'
import { FormControl, Grid, makeStyles, MenuItem, Select } from '@material-ui/core'
import { PredioFilter, ProductoresList } from '../../components/produccion/filter'
import { FilterContext } from '../../contexts/produccion'
import axios from 'axios'
import { useHistory } from 'react-router'
import { NuevoDatoPopover, NuevoDatoVacasPopover } from '../../modals'

const KpiEditor = () => {
    const history = useHistory()
    const [datos, setDatos] = useState([])
    const [datosVacas, setDatosVacas] = useState([])
    const { filtered } = useContext(FilterContext)
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorElVacas, setAnchorElVacas] = useState(null);
    const [elementData, setElementData] = useState()
    const [elementDataVacas, setElementDataVacas] = useState()
    const [tipoDatoSeleccionado, setTipoDatoSelecciondo] = useState('vacas')

    useEffect(() => {
        if (filtered.length > 0) {
            init()
        }
    },[filtered])

    const handleClick = (event) => {
        if (tipoDatoSeleccionado === 'vacas') {
            setAnchorElVacas(event.currentTarget)
        } else if (tipoDatoSeleccionado === 'hectareas') {
            setAnchorEl(event.currentTarget);
        }
    }

    useEffect(() => {
        init()
    }, [tipoDatoSeleccionado])
  
    const handleClose = () => {
      setAnchorEl(null)
      setAnchorElVacas(null)
      init()
    }
  
    const open = Boolean(anchorEl)
    const openVacaData = Boolean(anchorElVacas)
    const id = (open || openVacaData) ? 'simple-popover' : undefined

    const init = async () => {
        const salas = filtered.map(sala => { return sala._id})
        console.log(salas)
        if (tipoDatoSeleccionado === 'vacas') {
            const response = await axios.post('/kpi/getKpiVacasBySociedadId', {salas: salas})
            console.log(response.data)
            setDatosVacas(response.data.kpiVacas)
        } else {
            const response = await axios.post('/kpi/getKpiBySociedadId', {salas: salas})
            console.log(response.data)
            setDatos(response.data.kpi)
        }
    }

    const useStyles = makeStyles(theme => ({
        formControl: {
            margin: theme.spacing(1, 'auto'),
            '& .MuiInputBase-input': {
                background: 'white',
                fontSize: 16,
                fontWeight: 600,
                borderRadius: 12,
            },
            '& .MuiInputBase-root': {
                background: 'white',
                fontSize: 16,
                fontWeight: 600,
                borderRadius: 12,
            },
            '& .MuiOutlinedInput-root': {
                borderRadius: 12,
            },
            '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                borderWidth: 0,
            },
            '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
                transform: 'translate(14px, -14px) scale(0.75)',
            },
        },
    }))

    const selectTipoDato = (e) => {
        /* console.log(e.target.value.toString()) */
        setTipoDatoSelecciondo(e.target.value.toString())
    }

    const deleteKpi = async (_id) => {
        if (window.confirm('Confirme que se eliminará el dato. No se podrá recuperar.')) {
            const response = await axios.post('/kpi/deleteKpi', {_id: _id})
            console.log(response.data)
            init()
        }
    }

    const deleteKpiVacas = async (_id) => {
        if (window.confirm('Confirme que se eliminará el dato. No se podrá recuperar.')) {
            const response = await axios.post('/kpi/deleteKpiVacas', {_id: _id})
            console.log(response.data)
            init()
        }
    }
    
    const classes = useStyles()

    return (
        <div>
            <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                            <FormControl fullWidth variant='outlined' className={classes.formControl}>
                                <PredioFilter />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                            <FormControl fullWidth variant='outlined' style={{ marginLeft: 10 }} className={classes.formControl}>
                                <Select
                                    value={tipoDatoSeleccionado}
                                    onChange={selectTipoDato}
                                >
                                    <MenuItem value={'vacas'}>Vacas</MenuItem>
                                    <MenuItem value={'hectareas'}>Hectáreas</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12}>
                            <ProductoresList />
                            <br />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <div style={{padding: 10, borderRadius: 20, backgroundColor: 'white', width: '100%', height: 'calc(100vh - 300px)'}}>
                <button className='buttonNuevoDato' onClick={handleClick}>
                    Ingresar nuevo dato
                </button>
                <button className='buttonSalir' style={{marginLeft: 10}} onClick={() => { history.replace('/kpi')}}>
                    Salir del modo editor
                </button>
                <NuevoDatoPopover
                    id={id}
                    anchorEl={anchorEl}
                    filtered={filtered}
                    tipoDatoSeleccionado={tipoDatoSeleccionado}
                    elementData={elementData}
                    open={open}
                    handleClose={handleClose}
                    setElementData={setElementData}
                    isKPIPage={false}
                />
                <NuevoDatoVacasPopover
                    id={id}
                    anchorEl={anchorElVacas}
                    filtered={filtered}
                    tipoDatoSeleccionado={tipoDatoSeleccionado}
                    elementDataVacas={elementDataVacas}
                    openVacaData={openVacaData}
                    handleClose={handleClose}
                    setElementDataVacas={setElementDataVacas}
                    isKPIPage={false}
                />
                {(tipoDatoSeleccionado === 'vacas')
                ? 
                <div className='table'>
                    <div className='table-header'>
                        <div className='fecha'>
                            <p>Fecha Ini.</p>
                        </div>
                        <div className='fecha'>
                            <p>Fecha Térm.</p>
                        </div>
                        <div className='sala'>
                            <p>Sala</p>
                        </div>
                        <div className='v-ordenas'>
                            <p>Vacas Ordeñas</p>
                        </div>
                        <div className='v-preparto'>
                            <p>Vacas Preparto</p>
                        </div>
                        <div className='v-secas-prenadas'>
                            <p>Vacas Secas</p>
                        </div>
                        <div className='v-masa'>
                            <p>Vaca Masa</p>
                        </div>
                        <div className='acciones'>
                            <p>Acciones</p>
                        </div>
                    </div>
                    <div className='table-body'>
                        {
                            datosVacas.map((row, index) => {
                                console.log(row.sala)
                                return (
                                    <div key={index} className={'table-row'}>
                                        <div className='fecha lines'>
                                            <p>{new Date(row.fechaInicio).toISOString().split('T')[0]}</p>
                                        </div>
                                        <div className='fecha lines'>
                                            <p>{new Date(row.fechaTermino).toISOString().split('T')[0]}</p>
                                        </div>
                                        <div className='sala lines'>
                                            <p>{row.sala.producerCode}</p>
                                        </div>
                                        <div className='v-ordenas lines'>
                                            <p>{row.vOrdenas}</p>
                                        </div>
                                        <div className='v-preparto lines'>
                                            <p>{row.vReparto}</p>
                                        </div>
                                        <div className='v-secas-prenadas lines'>
                                            <p>{row.vSecas}</p>
                                        </div>
                                        <div className='v-masa lines'>
                                            <p>{row.vMasa}</p>
                                        </div>
                                        <div className='acciones lines-bottom'>
                                            <p style={{ width: '100%'}}>
                                                <img src={'/icons/pen.png'} className={'button-icon'} height={15} alt='edit' onClick={(e) => {handleClick(e); setElementDataVacas(row)}} />
                                                <img src={'/icons/close.png'} className={'button-icon'} style={{marginLeft: 25}} height={15} alt='close' onClick={() => { deleteKpiVacas(row._id) }} />
                                            </p>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                :
                <div className='table'>
                    <div className='table-header'>
                        <div className='fecha'>
                            <p>Fecha Ini.</p>
                        </div>
                        <div className='fecha'>
                            <p>Fecha Term.</p>
                        </div>
                        <div className='sala'>
                            <p>Sala</p>
                        </div>
                        <div className='h-totales'>
                            <p>Has totales</p>
                        </div>
                        <div className='h-pastoreo'>
                            <p>Has de pastoreo</p>
                        </div>
                        <div className='h-cultivo'>
                            <p>Has de cultivo</p>
                        </div>
                        <div className='h-recria'>
                            <p>Has de recría</p>
                        </div>
                        <div className='otros'>
                            <p>Otros</p>
                        </div>
                        <div className='acciones'>
                            <p>Acciones</p>
                        </div>
                    </div>
                    <div className='table-body'>
                        {
                            datos.map((row, index) => {
                                return (
                                    <div key={index} className={'table-row'}>
                                        <div className='fecha lines'>
                                            <p>{new Date(row.fechaInicio).toISOString().split('T')[0]}</p>
                                        </div>
                                        <div className='fecha lines'>
                                            <p>{new Date(row.fechaTermino).toISOString().split('T')[0]}</p>
                                        </div>
                                        <div className='sala lines'>
                                            <p>{row.sala.producerCode}</p>
                                        </div>
                                        <div className='h-totales lines'>
                                            <p>{row.hectareasTotales}</p>
                                        </div>
                                        <div className='h-pastoreo lines'>
                                            <p>{row.hectareasPastoreo}</p>
                                        </div>
                                        <div className='h-cultivo lines'>
                                            <p>{row.hectareasCultivo.total}</p>
                                        </div>
                                        <div className='h-recria lines'>
                                            <p>{row.hectareasRecria}</p>
                                        </div>
                                        <div className='otros lines'>
                                            <p>{row.otros}</p>
                                        </div>
                                        <div className='acciones lines-bottom'>
                                            <p style={{ width: '100%'}}>
                                                <img src={'/icons/pen.png'} className={'button-icon'} height={15} alt='edit' onClick={(e) => {handleClick(e); setElementData(row)}} />
                                                <img src={'/icons/close.png'} className={'button-icon'} style={{marginLeft: 25}} height={15} alt='close' onClick={() => { deleteKpi(row._id) }} />
                                            </p>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                
            }
            </div>
            <br />
            <br />
            <br />
        </div>
    )
}
export default KpiEditor