/* React */
import React from 'react'

const AvatarMaskMobile = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xlink="http://www.w3.org/1999/xlink"
        width="82"
        height="83"
        viewBox="0 0 82 83"
    >
        {/* <defs>
            <clipPath id="a">
                <ellipse cx="41" cy="41.5" rx="41" ry="41.5" fill="#00539b" />
            </clipPath>
        </defs>
        <g clipPath="url(#a)">
            <ellipse cx="41" cy="41.5" rx="41" ry="41.5" fill="#72bf44" />
            <ellipse cx="41.5" cy="42" rx="41.5" ry="42" transform="translate(4 3)" fill="#00539b" />
        </g> */}
    </svg>
)

export default AvatarMaskMobile
