/* React */
import React, { useContext } from 'react'

/* Material UI */
import { FormControl, Grid, IconButton, Input, InputAdornment, InputLabel, makeStyles } from '@material-ui/core'
import { Search } from '@material-ui/icons'

/* Context */
import { AdminFilterContext } from '../../contexts/administracion'

/* Components */
import { PrimaryButton } from '../comun'

const useStyles = makeStyles(theme => ({
    filters: {
        padding: '24px 16px 24px 16px',
    },
    hide: {
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
}))

const AdminFilter = ({ createButtonText, onClick, isOnlySearch, setIsCreate }) => {
    const { search, setSearch, setResetPage } = useContext(AdminFilterContext)
    const classes = useStyles()

    const handleOnChangeSearch = event => {
        setSearch(event.target.value)
        setResetPage(true)
    }

    const handleOnClick = event => {
        setIsCreate(true)
        onClick()
    }

    return (
        <Grid container spacing={1} className={classes.filters}>
            {!isOnlySearch ? (
                <Grid item xs={12} sm={4} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <PrimaryButton onClick={() => handleOnClick()}>{createButtonText}</PrimaryButton>
                </Grid>
            ) : (
                <Grid item xs={12} sm={4} className={classes.hide} />
            )}
            <Grid item xs={12} sm={8} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <FormControl style={{ display: 'flex', marginRight: 8, width: !isOnlySearch ? 570 : '100%' }}>
                    <InputLabel htmlFor="lookforfilter">Buscador</InputLabel>
                    <Input
                        type="text"
                        value={search}
                        onChange={event => handleOnChangeSearch(event)}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton aria-label="toggle password visibility">
                                    <Search />
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>
            </Grid>
        </Grid>
    )
}

export default AdminFilter
