/* React */
import React, { createContext, useEffect, /* useContext, useEffect, */ useState } from 'react'

/* Context */
// import { AuthContext } from '../'

export const FilterContext = createContext()

const currentDate = new Date()
currentDate.setHours(14, 59, 59, 999)

const previousDate = new Date()
previousDate.setDate(previousDate.getDate() - 7)
previousDate.setHours(0, 0, 0, 0)

const FilterContextProvider = props => {
    const [predioFilter, setPredioFilter] = useState([])
    const [filtered, setFiltered] = useState([])
    const [primer, setPrimer] = useState()
    const [initialFiltered, setInitialFiltered] = useState(false)
    const [temporalidadFilter, setTemporalidadFilter] = useState('')
    const [endTimeFilter, setEndTimeFilter] = useState(currentDate)
    const [startTimeFilter, setStartTimeFilter] = useState(previousDate)
    const [yearFilter, setYearFilter] = useState('')

    useEffect(() => {
        if (filtered.length > 0) {
            console.log(filtered[0])
            setPrimer(filtered[0])
        }
        if (filtered != null && filtered.length > 0) {
            setPredioFilter(filtered.filter(x => !x.isSociedad).map(filter => filter.code))
        }
    }, [filtered])

    /* useEffect(() => {
        console.log(predioFilter)
    }, [predioFilter]) */

    useEffect(() => {
        console.log(primer)
    },[primer])

    return (
        <FilterContext.Provider
            value={{
                primer,
                predioFilter,
                setPredioFilter,
                filtered,
                setFiltered,
                initialFiltered,
                setInitialFiltered,
                temporalidadFilter,
                setTemporalidadFilter,
                startTimeFilter,
                setStartTimeFilter,
                endTimeFilter,
                setEndTimeFilter,
                yearFilter,
                setYearFilter,
            }}
        >
            {props.children}
        </FilterContext.Provider>
    )
}

export default FilterContextProvider
