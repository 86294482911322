/* React */
import React, { useEffect, useState } from 'react'

/* Material UI */
import {
    Grid,
    Link,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    withStyles,
} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    paperGeneralCard: {
        // margin: theme.spacing(2, 'auto'),
        // padding: theme.spacing(2),
        borderRadius: 12,
        overflow: 'hidden',
    },
    chart: {
        height: 300,
        margin: '10px 20px',
        marginLeft: 0,
        [theme.breakpoints.up('lg')]: {
            margin: '10px 20px',
        },
    },
    headerGraph: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: 20,
        marginLeft: 20,
        marginRight: 20,
        [theme.breakpoints.up('lg')]: {
            marginLeft: 45,
        },
    },
    subHeaderGraph: {
        display: 'inline-block',
        marginLeft: 20,
        marginRight: 20,
        [theme.breakpoints.up('lg')]: {
            marginLeft: 45,
        },
    },
    tableHeadCell: {
        fontSize: 20,
        fontWeight: 'bold',
        backgroundColor: '#e4efff',
        borderBottom: 'none',
        borderRight: 'solid 1px #dfdfdf',
        borderLeft: 'solid 1px #dfdfdf',
        padding: '16px',
    },
    tableBodyCell: {
        fontSize: 20,
        borderBottom: 'none',
        borderRight: 'solid 1px #dfdfdf',
        borderLeft: 'solid 1px #dfdfdf',
        padding: '16px',
    },
}))

const headCells = [
    { id: 'date', label: 'Fecha' },
    { id: 'download', label: 'Descargar documento' },
]

const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: '#f9f9f9',
        },
    },
}))(TableRow)

const CartaLiquidaciones = ({ title, subtitle, data }) => {
    const classes = useStyles()
    const [parsedData, setParsedData] = useState([])

    const createData = point => {
        return {
            date: point.name,
            download: <Link href={`${point.link}`}>Descargar PDF</Link>,
        }
    }

    useEffect(() => {
        const newParsed = data.map(point => createData(point))
        setParsedData(newParsed)
    }, [data])

    return (
        <Paper elevation={0} className={classes.paperGeneralCard}>
            <Grid container>
                <Grid item xs={12}>
                    <div className={classes.headerGraph}>
                        <div style={{ display: 'inline-block' }}>
                            <span
                                style={{
                                    display: 'block',
                                    color: '#292929',
                                    fontSize: 32,
                                    fontWeight: 800,
                                }}
                            >
                                {title}
                            </span>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.subHeaderGraph}>
                        <span
                            style={{
                                display: 'block',
                                color: '#292929',
                                fontSize: 16,
                                fontWeight: 'normal',
                            }}
                        >
                            {subtitle}
                        </span>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <Table style={{ marginTop: 18 }}>
                        <TableHead>
                            <TableRow>
                                {headCells.map(head => {
                                    return (
                                        <TableCell
                                            className={classes.tableHeadCell}
                                            key={head.id}
                                            style={{ textAlign: 'center' }}
                                        >
                                            {head.label}
                                        </TableCell>
                                    )
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {parsedData.map((row, index) => {
                                return (
                                    <StyledTableRow key={index}>
                                        <TableCell className={classes.tableBodyCell} style={{ textAlign: 'center' }}>
                                            {row.date}
                                        </TableCell>
                                        <TableCell
                                            className={classes.tableBodyCell}
                                            style={{ textAlign: 'center', fontWeight: 'bold' }}
                                        >
                                            {row.download}
                                        </TableCell>
                                    </StyledTableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
        </Paper>
    )
}

export default CartaLiquidaciones
