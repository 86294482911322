/* React */
import React from 'react'

/* Material UI */
import { Grid, makeStyles, Paper } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    current: {
        borderRadius: 12,
        padding: 10,
    },
    projection: {
        borderRadius: 12,
        padding: 20,
    },
    embedIframe: {
        width: '100%',
        height: 900,
        minWidth: 1000,
    },
}))

const ClimaEmbed = props => {
    const classes = useStyles()
    return (
        <div>
            <Grid container spacing={2} style={{ marginTop: 8 }}>
                <Grid item xs={12}>
                    <Paper elevation={0} className={classes.projection}>
                        <div style={{ overflowX: 'auto' }}>
                            <div
                                style={{
                                    height: 900,
                                    overflow: 'hidden',
                                    minWidth: 1000,
                                    marginTop: -103,
                                }}
                            >
                                <iframe
                                    id="iframe"
                                    src="https://agrometeorologia.cl"
                                    /* scrolling="no" */
                                    className={classes.embedIframe}
                                ></iframe>
                            </div>
                        </div>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    )
}

export default ClimaEmbed
