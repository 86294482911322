import { Button, Fab, FormControl, InputLabel, MenuItem, Popover, Select, TextField } from "@material-ui/core"
import { Close } from "@material-ui/icons"
import axios from "axios"
import React, { useEffect, useState } from "react"

const NuevoDatoPopover = ({id, anchorEl, filtered, elementData, open, handleClose, setElementData, tipoDatoSeleccionado, toModoEditor, isKPIPage}) => {
    const [idData, setId] = useState()
    const [fechaInicio, setFechaInicio] = useState('')
    const [fechaTermino, setFechaTermino] = useState('')
    const [hTotales, setHTotales] = useState(0)
    const [hPastoreo, setHPastoreo] = useState(0)
    const [hRecria, setHRecria] = useState(0)
    const [hCultivo, setHCultivo] = useState(0)
    const [otros, setOtros] = useState(0)
    const [maizEnsilaje, setMaizEnsilaje] = useState(0)
    const [alfalfa, setAlfalfa] = useState(0)
    const [achicoria, setAchicoria] = useState(0)
    const [triticale, setTriticale] = useState(0)
    const [avenaForrajera, setAvenaForrajera] = useState(0)
    const [rapsForrajero, setRapsForrajero] = useState(0)
    const [naboForrajero, setNaboForrajero] = useState(0)
    const [colForrajero, setColForrajero] = useState(0)
    const [remolachaForrajero, setRemolachaForrajero] = useState(0)
    const [rutubagaForrajero, setRutubagaForrajero] = useState(0)
    const [otrosCereales, setOtrosCereales] = useState(0)
    const [otrasLegumbres, setOtrasLegumbres] = useState(0)
    const [otrosDatos, setOtrosDatos] = useState(0)
    const [salaSeleccionada, setSalaSeleccionada] = useState('')
    const [listadoTipos, setListadoTipos] = useState([])
    const [dataFiltered, setDataFiltered] = useState([])

    useEffect(() => {
        const res = maizEnsilaje + alfalfa + achicoria + triticale + avenaForrajera + rapsForrajero + naboForrajero + colForrajero + remolachaForrajero + rutubagaForrajero + otrosCereales + otrasLegumbres
        if (res === 0) {
            setOtrosDatos(hCultivo)
        }
        if ((res > 0) && (res < hCultivo)) {
            setOtrosDatos(hCultivo - res)
        }
        if (hCultivo.toString().length > 1)
        if (res > hCultivo) {
            setMaizEnsilaje(0)
            setAlfalfa(0)
            setAchicoria(0)
            setTriticale(0)
            setAvenaForrajera(0)
            setRapsForrajero(0)
            setNaboForrajero(0)
            setColForrajero(0)
            setRemolachaForrajero(0)
            setRutubagaForrajero(0)
            setOtrosCereales(0)
            setOtrasLegumbres(0)
            setOtrosDatos(hCultivo)
        }
    }, [hCultivo])

    useEffect(() => {
        if (filtered.length > 0) {
            const filteredCache = [...filtered]
            setDataFiltered(filteredCache.sort((a, b) => {
                if (a.code<b.code) {
                    return -1
                } else if (a.code > b.code) {
                    return 1
                } else {
                    return 0
                }
            }))
        }
    }, [filtered])

    useEffect(() => {
        console.log(elementData, open)
        if (elementData) {
            setId(elementData._id)
            const fechaI = new Date(elementData.fechaInicio)
            const fechaT = new Date(elementData.fechaTermino)
            const mInicio = ((fechaI.getMonth()+ 1) > 9) ? (fechaI.getMonth() + 1) : `0${fechaI.getMonth() + 1}`
            const mFinal = ((fechaT.getMonth()+1) > 9) ? (fechaT.getMonth() + 1) : `0${fechaT.getMonth() + 1}`
            setFechaInicio(fechaI.getFullYear() + "-" + mInicio + "-" + fechaI.getDate())
            setFechaTermino(fechaT.getFullYear() + "-" + mFinal + "-" + fechaT.getDate())
            setHTotales(Number(elementData.hectareasTotales))
            setHPastoreo(Number(elementData.hectareasPastoreo))
            setHRecria(Number(elementData.hectareasRecria))
            setHCultivo(Number(elementData.hectareasCultivo.total))
            setOtros(Number(elementData.otros))
            setListadoTipos(elementData.hectareasCultivo.listadoTipos)
            setSalaSeleccionada(elementData.sala._id)
        } else {
            setFechaInicio('')
            setFechaTermino('')
            setHTotales(0)
            setHPastoreo(0)
            setHRecria(0)
            setHCultivo(0)
            setOtros(0)
            setListadoTipos([])
            setSalaSeleccionada('')
            setMaizEnsilaje(0)
            setAlfalfa(0)
            setAchicoria(0)
            setTriticale(0)
            setAvenaForrajera(0)
            setRapsForrajero(0)
            setNaboForrajero(0)
            setColForrajero(0)
            setRemolachaForrajero(0)
            setRutubagaForrajero(0)
            setOtrosCereales(0)
            setOtrasLegumbres(0)
            setOtrosDatos(0)
        }
    }, [open])

    useEffect(() => {
        if (listadoTipos.length > 0) {
            setMaizEnsilaje(Number(listadoTipos[0].maizEnsilaje))
            setAlfalfa(Number(listadoTipos[1].alfalfa))
            setAchicoria(Number(listadoTipos[2].achicoria))
            setTriticale(Number(listadoTipos[3].triticale))
            setAvenaForrajera(Number(listadoTipos[4].avenaForrajera))
            setRapsForrajero(Number(listadoTipos[5].rapsForrajero))
            setNaboForrajero(Number(listadoTipos[6].naboForrajero))
            setColForrajero(Number(listadoTipos[7].colForrajero))
            setRemolachaForrajero(Number(listadoTipos[8].remolachaForrajero))
            setRutubagaForrajero(Number(listadoTipos[9].rutubagaForrajero))
            setOtrosCereales(Number(listadoTipos[10].otrosCereales))
            setOtrasLegumbres(Number(listadoTipos[11].otrasLegumbres))
            setOtrosDatos(Number(listadoTipos[12].otrosDatos))
        }
    }, [listadoTipos])

    const getNumber = (e) => {
        const {value, name} = e.target
        console.log(value, name)
        if (name === 'fechaInicio') {
            setFechaInicio(value)
        } else if (name === 'fechaTermino') {
            setFechaTermino(value)
        } else if (name === 'hTotales') {
            setHTotales(Math.abs((value.includes('-')) ? 0 : value))
        } else if (name === 'hPastoreo') {
            setHPastoreo(Math.abs((value.includes('-')) ? 0 : value))
        } else if (name === 'hRecria') {
            setHRecria(Math.abs((value.includes('-')) ? 0 : value))
        } else if (name === 'hCultivo') {
            setHCultivo(Math.abs((value.includes('-')) ? 0 : value))
        } else if (name === 'otros') {
            setOtros(Math.abs((value.includes('-')) ? 0 : value))
        } else if (name === 'maizEnsilaje') {
            setMaizEnsilaje(Math.abs((value.includes('-')) ? 0 : value))
        } else if (name === 'alfalfa') {
            setAlfalfa(Math.abs((value.includes('-')) ? 0 : value))
        } else if (name === 'achicoria') {
            setAchicoria(Math.abs((value.includes('-')) ? 0 : value))
        } else if (name === 'triticale') {
            setTriticale(Math.abs((value.includes('-')) ? 0 : value))
        } else if (name === 'avenaForrajera') {
            setAvenaForrajera(Math.abs((value.includes('-')) ? 0 : value))
        } else if (name === 'rapsForrajero') {
            setRapsForrajero(Math.abs((value.includes('-')) ? 0 : value))
        } else if (name === 'naboForrajero') {
            setNaboForrajero(Math.abs((value.includes('-')) ? 0 : value))
        } else if (name === 'colForrajero') {
            setColForrajero(Math.abs((value.includes('-')) ? 0 : value))
        } else if (name === 'remolachaForrajero') {
            setRemolachaForrajero(Math.abs((value.includes('-')) ? 0 : value))
        } else if (name === 'rutubagaForrajero') {
            setRutubagaForrajero(Math.abs((value.includes('-')) ? 0 : value))
        } else if (name === 'otrosCereales') {
            setOtrosCereales(Math.abs((value.includes('-')) ? 0 : value))
        } else if (name === 'otrasLegumbres') {
            setOtrasLegumbres(Math.abs((value.includes('-')) ? 0 : value))
        } else if (name === 'otrosDatos') {
            setOtrosDatos(Math.abs((value.includes('-')) ? 0 : value))
        }
    }

    const saveKpi = async () => {
        if (hTotales > 0) {
            if (fechaInicio.length > 0) {
                if (salaSeleccionada.length > 0) {
                    const kpi = {
                        _id: idData,
                        fechaInicio: fechaInicio,
                        fechaTermino: fechaTermino,
                        hectareasTotales: hTotales,
                        hectareasPastoreo: hPastoreo,
                        hectareasRecria: hRecria,
                        hectareasCultivo: {
                            listadoTipos: [
                                {maizEnsilaje: maizEnsilaje},
                                {alfalfa: alfalfa},
                                {achicoria: achicoria},
                                {triticale: triticale},
                                {avenaForrajera: avenaForrajera},
                                {rapsForrajero: rapsForrajero},
                                {naboForrajero: naboForrajero},
                                {colForrajero: colForrajero},
                                {remolachaForrajero: remolachaForrajero},
                                {rutubagaForrajero: rutubagaForrajero},
                                {otrosCereales: otrosCereales},
                                {otrasLegumbres: otrasLegumbres},
                                {otrosDatos: otrosDatos}
                            ],
                            total: hCultivo
                        },
                        otros: otros,
                        sala: salaSeleccionada,
                        tipo: tipoDatoSeleccionado,
                    }
                    console.log(kpi)
                    const response = await axios.post('/kpi/saveKpi', {kpi: kpi})
                    console.log(response)
                    handleClose()
                } else {
                    alert('Debe seleccionar una sala')
                }
            } else {
                alert('La fecha de inicio es obligatoria.')
            }
        } else {
            alert('Las Hectáreas totales debe ser mayor a 0')
        }
    }

    useEffect(() => {
        const total = Number(maizEnsilaje) + Number(alfalfa) + Number(achicoria) + Number(triticale) + Number(avenaForrajera) + Number(rapsForrajero) + Number(naboForrajero) + Number(colForrajero) + Number(remolachaForrajero) + Number(rutubagaForrajero) + Number(otrosCereales) + Number(otrasLegumbres) + Number(otrosDatos)
        setHCultivo(total)
    },[
        maizEnsilaje,
        alfalfa,
        achicoria,
        triticale,
        avenaForrajera,
        rapsForrajero,
        naboForrajero,
        colForrajero,
        remolachaForrajero,
        rutubagaForrajero,
        otrosCereales,
        otrasLegumbres,
        otrosDatos
    ])

    useEffect(() => {
        const total = Number(hPastoreo) + Number(hRecria) + Number(otros) + Number(hCultivo)
        setHTotales(total)
    }, [hPastoreo, hRecria, otros, hCultivo])

    return (
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            /* onClose={() => {handleClose(); setElementData(undefined)}} */
            anchorOrigin={{
                vertical: 'center',
                horizontal: 'center',
            }}
            >
            <div className={ 'popover-container-areas'} style={{position: 'relative'}}>
                <Fab onClick={() => {handleClose(); setElementData(undefined)}} size="small" color="secondary" aria-label="add" style={{position: 'absolute', top: 10, right: 10}}>
                    <Close />
                </Fab>
                <div className={'popover-container'}>
                    <div style={{ width: '100%', display: 'inline-block', padding: 5 }}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Seleccione Sala</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={salaSeleccionada}
                            onChange={(e) => { setSalaSeleccionada(e.target.value) }}
                        >
                            {
                                dataFiltered.map((el, i) => {
                                    return (
                                        <MenuItem key={i} value={el._id}>{el.code} - {el.name}</MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>
                    </div>
                    <div style={{ width: '50%', display: 'inline-block', padding: 5 }}>
                        <p>Fecha inicio</p>
                        <TextField onClick={(e) => {e.target.value = ''}} value={fechaInicio} onChange={getNumber} name={'fechaInicio'} variant='outlined' type={'date'} style={{ width: '100%' }}/>
                    </div>
                    <div style={{ width: '50%', display: 'inline-block', padding: 5 }}>
                        <p>Fecha Término</p>
                        <TextField onClick={(e) => {e.target.value = ''}} value={fechaTermino} onChange={getNumber} name={'fechaTermino'} variant='outlined' type={'date'} style={{ width: '100%' }}/>
                    </div>
                    <br />
                    <div style={{ width: '50%', display: 'inline-block', padding: 5 }}>
                        <TextField disabled={true} color={'secondary'} onClick={(e) => {e.target.value = ''}} value={hTotales} onChange={getNumber} name={'hTotales'} variant='outlined' label={'Hectáreas Totales'} type={'text'} style={{ width: '100%' }}/>
                    </div>
                    <div style={{ width: '50%', display: 'inline-block', padding: 5 }}>
                        <TextField onClick={(e) => {e.target.value = ''}} value={hPastoreo} onChange={getNumber} name={'hPastoreo'} variant='outlined' label={'Hectáreas de pastoreo'} type={'text'} style={{ width: '100%' }}/>
                    </div>
                    <br />
                    <div style={{ width: '50%', display: 'inline-block', padding: 5 }}>
                        <TextField onClick={(e) => {e.target.value = ''}} value={hRecria} onChange={getNumber} name={'hRecria'} variant='outlined' label={'Hectáreas para recría'} type={'text'} style={{ width: '100%' }}/>
                    </div>
                    <div style={{ width: '50%', display: 'inline-block', padding: 5 }}>
                        <TextField onClick={(e) => {e.target.value = ''}} value={otros} onChange={getNumber} name={'otros'} variant='outlined' label={'Otros'} type={'text'} style={{ width: '100%' }}/>
                    </div>
                    <div style={{ width: '100%', display: 'inline-block', padding: 5 }}>
                        <TextField disabled={true} onClick={(e) => {e.target.value = ''}} value={hCultivo} onChange={getNumber} name={'hCultivo'} variant='outlined' label={'Hectáreas de cultivo (Suma de hectáreas de cultivo)'} type={'text'} style={{ width: '100%' }}/>
                    </div>
                </div>
                <div style={{ display: 'inline-block', width: '400px' }}>
                    <div>
                        <h3>Hectáreas de cultivo</h3>
                    </div>
                    <div style={{ width: '50%', display: 'inline-block', padding: 5 }}>
                        <TextField onClick={(e) => {e.target.value = ''}} value={maizEnsilaje} onChange={getNumber} name={'maizEnsilaje'} size={'small'} variant='outlined' label={'Maíz ensilaje'} type={'text'} style={{ width: '100%' }}/>
                    </div>
                    <div style={{ width: '50%', display: 'inline-block', padding: 5 }}>
                        <TextField onClick={(e) => {e.target.value = ''}} value={alfalfa} onChange={getNumber} name={'alfalfa'} size={'small'} variant='outlined' label={'Alfalfa'} type={'text'} style={{ width: '100%' }}/>
                    </div>
                    <div style={{ width: '50%', display: 'inline-block', padding: 5 }}>
                        <TextField onClick={(e) => {e.target.value = ''}} value={achicoria} onChange={getNumber} name={'achicoria'} size={'small'} variant='outlined' label={'Achicoria'} type={'text'} style={{ width: '100%' }}/>
                    </div>
                    <div style={{ width: '50%', display: 'inline-block', padding: 5 }}>
                        <TextField onClick={(e) => {e.target.value = ''}} value={triticale} onChange={getNumber} name={'triticale'} size={'small'} variant='outlined' label={'Triticale'} type={'text'} style={{ width: '100%' }}/>
                    </div>
                    <div style={{ width: '50%', display: 'inline-block', padding: 5 }}>
                        <TextField onClick={(e) => {e.target.value = ''}} value={avenaForrajera} onChange={getNumber} name={'avenaForrajera'} size={'small'} variant='outlined' label={'Avena forrajera'} type={'text'} style={{ width: '100%' }}/>
                    </div>
                    <div style={{ width: '50%', display: 'inline-block', padding: 5 }}>
                        <TextField onClick={(e) => {e.target.value = ''}} value={rapsForrajero} onChange={getNumber} name={'rapsForrajero'} size={'small'} variant='outlined' label={'Raps forrajero'} type={'text'} style={{ width: '100%' }}/>
                    </div>
                    <div style={{ width: '50%', display: 'inline-block', padding: 5 }}>
                        <TextField onClick={(e) => {e.target.value = ''}} value={naboForrajero} onChange={getNumber} name={'naboForrajero'} size={'small'} variant='outlined' label={'Nabo forrajero'} type={'text'} style={{ width: '100%' }}/>
                    </div>
                    <div style={{ width: '50%', display: 'inline-block', padding: 5 }}>
                        <TextField onClick={(e) => {e.target.value = ''}} value={colForrajero} onChange={getNumber} name={'colForrajero'} size={'small'} variant='outlined' label={'Col forrajero'} type={'text'} style={{ width: '100%' }}/>
                    </div>
                    <div style={{ width: '50%', display: 'inline-block', padding: 5 }}>
                        <TextField onClick={(e) => {e.target.value = ''}} value={remolachaForrajero} onChange={getNumber} name={'remolachaForrajero'} size={'small'} variant='outlined' label={'Remolacha forrajera'} type={'text'} style={{ width: '100%' }}/>
                    </div>
                    <div style={{ width: '50%', display: 'inline-block', padding: 5 }}>
                        <TextField onClick={(e) => {e.target.value = ''}} value={rutubagaForrajero} onChange={getNumber} name={'rutubagaForrajero'} size={'small'} variant='outlined' label={'Rutabaga forrajera'} type={'text'} style={{ width: '100%' }}/>
                    </div>
                    <div style={{ width: '50%', display: 'inline-block', padding: 5 }}>
                        <TextField onClick={(e) => {e.target.value = ''}} value={otrosCereales} onChange={getNumber} name={'otrosCereales'} size={'small'} variant='outlined' label={'Otros cereales'} type={'text'} style={{ width: '100%' }}/>
                    </div>
                    <div style={{ width: '50%', display: 'inline-block', padding: 5 }}>
                        <TextField onClick={(e) => {e.target.value = ''}} value={otrasLegumbres} onChange={getNumber} name={'otrasLegumbres'} size={'small'} variant='outlined' label={'Otras legumbres'} type={'text'} style={{ width: '100%' }}/>
                    </div>
                    <div style={{ width: '50%', display: 'inline-block', padding: 5 }}>
                        <TextField onClick={(e) => {e.target.value = ''}} value={otrosDatos} onChange={getNumber} name={'otrosDatos'} size={'small'} variant='outlined' label={'Otros'} type={'text'} style={{ width: '100%' }}/>
                    </div>
                </div>
            </div>
            <div style={{ width: '100%', padding: 10 }}>
                <Button variant={'contained'} color={'primary'} style={{ width: '100%' }} onClick={saveKpi}>
                    Guardar Dato
                </Button>
            </div>
            {isKPIPage && <div style={{ width: '100%', padding: 10 }}>
                <Button variant={'contained'} color={'primary'} style={{ width: '100%' }} onClick={toModoEditor}>
                    KPI Modo Editor
                </Button>
            </div>}
        </Popover>
    )
}

export default NuevoDatoPopover