/* GraphQL */
import { gql } from '@apollo/client'

export const HORARIOS = gql`
    query Horarios($horariosIds: [ID]) {
        horarios(horariosIds: $horariosIds) {
            _id
            duracion
            horaInicio
            horaTermino
            horaCold
            timeInicio {
                hour
                min
            }
            timeTermino {
                hour
                min
            }
            timeCold {
                hour
                min
            }
            enabled
            createdAt
            updatedAt
        }
    }
`

export const EDIT_HORARIO = gql`
    mutation Horarios(
        $horarioId: ID!
        $inicioHour: Int
        $inicioMin: Int
        $terminoHour: Int
        $terminoMin: Int
        $coldHour: Int
        $coldMin: Int
        $enabled: Boolean
    ) {
        editHorario(
            horarioId: $horarioId
            inicioHour: $inicioHour
            inicioMin: $inicioMin
            terminoHour: $terminoHour
            terminoMin: $terminoMin
            coldHour: $coldHour
            coldMin: $coldMin
            enabled: $enabled
        ) {
            _id
            duracion
            horaInicio
            horaTermino
            horaCold
            timeInicio {
                hour
                min
            }
            timeTermino {
                hour
                min
            }
            timeCold {
                hour
                min
            }
            enabled
            createdAt
            updatedAt
        }
    }
`

export const CREATE_HORARIO = gql`
    mutation Horarios(
        $inicioHour: Int
        $inicioMin: Int
        $terminoHour: Int
        $terminoMin: Int
        $coldHour: Int
        $coldMin: Int
        $enabled: Boolean
    ) {
        createHorario(
            inicioHour: $inicioHour
            inicioMin: $inicioMin
            terminoHour: $terminoHour
            terminoMin: $terminoMin
            coldHour: $coldHour
            coldMin: $coldMin
            enabled: $enabled
        ) {
            _id
            duracion
            horaInicio
            horaTermino
            horaCold
            timeInicio {
                hour
                min
            }
            timeTermino {
                hour
                min
            }
            timeCold {
                hour
                min
            }
            enabled
            createdAt
            updatedAt
        }
    }
`

export const DELETE_HORARIO = gql`
    mutation Horarios($horarioId: ID!) {
        deleteHorario(horarioId: $horarioId) {
            _id
            duracion
            horaInicio
            horaTermino
            horaCold
            timeInicio {
                hour
                min
            }
            timeTermino {
                hour
                min
            }
            timeCold {
                hour
                min
            }
            enabled
            createdAt
            updatedAt
        }
    }
`
