/* React */
import React from 'react'

/* Material UI */
import { SvgIcon } from '@material-ui/core'

const FodderIcon = props => (
    <SvgIcon {...props} viewBox="0 0 35.623 26.768">
        <path
            d="M387.44,396.514c-.279-.018-10.778-.025-10.883-.025v.026c-.243-.015-.488-.025-.734-.025-6.218,0-11.378,4.985-12.247,11.453l-.016,0a13.265,13.265,0,0,0-.115,1.738c0,.039,0,.076,0,.114s0,.054,0,.081c0,7.38,5.552,13.384,12.377,13.384a11.535,11.535,0,0,0,1.323-.077l0,.018c.438.045,9.457.059,9.547.059,6.825,0,12.377-6,12.377-13.384C399.068,402.8,393.961,396.935,387.44,396.514ZM371.79,420.427c.186.014.373.024.562.024,4.9,0,8.88-4.8,8.906-10.716l.033-.135-.032-.12c0-.27-.014-.538-.038-.793v0h0v-.012c-.346-3.756-2.99-6.588-6.151-6.588s-5.8,2.829-6.149,6.581l1.932.18c.256-2.748,2.069-4.821,4.218-4.821s3.963,2.072,4.218,4.826c.019.248.029.495.03.735l-.006.119.007.026c-.022,4.847-3.138,8.783-6.966,8.783-3.8,0-6.924-3.916-6.965-8.734.047-6.266,4.709-11.348,10.435-11.348,5.755,0,10.437,5.133,10.437,11.444s-4.682,11.444-10.437,11.444A9.6,9.6,0,0,1,371.79,420.427Zm14.9.889c-.342,0-2.361,0-4.438-.012a13.96,13.96,0,0,0-.009-22.869c2.483,0,4.878.009,5.071.015,5.5.355,9.813,5.373,9.813,11.423C397.128,416.183,392.446,421.317,386.691,421.317Z"
            transform="translate(-363.445 -396.489)"
        />
    </SvgIcon>
)

export default FodderIcon
